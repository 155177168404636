<template src="./shiftRequestsCaregiverRatingModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

export default {
    name: 'shift-request-caregiver-rating-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            shiftDto: {},
            ratingDto: {},
            mode: "",
            ratingList: [1, 2, 3, 4, 5],
        }
    },
    computed: {
        lblTitle() {
            if (this.mode === "add" || this.mode === "forceClockOut"){
                return 'Give a Rating: ' + this.shiftDto.CaregiverName;
            } else if (this.mode === "edit") {
                return 'Edit Rating: ' + this.shiftDto.CaregiverName;
            }
        },
    },
    methods: {
        forceClockOut(){           
            this.ratingDto.ShiftID = this.shiftDto.ShiftID;
            this.ratingDto.OrganizationID = this.shiftDto.OrganizationID;

            this.$store
                .dispatch('ShiftModule/forceShiftClockOutByID', this.ratingDto)
                    .then(() => {
                        this.shouldModalStayOpen = false;
                        this.successClose(this.ratingDto);
                    });  
        },
        btnSaveOnClick() {
            if (this.mode === "add"){
                this.ratingDto.ShiftID = this.shiftDto.ShiftID;
                this.ratingDto.OrganizationID = this.shiftDto.OrganizationID;
                this.$store
                    .dispatch('AdminCaregiverModule/createCaregiverRating', this.ratingDto)
                        .then(() => {
                            this.shouldModalStayOpen = false;
                            this.successClose(this.ratingDto);
                        }); 
            } else if (this.mode === "edit") {
                this.$store
                    .dispatch('AdminCaregiverModule/updateCaregiverRating', this.ratingDto)
                        .then(() => {
                            this.shouldModalStayOpen = false;
                            this.successClose(this.ratingDto);
                        }); 
            } else if (this.mode === "forceClockOut") {
                    const message = 'Are you sure you want to Force Clock Out? This will Close the Shift and prevent the Caregiver from Clocking In.';
                    const title = 'Confirm';
                    const confirmCallback = this.forceClockOut;

                    DMUtils.openApplicationModal('dmConfirmModal', {
                        title: title,
                        message: message,
                        confirmCallback: confirmCallback,
                        confirmText: 'Yes',
                        denyText: 'Cancel',
                    });
            }              
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.mode = DMUtils.copy(this.modalData.mode);
        this.shiftDto = DMUtils.copy(this.modalData.shiftDto);   
        if (this.mode === 'edit'){
            this.ratingDto = DMUtils.copy(this.modalData.ratingDto);   
        }  
    },
}
</script>
