export default {
    // Config like options here
    repoName: 'myshyft-admin-organization-frontend',
    unreadMessagesTimer: null,
    formatDateDisplay: (date, timezone) => {
        return moment(date).tz(timezone).format('MMM DD, YYYY');
    },
    formatTimeDisplay: (date, timezone) => {
        return moment(date).tz(timezone).format('h:mma');
    },
    openURL(url) {
        if(url.includes('mailto')) {
            if(!!window.cordova) {
                cordova.InAppBrowser.open(url, '_system');
            } else {
                window.location.href = url;
            }
        } else {
            if(!!window.cordova) {
                cordova.InAppBrowser.open(url, '_system');
            } else {
                window.open(url, '_blank');
            }
        }
    },
    resizeImage(reader, data, propertyName, size) {
        let blobBase64 = reader.result;

        let image = new Image();
        image.onload = () => {
            let canvas = document.createElement('canvas');

            let maxHeight = size;
            let maxWidth = size;
            let ratio = 0;

            let width = image.width;
            let height = image.height;

            if (width > height) {
                ratio = width / height;
                if (width > maxWidth) {
                    height = Math.round(maxWidth / ratio);
                    width = maxWidth;
                }
            } else if (height > width) {
                ratio = height / width;
                if (height > maxHeight) {
                    width = Math.round(maxHeight / ratio);
                    height = maxHeight;
                }
            } else {
                width = maxWidth;
                height = maxHeight;
            }

            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, width, height);
            let resized = canvas.toDataURL('image/jpeg');

            let base64Length = resized.length - 'data:image/jpeg;base64,'.length;
            let sizeInBytes = base64Length * (3/4);
            let sizeInMb = sizeInBytes / 1048576;

            if (sizeInMb > 2.0){
                DMUtils.alertShow(
                    'The selected file is too large.'
                );
                return;
            }

            data[propertyName] = resized;
        };

        image.src = blobBase64;
    },
    fileSizeTooLarge(fileSizeInBytes) {
        return fileSizeInBytes > 2097152;
    },
    formatBytes(base64) {
        let base64Length = base64.length - 'data:image/jpeg;base64,'.length;
        let bytes = base64Length * (3/4);

        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = 0
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    getUnreadMessageCount(userRole) {
        endpoints.getUserProfile(true, true)
            .then(response => {
                if (userRole === 'MyShyftAdmin' || userRole === 'MyShyftEmployee' || userRole === 'MyShyftViewer') {
                    app.displayUnreadMessageCount(response.NumberOfNewAdminMessages);
                } else {
                    app.displayUnreadMessageCount(response.NumberOfNewMessages);
                }
            });
    },
    getUnreadMessageCountAndStartTimer(userRole) {
        app.getUnreadMessageCount(userRole);
        app.unreadMessagesTimer = setInterval(() => {
            if(document.hasFocus()) { // Don't keep getting messages in the background
                app.getUnreadMessageCount(userRole);
            }
        }, 60 * 1000);
    },
    stopUnreadMessagesTimer() {
        clearInterval(app.unreadMessagesTimer);
    },
    displayUnreadMessageCount(count) {
        if(count === 0) {
            let unreadMessageIcon = document.querySelector('.unread-messages-icon');
            if(unreadMessageIcon) {
                unreadMessageIcon.remove();
            }
            return;
        }

        let countLabel = count;
        if(count >= 99) {
            countLabel = '99';
        }

        let unreadMessageCount = document.querySelector('.unread-message-count');
        if(unreadMessageCount) {
            unreadMessageCount.innerHTML = countLabel;
        } else {
            
            let unreadMessageIcon = document.createElement('div');
            unreadMessageIcon.classList.add('unread-messages-icon');
            let unreadMessageCount = document.createElement('div');
            unreadMessageCount.classList.add('unread-message-count');
            unreadMessageCount.innerHTML = countLabel;
            unreadMessageIcon.append(unreadMessageCount);
            let commentIcon = document.querySelector('.fa-comment');
            commentIcon.parentElement.append(unreadMessageIcon);
        }
    },
    organizationLocationTerminology(category, lowerCase, plural) {
        let term = '';
        if (category === 'Facility') {
            term =  'Building';
        } else if (category === 'Agency') {
            term = 'Patient';
        }

        if (lowerCase === true) {
            term = term.toLowerCase();
        }

        if (plural === true) {
            term = term + 's';
        }

        return term;
    }
};

