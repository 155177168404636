<template src="./shiftApplicantsModal.html"></template>

<script>
import Vue from 'vue'
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

export default {
    name: 'shift-applicants-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            dto: {},
            viewShiftApplicantsTableColumns: [],
            selectShiftApplicantsTableColumns: [],
            viewShiftApplicantsTableSortBy: {
                columns: [
                    { field: 'CreatedUtc', direction: 'Descending' },
                ]
            },
            selectShiftApplicantsTableSortBy: {
                columns: [
                    { field: 'ShiftHourlyRate', direction: 'Ascending' },
                ]
            },
            selectShiftApplicantsTableActions: [
                {
                    text: 'View Caregiver Profile'
                },
                {
                    text: 'Assign'
                },
                {
                    text: 'Decline'
                },
                {
                    text: 'Favorite'
                },
                {
                    text: 'Block'
                },
                {
                    text: 'View Chat'
                }
            ],
            confirmShiftApplicantsTableActions: [
                {
                    text: 'View Caregiver Profile'
                },
                {
                    text: 'Unconfirm'
                },
                {
                    text: 'Favorite'
                },
                {
                    text: 'Block'
                },
                {
                    text: 'View Chat'
                }
            ],
            historyShiftApplicantsTableActions: [
                {
                    text: 'View Caregiver Profile'
                },
                {
                    text: 'Favorite'
                },
                {
                    text: 'Block'
                },
                {
                    text: 'View Chat'
                }
            ],
            assignedShiftApplicantsTableActions: [
                {
                    text: 'View Caregiver Profile'
                },
                {
                    text: 'Unassign'
                },
                {
                    text: 'Favorite'
                },
                {
                    text: 'Block'
                },
                {
                    text: 'View Chat'
                }
            ],
            viewShiftApplicantsTableCustomClass: 'dm-bg-color-black',          
            selectedRow: null,
            hourlyRateMarkup: 0
        }
    },
    computed: {
        lblTitle() {
            return 'Applicants';
        },
        mode() {
            return this.modalData.mode;
        },
        viewShiftApplicantsTableItems() {
            return this.$store.state.ShiftModule.AllShiftApplicants;
        },
    },
    methods: {      
        btnCloseOnClick() {
            this.closeModal();
        },
        shiftApplicantsTableRowOnClick(args) {
            this.selectedRow = args;
        },
        viewCaregiverProfile(args) {
            this.$store
                .dispatch('ShiftModule/getShiftApplicantByID', this.selectedRow.rowData.ShiftApplicantID)
                    .then((shiftApplicantResponse) => {
                        this.$store
                            .dispatch('AdminCaregiverModule/getCaregiverByIDOrganization', this.selectedRow.rowData.UserID)
                                .then((caregiverResponse) => {
                                    let modalData = {
                                        shiftApplicantDto: shiftApplicantResponse,
                                        caregiverDto: caregiverResponse,
                                    };
                                    DMUtils.openApplicationModal('shiftRequestsViewProfileModal', modalData);
                            });
                    });
        },
        assignApplicant(args) {
            this.$store
                .dispatch('ShiftModule/getShiftApplicantByID', this.selectedRow.rowData.ShiftApplicantID)
                    .then((response) => {   
                        response.OrganizationID = this.dto.OrganizationID;
                        response.ShiftID = this.dto.ShiftID;                  
                        this.$store.dispatch('ShiftModule/assignShiftApplicant', response)
                        .then(() => {
                            this.$store.dispatch('ShiftModule/getPendingShifts');
                        })
                    });
        },
        unassignApplicant(args) {
            this.$store
                .dispatch('ShiftModule/getShiftApplicantByID', this.selectedRow.rowData.ShiftApplicantID)
                    .then((response) => {   
                        response.OrganizationID = this.dto.OrganizationID;
                        response.ShiftID = this.dto.ShiftID;                  
                        this.$store.dispatch('ShiftModule/unassignShiftApplicant', response)
                        .then(() => {
                            this.$store.dispatch('ShiftModule/getPendingShifts');
                        })
                    });
        },
        unconfirmApplicant(args) {
            this.$store
                .dispatch('ShiftModule/getShiftApplicantByID', this.selectedRow.rowData.ShiftApplicantID)
                    .then((response) => {   
                        response.OrganizationID = this.dto.OrganizationID;
                        response.ShiftID = this.dto.ShiftID;                  
                        this.$store.dispatch('ShiftModule/unconfirmShiftApplicant', response)
                        .then(() => {
                            this.$store.dispatch('ShiftModule/getConfirmedShifts');
                            this.closeModal();
                        })
                    });
        },
        declineApplicant(args) {
            this.$store
                .dispatch('ShiftModule/getShiftApplicantByID', this.selectedRow.rowData.ShiftApplicantID)
                    .then((response) => {
                        response.OrganizationID = this.dto.OrganizationID;
                        response.ShiftID = this.dto.ShiftID;
                        let modalData = {
                            dto: response,
                        };
                        DMUtils.openApplicationModal('shiftRequestsDeclineModal', modalData);
                    });
        },
        blockCaregiver(args) {                  
            let dto = {
                CaregiverID: this.selectedRow.rowData.CaregiverID,
                RemoveAllFutureConfirmed: args
            };
            this.$store.dispatch('BlockedCaregiverModule/blockCaregiverOrganization', dto)
                .then(() => {
                    this.getAllShiftApplicants();
                })
        },
        favoriteCaregiver() {                  
            let dto = {
                CaregiverID: this.selectedRow.rowData.CaregiverID
            };
            
            this.$store.dispatch('FavoriteCaregiverModule/favoriteCaregiverOrganization', dto)
                .then(() => {
                    this.getAllShiftApplicants();
                })
        },
        selectShiftApplicantsTableActionOnClick(args) {
            if (args.item.text === 'View Caregiver Profile') {
                this.viewCaregiverProfile();
            } else if (args.item.text === 'Assign'){
                let message = 'Are you sure you want to Assign this shift applicant?';
                const title = 'Confirm';
                const confirmCallback = this.assignApplicant;

                if (this.selectedRow.rowData.GreaterThanDesiredRate) {
                    message = 'The Organization Hourly Rate is above the Max Desired Hourly Rate, are you sure you want to Assign this shift applicant?';
                };

                DMUtils.openApplicationModal('dmConfirmModal', {
                    title: title,
                    message: message,
                    confirmCallback: confirmCallback,
                    confirmText: 'Yes',
                    denyText: 'Cancel',
                });
            } else if (args.item.text === 'Unassign'){
                const message = 'Are you sure you want to Unassign this shift applicant?';
                const title = 'Confirm';
                const confirmCallback = this.unassignApplicant;

                DMUtils.openApplicationModal('dmConfirmModal', {
                    title: title,
                    message: message,
                    confirmCallback: confirmCallback,
                    confirmText: 'Yes',
                    denyText: 'Cancel',
                });
            } else if (args.item.text === 'Unconfirm'){
                let message = 'Are you sure you want to Unconfirm this shift applicant? The shift will be deleted and reposted with the previous shift applicants.';
                const title = 'Unconfirm';
                const confirmCallback = this.unconfirmApplicant;

                DMUtils.openApplicationModal('dmConfirmModal', {
                    title: title,
                    message: message,
                    confirmCallback: confirmCallback,
                    confirmText: 'Yes',
                    denyText: 'Cancel',
                });
            } else if (args.item.text === 'Decline'){
                this.declineApplicant();
            } else if (args.item.text === 'Favorite'){
                const message = 'Are you sure you want to Favorite this Caregiver?';
                const title = 'Confirm';
                const confirmCallback = this.favoriteCaregiver;

                DMUtils.openApplicationModal('dmConfirmModal', {
                    title: title,
                    message: message,
                    confirmCallback: confirmCallback,
                    confirmText: 'Yes',
                    denyText: 'Cancel',
                });               
            } else if (args.item.text === 'Block'){
                endpoints.getFutureShiftsCaregiverConfirmed(this.selectedRow.rowData.CaregiverID)
                .then((response) => {
                    const message = 'Are you sure you want to Block this Caregiver?';
                    const futureShiftsMessage = `All future confirmed shifts:\n${response}`;
                    const title = 'Confirm';

                DMUtils.openApplicationModal('shiftApplicantBlockConfirmModal', {
                    title: title,
                    message: message,
                    futureShiftsMessage: futureShiftsMessage,
                    confirmCallback: this.blockCaregiver,
                    confirmText: 'Yes',
                    denyText: 'Cancel',
                });
                })
            } else if (args.item.text === 'View Chat'){
                let modalData = {
                    selectedShiftID: this.selectedRow.rowData.ShiftID,
                    selectedCaregiverID: this.selectedRow.rowData.CaregiverID,
                    selectedCaregiverName: this.selectedRow.rowData.ApplicantFirstLastName,
                    selectedOrganizationID: this.dto.OrganizationID
                }
                DMUtils.openApplicationModal('messagesShiftThreadModal', modalData);
            }
        },
        getAllShiftApplicants() {
            this.dto = DMUtils.copy(this.modalData.dto);
            let paramsDto = {
                ShiftID: this.dto.ShiftID,
                OrganizationID: this.dto.OrganizationID
            }
            this.$store.dispatch('ShiftModule/getAllShiftApplicants', paramsDto);
        }
    },
    created() {
        let self = this;

        this.$store
                .dispatch('ShiftModule/getHourlyRateMarkup')
                .then((response) => {
                    this.hourlyRateMarkup = response.HourlyRateMarkupPercentage;
                });

        this.getAllShiftApplicants();

        this.viewShiftApplicantsTableColumns = [
            {
                type: 'iconTemplate',
                iconName: 'ellipsis',
                ellipsisActions: [{
                    text: 'View Chat'
                }],
                iconOnClick: this.selectShiftApplicantsTableActionOnClick,
                width: '55px',
            },
            {
                key: 'ApplicantFirstLastName',
                label: 'Name',
                width: '230px',
                formatter: function(column, data) {
                    return data.ApplicantFirstLastName 
                    + (data.IsFavorite ? ' <i class="fa fa-heart brand-color-red"></i>' : '')
                    + (data.IsBlocked ? ' <i class="fa fa-ban brand-color-red"></i>' : '');
                }
            },
            {
                key: 'ApplicantMessage',
                label: 'Message',
                width: '230px',
            },
            {
                key: 'ApplicantRatingString',
                label: 'Rating', 
                textAlign: 'Center',
                width: '110px',
                formatter: function(column, data) {
                    return data.ApplicantRatingString + ' <i class="fa fa-star rating-star"></i>';
                }
            },
            {
                key: 'ReliabilityScoreString',
                label: 'Reliability Score', 
                textAlign: 'Center',
                width: '160px',
                formatter: function(column, data) {
                    return data.ReliabilityScoreString + '%';
                }
            },
            {
                key: 'ShiftHourlyRate',
                label: 'Caregiver Hourly Rate',
                format: 'C2',
                textAlign: 'Right',
                width: '180px',
            },
            {
                key: 'OrganizationShiftHourlyRate',
                label: 'Org. Hourly Rate',
                format: 'C2',
                textAlign: 'Right',
                width: '160px',
            },
            {
                key: 'ApplicantDistance',
                label: 'Distance',
                textAlign: 'Right',
                width: '90px'
            },
            {
                key: 'ApplicantStatus',
                label: 'Status',
                width: '120px',
            },
        ];

        this.selectShiftApplicantsTableColumns = [ 
            {
                type: 'template',
                width: '55px',
                template: function () {
                    return {
                        template: Vue.component('columnTemplate', {
                            template: `<ejs-dropdownbutton
                                                :items="grdRowActions"
                                                :select="select"
                                                iconCss="e-icons e-ellipsis-vert"
                                                cssClass="e-caret-hide">
                                           </ejs-dropdownbutton>`,
                            methods: {
                                select(args) {
                                    self.selectShiftApplicantsTableActionOnClick(args);
                                },
                            },
                            computed: {
                                grdRowActions: function () {
                                    if (this.data.ApplicantStatus === 'Assigned') {
                                        return self.assignedShiftApplicantsTableActions;
                                    } else if (this.data.ApplicantStatus === 'Applied') {
                                        return self.selectShiftApplicantsTableActions; 
                                    } else if (this.data.ApplicantStatus === 'Confirmed') {
                                        return self.confirmShiftApplicantsTableActions; 
                                    } else {
                                        return self.historyShiftApplicantsTableActions;
                                    }
                                },
                            },
                        }),
                    };
                },
            },     
            {
                key: 'ApplicantFirstLastName',
                label: 'Name',
                width: '240px',
                formatter: function(column, data) {
                    return data.ApplicantFirstLastName 
                    + (data.IsFavorite ? ' <i class="fa fa-heart brand-color-red"></i>' : '')
                    + (data.IsBlocked ? ' <i class="fa fa-ban brand-color-red"></i>' : '');
                }
            },
            {
                key: 'ApplicantMessage',
                label: 'Message',
                width: '240px',
            },
            {
                key: 'ApplicantPhoneNumberF',
                label: 'Phone Number',
                width: '150px',
            },
            {
                key: 'ApplicantRatingString',
                label: 'Rating',
                textAlign: 'Center',
                width: '100px',
                formatter: function(column, data) {
                    return data.ApplicantRatingString + ' <i class="fa fa-star rating-star"></i>';
                }
            },
            {
                key: 'ReliabilityScoreString',
                label: 'Reliability Score', 
                textAlign: 'Center',
                width: '160px',
                formatter: function(column, data) {
                    return data.ReliabilityScoreString + '%';
                }
            },
            {
                key: 'OrganizationShiftHourlyRate',
                label: 'Org. Hourly Rate',
                textAlign: 'Right',
                width: '160px',
                formatter: function (column, data) {
                    if (data.GreaterThanDesiredRate) {
                        return (
                            ' <i class="fa fa-exclamation-circle brand-color-red"></i> ' +
                            '$' + parseFloat(data.OrganizationShiftHourlyRate).toFixed(2)
                        );
                    }
                    return '$' + parseFloat(data.OrganizationShiftHourlyRate).toFixed(2);
                },
            },
            {
                key: 'ApplicantDistance',
                label: 'Distance',
                textAlign: 'Right',
                width: '90px'
            },
            {
                key: 'ApplicantStatus',
                label: 'Status',
                width: '110px',
            },
        ];
    },
}
</script>
