<template src="./editLegalDocumentModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'
import { Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar } from "@syncfusion/ej2-vue-richtexteditor";

export default {
    name: 'edit-legal-document-modal',
    provide: {
        richtexteditor:[Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar]
    },
    mixins: [dmModalMixin],
    data() {
        return {
            dto: {},
            imageSettings: {
                saveFormat: "Base64"
            }
        }
    },
    computed: {
        lblTitle() {
            return 'Edit Legal Document';
        },
    },
    methods: {
        btnSaveOnClick() {
            this.$store
                .dispatch('LegalDocumentModule/updateLegalDocument', this.dto)
                .then(() => {
                    this.shouldModalStayOpen = false;
                    this.successClose(this.dto);
                });
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.dto = DMUtils.copy(this.modalData.dto);     
    },
}
</script>
