import endpoints from "../../js/endpoints";

const userModule = {
    namespaced: true,
    state: () => ({
        AllCaregiverProofOfOwnershipTypes: [],
        AllCaregiverProofOfOwnerships: []
    }),
    mutations: {
        setCaregiverProofOfOwnershipTypes(state, proofs) {
            state.AllCaregiverProofOfOwnershipTypes = proofs;
        },
        setCaregiverProofOfOwnerships(state, proofs) {
            state.AllCaregiverProofOfOwnerships = proofs;
        },
    },
    actions: {
        getAllCaregiverProofOfOwnershipTypes({commit}) {
            commit('setCaregiverProofOfOwnershipTypes', []);

            return endpoints.getAllCaregiverProofOfOwnershipTypes()
                .then((response) => {
                    commit('setCaregiverProofOfOwnershipTypes', response);
                    return response;
                });
        },
        getCaregiverProofOfOwnershipByID({commit}, caregiverProofOfOwnershipID) {
            return endpoints.getCaregiverProofOfOwnershipByID(caregiverProofOfOwnershipID)
                .then((response) => {
                    return response;
                });
        },
        getAllCaregiverProofOfOwnershipsMyShyftAdmin({commit}, caregiverID) {
            commit('setCaregiverProofOfOwnerships', []);

            return endpoints.getAllCaregiverProofOfOwnershipsMyShyftAdmin(caregiverID)
                .then((response) => {
                    commit('setCaregiverProofOfOwnerships', response);
                    return response;
                });
        },
        getAllCaregiverProofOfOwnershipsOrganization({commit}, caregiverID) {
            commit('setCaregiverProofOfOwnerships', []);

            return endpoints.getAllCaregiverProofOfOwnershipsOrganization(caregiverID)
                .then((response) => {
                    commit('setCaregiverProofOfOwnerships', response);
                    return response;
                });
        },
        createCaregiverProofOfOwnershipMyShyftAdmin({dispatch}, dto) {
            return endpoints.createCaregiverProofOfOwnershipMyShyftAdmin(dto)
                .then(() => {
                    dispatch('getAllCaregiverProofOfOwnershipsMyShyftAdmin', dto.CaregiverID);
                });
        },
        updateCaregiverProofOfOwnership({dispatch}, dto) {
            return endpoints.updateCaregiverProofOfOwnership(dto)
                .then(() => {
                    dispatch('getAllCaregiverProofOfOwnershipsMyShyftAdmin', dto.CaregiverID);
                });
        },
        approveCaregiverProofOfOwnership({dispatch}, dto) {
            return endpoints.approveCaregiverProofOfOwnership(dto)
                .then(() => {
                    dispatch('getAllCaregiverProofOfOwnershipsMyShyftAdmin', dto.CaregiverID);
                });
        },
        denyCaregiverProofOfOwnership({dispatch}, dto) {
            return endpoints.denyCaregiverProofOfOwnership(dto)
                .then(() => {
                    dispatch('getAllCaregiverProofOfOwnershipsMyShyftAdmin', dto.CaregiverID);
                });
        },
    },
};

export default userModule;
