<template src="./caregiverProfileModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin';
import dmStates from '../../../../../dmFramework/dmJS/dmStates.js';
import imageUploadMixin from '../../../../js/imageUploadMixin';
import Vue from 'vue';

export default {
    name: 'caregiver-profile-modal',
    mixins: [dmModalMixin, imageUploadMixin],
    data() {
        return {
            dto: {},
            editableStatusList: [],
            caregiverMedicalLicenses: [],
            caregiverBackgroundCheck: [],
            caregiverOrganizationTypes: [],
            caregiverOtherDocuments: [],
            caregiverReferences: [],
            statusList: ['Active', 'Inactive'],
            stateList: dmStates,
            caregiverRatingsTableColumns: [],
            caregiverRatingsTableSortBy: {
                columns: [{ field: 'CreatedUtc', direction: 'Descending' }],
            },
            caregiverRatingsTableActions: [
                {
                    text: 'Edit',
                },
                {
                    text: 'Delete',
                },
            ],
            caregiverWorkExperienceTableColumns: [],
            caregiverWorkExperienceTableSortBy: {
                columns: [{ field: 'CaregiverWorkExperienceID', direction: 'Descending' }],
            },
            caregiverWorkExperienceTableActions: [
                {
                    text: 'Edit',
                },
            ],
            caregiverReferenceTableColumns: [],
            caregiverReferenceTableSortBy: {
                columns: [{ field: 'ReferenceID', direction: 'Ascending' }],
            },
            caregiverReferenceTableActions: [
                {
                    text: 'Edit',
                },
                {
                    text: 'Send Email',
                },
            ],
            caregiverMedicalLicensesTableColumns: [],
            caregiverMedicalLicensesTableSortBy: {
                columns: [{ field: 'CreatedUtc', direction: 'Descending' }],
            },
            caregiverMedicalLicensesTableActions: [
                {
                    text: 'Edit',
                },
                {
                    text: 'Approve',
                },
                {
                    text: 'Deny',
                },
            ],
            caregiverOrganizationTypesTableColumns: [],
            caregiverOrganizationTypesTableSortBy: {
                columns: [{ field: 'OrganizationTypeName', direction: 'Ascending' }],
            },
            caregiverOrganizationTypesTableActions: [
                {
                    text: 'Toggle Status',
                }
            ],
            caregiverProofOfOwnershipTableColumns: [],
            caregiverProofOfOwnershipTableSortBy: {
                columns: [{ field: 'CreatedUtc', direction: 'Descending' }],
            },
            caregiverProofOfOwnershipTableActions: [
                {
                    text: 'View',
                },
                {
                    text: 'Edit',
                },
            ],
            caregiverDocumentsTableColumns: [],
            caregiverDocumentsTableSortBy: {
                columns: [{ field: 'CreatedUtc', direction: 'Descending' }],
            },
            caregiverDocumentsTableActions: [
                {
                    text: 'Mark as Approved',
                },
                {
                    text: 'Mark as Denied',
                },
                {
                    text: 'Download',
                },
                {
                    text: 'Delete',
                }
            ],
            selectedRow: null,
        };
    },
    computed: {
        lblTitle() {
            return 'View Profile: ' + this.dto.FirstLastName + '<span class="app-copy-icon fa fa-clone dm-margin-left-sm" id="copy-btn"></span>';
        },
        myShyftAdminTimeZone() {
            return this.$store.state.AdminModule.MyShyftAdminTimeZone;
        },
        caregiverReferenceTableItems() {
            return this.$store.state.AdminCaregiverModule.AllReferencesForCaregiver;
        },
        caregiverDocumentsTableItems() {
            return this.$store.state.DocumentCenterModule.AllDocumentsForUser;
        },
        caregiverRatingsTableItems() {
            let caregiverRatings = this.$store.state.AdminCaregiverModule.AllRatingsForCaregiver;
            let moment = require('moment-timezone');

            if (caregiverRatings) {
                caregiverRatings.forEach((rating) => {
                    rating.CreatedUtc = moment.tz(rating.CreatedUtc, this.myShyftAdminTimeZone);
                });
            }

            return caregiverRatings;
        },
        caregiverWorkExperienceTableItems() {
            let caregiverWorkExperiences = this.$store.state.AdminCaregiverModule.CaregiverWorkExperiences;
            return caregiverWorkExperiences;
        },
        caregiverMedicalLicensesTableItems() {
            let caregiverMedicalLicenses = this.$store.state.MedicalLicenseModule.AllCaregiverMedicalLicenses;
            let moment = require('moment-timezone');

            if (caregiverMedicalLicenses) {
                caregiverMedicalLicenses.forEach((license) => {
                    license.CreatedUtc = moment.tz(license.CreatedUtc, this.myShyftAdminTimeZone);
                    license.UpdatedUtc = moment.tz(license.UpdatedUtc, this.myShyftAdminTimeZone);
                    license.LicenseExpirationDate = moment.tz(license.LicenseExpirationDate, this.myShyftAdminTimeZone);
                });
            }

            return caregiverMedicalLicenses;
        },
        caregiverOrganizationTypesTableItems() {
            return this.caregiverOrganizationTypes;
        },
        caregiverProofOfOwnershipTableItems() {
            let caregiverProofOfOwnerships = this.$store.state.ProofOfOwnershipModule.AllCaregiverProofOfOwnerships;
            let moment = require('moment-timezone');

            if (caregiverProofOfOwnerships) {
                caregiverProofOfOwnerships.forEach((proof) => {
                    proof.FormattedProofName = proof.ProofName;
                    if (proof.ProofType !== '') {
                        proof.FormattedProofName = proof.ProofName + ' - ' + proof.ProofType;
                    }

                    proof.CreatedUtc = moment.tz(proof.CreatedUtc, this.myShyftAdminTimeZone);
                    proof.UpdatedUtc = moment.tz(proof.UpdatedUtc, this.myShyftAdminTimeZone);
                    proof.ReportDate = moment.tz(proof.ReportDate, this.myShyftAdminTimeZone);
                });
            }

            return caregiverProofOfOwnerships;
        },
        getFormattedName() {
            let formattedName = '';

            formattedName += this.dto.FirstName;

            if (this.dto.PreferredName) {
                formattedName += ' "' + this.dto.PreferredName + '"';
            }

            if (this.dto.MiddleName) {
                formattedName += ' ' + this.dto.MiddleName;
            }

            if (this.dto.LastName) {
                formattedName += ' ' + this.dto.LastName;
            }

            return formattedName;
        },
        getFormattedAddress() {
            let formattedAddress = '';

            formattedAddress += this.dto.AddressLine1 ? this.dto.AddressLine1 + ", " : "";
            formattedAddress += this.dto.AddressLine2 ? this.dto.AddressLine2 + ", " : "";
            formattedAddress += this.dto.City ? this.dto.City + ", " : "";
            formattedAddress += this.dto.State ? this.dto.State + " ": "";
            formattedAddress += this.dto.Zipcode ? this.dto.Zipcode : "";

            return formattedAddress;
        },
        getFormattedHourlyRate() {
            if (!this.dto.HourlyRate) {
                return '$0.00';
            }

            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            });

            return formatter.format(this.dto.HourlyRate);
        },
        getFormattedBackgroundCheckCreatedDate() {
            let moment = require('moment-timezone');

            let formattedDate = moment
                .tz(this.caregiverBackgroundCheck.ReportCreatedDate, this.myShyftAdminTimeZone)
                .format('MM/DD/YYYY hh:mmA z');

            if (formattedDate === 'Invalid date') {
                formattedDate = 'N/A';
            }

            return formattedDate;
        },
        getFormattedBackgroundCheckCompletedDate() {
            let moment = require('moment-timezone');

            let formattedDate = moment
                .tz(this.caregiverBackgroundCheck.ReportCompletedDate, this.myShyftAdminTimeZone)
                .format('MM/DD/YYYY hh:mmA z');

            if (formattedDate === 'Invalid date') {
                formattedDate = 'N/A';
            }

            return formattedDate;
        },
        getFormattedBirthDate() {
            let moment = require('moment-timezone');

            let formattedDate = moment.utc(this.dto.BirthDate).format('MM/DD/YYYY');

            if (formattedDate === 'Invalid date') {
                formattedDate = 'N/A';
            }

            return formattedDate;
        },
        getFormattedCreatedUtc() {
            let moment = require('moment-timezone');

            let formattedDate = moment.tz(this.dto.CreatedUtc, this.myShyftAdminTimeZone).format('MM/DD/YYYY hh:mmA z');

            if (formattedDate === 'Invalid date') {
                formattedDate = 'N/A';
            }

            return formattedDate;
        },
        getFormattedUpdatedUtc() {
            let moment = require('moment-timezone');

            let formattedDate = moment.tz(this.dto.UpdatedUtc, this.myShyftAdminTimeZone).format('MM/DD/YYYY hh:mmA z');

            if (formattedDate === 'Invalid date') {
                formattedDate = 'N/A';
            }

            return formattedDate;
        },
        getFormattedLastSignedInUtc() {
            let moment = require('moment-timezone');

            let formattedDate = moment
                .tz(this.dto.LastSignedIn, this.myShyftAdminTimeZone)
                .format('MM/DD/YYYY hh:mmA z');

            if (formattedDate === 'Invalid date') {
                formattedDate = 'N/A';
            }

            return formattedDate;
        },
        getFormattedLastWorkedUtc() {
            let moment = require('moment-timezone');
            let formattedDate = moment
                .tz(this.dto.LastWorked, this.myShyftAdminTimeZone)
                .format('MM/DD/YYYY hh:mmA z');

            if (formattedDate === 'Invalid date') {
                formattedDate = 'N/A';
            }

            return formattedDate;
        },
    },
    methods: {
        modalCreated: function () {
            document.getElementById("copy-btn").addEventListener("click", this.copyFirstLastName);
        },
        copyFirstLastName() {
            let copyText = this.dto.FirstLastName;
            navigator.clipboard.writeText(copyText);
        },
        getCurrentTabData() {
            try {
                let selectingID = this.$refs.caregiverTabs.ej2Instances.selectingID;

                if (selectingID === '0') {
                    //View All Tab
                    this.getViewAllPage();
                    return;
                }
                1;

                if (selectingID === '2') {
                    //Checkr Details Tab
                    this.$store
                        .dispatch('AdminCaregiverModule/getCheckrReportByUserID', this.dto.UserID)
                        .then((response) => {
                            this.caregiverBackgroundCheck = response;
                        });
                    return;
                }

                if (selectingID === '4') {
                    //Professional Licenses Tab
                    this.$store.dispatch(
                        'MedicalLicenseModule/getAllCaregiverMedicalLicensesForCaregiverMyShyftAdmin',
                        this.dto.CaregiverID
                    );
                    return;
                }

                if (selectingID === '5') {
                    //Organization Types Tab
                    this.getAllOrganizationTypes()
                    return;
                }

                if (selectingID === '6') {
                    //Other Documents Tab
                    this.$store.dispatch(
                        'ProofOfOwnershipModule/getAllCaregiverProofOfOwnershipsMyShyftAdmin',
                        this.dto.CaregiverID
                    );
                    return;
                }

                if (selectingID === '7') {
                    //Work Experience Tab
                    this.$store
                        .dispatch('AdminCaregiverModule/getCaregiverByIDMyShyftAdmin', this.dto.UserID)
                        .then((response) => {
                            this.dto = response;
                        });
                }

                if (selectingID === '8') {
                    //References Tab
                    this.$store.dispatch(
                        'AdminCaregiverModule/getAllReferencesForCaregiver', 
                        this.dto.CaregiverID
                    );
                    return;
                }

                if (selectingID === '9') {
                    //Ratings Tab
                    this.$store.dispatch(
                        'AdminCaregiverModule/getAllRatingsForCaregiverMyShyftAdmin',
                        this.dto.CaregiverID
                    );
                    return;
                }

                if (selectingID === '10') {
                    //Ratings Tab
                    this.$store.dispatch(
                        'DocumentCenterModule/getAllDocumentsForUser',
                        this.dto.UserID
                    );
                    return;
                }
            } catch (error) {
                //Throwing the error away to avoid undefined selectingID
            }
        },
        tableRowOnClick(args) {
            this.selectedRow = args;
        },
        editRating() {
            this.$store
                .dispatch('AdminCaregiverModule/getRatingByID', this.selectedRow.rowData.CaregiverRatingID)
                .then((response) => {
                    let modalData = {
                        mode: 'edit',
                        ratingDto: response,
                        shiftDto: {
                            CaregiverName: this.dto.FirstLastName,
                        },
                    };

                    DMUtils.openApplicationModal('shiftRequestsCaregiverRatingModal', modalData);
                });
        },
        deleteCaregiverRating() {
            this.$store.dispatch(
                'AdminCaregiverModule/deleteCaregiverRating',
                this.selectedRow.rowData.CaregiverRatingID
            );
        },    
        createBackgroundCheck() {
            this.$store.dispatch('AdminCaregiverModule/createCheckrReport', this.dto).then(() => {
                this.$store
                    .dispatch('AdminCaregiverModule/getCheckrReportByUserID', this.dto.UserID)
                    .then((response) => {
                        this.caregiverBackgroundCheck = response;
                    });
            });
        },
        addCaregiverReference() {
            let modalData = {
                mode: 'add',
                dto: {
                    CaregiverID: this.dto.CaregiverID,
                    LicenseExpirationDate: null,
                    MedicalLicenseID: null,
                    MedicalLicenseState: null,
                },
            };

            DMUtils.openApplicationModal('caregiverReferenceEditModal', modalData);
        },
        addDocument() {
            let modalData = {
                mode: 'add',
                userID: this.dto.UserID
            };

            DMUtils.openApplicationModal('caregiverDocumentCenterEditModal', modalData);
        },
        editReference() {
            this.$store
                .dispatch('AdminCaregiverModule/getReferenceByID', this.selectedRow.rowData.ReferenceID)
                .then((response) => {
                    let modalData = {
                        mode: 'edit',
                        dto: response,
                        callback: (updatedReference) => {
                            let ref = this.caregiverReferenceTableItems.filter(
                                (r) => r.ReferenceID === this.selectedRow.rowData.ReferenceID
                            )[0];
                            Object.assign(ref, updatedReference);
                        },
                    };

                    DMUtils.openApplicationModal('caregiverReferenceEditModal', modalData);
                });
        },
        sendReferenceEmail() {
            this.$store.dispatch('AdminCaregiverModule/sendReferenceEmail', this.selectedRow.rowData);
        },
        addCaregiverMedicalLicense() {
            let modalData = {
                mode: 'add',
                dto: {
                    CaregiverID: this.dto.CaregiverID,
                    LicenseExpirationDate: null,
                    MedicalLicenseID: null,
                    MedicalLicenseState: null,
                },
            };

            DMUtils.openApplicationModal('caregiverMedicalLicenseEditModal', modalData);
        },
        editCaregiverMedicalLicense() {
            this.$store
                .dispatch(
                    'MedicalLicenseModule/getCaregiverMedicalLicenseByID',
                    this.selectedRow.rowData.CaregiverMedicalLicenseID
                )
                .then((response) => {
                    let modalData = {
                        mode: 'edit',
                        dto: response,
                    };

                    DMUtils.openApplicationModal('caregiverMedicalLicenseEditModal', modalData);
                });
        },
        approveCaregiverMedicalLicense() {
            this.$store
                .dispatch(
                    'MedicalLicenseModule/getCaregiverMedicalLicenseByID',
                    this.selectedRow.rowData.CaregiverMedicalLicenseID
                )
                .then((response) => {
                    this.$store.dispatch('MedicalLicenseModule/approveCaregiverMedicalLicense', response);
                });
        },
        denyCaregiverMedicalLicense() {
            this.$store
                .dispatch(
                    'MedicalLicenseModule/getCaregiverMedicalLicenseByID',
                    this.selectedRow.rowData.CaregiverMedicalLicenseID
                )
                .then((response) => {
                    this.$store.dispatch('MedicalLicenseModule/denyCaregiverMedicalLicense', response);
                });
        },
        viewCaregiverProofOfOwnership() {
            this.$store
                .dispatch(
                    'ProofOfOwnershipModule/getCaregiverProofOfOwnershipByID',
                    this.selectedRow.rowData.CaregiverProofOfOwnershipID
                )
                .then((response) => {
                    let modalData = {
                        dto: response,
                    };

                    DMUtils.openApplicationModal('caregiverProofOfOwnershipViewModal', modalData);
                });
        },
        addCaregiverProofOfOwnership() {
            let modalData = {
                mode: 'add',
                dto: {
                    CaregiverID: this.dto.CaregiverID,
                    ReportDate: null,
                    ProofName: null,
                    ProofType: null,
                    ProofImageBase64: null,
                    ProofImageFileName: null,
                },
            };

            DMUtils.openApplicationModal('caregiverProofOfOwnershipEditModal', modalData);
        },
        editCaregiverProofOfOwnership() {
            this.$store
                .dispatch(
                    'ProofOfOwnershipModule/getCaregiverProofOfOwnershipByID',
                    this.selectedRow.rowData.CaregiverProofOfOwnershipID
                )
                .then((response) => {
                    let modalData = {
                        mode: 'edit',
                        dto: response,
                    };

                    DMUtils.openApplicationModal('caregiverProofOfOwnershipEditModal', modalData);
                });
        },
        addCaregiverWorkExperience() {
            let modalData = {
                mode: 'add',
                dto: {
                    UserID: this.dto.UserID,
                    CaregiverID: this.dto.CaregiverID,
                    WorkExperienceType: '',
                    WorkExperienceYear: '',
                    WorkExperienceMonth: '',
                },
            };

            DMUtils.openApplicationModal('caregiverWorkExperienceEditModal', modalData);
        },
        editCaregiverWorkExperience() {
            let modalData = {
                mode: 'edit',
                dto: {
                    UserID: this.dto.UserID,
                    CaregiverWorkExperienceID: this.selectedRow.rowData.CaregiverWorkExperienceID,
                    WorkExperienceType: this.selectedRow.rowData.WorkExperienceType,
                    WorkExperienceYear: this.selectedRow.rowData.WorkExperienceYear,
                    WorkExperienceMonth: this.selectedRow.rowData.WorkExperienceMonth,
                },
            };

            DMUtils.openApplicationModal('caregiverWorkExperienceEditModal', modalData);
        },
        caregiverWorkExperienceTableActionOnClick(args) {
            if (args.item.text === 'Edit') {
                this.editCaregiverWorkExperience();
            }
        },
        caregiverReferenceTableActionOnClick(args) {
            if (args.item.text === 'Edit') {
                this.editReference();
            } else if (args.item.text === 'Send Email') {
                const message = 'Are you sure you want to send this Reference an email?';
                const title = 'Confirm';
                const confirmCallback = this.sendReferenceEmail;

                DMUtils.openApplicationModal('dmConfirmModal', {
                    title: title,
                    message: message,
                    confirmCallback: confirmCallback,
                    confirmText: 'Yes',
                    denyText: 'Cancel',
                });
            }
        },
        caregiverRatingsTableActionOnClick(args) {
            if (args.item.text === 'Edit') {
                this.editRating();
            } else if (args.item.text === 'Delete') {
                const message = 'Are you sure you want to delete this Caregiver Rating?';
                const title = 'Confirm';
                const confirmCallback = this.deleteCaregiverRating;

                DMUtils.openApplicationModal('dmConfirmModal', {
                    title: title,
                    message: message,
                    confirmCallback: confirmCallback,
                    confirmText: 'Yes',
                    denyText: 'Cancel',
                });
            }
        },
        caregiverMedicalLicensesTableActionOnClick(args) {
            if (args.item.text === 'Edit') {
                this.editCaregiverMedicalLicense();
            } else if (args.item.text === 'Approve') {
                const message = 'Are you sure you want to Approve this Professional License?';
                const title = 'Confirm';
                const confirmCallback = this.approveCaregiverMedicalLicense;

                DMUtils.openApplicationModal('dmConfirmModal', {
                    title: title,
                    message: message,
                    confirmCallback: confirmCallback,
                    confirmText: 'Yes',
                    denyText: 'Cancel',
                });
            } else if (args.item.text === 'Deny') {
                const message = 'Are you sure you want to Deny this Professional License?';
                const title = 'Confirm';
                const confirmCallback = this.denyCaregiverMedicalLicense;

                DMUtils.openApplicationModal('dmConfirmModal', {
                    title: title,
                    message: message,
                    confirmCallback: confirmCallback,
                    confirmText: 'Yes',
                    denyText: 'Cancel',
                });
            }
        },
        caregiverOrganizationTypesTableActionOnClick(args) {
            if (args.item.text === 'Toggle Status') {     
                this.toggleOrganizationTypeStatus();
            }
        },
        getAllOrganizationTypes() {
            endpoints.getAllCaregiverOrganizationTypesForCaregiverMyShyftAdmin(this.dto.CaregiverID)
                .then((response) => {
                    this.caregiverOrganizationTypes = response;
                }); 
        },
        toggleOrganizationTypeStatus() {
            let dto = this.selectedRow.rowData;
            dto.CaregiverID = this.dto.CaregiverID;

            endpoints.toggleStatusCaregiverOrganizationType(dto)
                .then(() => {
                    this.getAllOrganizationTypes();
                }); 
        },
        caregiverProofOfOwnershipTableActionOnClick(args) {
            if (args.item.text === 'View') {
                this.viewCaregiverProofOfOwnership();
            } else if (args.item.text === 'Edit') {
                this.editCaregiverProofOfOwnership();
            }
        },
        downloadDocument() {
            this.$store.dispatch('DocumentCenterModule/downloadDocumentMyShyftAdmin', this.selectedRow.rowData.DocumentID)
            .then((response) => {
                DMUtils.downloadAjaxResponseAsFile(response);
            });
        },
        updateDocumentStatus(status) {
            let dto = this.selectedRow.rowData;
            dto.ApprovalStatus = status;

            this.$store.dispatch('DocumentCenterModule/updateDocumentStatus', dto)
        },
        deleteDocument() {
            this.$store.dispatch('DocumentCenterModule/deleteDocumentMyShyftAdmin', this.selectedRow.rowData);
        },
        caregiverDocumentsTableActionOnClick(args) {
            if (args.item.text === 'Mark as Approved') {
                this.updateDocumentStatus('Approved');
            } else if (args.item.text === 'Mark as Denied') {
                this.updateDocumentStatus('Denied');
            } else if (args.item.text === 'Download') {
                this.downloadDocument();
            } else if (args.item.text === 'Delete') {
                const message = 'Are you sure you want to delete this Document?';
                const title = 'Confirm';
                const confirmCallback = this.deleteDocument;

                DMUtils.openApplicationModal('dmConfirmModal', {
                    title: title,
                    message: message,
                    confirmCallback: confirmCallback,
                    confirmText: 'Yes',
                    denyText: 'Cancel',
                });          
            }
        },
        getViewAllPage() {
            this.$store
                .dispatch('AdminCaregiverModule/getCaregiverByIDMyShyftAdmin', this.dto.UserID)
                .then((response) => {
                    this.dto = response;
                });

            this.$store
                .dispatch(
                    'MedicalLicenseModule/getAllCaregiverMedicalLicensesForCaregiverMyShyftAdmin',
                    this.dto.CaregiverID
                )
                .then((response) => {
                    this.caregiverMedicalLicenses = response;
                    let moment = require('moment-timezone');

                    if (this.caregiverMedicalLicenses) {
                        this.caregiverMedicalLicenses.forEach((license) => {
                            license.FormattedCreatedUtc = moment
                                .tz(license.CreatedUtc, this.myShyftAdminTimeZone)
                                .format('MM/DD/YYYY');
                            license.FormatttedUpdatedUtc = moment
                                .tz(license.UpdatedUtc, this.myShyftAdminTimeZone)
                                .format('MM/DD/YYYY');
                            license.FormattedLicenseExpirationDate = moment
                                .tz(license.LicenseExpirationDate, this.myShyftAdminTimeZone)
                                .format('MM/DD/YYYY');
                        });
                    }
                });

            this.$store
                .dispatch('ProofOfOwnershipModule/getAllCaregiverProofOfOwnershipsMyShyftAdmin', this.dto.CaregiverID)
                .then((response) => {
                    this.caregiverOtherDocuments = response;
                    let moment = require('moment-timezone');

                    if (this.caregiverOtherDocuments) {
                        this.caregiverOtherDocuments.forEach((proof) => {
                            proof.FormattedProofName = proof.ProofName;

                            if (proof.ProofType !== '') {
                                proof.FormattedProofName = proof.ProofName + ' - ' + proof.ProofType;
                            }

                            proof.FormattedCreatedUtc = moment
                                .tz(proof.CreatedUtc, this.myShyftAdminTimeZone)
                                .format('MM/DD/YYYY');
                            proof.FormattedUpdatedUtc = moment
                                .tz(proof.UpdatedUtc, this.myShyftAdminTimeZone)
                                .format('MM/DD/YYYY');
                            proof.FormattedReportDate = moment
                                .tz(proof.ReportDate, this.myShyftAdminTimeZone)
                                .format('MM/DD/YYYY');
                        });
                    }
                });

            this.$store.dispatch('AdminCaregiverModule/getCheckrReportByUserID', this.dto.UserID).then((response) => {
                this.caregiverBackgroundCheck = response;
            });

            this.$store
                .dispatch('AdminCaregiverModule/getAllReferencesForCaregiver', this.dto.CaregiverID)
                .then((response) => {
                    this.caregiverReferences = response;
                });

            this.getAllOrganizationTypes();
        },
        btnSaveOnClick() {
            this.$store.dispatch('AdminCaregiverModule/updateCaregiver', this.dto).then(() => {
                this.shouldModalStayOpen = false;
                this.successClose(this.dto);
            });
        },
        driversLicenseImageEnlarge() {
            let modalData = {
                imageFileName: this.dto.DriversLicenseImageFileName,
                imageBase64: this.dto.DriversLicenseImageBase64
            }                    
            DMUtils.openApplicationModal('viewImageModal', modalData);
        },
        profileImageEnlarge() {
            let modalData = {
                imageFileName: this.dto.ProfileImageFileName,
                imageBase64: this.dto.ProfileImageBase64
            }                    
            DMUtils.openApplicationModal('viewImageModal', modalData);
        },     
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    mounted() {
        this.$refs.caregiverTabs.ej2Instances.animation.next.effect = 'None';
        this.$refs.caregiverTabs.ej2Instances.animation.previous.effect = 'None';
    },
    created() {
        this.dto = DMUtils.copy(this.modalData.dto);
        this.$store
            .dispatch('AdminCaregiverModule/getAllCaregiverEditableStatuses')
            .then((response) => {
                this.editableStatusList = response;
        });
        this.getViewAllPage();
        let self = this;

        this.caregiverReferenceTableColumns = [
            {
                type: 'iconTemplate',
                iconName: 'ellipsis',
                ellipsisActions: this.caregiverReferenceTableActions,
                iconOnClick: this.caregiverReferenceTableActionOnClick,
                width: '55px',
            },
            {
                key: 'ReferenceID',
                label: 'ID',
                width: '75px',
            },
            {
                key: 'ReferenceFirstLastName',
                label: 'Name',
            },
            {
                key: 'Relationship',
                label: 'Relationship',
            },
            {
                key: 'ReferenceEmailAddress',
                label: 'Email Address',
            },
            {
                key: 'ReferencePhoneNumberF',
                label: 'Phone Number',
            },
            {
                key: 'ReferenceNotes',
                label: 'Notes',
            },
        ];

        this.caregiverWorkExperienceTableColumns = [
            {
                type: 'iconTemplate',
                iconName: 'ellipsis',
                ellipsisActions: this.caregiverWorkExperienceTableActions,
                iconOnClick: this.caregiverWorkExperienceTableActionOnClick,
                width: '55px',
            },
            {
                key: 'CaregiverWorkExperienceID',
                label: 'ID',
                width: '75px',
            },
            {
                key: 'WorkExperienceType',
                label: 'Type',
            },
            {
                key: 'WorkExperienceYear',
                label: 'Years',
                width: '100px',
            },
            {
                key: 'WorkExperienceMonth',
                label: 'Months',
                width: '100px',
            },
        ];

        this.caregiverRatingsTableColumns = [
            {
                type: 'iconTemplate',
                iconName: 'ellipsis',
                ellipsisActions: this.caregiverRatingsTableActions,
                iconOnClick: this.caregiverRatingsTableActionOnClick,
                width: '55px',
            },
            {
                key: 'OrganizationName',
                label: 'Organization Name',
                width: '300px',
            },
            {
                key: 'RatingComment',
                label: 'Comment',
            },
            {
                key: 'Rating',
                label: 'Rating',
                width: '110px',
                format: '0.0',
                textAlign: 'Center',
            },
            {
                key: 'CreatedUtc',
                label: 'Created On',
                width: '220px',
                type: 'date',
                formatter(column, rating) {
                    return moment.tz(rating.CreatedUtc, self.myShyftAdminTimeZone).format("MM/DD/YYYY hh:mmA z"); 
                } 
            },
        ];

        this.caregiverMedicalLicensesTableColumns = [
            {
                type: 'iconTemplate',
                iconName: 'ellipsis',
                ellipsisActions: this.caregiverMedicalLicensesTableActions,
                iconOnClick: this.caregiverMedicalLicensesTableActionOnClick,
                width: '55px',
            },
            {
                key: 'MedicalLicenseName',
                label: 'License Name',
            },
            {
                key: 'MedicalLicenseNumber',
                label: 'License Number',
            },
            {
                key: 'LicenseExpirationDate',
                label: 'Expiration Date',
                width: '200px',
                type: 'date',
                format: 'MM/dd/yyyy',
            },
            {
                key: 'CreatedUtc',
                label: 'Created On',
                width: '220px',
                type: 'date',
                formatter(column, license) {
                    return moment.tz(license.CreatedUtc, self.myShyftAdminTimeZone).format("MM/DD/YYYY hh:mmA z"); 
                } 
            },
            {
                key: 'MedicalLicenseState',
                label: 'State',
                width: '100px',
            },
            {
                key: 'ApprovalStatus',
                label: 'Status',
                width: '125px',
            },
        ];

        this.caregiverOrganizationTypesTableColumns = [
            {
                type: 'iconTemplate',
                iconName: 'ellipsis',
                ellipsisActions: this.caregiverOrganizationTypesTableActions,
                iconOnClick: this.caregiverOrganizationTypesTableActionOnClick,
                width: '55px',
            }, 
            {
                key: 'OrganizationTypeName',
                label: 'Organization Type Name',
            },
            {
                key: 'OrganizationTypeCategory',
                label: 'Category',
                width: '150px',
            },
            {
                key: 'UpdatedUtc',
                label: 'Last Updated On',
                width: '220px',
                type: 'date',
                formatter(column, organizationType) {
                    if (organizationType.UpdatedUtc) {
                        return moment.tz(organizationType.UpdatedUtc, self.myShyftAdminTimeZone).format("MM/DD/YYYY hh:mmA z"); 
                    }
                    
                    return '';
                }                           
            },
            {
                key: 'Status',
                label: 'Status',
                width: '120px',
                template: function () {
                    return {
                        template: Vue.component('columnTemplate', {
                            template: `<div :class="setColor">{{data.Status}}</div>`,
                            computed: {
                                setColor: function () {
                                    if (this.data.Status === 'Inactive') {
                                        return 'app-grid-status-orange';
                                    } else if (this.data.Status === 'Active') {
                                        return 'app-grid-status-green';
                                    }   
                                },                             
                            },
                        }),
                    };
                },
            },
        ];

        this.caregiverProofOfOwnershipTableColumns = [
            {
                type: 'iconTemplate',
                iconName: 'ellipsis',
                ellipsisActions: this.caregiverProofOfOwnershipTableActions,
                iconOnClick: this.caregiverProofOfOwnershipTableActionOnClick,
                width: '55px',
            },
            {
                key: 'FormattedProofName',
                label: 'Name',
            },
            {
                key: 'ReportDate',
                label: 'Date',
                type: 'date',
                format: 'MM/dd/yyyy',
            },
            {
                key: 'CreatedUtc',
                label: 'Created On',
                width: '220px',
                type: 'date',
                formatter(column, proof) {
                    return moment.tz(proof.CreatedUtc, self.myShyftAdminTimeZone).format("MM/DD/YYYY hh:mmA z"); 
                }               
            },
            {
                key: 'ApprovalStatus',
                label: 'Status',
                width: '125px',
            },
        ];

        this.caregiverDocumentsTableColumns = [
            {
                type: 'iconTemplate',
                iconName: 'ellipsis',
                ellipsisActions: this.caregiverDocumentsTableActions,
                iconOnClick: this.caregiverDocumentsTableActionOnClick,
                width: '55px',
            },
            {
                key: 'DocumentID',
                label: 'ID',
                width: '75px',
            },
            {
                key: 'DocumentTypeName',
                label: 'Name',
            },
            {
                key: 'CreatedUtc',
                label: 'Created On',
                type: 'date',
                width: '220px',
                formatter(column, document) {
                    return moment.tz(document.CreatedUtc, self.myShyftAdminTimeZone).format("MM/DD/YYYY hh:mmA z"); 
                }
            },
            {
                key: 'UpdatedUtc',
                label: 'Last Updated On',
                type: 'date',
                width: '220px',
                formatter(column, document) {
                    return moment.tz(document.UpdatedUtc, self.myShyftAdminTimeZone).format("MM/DD/YYYY hh:mmA z"); 
                }
            },
            {
                key: 'ApprovalStatus',
                label: 'Status',
                width: '125px',
            },
        ];

        setTimeout(() => {
            // Force resize because the tabs get cut off unless window is resized
            window.dispatchEvent(new Event('resize'));
        });
    },
};
</script>
