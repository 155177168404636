<template src="./caregiverMedicalLicenseEditModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

export default {
    name: 'users-edit-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            medicalLicenses: [],
            dto: {},
        }
    },
    computed: {
        mode() {
            return this.modalData.mode;
        },
        lblTitle() {
            return this.modalData.mode === 'edit' ? 'Edit Professional License: ' + this.modalData.dto.MedicalLicenseName : 'Add Professional License';
        },
        myShyftAdminTimeZone(){
            return this.$store.state.AdminModule.MyShyftAdminTimeZone;
        },
        getFormattedCreatedUtc(){
            let moment = require('moment-timezone');
            return moment.tz(this.dto.CreatedUtc, this.myShyftAdminTimeZone).format("M/D/YYYY");
        },
        getFormattedUpdatedUtc(){
            let moment = require('moment-timezone');
            return moment.tz(this.dto.UpdatedUtc, this.myShyftAdminTimeZone).format("M/D/YYYY");
        },
        getSelectedLicenseStates(){
            let medicalLicense = this.medicalLicenses.find(license => {return license.MedicalLicenseID === this.dto.MedicalLicenseID});

            if (medicalLicense) {
                return medicalLicense.MedicalLicenseStates;
            }
        
            return [];
        },
        LicenseExpirationDate(){
            let moment = require('moment-timezone');
            let utcNow = moment.utc();
            let timeZoneOffset = moment.tz.zone(this.myShyftAdminTimeZone).utcOffset(utcNow);

            this.dto.LicenseExpirationDate = moment.utc(this.dto.LicenseExpirationDate).add(timeZoneOffset).format();
        }
    },
    methods: {
        btnSaveOnClick() {
            if (this.mode === 'edit') {              
                this.$store
                    .dispatch('MedicalLicenseModule/updateCaregiverMedicalLicense', this.dto)
                    .then(() => {
                        this.shouldModalStayOpen = false;
                        this.successClose(this.dto);
                    });
            } else if (this.mode === 'add') {
                this.$store
                    .dispatch('MedicalLicenseModule/createCaregiverMedicalLicenseMyShyftAdmin', this.dto)
                    .then(() => {
                        this.shouldModalStayOpen = false;
                        this.successClose(this.dto);
                    });            
            }
        },
        btnCancelOnClick() {         
            this.closeModal();
        },
    },
    created() {
        this.dto = DMUtils.copy(this.modalData.dto);
        this.$store
            .dispatch('MedicalLicenseModule/getAllMedicalLicensesMyShyftAdmin')
            .then(() => {
                this.medicalLicenses = this.$store.state.MedicalLicenseModule.AllMedicalLicenses;
        });
    }
}
</script>
