import endpoints from "../../../js/endpoints";

const supportModule = {
    namespaced: true,
    state: () => ({
        AllGeneralSupportRequests: [],
        GeneralSupportNumberOfRows: 0,

        AllShiftSupportRequests: [],
        ShiftSupportNumberOfRows: 0,

    }),
    mutations: {
        setGeneralSupportRequests(state, requests) {
            state.AllGeneralSupportRequests = requests;
        },
        setGeneralSupportNumberOfRows(state, count) {
            state.GeneralSupportNumberOfRows = count;
        },
        setShiftSupportRequests(state, requests) {
            state.AllShiftSupportRequests = requests;
        },
        setShiftSupportNumberOfRows(state, count) {
            state.ShiftSupportNumberOfRows = count;
        }
    },
    actions: {
        getAllGeneralSupportRequestsMyShyftAdmin({commit}, paramsDto) {
            commit('setGeneralSupportRequests', []);
            commit('setGeneralSupportNumberOfRows', 0);

            return endpoints.getAllGeneralSupportRequestsMyShyftAdmin(paramsDto)
                .then((response) => {                  
                    commit('setGeneralSupportRequests', response.Rows);
                    commit('setGeneralSupportNumberOfRows', response.NumberOfRows);
                });
        },
        getAllGeneralSupportRequestsOrganization({commit}, paramsDto) {
            commit('setGeneralSupportRequests', []);
            commit('setGeneralSupportNumberOfRows', 0);

            return endpoints.getAllGeneralSupportRequestsOrganization(paramsDto)
                .then((response) => {                  
                    commit('setGeneralSupportRequests', response.Rows);
                    commit('setGeneralSupportNumberOfRows', response.NumberOfRows);
                });
        },
        getAllShiftSupportRequestsMyShyftAdmin({commit}, paramsDto) {
            commit('setShiftSupportRequests', []);
            commit('setShiftSupportNumberOfRows', 0);

            return endpoints.getAllShiftSupportRequestsMyShyftAdmin(paramsDto)
                .then((response) => {                  
                    commit('setShiftSupportRequests', response.Rows);
                    commit('setShiftSupportNumberOfRows', response.NumberOfRows);
                });
        },
        getAllShiftSupportRequestsOrganization({commit}, paramsDto) {
            commit('setShiftSupportRequests', []);
            commit('setShiftSupportNumberOfRows', 0);

            return endpoints.getAllShiftSupportRequestsOrganization(paramsDto)
                .then((response) => {                  
                    commit('setShiftSupportRequests', response.Rows);
                    commit('setShiftSupportNumberOfRows', response.NumberOfRows);
                });
        },
        getShiftSupportTypes() {
            return endpoints.getShiftSupportTypes()
                .then((response) => {                  
                    return response;
                });
        },
        getGeneralSupportRequestByID({}, generalSupportID) {
            return endpoints.getGeneralSupportRequestByID(generalSupportID)
            .then((response) => {
                return response
            });
        },
        getShiftSupportRequestByID({}, shiftSupportID) {
            return endpoints.getShiftSupportRequestByID(shiftSupportID)
            .then((response) => {
                return response
            });
        },
        updateGeneralSupportRequest({dispatch}, dto) {
            return endpoints.updateGeneralSupportRequest(dto)
                .then(() => {
                    dispatch('getAllGeneralSupportRequestsMyShyftAdmin', dto);
                });
        },
        updateShiftSupportRequest({dispatch}, dto) {
            return endpoints.updateShiftSupportRequest(dto)
                .then(() => {
                    dispatch('getAllShiftSupportRequestsMyShyftAdmin', dto);
                });
        },
        createGeneralSupportRequest({dispatch}, dto) {
            return endpoints.createGeneralSupportRequest(dto)
                .then(() => {
                });
        },
        createShiftSupportRequest({dispatch}, dto) {
            return endpoints.createShiftSupportRequest(dto)
                .then(() => {
                });
        },       
    },
};

export default supportModule;
