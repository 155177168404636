x<template src="./organizationUsersPage.html"></template>

<script>
    import Vue from 'vue';

    export default {
        name: 'user-users-page',
        data() {
            return {
                searchString: '',
                organizationUsersTableColumns: [],
                organizationUsersTableSortBy: {
                    columns: [
                        { field: 'FirstLastName', direction: 'Ascending' },
                    ]
                },
                organizationUsersTableCustomClass: 'dm-bg-color-black',
                organizationUsersTableActions: [
                    {
                        text: 'Edit'
                    },
                    {
                        text: 'Delete'
                    }
                ],
                selectedRow: null
            }
        },
        computed: {
            organizationUsersTableItems() {
                let organizationUsers = this.$store.state.UserModule.AllUsers
            
                if (organizationUsers){
                    organizationUsers.forEach(user => {
                        if (user.Role === "OrganizationAdmin"){
                            user.Role = "Admin";
                        } else if (user.Role === "OrganizationEmployee"){
                            user.Role = "Employee";
                        } else if (user.Role === "OrganizationCorporate"){
                            user.Role = "Corporate";
                        }
                    });
                }

                let filteredUsers = organizationUsers.filter(
                    (user) => {
                        return  user.FirstLastName.toLowerCase().includes(this.searchString.toLowerCase()) || 
                                user.EmailAddress.toLowerCase().includes(this.searchString.toLowerCase()) ||
                                user.Role.toLowerCase().includes(this.searchString.toLowerCase()) || 
                                user.PhoneNumberF.toLowerCase().includes(this.searchString.toLowerCase());
                    }
                )
        
                return filteredUsers;
            },
            userID() {
                return this.selectedRow.rowData.UserID;
            },
            signedInUser() {
                return this.$store.state.AuthorizationModule.SignedInUser;
            },
        },
        methods: {
            organizationUsersTableRowOnClick(args) {
                this.selectedRow = args;
            },
            addNewOrganizationUser() {
                let modalData = {
                    mode: 'add',
                    accountType: 'OrganizationUser',
                    dto: {},
                };

                DMUtils.openApplicationModal('usersEditModal', modalData);
            },
            editOrganizationUser() {
                let modalData = {
                    mode: 'edit',
                    accountType: 'OrganizationUser',
                    dto: {},
                };

                this.$store
                    .dispatch('UserModule/getUserByID', this.userID)
                    .then((response) => {
                        modalData.dto = response;                     
                        DMUtils.openApplicationModal('usersEditModal', modalData);
                    });
            },
            deleteOrganizationUser() {
                let dto = {
                    UserID: this.userID,
                };

                this.$store.dispatch('UserModule/deleteUser', dto);
            },
            organizationUsersTableActionOnClick(args) {
                if (args.item.text === 'Edit') {
                    this.editOrganizationUser();
                } 
                else if (args.item.text === 'Delete') {
                    const message = 'Are you sure you want to delete this user?';
                    const title = 'Confirm';
                    const confirmCallback = this.deleteOrganizationUser;

                    DMUtils.openApplicationModal('dmConfirmModal', {
                        title: title,
                        message: message,
                        confirmCallback: confirmCallback,
                        confirmText: 'Yes',
                        denyText: 'Cancel',
                    });
                }
            },
        },
        created() {
            this.$store.dispatch('UserModule/getAllUsers');

            if (this.signedInUser.Role === 'OrganizationAdmin'){
                this.organizationUsersTableColumns = [
                    {
                        type: 'iconTemplate', 
                        iconName: 'ellipsis',
                        ellipsisActions: this.organizationUsersTableActions,
                        iconOnClick: this.organizationUsersTableActionOnClick,
                        width: '55px',
                    },
                    {
                        key: 'FirstLastName',
                        label: 'Name',
                    },
                    {
                        key: 'EmailAddress',
                        label: 'Email Address',
                    },
                    {
                        key: 'Role',
                        label: 'User Role',
                    },
                    {
                        key: 'PhoneNumberF',
                        label: 'Phone Number',
                    },
                ];            
            } else {
                this.organizationUsersTableColumns = [
                    {
                        key: 'FirstLastName',
                        label: 'Name',
                    },
                    {
                        key: 'EmailAddress',
                        label: 'Email Address',
                    },
                    {
                        key: 'Role',
                        label: 'User Role',
                    },
                    {
                        key: 'PhoneNumberF',
                        label: 'Phone Number',
                    },
                ]; 
            }   
        },
    }
</script>