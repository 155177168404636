<template src="./shiftRequestsShiftHistoryModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'organization-requests-shift-history-modal',
        mixins: [dmModalMixin],
        data() {
            return {
                dto: {},
                shiftHistoryTableColumns: [],
                shiftHistoryTableSortBy: {
                    columns: [
                        { field: 'CreatedUtc', direction: 'Descending' },
                    ]
                },
                shiftHistoryTableCustomClass: 'dm-bg-color-black',
            }
        },
        computed: {
            lblTitle() {
                return 'Shift History for ShiftID: ' + this.dto.ShiftID;
            },
            shiftHistoryTableItems() {
                let shiftHistory = this.$store.state.ShiftModule.AllShiftHistory;

                if (shiftHistory){
                    shiftHistory.forEach((history) => {             
                        history.DisplayTimeZone = this.timeZone;
                    });
                }

                return shiftHistory;
            },
            timeZone() {
                let userRole = this.$store.state.AuthorizationModule.SignedInUser.Role;

                if (userRole === "MyShyftAdmin" || userRole === 'MyShyftEmployee'){
                    return this.$store.state.AdminModule.MyShyftAdminTimeZone;
                }

                return this.$store.state.AuthorizationModule.SignedInUser.LocalTimeZone;
            }
        },
        methods: {
            btnCloseOnClick(){
                this.closeModal();
            },
        },
        created() {
            this.dto = DMUtils.copy(this.modalData.dto);
            this.$store.dispatch('ShiftModule/getAllShiftHistoryForShift', this.dto.ShiftID);
            this.shiftHistoryTableColumns = [
                 {
                    key: 'Description',
                    label: 'Description',
                    width: '500px'
                },
                {
                    key: 'LastEditFirstLastName',
                    label: 'Signed In User',
                    width: '400px',
                },
                {
                    key: 'CreatedUtc',
                    label: 'History Date',
                    type: "date",
                    width: '220px',
                    formatter(column, organization) {
                        return moment.tz(organization.CreatedUtc, organization.DisplayTimeZone).format("MM/DD/YYYY hh:mmA z"); 
                    } 
                },
            ];
        },
    }
</script>
