<template src="./usersEditModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

export default {
    name: 'users-edit-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            myShyftUserRoles: 
                [
                    {text: 'MyShyft Admin', value: 'MyShyftAdmin'}, 
                    {text: 'MyShyft Employee', value: 'MyShyftEmployee'},
                    {text: 'MyShyft Viewer', value: 'MyShyftViewer'}
                ],
            organizationUserRoles: 
                [
                    {text: 'Admin', value: 'OrganizationAdmin'}, 
                    {text: 'Employee', value: 'OrganizationEmployee'}, 
                    {text: 'Corporate', value: 'OrganizationCorporate'}
                ],
            userStatus: ['Active', 'Inactive'],
            roleFields: { text: 'text', value: 'value' },
            pronounOptions: [],
            organizationID: null,
            dto: {},
        }
    },
    computed: {
        mode() {
            return this.modalData.mode;
        },
        accountType() {
            return this.modalData.accountType;
        },
        lblTitle() {
            return this.modalData.mode === 'edit' ? 'Edit User: ' + this.modalData.dto.FirstLastName 
                : this.modalData.mode === 'add' && (this.modalData.accountType === 'MyShyftAdmin') ? 'Add MyShyft User' 
                : 'Add User ';
        },
        getFormattedCreatedUtc(){
            let moment = require('moment-timezone');
            return moment.tz(this.dto.CreatedUtc, this.myShyftAdminTimeZone).format("M/D/YYYY hh:mmA z");
        },
        myShyftAdminTimeZone(){
            return this.$store.state.AdminModule.MyShyftAdminTimeZone;
        },
    },
    methods: {
        btnSaveOnClick() {
            if (this.mode === 'edit') {
                this.$store
                    .dispatch('UserModule/updateUser', this.dto)
                    .then(() => {
                        this.shouldModalStayOpen = false;
                        this.successClose(this.dto);
                    });
            } else if (this.mode === 'add') {
                if (this.accountType === 'MyShyftAdmin'){
                    this.$store
                        .dispatch('UserModule/createMyShyftAdmin', this.dto)
                        .then(() => {
                            this.shouldModalStayOpen = false;
                            this.successClose(this.dto);
                        });
                } else if (this.accountType === 'OrganizationUser'){
                    this.dto.OrganizationID = this.organizationID;
                     this.$store
                        .dispatch('UserModule/createOrganizationUser', this.dto)
                        .then(() => {
                            this.shouldModalStayOpen = false;
                            this.successClose(this.dto);
                        });
                }
            }
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.organizationID = this.modalData.organizationID;
        this.dto = DMUtils.copy(this.modalData.dto);

        endpoints.getUserPronouns()
            .then(response => {
                this.pronounOptions = response;
            });
    },
}
</script>
