import endpoints from "../../js/endpoints";

const favoriteCaregiversModule = {
    namespaced: true,
    state: () => ({
        AllFavoriteCaregivers: []
    }),
    mutations: {
        setFavoriteCaregivers(state, caregivers) {
            state.AllFavoriteCaregivers = caregivers;
        },
    },
    actions: {
        getAllFavoriteCaregiversForOrganization({commit}, organizationID) {
            commit('setFavoriteCaregivers', []);

            return endpoints.getAllFavoriteCaregiversForOrganization(organizationID)
                .then((response) => {
                    commit('setFavoriteCaregivers', response);
                });
        },     
        favoriteCaregiverOrganization({dispatch}, dto) {
            return endpoints.favoriteCaregiverOrganization(dto)
                .then(() => {
                    dispatch('getAllFavoriteCaregiversForOrganization', 0);
                });
        },
        removeFavoriteCaregiverOrganization({dispatch}, dto) {
            return endpoints.removeFavoriteCaregiverOrganization(dto)
                .then(() => {
                    dispatch('getAllFavoriteCaregiversForOrganization', 0);
                });
        },            
    },
};

export default favoriteCaregiversModule;
