<template src="./shiftRequestsCalendarModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'
import Vue from 'vue'
import { Day, Week, WorkWeek, Month, Agenda } from '@syncfusion/ej2-vue-schedule';
import { Internationalization } from "@syncfusion/ej2-base";

var contentTemplateVue = Vue.component('contentTemplate', {
  template: 
  ` <div class="quick-info-content">
        <div class="event-content brand-color-black">
            <div class="e-date-time dm-padding-bottom-sm">
                <div class="e-date-time-icon e-icons"></div>
                <div class="e-date-time-wrapper e-text-ellipsis">
                    <div class="e-date-time-details e-text-ellipsis">{{getDateDetails(data)}}</div>
                </div>
            </div>
            <div class="notes-wrap"><label class="app-label">Shift ID</label>: {{data.ShiftID}}<span></span></div>
            <div class="notes-wrap"><label class="app-label">Caregiver</label>: {{data.CaregiverName}}<span></span></div>
        </div>
    </div>
  `,
  data() {
    return {
      intl: new Internationalization(),
      fields: { text: "Name", value: "Id" },
      data: {}
    };
  },
  methods: {
    getDateDetails: function(data) {
      return (
        this.intl.formatDate(data.StartTime, { type: "date", skeleton: "long" }) + " (" +
        this.intl.formatDate(data.StartTime, { skeleton: 'hm' }) + " - " +
        this.intl.formatDate(data.EndTime, { skeleton: 'hm' }) + " " +
        moment.tz(data.LocalTimeZone).format('z') + ")"
      );
    }
  }
});

export default {
    name: 'shift-requests-calendar-modal',
    provide: {
            schedule: [ 
                Day, 
                Week, 
                WorkWeek, 
                Month, 
                Agenda
            ]
        },
    mixins: [dmModalMixin],
    data() {
        return {
            dto: {},   
            showPastShifts: false,
            eventSettings: {
                dataSource: {},
                fields: {
                    id: 'ShiftID',
                    subject: { name: 'ShiftStatus', title: 'Shift Status' },
                    startTime: { name: 'StartTime', title: 'Start Time' },
                    endTime: { name: 'EndTime', title: 'End Time'  }
                }
            },   
            resourceDataSource: {},
            quickInfoTemplates: {
                content: function(e) {
                    return {
                        template: contentTemplateVue
                    };
                },
                footer: function(e) {
                    return {
                        template: ""
                    };
                }
            }        
        }
    },
    computed: {
        lblTitle() {
            return 'Shift Calendar';
        },     
        organizationTimeZone() {
            return this.$store.state.AuthorizationModule.SignedInUser.LocalTimeZone;
        },        
    },
    methods: { 
        shiftsDataSource() {      
            let allShifts = DMUtils.copy(this.$store.state.ShiftModule.OrganizationCalendar);  
            let moment = require('moment-timezone');

            if (allShifts){
                if (this.showPastShifts === false){
                    allShifts.forEach((shift) => { 
                        if (moment.utc(shift.ShiftEndUtc) > moment.utc()){
                            shift.StartTime = new Date(moment.tz(shift.ShiftStartUtc, shift.LocalTimeZone)); 
                            shift.EndTime = new Date(moment.tz(shift.ShiftEndUtc, shift.LocalTimeZone));
                            shift.StartTimezone = shift.LocalTimeZone,
                            shift.EndTimezone = shift.LocalTimeZone
                        }               
                    });
                }

                if (this.showPastShifts === true){
                     allShifts.forEach((shift) => { 
                        shift.StartTime = new Date(moment.tz(shift.ShiftStartUtc, shift.LocalTimeZone));  
                        shift.EndTime = new Date(moment.tz(shift.ShiftEndUtc, shift.LocalTimeZone));        
                        shift.StartTimezone = shift.LocalTimeZone,
                        shift.EndTimezone = shift.LocalTimeZone         
                    });
                }
            };

            this.eventSettings = { dataSource: allShifts};
        },
        getShiftsForVisableDates () {
            let visableDates = this.$refs.shiftRequestsCalendarModal.$children[1].getCurrentViewDates();
            let paramsDto = {};
            let calendarStartDate = '';
            let calendarEndDate = '';

            if (visableDates) {
                calendarStartDate = visableDates[0];
                calendarEndDate = visableDates[visableDates.length - 1]
            }

            let moment = require('moment-timezone');
            let utcNow = moment.utc();

            let startDay = moment.utc(calendarStartDate).startOf('day');
            let endDay = moment.utc(calendarEndDate).startOf('day').endOf('day');
            let timeZoneOffset = moment.tz.zone(this.organizationTimeZone).utcOffset(utcNow);

            paramsDto.StartDateRange = startDay.add(timeZoneOffset).format();
            paramsDto.EndDateRange = endDay.add(timeZoneOffset).format();
                
            this.$store.dispatch('ShiftModule/getOrganizationCalendar', paramsDto).then(() => {
                this.shiftsDataSource();
            });        
        },
        OnActionCompletion(args){
            if (args.event && (args.requestType === 'viewNavigate' || args.requestType === 'dateNavigate')) {
               this.getShiftsForVisableDates();
            }
        },
        OnEventRendered(args) { 
            let categoryColor; 
            if (args.data.ShiftStatus === "Closed") { 
                categoryColor = '#2364AA'; 
                args.element.style.borderColor = categoryColor; 
                args.element.style.backgroundColor = categoryColor; 
            } else if (args.data.ShiftStatus === "Completed") { 
                categoryColor = '#48c064'; 
                args.element.style.borderColor = categoryColor; 
                args.element.style.backgroundColor = categoryColor; 
            } else if (args.data.ShiftStatus === "Resigned") { 
                categoryColor = '#Ef5D60'; 
                args.element.style.borderColor = categoryColor; 
                args.element.style.backgroundColor = categoryColor; 
            } else if (args.data.ShiftStatus === "Confirmed") { 
                categoryColor = '#48c064'; 
                args.element.style.borderColor = categoryColor; 
                args.element.style.color = categoryColor; 
                args.element.style.backgroundColor = '#FFFFFF'; 
            } else if (args.data.ShiftStatus === "Pending") { 
                categoryColor = '#EA7317';  
                args.element.style.borderColor = categoryColor; 
                args.element.style.color = categoryColor; 
                args.element.style.backgroundColor = '#FFFFFF'; 
            } else if (args.data.ShiftStatus === "Assigned") { 
                categoryColor = '#2364AA'; 
                args.element.style.borderColor = categoryColor; 
                args.element.style.color = categoryColor; 
                args.element.style.backgroundColor = '#FFFFFF'; 
            } else if (args.data.ShiftStatus === "Late") { 
                categoryColor = '#Ef5D60'; 
                args.element.style.borderColor = categoryColor; 
                args.element.style.backgroundColor = categoryColor; 
            } else if (args.data.ShiftStatus === "On Duty") { 
                categoryColor = '#48c064'; 
                args.element.style.borderColor = categoryColor; 
                args.element.style.backgroundColor = categoryColor; 
            } else if (args.data.ShiftStatus === "Clocked Out") { 
                categoryColor = '#2364AA'; 
                args.element.style.borderColor = categoryColor; 
                args.element.style.backgroundColor = categoryColor; 
            } 
        },
        OnPopupOpen(args) { 
            let categoryColor; 
            if (args.data.ShiftStatus === "Closed") { 
                categoryColor = '#2364AA'; 
                args.element.style.borderColor = categoryColor; 
                args.element.style.backgroundColor = '#FFFFFF'; 
                args.element.style.color = '#000000';  
            } else if (args.data.ShiftStatus === "Completed") { 
                categoryColor = '#48c064'; 
                args.element.style.borderColor = categoryColor; 
                args.element.style.backgroundColor = '#FFFFFF'; 
                args.element.style.color = '#000000'; 
            } else if (args.data.ShiftStatus === "Resigned") { 
                categoryColor = '#Ef5D60'; 
                args.element.style.borderColor = categoryColor; 
                args.element.style.backgroundColor = '#FFFFFF'; 
                args.element.style.color = '#000000'; 
            } else if (args.data.ShiftStatus === "Confirmed") { 
                categoryColor = '#48c064'; 
                args.element.style.borderColor = categoryColor; 
                args.element.style.color = categoryColor; 
                args.element.style.backgroundColor = '#FFFFFF'; 
            } else if (args.data.ShiftStatus === "Pending") { 
                categoryColor = '#EA7317';  
                args.element.style.borderColor = categoryColor; 
                args.element.style.color = categoryColor; 
                args.element.style.backgroundColor = '#FFFFFF'; 
            } else if (args.data.ShiftStatus === "Assigned") { 
                categoryColor = '#2364AA'; 
                args.element.style.borderColor = categoryColor; 
                args.element.style.color = categoryColor; 
                args.element.style.backgroundColor = '#FFFFFF'; 
            } else if (args.data.ShiftStatus === "Late") { 
                categoryColor = '#Ef5D60'; 
                args.element.style.borderColor = categoryColor; 
                args.element.style.color = '#000000'; 
                args.element.style.backgroundColor = '#FFFFFF'; 
            } else if (args.data.ShiftStatus === "On Duty") { 
                categoryColor = '#48c064'; 
                args.element.style.borderColor = categoryColor; 
                args.element.style.color = '#000000'; 
                args.element.style.backgroundColor = '#FFFFFF'; 
            } else if (args.data.ShiftStatus === "Clocked Out") { 
                categoryColor = '#2364AA'; 
                args.element.style.borderColor = categoryColor; 
                args.element.style.color = '#000000'; 
                args.element.style.backgroundColor = '#FFFFFF'; 
            } 
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    mounted() {
       this.getShiftsForVisableDates();
    },
    created() {     
    },
}
</script>
