<template src="./messagesShiftThreadModal.html"></template>

<script>
import dmModalMixin from '../../../../dmFramework/dmJS/dmModalMixin'
import messagesThreadPage from '../../../messagesComponents/messagesShiftThreadPage/messagesShiftThreadPage'

export default {
    name: 'messages-shift-thread-modal',
    mixins: [dmModalMixin],
    components: {
        messagesThreadPage
    },
    data() {
        return {

        }
    },
    computed: {
        lblTitle() {
            return this.modalData.selectedCaregiverName;
        },
    },
    methods: {
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {

    },
}
</script>
