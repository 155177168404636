import endpoints from "../../../js/endpoints";

const licenseGlobalRatesModule = {
    namespaced: true,
    state: () => ({
        AllGlobalRates: []
    }),
    mutations: {
        setGlobalRates(state, globalRates) {
            state.AllGlobalRates = globalRates;
        },
    },
    actions: {
        getAllOrganizationGlobalRatesForOrganization({commit}) {
            commit('setGlobalRates', []);

            return endpoints.getAllOrganizationGlobalRatesForOrganization()
                .then((response) => {
                    commit('setGlobalRates', response);
                });
        },  
        getAllOrganizationGlobalRatesForOrganizationMyShyftAdmin({commit}, organizationID) {
            commit('setGlobalRates', []);

            return endpoints.getAllOrganizationGlobalRatesForOrganizationMyShyftAdmin(organizationID)
                .then((response) => {
                    commit('setGlobalRates', response);
                });
        },     
        getOrganizationGlobalRateByID({}, globalRateIDs) {
            return endpoints.getOrganizationGlobalRateByID(globalRateIDs)
                .then((response) => {
                    return response;
                });
        },
        createOrganizationGlobalRate({dispatch}, dto) {
            return endpoints.createOrganizationGlobalRate(dto)
                .then(() => {
                    dispatch('getAllOrganizationGlobalRatesForOrganizationMyShyftAdmin', dto.OrganizationID);
                });
        },  
        updateOrganizationGlobalRate({dispatch}, dto) {
            return endpoints.updateOrganizationGlobalRate(dto)
                .then(() => {
                    dispatch('getAllOrganizationGlobalRatesForOrganizationMyShyftAdmin', dto.OrganizationID);
                });
        },
        deleteOrganizationGlobalRate({dispatch}, dto) {
            return endpoints.deleteOrganizationGlobalRate(dto)
                .then(() => {
                    dispatch('getAllOrganizationGlobalRatesForOrganization');
                });
        },   
        deleteOrganizationGlobalRateMyShyftAdmin({dispatch}, dto) {
            return endpoints.deleteOrganizationGlobalRate(dto)
                .then(() => {
                    dispatch('getAllOrganizationGlobalRatesForOrganizationMyShyftAdmin', dto.OrganizationID);
                });
        },         
    },
};

export default licenseGlobalRatesModule;
