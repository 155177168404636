<template src="./shiftRequestsPage.html"></template>

<script>
import Vue from 'vue';
import dmStates from '../../../../../dmFramework/dmJS/dmStates.js';

export default {
    name: 'admin-organization-requests-page',
    data() {
        return {
            searchStartDate: '',
            searchEndDate: '',
            stateList: dmStates,
            dto: {},
            paramsDto: {},
            allowFiltering: true,
            filterType: 'Contains',
            statusList: [
                'Pending',
                'Assigned',
                'Confirmed',
                'Resigned',
                'Closed',
            ],
            organizationNameList: [],
            organizationFields: { text: 'OrganizationName', value: 'OrganizationID' },
            shiftRequestsTableColumns: [],
            shiftsNotInvoicedTableColumns: [],
            shiftRequestsTableSortBy: {
                columns: [{ field: 'ShiftStartUtc', direction: 'Descending' }],
            },
            selectedRow: null,
            shiftRequestsTablePageSettings: { pageSize: 300, pageSizes: [500, 300, 100, 50], pageCount: 4, direction: 'Descending' },
            shiftsNotInvoicedTablePageSettings: { pageSize: 300, pageSizes: [500, 300, 100, 50], pageCount: 4, direction: 'Descending' },
        };
    },
    computed: {
        shiftRequestsTableItems() {
            let shifts = this.$store.state.ShiftModule.AllShifts;

            if (shifts) {
                shifts.forEach((shift) => {
                    shift.MedicalLicenseNames = [];
                    let licenses = shift.MedicalLicenses;
                    if (licenses) {
                        let licenseCounter = 0;
                        licenses.forEach((license) => {
                            shift.MedicalLicenseNames += license.LicenseName;
                            licenseCounter++;
                            if (licenses.length > licenseCounter) {
                                shift.MedicalLicenseNames += ', ';
                            }
                        });
                    }

                    if (shift.ConfirmedHourlyRate === -1) {
                        shift.ConfirmedHourlyRate = 'N/A';
                    } else {
                        shift.ConfirmedHourlyRate = shift.ConfirmedHourlyRate * (1 + shift.HourlyRateMarkUpPercentage);
                    }
                });
            }

            return {
                result: shifts,
                count: this.$store.state.ShiftModule.AllShiftsNumberOfRows,
            };
        },
        shiftsNotInvoicedTableItems() {
            let shifts = this.$store.state.ShiftModule.AllShiftsNotInvoiced;

            if (shifts) {
                shifts.forEach((shift) => {
                    shift.MedicalLicenseNames = [];
                    let licenses = shift.MedicalLicenses;
                    if (licenses) {
                        let licenseCounter = 0;
                        licenses.forEach((license) => {
                            shift.MedicalLicenseNames += license.LicenseName;
                            licenseCounter++;
                            if (licenses.length > licenseCounter) {
                                shift.MedicalLicenseNames += ', ';
                            }
                        });
                    }
                });
            }

            return {
                result: shifts,
                count: this.$store.state.ShiftModule.ShiftsNotInvoicedNumberOfRows,
            };
        },
        myShyftAdminTimeZone() {
            return this.$store.state.AdminModule.MyShyftAdminTimeZone;
        },
    },
    methods: {
        scrollshiftRequestsGridToTop() {
            this.$refs.shiftRequestsTable.$refs.grdTableList.getContent().children[0].scrollTop = 0;
        },
        scrollShiftsNotInvoicedGridToTop() {
            this.$refs.shiftsNotInvoicedTable.$refs.grdTableList.getContent().children[0].scrollTop = 0;
        },
        filtering: function (e) {
            let query = new Query();
            query = e.text != '' ? query.where('Contains', e.text, true) : query;
            e.updateData(this.organizationNameList, query);
        },
        getCurrentTabList() {
            try {
                let selectingID = this.$refs.shiftRequestTabs.ej2Instances.selectingID;
                if (selectingID === '0') {
                    this.searchshiftRequests();
                    return;
                }

                if (selectingID === '1') {
                    this.searchShiftsNotInvoiced();
                    return;
                }               
            } catch (error) {
                //Throwing the error away to avoid undefined selectingID
            }

            this.searchshiftRequests();
        },
        searchshiftRequests() {
            let moment = require('moment-timezone');
            let utcNow = moment.utc();

            let startDay = moment.utc(this.searchStartDate).startOf('day');
            let endDay = moment.utc(this.searchEndDate).endOf('day');
            let timeZoneOffset = moment.tz.zone(this.myShyftAdminTimeZone).utcOffset(utcNow);

            this.paramsDto.StartDateRange = startDay.add(timeZoneOffset).format();
            this.paramsDto.EndDateRange = endDay.add(timeZoneOffset).format();
            this.paramsDto.PageToLoad = this.$refs.shiftRequestsTable.$refs.grdTableList.ej2Instances.pagerModule.pagerObj.properties.currentPage;
            this.paramsDto.RowsPerPage = this.shiftRequestsTablePageSettings.pageSize;
            this.paramsDto.SortByColumn = this.shiftRequestsTablePageSettings.columnName;
            this.paramsDto.SortDescending = this.shiftRequestsTablePageSettings.direction === 'Descending';

            this.scrollshiftRequestsGridToTop();
            this.$store.dispatch('ShiftModule/getAllShiftsMyShyftAdmin', this.paramsDto);
        },
        searchShiftsNotInvoiced() {
            let moment = require('moment-timezone');
            let utcNow = moment.utc();

            let startDay = moment.utc(this.searchStartDate).startOf('day');
            let endDay = moment.utc(this.searchEndDate).endOf('day');
            let timeZoneOffset = moment.tz.zone(this.myShyftAdminTimeZone).utcOffset(utcNow);

            this.paramsDto.StartDateRange = startDay.add(timeZoneOffset).format();
            this.paramsDto.EndDateRange = endDay.add(timeZoneOffset).format();
            this.paramsDto.PageToLoad = this.$refs.shiftsNotInvoicedTable.$refs.grdTableList.ej2Instances.pagerModule.pagerObj.properties.currentPage;
            this.paramsDto.RowsPerPage = this.shiftsNotInvoicedTablePageSettings.pageSize;
            this.paramsDto.SortByColumn = this.shiftsNotInvoicedTablePageSettings.columnName;
            this.paramsDto.SortDescending = this.shiftsNotInvoicedTablePageSettings.direction === 'Descending';

            this.scrollShiftsNotInvoicedGridToTop();
            this.$store.dispatch('ShiftModule/getNotInvoicedMyShyftAdmin', this.paramsDto);
        },
        shiftRequestsTableRowOnClick(args) {
            this.selectedRow = args;
        },
        createshiftRequest() {
            let modalData = {
                dto: {
                    ConfirmedHourlyRate: 0,
                    ConfirmedCaregiverID: null,
                    OrganizationID: null,
                    ShiftClockInUtc: null,
                    ShiftClockOutUtc: null,
                    ShiftEndUtc: null,
                    ShiftStartUtc: null,
                },
            };

            DMUtils.openApplicationModal('shiftRequestsCreateMyShyftAdminModal', modalData);
        },
        viewshiftRequest() {
            this.$store
                .dispatch('ShiftModule/getShiftByIDMyShyftAdmin', this.selectedRow.rowData.ShiftID)
                .then((response) => {
                    let modalData = {
                        dto: response,
                        accountType: 'MyShyftUser',
                    };
                    DMUtils.openApplicationModal('shiftRequestsViewModal', modalData);
                });
        },
        viewShiftApplicants() {
            this.$store
                .dispatch('ShiftModule/getShiftByIDMyShyftAdmin', this.selectedRow.rowData.ShiftID)
                .then((response) => {
                    let modalData = {
                        mode: 'view',
                        dto: response,
                    };
                    DMUtils.openApplicationModal('shiftApplicantsModal', modalData);
                });
        },
        viewShiftHistory() {
            this.$store
                .dispatch('ShiftModule/getShiftByIDMyShyftAdmin', this.selectedRow.rowData.ShiftID)
                .then((response) => {
                    let modalData = {
                        dto: response,
                    };
                    DMUtils.openApplicationModal('shiftRequestsShiftHistoryModal', modalData);
                });
        },
        viewClocks() {
            this.$store
                .dispatch('ShiftModule/getShiftByIDMyShyftAdmin', this.selectedRow.rowData.ShiftID)
                .then((response) => {
                    let modalData = {
                        dto: response,
                        accountType: 'MyShyftUser',
                    };
                    DMUtils.openApplicationModal('shiftRequestsClocksModal', modalData);
                });
        },
        deleteShiftRequest() {
            this.$store
                .dispatch('ShiftModule/getShiftByIDMyShyftAdmin', this.selectedRow.rowData.ShiftID)
                .then((response) => {
                    let modalData = {
                        dto: response,
                        accountType: 'MyShyftUser',
                    };

                    modalData.dto.DeletedType = null;
                    modalData.dto.Params = this.paramsDto;

                    DMUtils.openApplicationModal('shiftRequestsDeleteModal', modalData);
                });
        },
        shiftRequestsTableActionOnClick(args) {
            if (args.item.text === 'View Request') {
                this.viewshiftRequest();
            } else if (args.item.text === 'View Shift Applicants') {
                this.viewShiftApplicants();
            } else if (args.item.text === 'View Shift History') {
                this.viewShiftHistory();
            } else if (args.item.text === 'Clock In/Out Time List') {
                this.viewClocks();
            } else if (args.item.text === 'View Chat') {
                this.viewChat();
            } else if (args.item.text === 'Delete') {
                this.deleteShiftRequest();
            }
        },
        shiftRequestsTableDataStateChange(state) {
            if (state.action.requestType === 'sorting' && state.action.direction && state.action.columnName) {
                this.shiftRequestsTablePageSettings.direction = state.action.direction;
                this.shiftRequestsTablePageSettings.columnName = state.action.columnName;
                this.searchshiftRequests();
            }

            if (state.action.requestType === 'paging') {
                this.shiftRequestsTablePageSettings.pageSize = state.take;
                this.searchshiftRequests();
            }
        },
        shiftsNotInvoicedTableDataStateChange(state) {
            if (state.action.requestType === 'sorting' && state.action.direction && state.action.columnName) {
                this.shiftsNotInvoicedTablePageSettings.direction = state.action.direction;
                this.shiftsNotInvoicedTablePageSettings.columnName = state.action.columnName;
                this.searchShiftsNotInvoiced();
            }

            if (state.action.requestType === 'paging') {
                this.shiftsNotInvoicedTablePageSettings.pageSize = state.take;
                this.searchShiftsNotInvoiced();
            }
        },
        viewChat() {
            let openModal = () => {
                let modalData = {
                    selectedShiftID: this.selectedRow.rowData.ShiftID,
                    selectedCaregiverID: this.selectedRow.rowData.ConfirmedCaregiverID,
                    selectedCaregiverName: this.selectedRow.rowData.CaregiverName,
                    selectedOrganizationID: this.selectedRow.rowData.OrganizationID
                };

                DMUtils.openApplicationModal('messagesShiftThreadModal', modalData);
            };

            openModal();
        },
    },
    mounted() {
        this.$refs.shiftRequestTabs.ej2Instances.animation.next.effect = 'None';
        this.$refs.shiftRequestTabs.ej2Instances.animation.previous.effect = 'None';

        this.getCurrentTabList();
    },
    created() {
        this.$store.dispatch('AdminOrganizationModule/getAllOrganizationNames').then((response) => {
            this.organizationNameList = response;
        });

        let shortcutOrganizationID = this.$store.getters['NavigationModule/ShortcutOrganizationID'];
        if (shortcutOrganizationID > 0) {
            this.paramsDto.OrganizationID = shortcutOrganizationID;
            this.$store.commit('NavigationModule/setShortcutOrganizationID', 0);
        }

        let self = this;
        this.shiftRequestsTableColumns = [
            {
                type: 'template',
                template: () => {
                    return {
                        template: Vue.component('columnTemplate', {
                            template: `<ejs-dropdownbutton
                                        :items= "actions"
                                        :select= "select"
                                        iconCss= "e-icons e-ellipsis-vert"
                                        cssClass= "e-caret-hide">
                                    </ejs-dropdownbutton>`,
                            methods: {
                                select(args) {
                                    self.shiftRequestsTableActionOnClick(args);
                                },
                            },
                            data() {
                                return {
                                    data: {},
                                };
                            },
                            computed: {
                                actions() {
                                    let actions = [
                                        {
                                            text: 'View Request',
                                        },
                                        {
                                            text: 'View Shift Applicants',
                                        },
                                        {
                                            text: 'View Shift History',
                                        },
                                        {
                                            text: 'Clock In/Out Time List',
                                        },
                                    ];

                                    if (this.data.ConfirmedCaregiverID) {
                                        actions.push({
                                            text: 'View Chat',
                                        });
                                    }

                                    actions.push({
                                        text: 'Delete',
                                    });

                                    return actions;
                                },
                            },
                        }),
                    };
                },
                width: '55px',
            },
            {
                key: 'ShiftID',
                label: 'ID',
                width: '75px',
            },
            {
                key: 'OrganizationName',
                label: 'Organization Name',
                width: '200px',
            },
            {
                key: 'NumberOfApplicants',
                label: 'Applicants',
                textAlign: 'Center',
                width: '140px',
            },
            {
                key: 'CaregiverName',
                label: 'Assigned Caregiver',
                width: '200px',
            },
            {
                key: 'HoursWorked',
                label: 'Hours Worked',
                format: 'N2',
                textAlign: 'Right',
                width: '140px',
            },
            {
                key: 'CaregiverAmount',
                label: 'Caregiver Amount',
                format: 'C2',
                textAlign: 'Right',
                width: '160px',
            },
            {
                key: 'ShiftStatus',
                label: 'Status',
                width: '120px',
                template: function () {
                    return {
                        template: Vue.component('columnTemplate', {
                            template: `<div :class="setColor">{{data.ShiftStatus}}</div>`,
                            computed: {
                                setColor: function () {
                                    if (this.data.ShiftStatus === 'Pending') {
                                        return 'app-grid-status-orange';
                                    }

                                    if (this.data.ShiftStatus === 'Assigned') {
                                        return 'app-grid-status-blue';
                                    }

                                    if (this.data.ShiftStatus === 'Late') {
                                        return 'app-grid-status-red';
                                    }

                                    if (this.data.ShiftStatus === 'On Duty') {
                                        return 'app-grid-status-green';
                                    }

                                    if (this.data.ShiftStatus === 'Clocked Out') {
                                        return 'app-grid-status-blue';
                                    }

                                    if (this.data.ShiftStatus === 'Confirmed') {
                                        return 'app-grid-status-green';
                                    }
                                    if (this.data.ShiftStatus === 'Resigned') {
                                        return 'app-grid-status-red';
                                    }

                                    if (this.data.ShiftStatus === 'Completed') {
                                        return 'app-grid-status-green';
                                    }

                                    if (this.data.ShiftStatus === 'Closed') {
                                        return 'app-grid-status-blue';
                                    }

                                    return 'app-grid-status-blue';
                                },
                            },
                        }),
                    };
                },
            },
            {
                key: 'MedicalLicenseNames',
                label: 'Licenses Requested',
                width: '400px',
                allowSorting: false,
            },
            {
                key: 'ShiftStartUtc',
                label: 'Shift Start Time',
                width: '240px',
                formatter(column, shift) {
                    return moment.tz(shift.ShiftStartUtc, shift.LocalTimeZone).format("ddd MM/DD/YYYY hh:mmA z"); 
                }
            },
            {
                key: 'ShiftEndUtc',
                label: 'Shift End Time',
                width: '240px',
                formatter(column, shift) {
                    return moment.tz(shift.ShiftEndUtc, shift.LocalTimeZone).format("ddd MM/DD/YYYY hh:mmA z"); 
                }
            },  
            {
                key: 'CreatedUtc',
                label: 'Created On',
                width: '240px',
                formatter(column, shift) {
                    return moment.tz(shift.CreatedUtc, shift.LocalTimeZone).format("ddd MM/DD/YYYY hh:mmA z"); 
                }
            },
        ];

        this.shiftsNotInvoicedTableColumns = [
            {
                type: 'template',
                template: () => {
                    return {
                        template: Vue.component('columnTemplate', {
                            template: `<ejs-dropdownbutton
                                        :items= "actions"
                                        :select= "select"
                                        iconCss= "e-icons e-ellipsis-vert"
                                        cssClass= "e-caret-hide">
                                    </ejs-dropdownbutton>`,
                            methods: {
                                select(args) {
                                    self.shiftRequestsTableActionOnClick(args);
                                },
                            },
                            data() {
                                return {
                                    data: {},
                                };
                            },
                            computed: {
                                actions() {
                                    let actions = [
                                        {
                                            text: 'View Request',
                                        },
                                        {
                                            text: 'View Shift Applicants',
                                        },
                                        {
                                            text: 'View Shift History',
                                        },
                                        {
                                            text: 'Clock In/Out Time List',
                                        },
                                    ];

                                    if (this.data.ConfirmedCaregiverID) {
                                        actions.push({
                                            text: 'View Chat',
                                        });
                                    }

                                    actions.push({
                                        text: 'Delete',
                                    });

                                    return actions;
                                },
                            },
                        }),
                    };
                },
                width: '55px',
            },
            {
                key: 'ShiftID',
                label: 'ID',
                width: '75px',
            },
            {
                key: 'OrganizationName',
                label: 'Organization Name',
                width: '200px',
            },
            {
                key: 'CaregiverName',
                label: 'Assigned Caregiver',
                width: '200px',
            },
            {
                key: 'TotalCaregiverAmountForInvoiceID',
                label: 'Caregiver Amount',
                format: 'C2',
                textAlign: 'Right',
                width: '160px',
            },
            {
                key: 'TotalOrganizationAmountForInvoiceID',
                label: 'Org. Amount',
                format: 'C2',
                textAlign: 'Right',
                width: '160px',
            },
            {
                key: 'ShiftStatus',
                label: 'Status',
                width: '120px',
                template: function () {
                    return {
                        template: Vue.component('columnTemplate', {
                            template: `<div :class="setColor">{{data.ShiftStatus}}</div>`,
                            computed: {
                                setColor: function () {
                                    if (this.data.ShiftStatus === 'Pending') {
                                        return 'app-grid-status-orange';
                                    }

                                    if (this.data.ShiftStatus === 'Assigned') {
                                        return 'app-grid-status-blue';
                                    }

                                    if (this.data.ShiftStatus === 'Late') {
                                        return 'app-grid-status-red';
                                    }

                                    if (this.data.ShiftStatus === 'On Duty') {
                                        return 'app-grid-status-green';
                                    }

                                    if (this.data.ShiftStatus === 'Clocked Out') {
                                        return 'app-grid-status-blue';
                                    }

                                    if (this.data.ShiftStatus === 'Confirmed') {
                                        return 'app-grid-status-green';
                                    }
                                    if (this.data.ShiftStatus === 'Resigned') {
                                        return 'app-grid-status-red';
                                    }

                                    if (this.data.ShiftStatus === 'Completed') {
                                        return 'app-grid-status-green';
                                    }

                                    if (this.data.ShiftStatus === 'Closed') {
                                        return 'app-grid-status-blue';
                                    }

                                    return 'app-grid-status-blue';
                                },
                            },
                        }),
                    };
                },
            },
            {
                key: 'MedicalLicenseNames',
                label: 'Licenses Requested',
                width: '400px',
                allowSorting: false,
            },
            {
                key: 'ShiftStartUtc',
                label: 'Shift Start Time',
                width: '240px',
                formatter(column, shift) {
                    return moment.tz(shift.ShiftStartUtc, shift.LocalTimeZone).format("ddd MM/DD/YYYY hh:mmA z"); 
                }
            },
            {
                key: 'ShiftEndUtc',
                label: 'Shift End Time',
                width: '240px',
                formatter(column, shift) {
                    return moment.tz(shift.ShiftEndUtc, shift.LocalTimeZone).format("ddd MM/DD/YYYY hh:mmA z"); 
                }
            },
            {
                key: 'CreatedUtc',
                label: 'Created On',
                width: '240px',
                formatter(column, shift) {
                    return moment.tz(shift.CreatedUtc, shift.LocalTimeZone).format("ddd MM/DD/YYYY hh:mmA z"); 
                }
            },
        ];
    },
};
</script>
