<template src="./messagesAdminThreadPage.html"></template>

<script>
import Vue from 'vue'
import messagesMixin from '../messagesMixin';

let messageComponent = Vue.component('message', {
    template: `<div class="dm-flex-column dm-align-items-flex-end dm-margin-top-sm" v-if="data.rightSide" :key="data.AdminMessageID" :data-message-id="data.AdminMessageID">
                    <div class="date">{{ data.dateDisplay }}</div>
                    <div class="name dm-padding-right-sm">{{ data.senderName }}</div>
                    <div class="content1 dm-flex-column dm-align-items-flex-end dm-padding-sm">
                        <div class="info1">{{ data.MessageText }}</div>
                    </div>
                    <div class="time">{{ data.createdTime }}</div>
                </div>
                <div v-else class="dm-flex-column dm-align-items-start dm-margin-top-sm" :key="data.AdminMessageID" :data-message-id="data.AdminMessageID">
                    <div class="date">{{ data.dateDisplay }}</div>
                    <div class="name dm-padding-left-sm">{{ data.senderName }}</div>
                    <div class="content2 dm-padding-sm">
                        <div class="info1">{{ data.MessageText }}</div>
                    </div>
                    <div class="time">{{ data.createdTime }}</div>
                </div>`,
    data() {
        return {

        };
    }
});

export default {
    name: 'messages-admin-thread-page',
    mixins: [
        messagesMixin
    ],
    props: [
        'selectedCaregiverID',
        'selectedMessageID',
        'modalView'
    ],
    data() {
        return {
            messageText: '',
            messages: [],
            fields: { text: 'Name' },
            messageTemplate: function(e) {
                return {
                    template: messageComponent,
                };
            },
            recipientName: '',
            endpointsMap: {
                Caregiver: {
                    createMessage: 'createMessageAdmin',
                    getAllMessages: 'getAdminByIDCaregiver',
                    getNewMessages: 'getNewAdminMessagesCaregiver',
                },
                MyShyftAdmin: {
                    createMessage: 'createMessageAdmin',
                    getAllMessages: 'getAdminByIDMyShyftAdmin',
                    getNewMessages: 'getNewAdminMessagesMyShyftAdmin',
                },
                MyShyftEmployee: {
                    createMessage: 'createMessageAdmin',
                    getAllMessages: 'getAdminByIDMyShyftAdmin',
                    getNewMessages: 'getNewAdminMessagesMyShyftAdmin',
                },
                MyShyftViewer: {
                    createMessage: 'createMessageAdmin',
                    getAllMessages: 'getAdminByIDMyShyftAdmin',
                    getNewMessages: 'getNewAdminMessagesMyShyftAdmin',
                },
            },
            caregiverID: 0
        };
    },
    watch: {
        selectedCaregiverID(newCaregiverID, oldCaregiverID) {
            this.caregiverID = newCaregiverID;

            if(this.caregiverID) {
                this.getAllMessages();
            }
        },
        selectedMessageID(newMessageID) {
            this.scrollToMessage(newMessageID);
        }
    },
    methods: {
        formatMessages(messages) {
            let dateDisplayList = [];

            messages.forEach((m, index) => {
                if(this.signedInUser.Role === 'Caregiver') {
                    m.rightSide = m.IsCaregiver;
                    m.senderName = m.IsCaregiver ? this.signedInUser.FirstLastName : 'MyShyft Admin';
                    this.recipientName = 'MyShyft Admin';
                } else if(this.signedInUser.Role === 'MyShyftAdmin' || this.signedInUser.Role === 'MyShyftEmployee' || this.signedInUser.Role === 'MyShyftViewer') {
                    m.rightSide = !m.IsCaregiver;
                    if(m.IsCaregiver) {
                        m.senderName = m.SenderName;
                        this.recipientName = m.SenderName;
                    } else {
                        m.senderName = 'MyShyft Admin (' + m.SenderName + ')';
                    }

                }

                let timezone = moment.tz.guess();
                this.formatThreadDateTimeDetails(m, m.MessageCreatedUtc, timezone, dateDisplayList);
            });

            if(messages.length === 0 && this.isCaregiverOrganizationApp) {
                this.recipientName = 'MyShyft Admin';
            }

            return messages;
        },
        getAllMessages() {
            let caregiverID = this.isCaregiverOrganizationApp ? this.signedInUser.CaregiverID : this.selectedCaregiverID;
            endpoints[this.userRoleEndpoints.getAllMessages](caregiverID, true)
                .then(response => {
                    this.messages = this.formatMessages(response);

                    setTimeout(() => {
                        if(this.isCaregiverOrganizationApp) {
                            this.scrollToMessage(this.$route.params.messageID);
                        } else {
                            this.scrollToMessage(this.selectedMessageID);
                        }
                    });
            });
        },
        getNewMessages(newUserMessage) {
            let caregiverID = this.isCaregiverOrganizationApp ? this.signedInUser.CaregiverID : this.selectedCaregiverID;
            return endpoints[this.userRoleEndpoints.getNewMessages](caregiverID, true)
                .then(response => {
                    if (this.signedInUser.Role === 'MyShyftViewer') {
                        return response;
                    }

                    if (newUserMessage) {
                        response.push(newUserMessage);
                    }                 
                    this.messages = this.formatMessages(this.messages.concat(response));

                    if(response.length > 0) {
                        setTimeout(() => {
                            document.getElementById('List').scrollTop = document.getElementById('List').scrollHeight;
                        });
                    }
                    return response;
            });
        },
        sendMessage() {
            this.getNewMessages({
                AdminMessageID: DMUtils.getRandomGuid(),
                MessageText: this.messageText,
                MessageCreatedUtc: moment().format(),
                IsCaregiver: this.isCaregiverOrganizationApp,
                SenderName: this.signedInUser.FirstName  + ' ' + this.signedInUser.LastName
            })
                .then(() => {
                    return endpoints[this.userRoleEndpoints.createMessage]({
                        CaregiverID: this.isCaregiverOrganizationApp ? this.signedInUser.CaregiverID : this.selectedCaregiverID,
                        MessageText: this.messageText
                    }, true);
                })
                .then(() => {
                    this.messageText = '';
                });
        },
        scrollToMessage(messageID) {
            let messageDiv = document.querySelector('div[data-message-id="' + messageID + '"]');
            if(messageDiv) {
                document.getElementById('List').scrollTop = messageDiv.parentElement.offsetTop;
            } else {
                document.getElementById('List').scrollTop = document.getElementById('List').scrollHeight;
            }
        }
    },
    created() {
        this.userRoleEndpoints = this.endpointsMap[this.signedInUser.Role];

        if(this.isCaregiverOrganizationApp) {
            this.caregiverID = this.signedInUser.CaregiverID;
            this.getAllMessages();
        } else {
            this.caregiverID = this.selectedCaregiverID;
            this.getAllMessages();
        }

        this.newMessagesTimer = setInterval(() => {
            if(document.hasFocus() && this.caregiverID) { // Don't keep getting messages in the background
                this.getNewMessages();
            }
        }, 5 * 1000);
    },
    destroyed() {
        clearInterval(this.newMessagesTimer);
    }
};
</script>
