<template src='./app.html'></template>

<script>
    //Shared
    import dmAppMixin from '../dmFramework/dmJS/dmAppMixin.js';
    import signInPage from './views/shared/authorization/signInPage/signInPage.vue';
    import usersMyProfileEditModal from './views/menus/usersMyProfileEditModal/usersMyProfileEditModal.vue';
    import usersChangePasswordModal from './views/menus/usersChangePasswordModal/usersChangePasswordModal.vue';
    import notificationHistoryModal from './views/menus/notificationHistoryModal/notificationHistoryModal.vue';
    import csvExportModal from './views/menus/csvExportModal/csvExportModal.vue';
    import usersEditModal from './views/shared/users/usersEditModal/usersEditModal.vue';
    import viewImageModal from './views/shared/viewImageModal/viewImageModal.vue';

    import organizationContactsModal from './views/shared/organization/organizationContactsModal/organizationContactsModal.vue';
    import organizationAddContactModal from './views/shared/organization/organizationAddContactModal/organizationAddContactModal.vue';
    
    import shiftRequestsViewModal from './views/shared/shifts/shiftRequestsViewModal/shiftRequestsViewModal.vue';
    import shiftRequestsShiftHistoryModal from './views/shared/shifts/shiftRequestsShiftHistoryModal/shiftRequestsShiftHistoryModal.vue';
    import shiftApplicantsModal from './views/shared/shifts/shiftApplicantsModal/shiftApplicantsModal.vue';  
    import shiftApplicantBlockConfirmModal from './views/shared/shifts/shiftApplicantBlockConfirmModal/shiftApplicantBlockConfirmModal.vue';
    import messagesShiftActiveChatPage from './views/shared/messagesShiftActiveChatPage/messagesShiftActiveChatPage'
    import messagesShiftThreadModal from './views/shared/messagesShiftThreadModal/messagesShiftThreadModal'

    import invoicesPage from './views/shared/invoices/invoicesPage/invoicesPage.vue';
    import viewShiftsForInvoiceModal from './views/shared/invoices/viewShiftsForInvoiceModal/viewShiftsForInvoiceModal.vue';
    import invoicesPayModal from './views/shared/invoices/invoicesPayModal/invoicesPayModal.vue';
    import invoicesSelectionModal from './views/shared/invoices/invoicesSelectionModal/invoicesSelectionModal.vue';

    import licenseGlobalRatesModal from './views/shared/organizationGlobalRates/licenseGlobalRatesModal/licenseGlobalRatesModal.vue';
    import editLicenseGlobalRatesModal from './views/shared/organizationGlobalRates/editLicenseGlobalRatesModal/editLicenseGlobalRatesModal.vue';

    import organizationLocationEditModal from './views/shared/organizationLocations/organizationLocationEditModal/organizationLocationEditModal.vue';
   
    //Organization only
    import organizationDashboardPage from './views/organization/organizationDashboard/organizationDashboardPage/organizationDashboardPage.vue';
   
    import paymentsPage from './views/organization/payments/paymentsPage/paymentsPage.vue';

    import organizationEditModalAsOrganization from './views/organization/organizationEditModal/organizationEditModal.vue';
    import organizationUsersPage from './views/organization/organizationUsers/organizationUsersPage/organizationUsersPage.vue';
   
    import organizationLocationsPage from './views/organization/organizationLocations/organizationLocationsPage/organizationLocationsPage.vue';

    import shiftRequestsPage from './views/organization/shiftRequests/shiftRequestsPage/shiftRequestsPage.vue';
    import shiftRequestsCreateModal from  './views/organization/shiftRequests/shiftRequestsCreateModal/shiftRequestsCreateModal.vue';
    import shiftRequestsSelectLowerRateModal from './views/organization/shiftRequests/shiftRequestsSelectLowerRateModal/shiftRequestsSelectLowerRateModal.vue';
    import shiftRequestsDeclineModal from './views/organization/shiftRequests/shiftRequestsDeclineModal/shiftRequestsDeclineModal.vue';
    import shiftRequestsViewProfileModal from './views/organization/shiftRequests/shiftRequestsViewProfileModal/shiftRequestsViewProfileModal.vue';
    import shiftRequestsCalendarModal from './views/organization/shiftRequests/shiftRequestsCalendarModal/shiftRequestsCalendarModal.vue';
    import shiftRequestsDeleteModal from './views/organization/shiftRequests/shiftRequestsDeleteModal/shiftRequestsDeleteModal.vue';
    import shiftRequestsCaregiverRatingModal from './views/organization/shiftRequests/shiftRequestsCaregiverRatingModal/shiftRequestsCaregiverRatingModal.vue';
    import shiftRequestsMultipleDaysModal from './views/organization/shiftRequests/shiftRequestsMultipleDaysModal/shiftRequestsMultipleDaysModal.vue';

    import blockCaregiversModal from './views/menus/blockCaregiversModal/blockCaregiversModal.vue';
    import favoriteCaregiversModal from './views/menus/favoriteCaregiversModal/favoriteCaregiversModal.vue';
    import createSupportRequestsModal from './views/menus/createSupportRequestsModal/createSupportRequestsModal.vue';
    
    //MyShyft Users only
    import adminDashboardPage from './views/admin/adminDashboard/adminDashboardPage/adminDashboardPage.vue';
    import adminDashboardFailedPayoutModal from './views/admin/adminDashboard/adminDashboardFailedPayoutModal/adminDashboardFailedPayoutModal.vue';
    import adminDashboardCaregiverFirstShiftsModal from './views/admin/adminDashboard/adminDashboardCaregiverFirstShiftsModal/adminDashboardCaregiverFirstShiftsModal.vue';

    import organizationPage from './views/admin/organization/organizationPage/organizationPage.vue';
    import organizationEditModal from './views/admin/organization/organizationEditModal/organizationEditModal.vue';
    import organizationUserListModal from './views/admin/organization/organizationUserListModal/organizationUserListModal.vue';
    import organizationLocationListModal from './views/admin/organization/organizationLocationListModal/organizationLocationListModal.vue';
    import organizationSettingsModal from './views/admin/organization/organizationSettingsModal/organizationSettingsModal.vue';
    import organizationOldOutstandingBalanceModal from './views/admin/organization/organizationOldOutstandingBalanceModal/organizationOldOutstandingBalanceModal.vue';
    
    import caregiverPage from './views/admin/caregiver/caregiverPage/caregiverPage.vue';
    import caregiverProfileModal from './views/admin/caregiver/caregiverProfileModal/caregiverProfileModal.vue';
    import caregiverReferenceEditModal from './views/admin/caregiver/caregiverReferenceEditModal/caregiverReferenceEditModal.vue';
    import caregiverMedicalLicenseEditModal from './views/admin/caregiver/caregiverMedicalLicenseEditModal/caregiverMedicalLicenseEditModal.vue';
    import caregiverWorkExperienceEditModal from './views/admin/caregiver/caregiverWorkExperienceEditModal/caregiverWorkExperienceEditModal.vue';
    import caregiverProofOfOwnershipEditModal from './views/admin/caregiver/caregiverProofOfOwnershipEditModal/caregiverProofOfOwnershipEditModal.vue';
    import caregiverProofOfOwnershipViewModal from './views/admin/caregiver/caregiverProofOfOwnershipViewModal/caregiverProofOfOwnershipViewModal.vue';
    import caregiverPayoutsModal from './views/admin/caregiver/caregiverPayoutsModal/caregiverPayoutsModal.vue';
    import caregiverPayoutTransactionsModal from './views/admin/caregiver/caregiverPayoutTransactionsModal/caregiverPayoutTransactionsModal.vue';
    import caregiverPenaltiesModal from './views/admin/caregiver/caregiverPenaltiesModal/caregiverPenaltiesModal.vue';
    import caregiverHistoryModal from './views/admin/caregiver/caregiverHistoryModal/caregiverHistoryModal.vue';

    import usersPage from './views/admin/users/usersPage/usersPage.vue';

    import invoiceUpdatePaymentDetailsModal from './views/admin/invoices/invoiceUpdatePaymentDetailsModal/invoiceUpdatePaymentDetailsModal.vue';

    import shifRequestsMyshyftAdminPage from './views/admin/shiftRequests/shiftRequestsPage/shiftRequestsPage.vue';
    import shiftRequestsClocksModal from './views/admin/shiftRequests/shiftRequestsClocksModal/shiftRequestsClocksModal.vue';
    import shiftRequestsClocksEditModal from './views/admin/shiftRequests/shiftRequestsClocksEditModal/shiftRequestsClocksEditModal.vue';
    import shiftRequestsCreateMyShyftAdminModal from './views/admin/shiftRequests/shiftRequestsCreateModal/shiftRequestsCreateModal.vue';

    import typesOfCareModal from './views/admin/management/typesOfCareModal/typesOfCareModal.vue';
    import addTypeOfCareModal from './views/admin/management/addTypeOfCareModal/addTypeOfCareModal.vue';
    import medicalLicenseModal from './views/admin/management/medicalLicenseModal/medicalLicenseModal.vue';
    import addMedicalLicenseModal from './views/admin/management/addMedicalLicenseModal/addMedicalLicenseModal';
    import frequentlyAskedQuestionModal from './views/admin/management/frequentlyAskedQuestionModal/frequentlyAskedQuestionModal';
    import addFrequentlyAskedQuestionModal from './views/admin/management/addFrequentlyAskedQuestionModal/addFrequentlyAskedQuestionModal';
    import legalDocumentModal from './views/admin/management/legalDocumentModal/legalDocumentModal';
    import editLegalDocumentModal from './views/admin/management/editLegalDocumentModal/editLegalDocumentModal';
    import incompleteCaregiversEditModal from './views/admin/caregiver/incompleteCaregiversEditModal/incompleteCaregiversEditModal.vue';
    import caregiverDocumentCenterEditModal from './views/admin/caregiver/caregiverDocumentCenter/caregiverDocumentCenterEditModal/caregiverDocumentCenterEditModal.vue';

    import messagesAdminActiveChatPage from './views/admin/messagesAdminActiveChatPage/messagesAdminActiveChatPage'
    import messagesAdminThreadModal from './views/admin/messagesAdminThreadModal/messagesAdminThreadModal'

    import transfersPage from './views/admin/transfers/transfersPage/transfersPage.vue';
    import transactionErrorsModal from './views/admin/management/transactionErrorsModal/transactionErrorsModal';

    import supportRequestsPage from './views/shared/supportRequests/supportRequestsPage/supportRequestsPage.vue';
    import supportRequestsViewModal from './views/shared/supportRequests/supportRequestsViewModal/supportRequestsViewModal.vue';

    import notificationCenterPage from './views/admin/notificationCenterPage/notificationCenterPage'

    export default {
        name: 'app',
        mixins: [
            dmAppMixin
        ],
        components: {
            //Shared
            signInPage,
            usersMyProfileEditModal,
            usersChangePasswordModal,
            notificationHistoryModal,
            csvExportModal,
            usersEditModal,
            viewImageModal,

            organizationContactsModal,
            organizationAddContactModal,

            shiftRequestsViewModal,
            shiftRequestsShiftHistoryModal,
            shiftApplicantsModal,
            shiftApplicantBlockConfirmModal,

            messagesShiftActiveChatPage,
            messagesShiftThreadModal,

            invoicesPage,
            viewShiftsForInvoiceModal,
            invoicesPayModal,
            invoicesSelectionModal,

            licenseGlobalRatesModal,
            editLicenseGlobalRatesModal,

            organizationLocationEditModal,

            supportRequestsPage,
            supportRequestsViewModal,

            //Organization only
            organizationDashboardPage,

            paymentsPage,

            organizationEditModalAsOrganization,
            organizationUsersPage,

            organizationLocationsPage,

            shiftRequestsPage,
            shiftRequestsCreateModal,
            shiftRequestsSelectLowerRateModal,
            shiftRequestsDeclineModal,
            shiftRequestsViewProfileModal,
            shiftRequestsCalendarModal,
            shiftRequestsDeleteModal,
            shiftRequestsCaregiverRatingModal,
            shiftRequestsMultipleDaysModal,

            blockCaregiversModal,
            favoriteCaregiversModal,
            createSupportRequestsModal,

            //MyShyft Users only
            adminDashboardPage,
            adminDashboardFailedPayoutModal,
            adminDashboardCaregiverFirstShiftsModal,

            organizationPage,
            organizationEditModal,
            organizationUserListModal,
            organizationLocationListModal,
            organizationSettingsModal,
            organizationOldOutstandingBalanceModal,
            usersPage,

            caregiverPage,
            caregiverProfileModal,
            caregiverReferenceEditModal,
            caregiverMedicalLicenseEditModal,
            caregiverWorkExperienceEditModal,
            caregiverProofOfOwnershipEditModal,
            caregiverProofOfOwnershipViewModal,
            caregiverPayoutsModal,
            caregiverPayoutTransactionsModal,
            caregiverPenaltiesModal,
            caregiverHistoryModal,

            invoiceUpdatePaymentDetailsModal,
            
            shifRequestsMyshyftAdminPage,
            shiftRequestsClocksModal,
            shiftRequestsClocksEditModal,
            shiftRequestsCreateMyShyftAdminModal,

            typesOfCareModal,
            addTypeOfCareModal,
            medicalLicenseModal,
            addMedicalLicenseModal,
            frequentlyAskedQuestionModal,
            addFrequentlyAskedQuestionModal,
            legalDocumentModal,
            editLegalDocumentModal,
            incompleteCaregiversEditModal,
            caregiverDocumentCenterEditModal,

            messagesAdminActiveChatPage,
            messagesAdminThreadModal,

            transfersPage,
            transactionErrorsModal,

            notificationCenterPage
        },
        data() {
            return {
                isSidebarOpened: false,
                showBanner: false,
                bannerMessage: 'Scheduled Outage - MyShyft will be undergoing maintenance on Thursday, July 11th between 8:30 AM ET and 1:00 PM ET to update the platform. We apologize for any inconvenience.',
                enableGestures: false,
                previousSelectedMenuElement: null,
                mnuAdminSidebarItems: [
                    {
                        text: 'Dashboard',
                        onClick: this.mnuAdminDashboardOnClick,
                        iconCss: 'fa fa-tachometer brand-color-secondary-dark'
                    },
                    {
                        text: 'Organizations',
                        onClick: this.mnuAdminOrganizationsOnClick,
                        iconCss: 'fa fa-building brand-color-secondary-dark'
                    },
                    {
                        text: 'Caregivers',
                        onClick: this.mnuAdminCaregiversOnClick,
                        iconCss: 'fa fa-user-md brand-color-secondary-dark'
                    },
                    {
                        text: 'Shift Requests',
                        onClick: this.mnuAdminShiftRequestsOnClick,
                        iconCss: 'fa fa-clock-o brand-color-secondary-dark'
                    },
                    {
                        text: 'Invoices',
                        onClick: this.mnuAdminInvoicesOnClick,
                        iconCss: 'fa fa-usd brand-color-secondary-dark'
                    },
                    {
                        text: 'Transfers',
                        onClick: this.mnuAdminTransfersOnClick,
                        iconCss: 'fa fa-reply brand-color-secondary-dark'
                    },
                    {
                        text: 'Support Requests',
                        onClick: this.mnuAdminSupportRequestsOnClick,
                        iconCss: 'fa fa-question-circle brand-color-secondary-dark'
                    },
                    {
                        text: 'MyShyft Users',
                        onClick: this.mnuAdminUsersOnClick,
                        iconCss: 'fa fa-user-plus brand-color-secondary-dark'
                    },
                    {
                        text: 'Active Chat',
                        onClick: this.mnuAdminActiveChatOnClick,
                        cssClass: 'active-chat-menu-item',
                        iconCss: 'fa fa-comment brand-color-secondary-dark',
                    },
                    {
                        text: 'Notification Center',
                        onClick: this.mnuAdminNotificationCenterOnClick,
                        iconCss: 'fa fa-bell brand-color-secondary-dark',
                    },
                ],
                mnuOrganizationAdminSidebarItems: [
                     {
                        text: 'Dashboard',
                        onClick: this.mnuOrganizationDashboardOnClick,
                        iconCss: 'fa fa-tachometer brand-color-secondary-dark'
                    },
                    {
                        text: 'Shift Requests',
                        onClick: this.mnuMyShiftRequestOnClick,
                        iconCss: 'fa fa-user-md brand-color-secondary-dark'
                    },
                    {
                        text: 'Buildings',
                        onClick: this.mnuOrganizationLocationsOnClick,
                        iconCss: 'fa fa-building brand-color-secondary-dark',
                    },
                    {
                        text: 'Users',
                        onClick: this.mnuUsersOnClick,
                        iconCss: 'fa fa-user-plus brand-color-secondary-dark',
                    },
                    {
                        text: 'Support Requests',
                        onClick: this.mnuAdminSupportRequestsOnClick,
                        iconCss: 'fa fa-question-circle brand-color-secondary-dark'
                    },
                    {
                        text: 'Invoices',
                        onClick: this.mnuAdminInvoicesOnClick,
                        iconCss: 'fa fa-usd brand-color-secondary-dark'
                    },
                    {
                        text: 'Active Chat',
                        onClick: this.mnuShiftActiveChatOnClick,
                        cssClass: 'active-chat-menu-item',
                        iconCss: 'fa fa-comment brand-color-secondary-dark',
                    },
                ],
                mnuOrganizationSidebarItems: [
                     {
                        text: 'Dashboard',
                        onClick: this.mnuOrganizationDashboardOnClick,
                        iconCss: 'fa fa-tachometer brand-color-secondary-dark'
                    },
                    {
                        text: 'Shift Requests',
                        onClick: this.mnuMyShiftRequestOnClick,
                        iconCss: 'fa fa-user-md brand-color-secondary-dark'
                    },
                    {
                        text: 'Buildings',
                        onClick: this.mnuOrganizationLocationsOnClick,
                        iconCss: 'fa fa-building brand-color-secondary-dark',
                    },
                    {
                        text: 'Users',
                        onClick: this.mnuUsersOnClick,
                        iconCss: 'fa fa-user-plus brand-color-secondary-dark',
                    },
                    {
                        text: 'Support Requests',
                        onClick: this.mnuAdminSupportRequestsOnClick,
                        iconCss: 'fa fa-question-circle brand-color-secondary-dark'
                    },
                    {
                        text: 'Active Chat',
                        onClick: this.mnuShiftActiveChatOnClick,
                        cssClass: 'active-chat-menu-item',
                        iconCss: 'fa fa-comment brand-color-secondary-dark',
                    },
                ],
                mnuAgencyAdminSidebarItems: [
                     {
                        text: 'Dashboard',
                        onClick: this.mnuOrganizationDashboardOnClick,
                        iconCss: 'fa fa-tachometer brand-color-secondary-dark'
                    },
                    {
                        text: 'Shift Requests',
                        onClick: this.mnuMyShiftRequestOnClick,
                        iconCss: 'fa fa-user-md brand-color-secondary-dark'
                    },
                    {
                        text: 'Patients',
                        onClick: this.mnuOrganizationLocationsOnClick,
                        iconCss: 'fa fa-building brand-color-secondary-dark',
                    },
                    {
                        text: 'Users',
                        onClick: this.mnuUsersOnClick,
                        iconCss: 'fa fa-user-plus brand-color-secondary-dark',
                    },
                    {
                        text: 'Invoices',
                        onClick: this.mnuAdminInvoicesOnClick,
                        iconCss: 'fa fa-usd brand-color-secondary-dark'
                    },
                    {
                        text: 'Support Requests',
                        onClick: this.mnuAdminSupportRequestsOnClick,
                        iconCss: 'fa fa-question-circle brand-color-secondary-dark'
                    },
                    {
                        text: 'Active Chat',
                        onClick: this.mnuShiftActiveChatOnClick,
                        cssClass: 'active-chat-menu-item',
                        iconCss: 'fa fa-comment brand-color-secondary-dark',
                    },
                ],
                mnuAgencySidebarItems: [
                     {
                        text: 'Dashboard',
                        onClick: this.mnuOrganizationDashboardOnClick,
                        iconCss: 'fa fa-tachometer brand-color-secondary-dark'
                    },
                    {
                        text: 'Shift Requests',
                        onClick: this.mnuMyShiftRequestOnClick,
                        iconCss: 'fa fa-user-md brand-color-secondary-dark'
                    },
                    {
                        text: 'Patients',
                        onClick: this.mnuOrganizationLocationsOnClick,
                        iconCss: 'fa fa-building brand-color-secondary-dark',
                    },
                    {
                        text: 'Users',
                        onClick: this.mnuUsersOnClick,
                        iconCss: 'fa fa-user-plus brand-color-secondary-dark',
                    },
                    {
                        text: 'Support Requests',
                        onClick: this.mnuAdminSupportRequestsOnClick,
                        iconCss: 'fa fa-question-circle brand-color-secondary-dark'
                    },
                    {
                        text: 'Active Chat',
                        onClick: this.mnuShiftActiveChatOnClick,
                        cssClass: 'active-chat-menu-item',
                        iconCss: 'fa fa-comment brand-color-secondary-dark',
                    },
                ],
                sidebarCollapsed: false,
            }
        },
        computed: {
            ActiveTemplate() {
                return this.$store.getters['NavigationModule/ActiveTemplate'];
            },
            ModalsOpened() {
                return this.$store.getters['ModalModule/ModalsOpened'];
            },
            signedInUser() {
                return this.$store.state.AuthorizationModule.SignedInUser;
            },
            mnuSidebarItems() {
                if (this.signedInUser.RoleCategory === 'MyShyft'){
                    return this.mnuAdminSidebarItems;
                }

                if (this.signedInUser.OrganizationCategory === 'Facility') {  
                    if (this.signedInUser.Role === 'OrganizationAdmin' || this.signedInUser.Role === 'OrganizationCorporate'){
                        return this.mnuOrganizationAdminSidebarItems;
                    }
    
                    if (this.signedInUser.Role === "OrganizationEmployee"){
                        return this.mnuOrganizationSidebarItems;
                    }
                }

                if (this.signedInUser.OrganizationCategory === 'Agency') {   
                    if (this.signedInUser.Role === 'OrganizationAdmin' || this.signedInUser.Role === 'OrganizationCorporate'){
                        return this.mnuAgencyAdminSidebarItems;
                    }
    
                    if (this.signedInUser.Role === "OrganizationEmployee"){
                        return this.mnuAgencySidebarItems;
                    }
                }
            },
            mnuRightItems() {
                let userName = '';
                let organizationName = '';

                if (this.signedInUser) {
                    userName = this.signedInUser.FirstLastName;                 
                    organizationName = this.signedInUser.OrganizationName;
                }

                if (this.signedInUser.RoleCategory === 'MyShyft'){
                    return [
                    {
                        text: userName,
                        iconCss: 'fa fa-user',
                        items: [
                            {
                                text: 'My Profile',
                                onClick: this.mnuMyProfileOnClick,
                            },
                            {
                                text: 'Types of Care',
                                onClick: this.mnuTypesOfCareOnClick,
                            },
                            {
                                text: 'Professional Licenses',
                                onClick: this.mnuMedicalLicensesOnClick,
                            },
                            {
                                text: 'Frequently Asked Questions',
                                onClick: this.mnuFrequentlyAskedQuestionsOnClick,
                            },
                            {
                                text: 'Legal Documents',
                                onClick: this.mnuLegalDocumentsOnClick,
                            },
                            {
                                text: 'Notification History',
                                onClick: this.mnuNoticationHistoryOnClick,
                            },
                            {
                                text: 'CSV Exports',
                                onClick: this.mnuCsvExportsOnClick,
                            },
                            {
                                text: 'Change Password',
                                onClick: this.mnuChangePasswordOnClick,
                            },
                            {
                                text: 'Sign Out',
                                onClick: this.mnuSignOutOnClick,
                            },
                        ]
                    }
                ]; 
                }

                if (this.signedInUser.RoleCategory === 'Organization'){
                      return [
                    {
                        text: organizationName + " " + userName,
                        iconCss: 'fa fa-user',
                        items: [
                            {
                                text: 'My Profile',
                                onClick: this.mnuMyProfileOnClick,
                            },
                            {
                                text: 'Edit Organization',
                                onClick: this.mnuEditOrganizationOnClick,
                            },
                            {
                                text: 'Contacts',
                                onClick: this.mnuContactsOnClick,
                            },
                            {
                                text: 'Blocked Caregivers',
                                onClick: this.mnuBlockCaregiversOnClick,
                            },
                            {
                                text: 'Favorite Caregivers',
                                onClick: this.mnuFavoriteCaregiversOnClick,
                            },
                            {
                                text: 'Global Desired Rates',
                                onClick: this.mnuProfessionalLicenseGlobalRatesOnClick,
                            },
                            {
                                text: 'Notification History',
                                onClick: this.mnuNoticationHistoryOnClick,
                            },
                            {
                                text: 'CSV Exports',
                                onClick: this.mnuCsvExportsOnClick,
                            },
                            {
                                text: 'Change Password',
                                onClick: this.mnuChangePasswordOnClick,
                            },
                            {
                                text: 'Report Issue',
                                onClick: this.mnuReportIssueOnClick,
                            },
                            {
                                text: 'Sign Out',
                                onClick: this.mnuSignOutOnClick,
                            },
                        ]
                    }
                ];
                }             
            }
        },
        methods: {
            toggleClick() {
                this.isSidebarOpened = !this.isSidebarOpened;
                this.$refs.sidebarInstance.toggle();
            },
            toggleBanner() {
                console.log("test");
                this.showBanner = !this.showBanner;
            },
            logoOnClick() {

            },
            mnuRightItemsOnClick(args) {
                if (args.item.onClick) {
                    args.item.onClick();                 
                }
            },
            mnuSidebarOnClick(args) {
                if (args.item.onClick) {
                    args.item.onClick();
                    if (this.previousSelectedMenuElement){
                        this.previousSelectedMenuElement.classList.remove('app-navigation-selected');
                        this.previousSelectedMenuElement.firstChild.classList.add('brand-color-secondary-dark');
                        this.previousSelectedMenuElement.firstChild.classList.remove('brand-color-white');
                    }
                    args.element.classList.add('app-navigation-selected');
                    args.element.firstChild.classList.remove('brand-color-secondary-dark');
                    args.element.firstChild.classList.add('brand-color-white');
                    this.previousSelectedMenuElement = args.element;
                }
            },
            mnuUsersOnClick() {
                this.$store.commit('NavigationModule/setActiveTemplate', 'organizationUsersPage');
            },
            mnuOrganizationLocationsOnClick() {
                this.$store.commit('NavigationModule/setActiveTemplate', 'organizationLocationsPage');
            },
            mnuShiftActiveChatOnClick() {
                this.$store.commit('NavigationModule/setActiveTemplate', 'messagesShiftActiveChatPage');
            },
            mnuAdminActiveChatOnClick() {
                this.$store.commit('NavigationModule/setActiveTemplate', 'messagesAdminActiveChatPage');
            },
            mnuAdminNotificationCenterOnClick() {
                this.$store.commit('NavigationModule/setActiveTemplate', 'notificationCenterPage');
            },
            mnuOrganizationDashboardOnClick() {
                this.$store.commit('NavigationModule/setActiveTemplate', 'organizationDashboardPage');
            },
            mnuAdminDashboardOnClick() {
                this.$store.commit('NavigationModule/setActiveTemplate', 'adminDashboardPage');
            },
            mnuMyShiftRequestOnClick() {
                this.$store.commit('NavigationModule/setActiveTemplate', 'shiftRequestsPage');
            },
            mnuAdminOrganizationsOnClick() {
                this.$store.commit('NavigationModule/setActiveTemplate', 'organizationPage');
            },
            mnuAdminCaregiversOnClick() {
                this.$store.commit('NavigationModule/setActiveTemplate', 'caregiverPage');
            },
            mnuAdminUsersOnClick() {
                this.$store.commit('NavigationModule/setActiveTemplate', 'usersPage');
            },
            mnuAdminShiftRequestsOnClick() {
                this.$store.commit('NavigationModule/setActiveTemplate', 'shifRequestsMyshyftAdminPage');
            },
            mnuAdminInvoicesOnClick() {
                this.$store.commit('NavigationModule/setActiveTemplate', 'invoicesPage');
            },
            mnuAdminTransfersOnClick() {
                this.$store.commit('NavigationModule/setActiveTemplate', 'transfersPage');
            },
            mnuAdminSupportRequestsOnClick(){
                this.$store.commit('NavigationModule/setActiveTemplate', 'supportRequestsPage');
            },
            mnuMyProfileOnClick() {
                this.$store.dispatch('AuthorizationModule/getUserById', this.signedInUser.UserID)
                    .then((user) => {
                        let successCallback = () => {
                            this.$store.dispatch('AuthorizationModule/getUserById', this.signedInUser.UserID);
                        }

                        let modalData = {
                            dto: user,
                            callback: successCallback,
                        }

                        DMUtils.openApplicationModal('usersMyProfileEditModal', modalData)
                    });
            },
            mnuBlockCaregiversOnClick() {
                let modalData = {
                    dto: [],
                }
                
                DMUtils.openApplicationModal('blockCaregiversModal', modalData)
            },
            mnuFavoriteCaregiversOnClick() {
                  let modalData = {
                    dto: [],
                }
                
                DMUtils.openApplicationModal('favoriteCaregiversModal', modalData)
            },
            mnuContactsOnClick() {
                let modalData = {
                    dto: [],
                    accountType: "OrganizationAdmin"
                }

                DMUtils.openApplicationModal('organizationContactsModal', modalData)
            },
            mnuTypesOfCareOnClick() {
                DMUtils.openApplicationModal('typesOfCareModal')
            },
            mnuTransactionErrorsOnClick() {
                DMUtils.openApplicationModal('transactionErrorsModal')
            },
            mnuIncompleteCaregiversOnClick() {
                DMUtils.openApplicationModal('incompleteCaregiversModal')
            },
            mnuMedicalLicensesOnClick() {
                DMUtils.openApplicationModal('medicalLicenseModal')
            },
            mnuFrequentlyAskedQuestionsOnClick() {
                DMUtils.openApplicationModal('frequentlyAskedQuestionModal')
            },
            mnuLegalDocumentsOnClick() {
                DMUtils.openApplicationModal('legalDocumentModal')
            },
            mnuNoticationHistoryOnClick() {
                let modalData = {};

                if (this.signedInUser.Role === 'OrganizationAdmin' || this.signedInUser.Role === 'OrganizationEmployee' || this.signedInUser.Role === 'OrganizationCorporate'){
                    modalData = {
                        accountType: 'OrganizationUser',
                    };
                } else if (this.signedInUser.Role === 'MyShyftAdmin' || this.signedInUser.Role === 'MyShyftEmployee' || this.signedInUser.Role === 'MyShyftViewer') {
                    modalData = {
                        accountType: 'MyShyftUser',
                    };
                }

                DMUtils.openApplicationModal('notificationHistoryModal', modalData)
            },
            mnuProfessionalLicenseGlobalRatesOnClick() {
                let modalData = {};

                if (this.signedInUser.Role === 'OrganizationAdmin'){
                    modalData = {
                        accountType: 'OrganizationAdmin',
                    };
                } else if (this.signedInUser.Role === 'OrganizationEmployee' || this.signedInUser.Role === 'OrganizationCorporate') {
                    modalData = {
                        accountType: 'OrganizationEmployeeCorporate',
                    };
                }

                DMUtils.openApplicationModal('licenseGlobalRatesModal', modalData)
            },
            mnuCsvExportsOnClick() {
                let modalData = {};

                if (this.signedInUser.Role === 'OrganizationAdmin' || this.signedInUser.Role === 'OrganizationEmployee' || this.signedInUser.Role === 'OrganizationCorporate'){
                    modalData = {
                        accountType: 'OrganizationUser',
                    };
                } else if (this.signedInUser.Role === 'MyShyftAdmin' || this.signedInUser.Role === 'MyShyftEmployee' || this.signedInUser.Role === 'MyShyftViewer') {
                    modalData = {
                        accountType: 'MyShyftUser',
                    };
                }

                DMUtils.openApplicationModal('csvExportModal', modalData)
            },
            mnuChangePasswordOnClick() {
                let modalData = {
                    dto: {},
                };

                DMUtils.openApplicationModal('usersChangePasswordModal', modalData);
            },
            mnuReportIssueOnClick() {
                DMUtils.openApplicationModal('createSupportRequestsModal');
            },
            mnuSignOutOnClick() {
                this.$store.dispatch('AuthorizationModule/signOut');
                app.stopUnreadMessagesTimer();
            },
            mnuNotImplemented() {
                DMUtils.alertShow(
                        'This feature has not been implemented yet.'
                    );
            },
            mnuEditOrganizationOnClick() {
                endpoints.getUserByID(this.$store.state.AuthorizationModule.SignedInUser.UserID)
                    .then(response => {
                        return endpoints.getOrganizationByID(response.OrganizationID)
                    })
                    .then(response => {
                        DMUtils.openApplicationModal('organizationEditModalAsOrganization', {
                            dto: response
                        });
                    });
            },
            detectIfAppIsVisible() {
                let hidden;
                let visibilityChange;
                if (typeof document.hidden !== 'undefined') {
                    hidden = 'hidden';
                    visibilityChange = 'visibilitychange';
                } else if (typeof document.msHidden !== 'undefined') {
                    hidden = 'msHidden';
                    visibilityChange = 'msvisibilitychange';
                } else if (typeof document.webkitHidden !== 'undefined') {
                    hidden = 'webkitHidden';
                    visibilityChange = 'webkitvisibilitychange';
                }

                let handleVisibilityChange = () => {
                    if (document[hidden]) {
                    } else {
                        this.appIsVisible();
                    }
                }

                if (typeof document.addEventListener === 'undefined' || hidden === undefined) {
                } else {
                    document.addEventListener(visibilityChange, handleVisibilityChange, false);
                }
            },
            appIsVisible() {
                // TODO - Call endpoint
            }
        },
        created() {
            /*  Disable Right Click everywhere on the Website */
            
            // Quickly turn on / off right click for debugging purposes
            // True = Right Click Disabled
            // False = Right Click Enabled

            if (false) {
                let webpage = document.querySelector('#app');
                if (webpage.addEventListener) {
                    webpage.addEventListener('contextmenu', function(e) {
                        e.preventDefault();
                    }, false);
                    } else {
                        webpage.attachEvent('oncontextmenu', function() {
                            window.event.returnValue = false;
                    });
                }
            }

            /* / Disable Right Click everywhere on the Website */

            // Application Startup / Initialization 

           let authToken = DMUtils.getAuthToken();
            if (authToken) {
                this.$store.dispatch('AuthorizationModule/signInWithAuthToken', authToken)
                    .then(response => {
                        if(this.$route.name) {
                            return;
                        }

                        if (this.signedInUser.RoleCategory === 'MyShyft'){
                            this.$store.commit('NavigationModule/setActiveTemplate', 'adminDashboardPage');
                        } else if (this.signedInUser.RoleCategory === 'Organization'){
                            this.$store.commit('NavigationModule/setActiveTemplate', 'organizationDashboardPage');
                        }

                        app.getUnreadMessageCountAndStartTimer(this.signedInUser.Role);
                    });
            } else {
                if(!this.$route.name) {
                    this.$store.commit('NavigationModule/setActiveTemplate', 'signInPage');
                }
            }

            window.addEventListener('focus', (event) => {
                // alert('Welcome back');
            }, false);

            this.detectIfAppIsVisible();
        },
        watch: {
            '$route': function(route) {
                if(route.name) {
                    this.$store.commit('NavigationModule/setActiveTemplate', '');
                }
            }
        }
    }
</script>

<style>
    @import "../node_modules/@syncfusion/ej2-icons/styles/bootstrap.css";
    @import "../node_modules/@syncfusion/ej2-base/styles/bootstrap.css";
    @import "../node_modules/@syncfusion/ej2-vue-buttons/styles/bootstrap.css";
    @import "../node_modules/@syncfusion/ej2-vue-navigations/styles/bootstrap.css";
    @import "../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap.css";
    @import "../node_modules/@syncfusion/ej2-vue-popups/styles/bootstrap.css";
    @import "../node_modules/@syncfusion/ej2-vue-inputs/styles/bootstrap.css";
    @import "../node_modules/@syncfusion/ej2-vue-calendars/styles/bootstrap.css";
    @import '../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap.css';
    @import '../node_modules/@syncfusion/ej2-vue-dropdowns/styles/bootstrap.css';
    @import '../node_modules/@syncfusion/ej2-lists/styles/bootstrap.css';
    @import '../node_modules/@syncfusion/ej2-vue-richtexteditor/styles/bootstrap.css';
    @import '../node_modules/@syncfusion/ej2-vue-schedule/styles/bootstrap.css';
</style>