<template src="./shiftRequestsClocksEditModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'organization-requests-clocks-edit-modal',
        mixins: [dmModalMixin],
        data() {
            return {
                searchString: '',
                clockIn: null,
                clockOut: null,
                dto: {},
                originalShiftClockInUtc: new Date(),
                originalShiftClockOutUtc: new Date(),
                mode: '',
                shiftID: 0,   
                hourlyRateMarkUp: 0,
                shiftConfirmedHourlyRate: 0,
                transactionsTableColumns: [],
                transactionsTableSortBy: {
                    columns: [
                        { field: 'CreatedUtc', direction: 'Descending' },
                    ]
                },           
                selectedRow: null,
                offsetDifference: 0,
                localTimeZone: ''
            }
        },
        computed: {
            lblTitle() {
                if (this.mode === "edit"){
                    return 'Edit a Shift Clock Record';
                } else {
                    return 'Add a Shift Clock Record';
                }
            },  
            modalWidth() {
                if (this.mode === "edit"){
                    return '900px';
                } else {
                    return '600px';
                }
            },       
            shiftClockID() {
                return this.selectedRow.rowData.ShiftClockID;
            },
            transactionsTableItems() {
                let transactions = this.$store.state.InvoiceModule.AllTransactionsForShiftClock; 
                return transactions;
            },
            getCaregiverAdjustmentAmount() {     
                let originaltimeWorked = (this.originalShiftClockInUtc.getTime() - this.originalShiftClockOutUtc.getTime()) / 1000;
                let adjustedTimeWorked = (new Date(this.clockIn).getTime() - new Date(this.clockOut).getTime()) / 1000;

                let timeDifference = originaltimeWorked - adjustedTimeWorked;
                let timeDifferenceMinutes = timeDifference / 60;
                let timeDifferenceHours = timeDifferenceMinutes / 60;

                if (this.clockOut === null || this.clockIn === null) {
                    return '$0.00'
                }
        
                return '$' + (timeDifferenceHours * (parseFloat(this.dto.ConfirmedHourlyRate))).toFixed(2);
            },
            getCaregiverShiftClockAddAmount(){
                let timeWorked = (new Date(this.clockOut).getTime() - new Date(this.clockIn).getTime()) / 1000;

                let timeWorkedMinutes = timeWorked / 60;
                let timeWorkedHours = timeWorkedMinutes / 60;

                if (this.clockOut === null || this.clockIn === null) {
                    return '$0.00'
                }

                return '$' + (timeWorkedHours * (parseFloat(this.confirmedHourlyRate))).toFixed(2);
            },
            getOrganizationAdjustmentAmount() {     
                let originaltimeWorked = (this.originalShiftClockInUtc.getTime() - this.originalShiftClockOutUtc.getTime()) / 1000;
                let adjustedTimeWorked = (new Date(this.clockIn).getTime() - new Date(this.clockOut).getTime()) / 1000;

                let timeDifference = originaltimeWorked - adjustedTimeWorked;
                let timeDifferenceMinutes = timeDifference / 60;
                let timeDifferenceHours = timeDifferenceMinutes / 60;

                if (this.clockOut === null || this.clockIn === null) {
                    return '$0.00'
                }
        
                return '$' + (timeDifferenceHours * (parseFloat(this.dto.ConfirmedHourlyRate * (1 + this.dto.HourlyRateMarkUpPercentage)))).toFixed(2);
            },
            getOrganizationShiftClockAddAmount(){
                let timeWorked = (new Date(this.clockOut).getTime() - new Date(this.clockIn).getTime()) / 1000;

                let timeWorkedMinutes = timeWorked / 60;
                let timeWorkedHours = timeWorkedMinutes / 60;

                if (this.clockOut === null || this.clockIn === null) {
                    return '$0.00'
                }

                return '$' + (timeWorkedHours * (parseFloat(this.confirmedHourlyRate * (1 + this.hourlyRateMarkUp)))).toFixed(2);
            }
        },
        methods: {
            setTimezoneOffsetDifference() {
                let moment = require('moment-timezone');
                let deviceOffset = moment().utcOffset();
                let organizationOffset = moment().tz(this.localTimeZone).utcOffset();
                this.offsetDifference = deviceOffset - organizationOffset;
            },
            btnCancelOnClick(){
                this.closeModal();
            },
            btnSaveOnClick() {
                this.dto.ShiftClockInUtc = moment(this.clockIn).add(this.offsetDifference, 'minutes').utc().format();
                this.dto.ShiftClockOutUtc = moment(this.clockOut).add(this.offsetDifference, 'minutes').utc().format();

                if (this.mode === "add"){
                    this.dto.ShiftID = this.shiftID;
                    this.$store
                        .dispatch('ShiftModule/createShiftClock', this.dto)
                        .then(() => {
                            this.shouldModalStayOpen = false;
                            this.successClose(this.dto);
                        }); 
                } else if (this.mode === "edit") {
                    this.$store
                        .dispatch('ShiftModule/updateShiftClock', this.dto)
                        .then(() => {
                            this.shouldModalStayOpen = false;
                            this.successClose(this.dto);
                        }); 
                }               
            },
        },
        created() {
            this.dto = DMUtils.copy(this.modalData.dto);     
            this.mode = DMUtils.copy(this.modalData.mode);
            this.shiftID = DMUtils.copy(this.modalData.shiftID);  
            this.localTimeZone = DMUtils.copy(this.modalData.timeZone);

            this.setTimezoneOffsetDifference();
        
            if (this.mode === "edit") {
                this.$store.dispatch('InvoiceModule/getAllTransactionsForShiftClockID', this.dto.ShiftClockID);
                this.originalShiftClockInUtc = new Date(this.dto.ShiftClockInUtc);
                this.originalShiftClockOutUtc = new Date(this.dto.ShiftClockOutUtc);

                this.clockIn = moment(this.dto.ShiftClockInUtc).subtract(this.offsetDifference, 'minutes').utc().format();
                this.clockOut = moment(this.dto.ShiftClockOutUtc).subtract(this.offsetDifference, 'minutes').utc().format();
            }

            if (this.mode === "add") {
                this.confirmedHourlyRate = this.modalData.confirmedHourlyRate;
                this.hourlyRateMarkUp = this.modalData.hourlyRateMarkUp;
            }

            this.transactionsTableColumns = [
                {
                    key: 'TransactionID',
                    label: 'ID',
                    width: '100px'
                },
                {
                    key: 'CaregiverTotalAmount',
                    label: 'Caregiver Amount',   
                    format: 'C2',
                    textAlign: 'Right',
                    minWidth: '145px'  
                },
                {
                    key: 'PayoutStatus',
                    label: 'Payout Status',
                    minWidth: '140px'
                },
                {
                    key: 'ThirdPartyTransferID',
                    label: 'Transfer ID',   
                    width: '270px'               
                },
                {
                    key: 'InvoiceID',
                    label: 'Invoice ID',
                    width: '120px'   
                },
            ];
        },
    }
</script>
