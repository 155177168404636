<template src="./organizationLocationListModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'
import Vue from 'vue';

export default {
    name: 'organization-location-list-modal',   
    mixins: [dmModalMixin],
    data() {
        return {
            organizationLocationTableColumns: [],
            organizationLocationTableSortBy: {
                columns: [
                    { field: 'CreatedUtc', direction: 'Descending' },
                ]
            },
            organizationLocationTableCustomClass: 'dm-bg-color-black',
            organizationLocationTableActions: [
                {
                    text: 'Edit'
                },
                {
                    text: 'Delete'
                },
            ],
            selectedRow: null,
            dto: {},
        }
    },
    computed: {
        lblTitle() {
            return app.organizationLocationTerminology(this.dto.OrganizationCategory, false, false) + ' List for ' + this.modalData.dto.OrganizationName;
        },
        organizationLocationTableItems() {       
            return this.$store.state.OrganizationLocationModule.AllOrganizationLocations                   
        },
        organizationLocationID() {
            return this.selectedRow.rowData.OrganizationLocationID;
        },      
    },
    methods: {
        organizationLocationTableRowOnClick(args){
            this.selectedRow = args;
        },
        getOrganizationLocationTerminology(lowerCase, plural) {
            return app.organizationLocationTerminology(this.dto.OrganizationCategory, lowerCase, plural)
        },
        btnCloseOnClick() {
            this.closeModal();
        },
        addOrganizationLocation() {
            let modalData = {
                mode: 'add',
                dto: {
                    OrganizationID: this.dto.OrganizationID,
                    OrganizationCategory: this.dto.OrganizationCategory
                },
            };

            DMUtils.openApplicationModal('organizationLocationEditModal', modalData);
        },
        editOrganizationLocation() {
            this.dto.OrganizationLocationID = this.organizationLocationID;
            let modalData = {
                mode: 'edit',
                dto: {},
            };

            this.$store
                .dispatch('OrganizationLocationModule/getOrganizationLocationByID', this.dto)
                .then((response) => {
                    modalData.dto = response;
                    modalData.dto.OrganizationCategory = this.dto.OrganizationCategory;
                    DMUtils.openApplicationModal('organizationLocationEditModal', modalData);
                });
        },
        deleteOrganizationLocation() {
            let dto = {
                OrganizationID: this.selectedRow.rowData.OrganizationID,
                OrganizationLocationID: this.organizationLocationID,
                RowVersion: this.selectedRow.rowData.RowVersion,
            }
            this.$store.dispatch('OrganizationLocationModule/deleteOrganizationLocation', dto);
        },
        organizationLocationTableActionOnClick(args) {
           if (args.item.text === 'Edit') {
                this.editOrganizationLocation();
            } 
            else if (args.item.text === 'Delete') {
                const message = 'Are you sure you want to delete this location?';
                const title = 'Confirm';
                const confirmCallback = this.deleteOrganizationLocation;

                DMUtils.openApplicationModal('dmConfirmModal', {
                    title: title,
                    message: message,
                    confirmCallback: confirmCallback,
                    confirmText: 'Yes',
                    denyText: 'Cancel',
                });
            }
        },
    },
    created() {
        this.dto = DMUtils.copy(this.modalData.dto);
        this.$store.dispatch('OrganizationLocationModule/getAllOrganizationLocationsForOrganization', this.dto);

        this.organizationLocationTableColumns = [
            {
                type: 'iconTemplate',
                iconName: 'ellipsis',
                ellipsisActions: this.organizationLocationTableActions,
                iconOnClick: this.organizationLocationTableActionOnClick,
                width: '55px',
            },
            {
                key: 'OrganizationLocationID',
                label: 'ID',
                width: '75px',
            },
            {
                key: 'LocationName',
                label: 'Name',
            },
            {
                key: 'City',
                label: 'City',
            },
            {
                key: 'State',
                label: 'State',
                width: '100px'
            },
            {
                key: 'CreatedUtc',
                label: 'Created On',
                width: '240px',
                formatter(column, location) {
                    return moment.tz(location.CreatedUtc, location.LocalTimeZone).format("MM/DD/YYYY hh:mmA z"); 
                }
            },        
        ];
    },
}
</script>
