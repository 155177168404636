import endpoints from "../../../js/endpoints";

const ShiftModule = {
    namespaced: true,
    state: () => ({
        AllShifts: [],
        AllShiftsNumberOfRows: 0,

        OrganizationCalendar: [],

        AllShiftDeletedTypes: [],
        PendingShifts: [],
        PendingShiftsNumberOfRows: 0,
        ConfirmedShifts: [],
        ConfirmedShiftsNumberOfRows: 0,
        HistoryShifts: [],
        HistoryShiftsNumberOfRows: 0,

        AllShiftsForInvoice: [],
        AllShiftsNotInvoiced: [],
        ShiftsNotInvoicedNumberOfRows: 0,

        AllShiftApplicants: [],
        AllShiftClocks: [],
        AllShiftHistory: [],
    }),
    mutations: {
        setAllShifts(state, shifts) {
            state.AllShifts = shifts;
        },
        setAllShiftsNumberOfRows(state, count) {
            state.AllShiftsNumberOfRows = count;
        },
        setOrganizationCalendar(state, shifts) {
            state.OrganizationCalendar = shifts;
        },
        setPendingShifts(state, shifts) {
            state.PendingShifts = shifts;
        },
        setPendingShiftsNumberOfRows(state, count) {
            state.PendingShiftsNumberOfRows = count;
        },
        setConfirmedShifts(state, shifts) {
            state.ConfirmedShifts = shifts;
        },
        setConfirmedShiftsNumberOfRows(state, count) {
            state.ConfirmedShiftsNumberOfRows = count;
        },
        setCurrentShifts(state, shifts) {
            state.CurrentShifts = shifts;
        },
        setHistoryShifts(state, shifts) {
            state.HistoryShifts = shifts;
        },
        setHistoryShiftsNumberOfRows(state, count) {
            state.HistoryShiftsNumberOfRows = count;
        },
        setShiftsForInvoice(state, shifts) {
            state.AllShiftsForInvoice = shifts;
        },
        setShiftsNotInvoiced(state, shifts) {
            state.AllShiftsNotInvoiced = shifts;
        },
        setShiftsNotInvoicedNumberOfRows(state, count) {
            state.ShiftsNotInvoicedNumberOfRows = count;
        },
        setShiftApplicants(state, shiftApplicants) {
            state.AllShiftApplicants = shiftApplicants;
        },
        setShiftClocks(state, shiftClocks) {
            state.AllShiftClocks = shiftClocks;
        },
        setShiftHistory(state, shiftHistory) {
            state.AllShiftHistory = shiftHistory;
        },
        setShiftDeletedTypes(state, deletedTypes) {
            state.AllShiftDeletedTypes = deletedTypes;
        },
    },
    actions: {
        getAllShiftsMyShyftAdmin({ commit }, paramsDto) {
            commit('setAllShifts', []);
            commit('setAllShiftsNumberOfRows', 0);
            
            return endpoints.getAllShiftsMyShyftAdmin(paramsDto)
                .then((response) => {
                    commit('setAllShifts', response.Rows);
                    commit('setAllShiftsNumberOfRows', response.NumberOfRows);
                    return response;
                });
        },
        getShiftByIDMyShyftAdmin({ commit }, shiftID) {
            return endpoints.getShiftByIDMyShyftAdmin(shiftID)
                .then((response) => {
                    return response
                });
        },
        getShiftByIDOrganization({ commit }, shiftID) {
            return endpoints.getShiftByIDOrganization(shiftID)
                .then((response) => {
                    return response
                });
        },
        getOrganizationCalendar({ commit }, paramsDto) {
            return endpoints.getOrganizationCalendar(paramsDto)
                .then((response) => {
                    commit('setOrganizationCalendar', response);
                    return response;
                });
        },
        getPendingShifts({ commit }, paramsDto) {
            if (paramsDto === undefined) {
                paramsDto = {}
            }

            commit('setPendingShifts', []);
            commit('setPendingShiftsNumberOfRows', 0);

            return endpoints.getOrganizationPendingShifts(paramsDto)
                .then((response) => {
                    commit('setPendingShifts', response.Rows);
                    commit('setPendingShiftsNumberOfRows', response.NumberOfRows);
                    return response;
                });
        },
        getConfirmedShifts({ commit }, paramsDto) {
            if (paramsDto === undefined) {
                paramsDto = {}
            }

            commit('setConfirmedShifts', []);
            commit('setConfirmedShiftsNumberOfRows', 0);

            return endpoints.getOrganizationConfirmedShifts(paramsDto)
                .then((response) => {
                    commit('setConfirmedShifts', response.Rows);
                    commit('setConfirmedShiftsNumberOfRows', response.NumberOfRows);
                    return response;
                });
        },
        getOrganizationHistoryShifts({ commit }, paramsDto) {
            if (paramsDto === undefined) {
                paramsDto = {}
            }

            commit('setHistoryShifts', []);
            commit('setHistoryShiftsNumberOfRows', 0);

            return endpoints.getOrganizationHistoryShifts(paramsDto)
                .then((response) => {
                    commit('setHistoryShifts', response.Rows);
                    commit('setHistoryShiftsNumberOfRows', response.NumberOfRows);
                    return response;
                });
        },
        createShift({ dispatch }, dto) {
            return endpoints.createShift(dto)
                .then(() => {
                    dispatch('getPendingShifts');
                });
        },
        createShiftMyShyftAdmin({ dispatch }, dto) {
            return endpoints.createShiftMyShyftAdmin(dto)
                .then(() => {
                    dispatch('getAllShiftsMyShyftAdmin');
                });
        },
        deleteShiftOrganization({ dispatch }, dto) {
            return endpoints.deleteShift(dto)
                .then(() => {
                    dispatch('getPendingShifts');
                    dispatch('getConfirmedShifts');
                    dispatch('getOrganizationHistoryShifts');
                });
        },
        deleteShiftMyShyftAdmin({ dispatch }, dto) {
            return endpoints.deleteShift(dto)
                .then(() => {
                    dispatch('getAllShiftsMyShyftAdmin', dto.Params);
                });
        },
        getAllDeletedTypeCodes({ commit }) {
            commit('setShiftDeletedTypes', []);

            return endpoints.getAllDeletedTypeCodes()
                .then((response) => {
                    commit('setShiftDeletedTypes', response);
                });
        },
        getAllShiftApplicants({ commit }, paramsDto) {
            commit('setShiftApplicants', []);

            return endpoints.getAllShiftApplicants(paramsDto.ShiftID, paramsDto.OrganizationID)
                .then((response) => {
                    commit('setShiftApplicants', response);
                });
        },
        getShiftApplicantByID({ commit }, shiftApplicantID) {
            return endpoints.getShiftApplicantByID(shiftApplicantID)
                .then((response) => {
                    return response
                });
        },
        assignShiftApplicant({ dispatch }, dto) {
            return endpoints.assignShiftApplicant(dto)
                .then(() => {
                    let paramsDto = {
                        ShiftID: dto.ShiftID,
                        OrganizationID: dto.OrganizationID
                    }
                    dispatch('getAllShiftApplicants', paramsDto);
                });
        },
        unassignShiftApplicant({ dispatch }, dto) {
            return endpoints.unassignShiftApplicant(dto)
                .then(() => {
                    let paramsDto = {
                        ShiftID: dto.ShiftID,
                        OrganizationID: dto.OrganizationID
                    }
                    dispatch('getAllShiftApplicants', paramsDto);
                });
        },
        unconfirmShiftApplicant({ dispatch }, dto) {
            return endpoints.unconfirmShiftApplicant(dto);
        },       
        declineShiftApplicant({ dispatch }, dto) {
            return endpoints.declineShiftApplicant(dto)
                .then(() => {
                    let paramsDto = {
                        ShiftID: dto.ShiftID,
                        OrganizationID: dto.OrganizationID
                    }
                    dispatch('getAllShiftApplicants', paramsDto);
                });
        },
        getAllForInvoiceIDMyShyftAdmin({ commit }, invoiceID) {
            commit('setShiftsForInvoice', []);

            return endpoints.getAllForInvoiceIDMyShyftAdmin(invoiceID)
                .then((response) => {
                    commit('setShiftsForInvoice', response);
                });
        },
        getAllForInvoiceIDOrganization({ commit }, invoiceID) {
            commit('setShiftsForInvoice', []);

            return endpoints.getAllForInvoiceIDOrganization(invoiceID)
                .then((response) => {
                    commit('setShiftsForInvoice', response);
                });
        },
        getNotInvoicedMyShyftAdmin({ commit }, paramsDto) {
            commit('setShiftsNotInvoiced', []);
            commit('setShiftsNotInvoicedNumberOfRows', 0);

            return endpoints.getNotInvoicedMyShyftAdmin(paramsDto)
                .then((response) => {
                    commit('setShiftsNotInvoiced', response.Rows);
                    commit('setShiftsNotInvoicedNumberOfRows', response.NumberOfRows)
                });
        },
        getNotInvoicedOrganization({ commit }) {
            commit('setShiftsNotInvoiced', []);

            return endpoints.getNotInvoicedOrganization()
                .then((response) => {
                    commit('setShiftsNotInvoiced', response);
                });
        },
        getAllShiftClocksForShift({ commit }, shiftID) {
            commit('setShiftClocks', []);

            return endpoints.getAllShiftClocksForShift(shiftID)
                .then((response) => {
                    response.forEach(time => {
                        if (time.ShiftClockOutUtc) {
                            let ms = moment(time.ShiftClockOutUtc).diff(moment(time.ShiftClockInUtc));
                            let d = moment.duration(ms);
                            let s = Math.floor(d.asHours());
                            let minutes = parseInt(moment.utc(ms).format('mm')) / 60;
                            let number = s + minutes;
                            number = number.toFixed(2);
                            time.duration = number;
                        }
                    });

                    commit('setShiftClocks', response);
                });
        },
        getShiftClockByID({ commit }, shiftClockID) {
            return endpoints.getShiftClockByID(shiftClockID)
                .then((response) => {
                    return response
                });
        },
        createShiftClock({ dispatch }, dto) {
            return endpoints.createShiftClock(dto)
                .then(() => {
                    dispatch('getAllShiftClocksForShift', dto.ShiftID);
                });
        },
        updateShiftClock({ dispatch }, dto) {
            return endpoints.updateShiftClock(dto)
                .then(() => {
                    dispatch('getAllShiftClocksForShift', dto.ShiftID);
                    dispatch('InvoiceModule/getAllTransactionsMyShyftAdmin', {}, { root: true });
                });
        },
        deleteShiftClock({ dispatch }, shiftClockID) {
            let shiftID = 0;
            return endpoints.getShiftClockByID(shiftClockID)
                .then((response) => {
                    shiftID = response.ShiftID;
                    return endpoints.deleteShiftClock(response);
                })
                .then(() => {
                    dispatch('getAllShiftClocksForShift', shiftID);
                });
        },
        forceShiftClockOutByID({ dispatch }, dto) {
            return endpoints.forceShiftClockOutByID(dto)
                .then(() => {
                    dispatch('getAllShiftClocksForShift', dto.ShiftID);
                    dispatch('getConfirmedShifts');
                });
        },
        forceShiftClockOutMyShyftAdmin({ dispatch }, dto) {
            return endpoints.forceShiftClockOutMyShyftAdmin(dto)
                .then(() => {
                    dispatch('getAllShiftClocksForShift', dto.ShiftID);
                });
        },
        getShiftHistoryByID({ commit }, shiftHistoryID) {
            return endpoints.getShiftHistoryByID(shiftHistoryID)
                .then((response) => {
                    return response
                });
        },
        getAllShiftHistoryForShift({ commit }, shiftID) {
            commit('setShiftHistory', []);

            return endpoints.getAllShiftHistoryForShift(shiftID)
                .then((response) => {
                    commit('setShiftHistory', response);
                });
        },
        getHourlyRateMarkup() {
            return endpoints.getHourlyRateMarkup()
                .then((response) => {
                    return response;
                });
        }
    },
};

export default ShiftModule;
