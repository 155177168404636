<template src="./usersChangePasswordModal.html"></template>

<script>
    import dmModalMixin from '../../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'users-change-password-modal',
        mixins: [dmModalMixin],
        data() {
            return {
                lblTitle: 'Change Password',
                dto: {},
            }
        },
        methods: {
            btnSaveOnClick() {
                this.$store.dispatch('AuthorizationModule/updatePassword', this.dto)
                    .then(() => {
                        this.successClose();
                    });
            },
            btnCancelOnClick() {
                this.closeModal();
            },
        },
        created() {
            this.dto = DMUtils.copy(this.modalData.dto);
        },
    }
</script>
