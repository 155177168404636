import endpoints from "../../../js/endpoints";

const adminCaregiverModule = {
    namespaced: true,
    state: () => ({
        AllCaregivers: [],
        CaregiverWorkExperiences: [],
        AllCaregiverHistory: [],
        AllCaregiversNumberOfRows: 0,
        AllReferencesForCaregiver: [],
        AllRatingsForCaregiver: [],
        AllPenaltiesForCaregiver: [],
        RecentPayoutsForCaregiver: [],
        TransactionsForPayoutID: [],
        CaregiverBackgroundReport: [],
        ParamsDto: {}
    }),
    mutations: {
        setCaregivers(state, caregivers) {
            state.AllCaregivers = caregivers;
        },
        setCaregiverWorkExperiences(state, experiences) {
            state.CaregiverWorkExperiences = experiences;
        },
        setCaregiversNumberOfRows(state, count) {
            state.AllCaregiversNumberOfRows = count;
        },
        setCaregiverHistory(state, history) {
            state.AllCaregiverHistory = history;
        },
        setReferences(state, references) {
            state.AllReferencesForCaregiver = references;
        },
        setRatings(state, ratings) {
            state.AllRatingsForCaregiver = ratings;
        },
        setPenalties(state, penalties) {
            state.AllPenaltiesForCaregiver = penalties;
        },
        setRecentPayouts(state, payouts) {
            state.RecentPayoutsForCaregiver = payouts;
        },
        setTransactions(state, transactions) {
            state.TransactionsForPayoutID = transactions;
        },
        setBackgroundReport(state, report) {
            state.CaregiverBackgroundReport = report;
        },
        setParamsDto(state, paramsDto) {
            state.ParamsDto = paramsDto;
        },
    },
    actions: {
        getAllCaregivers({commit}) {
            commit('setCaregivers', []);
            commit('setCaregiversNumberOfRows', 0);
            return endpoints.getAllCaregivers(this.state.AdminCaregiverModule.ParamsDto)
                .then((response) => {
                    commit('setCaregivers', response.Rows);
                    commit('setCaregiversNumberOfRows', response.NumberOfRows);
                });
        },
        getCaregiverByIDMyShyftAdmin({commit}, userID) {
            commit('setCaregiverWorkExperiences', []);

            return endpoints.getCaregiverByIDMyShyftAdmin(userID)
                .then((response) => {
                    commit('setCaregiverWorkExperiences', response.WorkExperiences);
                    return response;
                });
        },
        getCaregiverByIDOrganization({commit}, userID) {
            return endpoints.getCaregiverByIDOrganization(userID)
                .then((response) => {
                    return response;
                });
        },
        updateCaregiver({dispatch}, dto) {
            return endpoints.updateCaregiver(dto)
                .then(() => {
                    dispatch('getAllCaregivers');
                });
        },
        deleteCaregiver({dispatch}, userID) {
            return endpoints.getCaregiverByIDMyShyftAdmin(userID)
                .then((response) => {
                    return endpoints.deleteCaregiver(response)
                })
                .then(() => {
                    dispatch('getAllCaregivers');
                    return userID;
                });
        },
        recoverCaregiver({dispatch}, userID) {
            return endpoints.getCaregiverByIDMyShyftAdmin(userID)
                .then((response) => {
                    return endpoints.recoverCaregiver(response)
                })
                .then(() => {
                    dispatch('getAllCaregivers');
                    return userID;
                });
        },
        getAllReferencesForCaregiver({commit}, caregiverID) {
            commit('setReferences', []);

            return endpoints.getAllReferencesForCaregiver(caregiverID)
                .then((response) => {
                    commit('setReferences', response);
                    return response;
                });
        },
        getAllReferencesRelationshipTypes({commit}, caregiverID) {
            return endpoints.getAllReferencesRelationshipTypes(caregiverID)
                .then((response) => {
                    return response;
                });
        },
        getReferenceByID({commit}, referenceID) {
            return endpoints.getReferenceByID(referenceID)
                .then((response) => {
                    return response;
                });
        },
        updateReference({dispatch}, dto) {
            return endpoints.updateReference(dto)
                .then(() => {
                    dispatch('getAllReferencesForCaregiver', dto.CaregiverID);
                });
        },
        createReference({dispatch}, dto) {
            return endpoints.createReference(dto)
                .then(() => {
                    dispatch('getAllReferencesForCaregiver', dto.CaregiverID);
                });
        },
        sendReferenceEmail({}, dto) {
            return endpoints.sendReferenceEmail(dto)
                .then((response) => {
                   return response;
                });
        },
        getAllRatingsForCaregiver({commit}, caregiverID) {
            commit('setRatings', []);

            return endpoints.getAllRatingsForCaregiver(caregiverID)
                .then((response) => {
                    commit('setRatings', response);
                });
        },
        getAllRatingsForCaregiverMyShyftAdmin({commit}, caregiverID) {
            commit('setRatings', []);

            return endpoints.getAllRatingsForCaregiverMyShyftAdmin(caregiverID)
                .then((response) => {
                    commit('setRatings', response);
                });
        },
        getRatingByShiftID({commit}, shiftID) {
            return endpoints.getRatingByShiftID(shiftID)
                .then((response) => {
                    return response;
                });
        },
        getRatingByID({commit}, ratingID) {
            return endpoints.getRatingByID(ratingID)
                .then((response) => {
                    return response;
                });
        },
        getRatingByIDMyShyftAdmin({commit}, ratingID) {
            return endpoints.getRatingByIDMyShyftAdmin(ratingID)
                .then((response) => {
                    return response;
                });
        },
        createCaregiverRating({dispatch}, dto) {
            return endpoints.createCaregiverRating(dto)
                .then(() => {
                    dispatch('ShiftModule/getOrganizationHistoryShifts', {}, {root:true});
                    dispatch('ShiftModule/getOrganizationConfirmedShifts', {}, {root:true});
                });
        },
        updateCaregiverRating({dispatch}, dto) {
            return endpoints.updateCaregiverRating(dto)
                .then(() => {
                    if (dto.AccountType === "MyShyftAdmin"){
                        dispatch('getAllRatingsForCaregiverMyShyftAdmin', dto.CaregiverID);
                    } else {
                        dispatch('getAllRatingsForCaregiver', dto.CaregiverID);
                    }
                });
        },
        deleteCaregiverRating({dispatch}, ratingID) {
            let caregiverID = 0;
            return endpoints.getRatingByID(ratingID)
                .then((response) => {
                    caregiverID = response.CaregiverID;
                    return endpoints.deleteCaregiverRating(response);
                })
                .then(() => {
                    dispatch('getAllRatingsForCaregiverMyShyftAdmin', caregiverID);
                });
        },
        getAllCaregiverWorkExperienceTypes({}) {
            return endpoints.getAllCaregiverWorkExperienceTypes()
            .then((response) => {
                return response
            });
        },
        createCaregiverWorkExperience({dispatch}, dto) {
            return endpoints.createCaregiverWorkExperience(dto)
                .then((response) => {
                    dispatch('getCaregiverByIDMyShyftAdmin', dto.UserID);
                    return response
                });
        },
        updateCaregiverWorkExperience({dispatch}, dto) {
            return endpoints.updateCaregiverWorkExperience(dto)
                .then((response) => {
                    dispatch('getCaregiverByIDMyShyftAdmin', dto.UserID);
                    return response
                });
        },
        getRecentPayoutsForCaregiver({commit}, userID) {
            commit('setRecentPayouts', []);

            return endpoints.getRecentPayoutsForCaregiver(userID)
                .then((response) => {
                    commit('setRecentPayouts', response);
                });
        },
        getTransactionsForPayoutID({commit}, dto) {
            commit('setTransactions', []);

            return endpoints.getTransactionsForPayoutID(dto)
                .then((response) => {
                    commit('setTransactions', response);
                });
        },
        getAllCaregiverNames({}, dto) {
            return endpoints.getAllCaregiverNames(dto)
                .then((response) => {
                    return response
                });
        },
        getCheckrReportByUserID({commit}, userID) {
            commit('setBackgroundReport', []);

            return endpoints.getCheckrReportByUserID(userID)
                .then((response) => {
                    commit('setBackgroundReport', response);
                    return response;
                });
        },
        createCheckrReport({dispatch}, dto) {
            return endpoints.createCheckrReport(dto)
                .then(() => {
                    dispatch('getCheckrReportByUserID', dto.UserID);
                });
        },
        getAllCaregiverPenaltiesForCaregiver({commit}, caregiverID) {
            commit('setPenalties', []);

            return endpoints.getAllCaregiverPenaltiesForCaregiver(caregiverID)
                .then((response) => {
                    commit('setPenalties', response);
                });
        },
        deleteCaregiverPenalty({dispatch}, penalty) {
            return endpoints.deleteCaregiverPenalty(penalty)
                .then(() => {
                    dispatch('getAllCaregiverPenaltiesForCaregiver', penalty.CaregiverID);
                });
        },
        getCaregiverHistoryForCaregiverID({commit}, caregiverID) {
            commit('setCaregiverHistory', []);

            return endpoints.getCaregiverHistoryForCaregiverID(caregiverID)
                .then((response) => {
                    commit('setCaregiverHistory', response);
                });
        },
        getAllPagesByUserIDMyShyftAdmin({ }, userID){
            return endpoints.getAllPagesByUserIDMyShyftAdmin(userID)
                .then((response) => {
                    return response;
                })
        },
        validateCaregiverCreateAccount({ }, user) {
            return endpoints.validateCaregiverCreateAccount(user);
        },
        validateCaregiverProofOfIdentity({ }, user) {
            return endpoints.validateCaregiverProofOfIdentity(user);
        },
        validateCaregiverReferences({ }, user) {
            return endpoints.validateCaregiverReferences(user);
        },
        validateCaregiverLicenseCategory({ }, user) {
            return endpoints.validateCaregiverLicenseCategory(user);
        },
        validateCaregiverExperienceAndInsurance({ }, user) {
            return endpoints.validateCaregiverExperienceAndInsurance(user);
        },
        validateCaregiverAboutYou({ }, user) {
            return endpoints.validateCaregiverAboutYou(user);
        },
        getAllCaregiverEditableStatuses({}) {
            return endpoints.getAllCaregiverEditableStatuses()
            .then((response) => {
                return response
            });
        },
        getAllCaregiverStatuses({}) {
            return endpoints.getAllCaregiverStatuses()
            .then((response) => {
                return response
            });
        },
    },
};

export default adminCaregiverModule;
