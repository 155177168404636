<template src="./caregiverWorkExperienceEditModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

export default {
    name: 'caregiver-work-experience-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            workExperienceTypes: [],
            dto: {},
        }
    },
    computed: {
        mode() {
            return this.modalData.mode;
        },
        lblTitle() {
            return this.modalData.mode === 'edit' ? 'Edit Work Experience: ' + this.modalData.dto.CaregiverWorkExperienceID : 'Add Work Experience';
        },
        myShyftAdminTimeZone(){
            return this.$store.state.AdminModule.MyShyftAdminTimeZone;
        },       
    },
    methods: {
        btnSaveOnClick() {
            if (this.mode === 'edit') {              
                this.$store
                    .dispatch('AdminCaregiverModule/updateCaregiverWorkExperience', this.dto)
                    .then(() => {                       
                        this.shouldModalStayOpen = false;
                        this.successClose(this.dto);
                    });
            } else if (this.mode === 'add') {
                this.$store
                    .dispatch('AdminCaregiverModule/createCaregiverWorkExperience', this.dto)
                    .then(() => {
                        this.shouldModalStayOpen = false;
                        this.successClose(this.dto);
                    });            
            }
        },
        btnCancelOnClick() {         
            this.closeModal();
        },
    },
    created() {
        this.dto = DMUtils.copy(this.modalData.dto);
        this.$store
            .dispatch('AdminCaregiverModule/getAllCaregiverWorkExperienceTypes')
            .then((response) => {
                this.workExperienceTypes = response;
        });
    }
}
</script>
