<template src="./addMedicalLicenseModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'
import dmStates from '../../../../../dmFramework/dmJS/dmStates.js';

export default {
    name: 'add-medical-license-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            dto: {},
            stateList: dmStates,
        }
    },
    computed: {
        mode() {
            return this.modalData.mode;
        },
        lblTitle() {
            return this.modalData.mode === 'edit' ? 'Edit Professional License' : 'Add Professional License';
        },
    },
    methods: {
        filtering: function(e) {
           let query = new Query();
            query = (e.text != "") ? query.where("Contains", e.text, true) : query;
            e.updateData(this.stateList, query);
        },
        btnSaveOnClick() {
            if (this.mode === 'add') {
                this.$store
                    .dispatch('MedicalLicenseModule/createMedicalLicense', this.dto)
                    .then(() => {
                        this.shouldModalStayOpen = false;
                        this.successClose(this.dto);
                    });
            } else if (this.mode === 'edit') {
                this.$store
                    .dispatch('MedicalLicenseModule/updateMedicalLicense', this.dto)
                    .then(() => {
                        this.shouldModalStayOpen = false;
                        this.successClose(this.dto);
                    });
            }
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.dto = DMUtils.copy(this.modalData.dto);
    },
}
</script>
