<template src="./invoicesPayModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'invoice-pay-modal',
        mixins: [dmModalMixin],
        data() {
            return {
                dto: {},    
                cardName: '',
                cardCountry: '',
                cardZipcode: '',
                cardNumberElement: {},
                cardExpiryElement: {},
                cardCvcElement: {},
                stripe: null,
                stripeError: '',
                cardCvcError: '',
                cardExpiryError: '',
                cardNumberError: '',
                loading: false,     
            }
        },
        computed: {
            lblTitle() {
                return 'Check out';
            },          
            getConfirmedHourlyRate(){
                this.shiftDto.ConfirmedHourlyRate =  this.shiftDto.ConfirmedHourlyRate * (1 +  this.shiftDto.HourlyRateMarkUpPercentage);
                
                var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    });

                return formatter.format(this.shiftDto.ConfirmedHourlyRate);
            },  
        },
        methods: {
            setUpStripeElements(){
                this.stripe = Stripe(window.stripeAPIKey);
                var elements = this.stripe.elements();
                
                var style = {
                    base: {                  
                        fontSize: '14px',
                        fontFamily: "'Poppins', sans-serif",
                        lineHeight: '34px',
                        color: '#555'
                    }
                };

                this.cardNumberElement = elements.create('cardNumber', { style: style });
                this.cardExpiryElement = elements.create('cardExpiry', { style: style });
                this.cardCvcElement = elements.create('cardCvc', { style: style });    
                    
                this.cardNumberElement.mount("#card-number");   
                this.cardExpiryElement.mount("#card-expiry");   
                this.cardCvcElement.mount("#card-cvc");  
            },
            payInvoices(){
                this.loading = true;

                if (this.cardName === ""){
                    DMUtils.alertShow("Name is required.");
                    this.loading = false;
                    return;
                }

                if (this.cardZipcode === ""){
                    DMUtils.alertShow("Billing Zipcode is required.");
                    this.loading = false;
                    return;
                }
       
                this.stripe.confirmCardPayment(this.dto.ClientSecret, {
                    payment_method: {
                    card: this.cardNumberElement,
                    billing_details: {
                        name: this.cardName,
                        address: {
                            postal_code: this.cardZipcode
                            }
                        }
                    }
                    }).then((result) => {
                        this.loading = false;
                        if (result.error) {
                            DMUtils.alertShow(result.error.message);
                        } else {
                            if (result.paymentIntent.status === 'succeeded') {

                                let invoiceIDs = [];
                                if (this.dto.Invoices){
                                    this.dto.Invoices.forEach((invoice) => {
                                        invoiceIDs.push(invoice.InvoiceID);
                                    })
                                }

                                let completePaymentDto = {
                                    InvoiceIDs: invoiceIDs,
                                    PaymentIntentID: result.paymentIntent.id
                                }
                                
                                this.$store.dispatch('InvoiceModule/completeInvoicePayment', completePaymentDto).then(() => {
                                    DMUtils.alertShow("Payment Success!");
                                });

                                this.closeModal();
                            }
                        }
                    });
            },        
            invoiceShiftsTableRowOnClick(args) {
                this.selectedRow = args;
            }, 
            btnCloseOnClick(){
                this.closeModal();
            },           
        },
        mounted() {
           this.setUpStripeElements();
        },
        created() {
            this.dto = DMUtils.copy(this.modalData.dto);     
        }
    }
</script>