<template src="./organizationPage.html"></template>

<script>
import Vue from 'vue';

export default {
    name: 'admin-organization-page',
    data() {
        return {
            searchString: '',
            dto: {},
            organizationTableColumns: [],
            organizationTableSortBy: {
                columns: [{ field: 'CreatedUtc', direction: 'Descending' }],
            },
            organizationTableCustomClass: 'dm-bg-color-black',
            organizationTableActions: [
                {
                    text: 'Toggle Active Status',
                },
                {
                    text: 'View',
                },
                {
                    text: 'Delete',
                },
                {
                    text: 'Users',
                },
                {
                    text: 'Buildings',
                },
                {
                    text: 'Contacts',
                },
                {
                    text: 'Global Desired Rates',
                },
                {
                    text: 'View Shifts',
                },
                {
                    text: 'Old System Outstanding Balance',
                }
            ],
            agencyTableActions: [
                {
                    text: 'Toggle Active Status',
                },
                {
                    text: 'View',
                },
                {
                    text: 'Delete',
                },
                {
                    text: 'Users',
                },
                {
                    text: 'Patients',
                },
                {
                    text: 'Contacts',
                },
                {
                    text: 'Global Desired Rates',
                },
                {
                    text: 'View Shifts',
                },
                {
                    text: 'Old System Outstanding Balance',
                }
            ],
            selectedRow: null,
        };
    },
    computed: {
        organizationTableItems() {
            let organizations = this.$store.state.AdminOrganizationModule.AllOrganizations;

            let filteredFacilties = organizations.filter((organization) => {
                return (
                    organization.OrganizationName.toLowerCase().includes(this.searchString.toLowerCase()) ||
                    organization.OrganizationID.toString().includes(this.searchString.toLowerCase()) ||
                    organization.PrimaryCity.toLowerCase().includes(this.searchString.toLowerCase()) ||
                    organization.PrimaryState.toLowerCase().includes(this.searchString.toLowerCase())
                );
            });

            if (filteredFacilties){
                 filteredFacilties.forEach((organization) => {
                    organization.myShyftAdminTimeZone = this.myShyftAdminTimeZone;
                });
            }

            return filteredFacilties;
        },
        organizationID() {
            return this.selectedRow.rowData.OrganizationID;
        },
        organizationCategory() {
            return this.dto.OrganizationCategory;
        },
        myShyftAdminTimeZone() {
            return this.$store.state.AdminModule.MyShyftAdminTimeZone;
        }
    },
    methods: {
        organizationTableRowOnClick(args) {
            this.selectedRow = args;
        },
        notImplemented() {
            DMUtils.alertShow('This feature has not been implemented yet.');
        },
        addNewOrganization() {
            //Sammie 7-14-2022 Added OrganizationType to prevent dirty modal check on organizationEditModal
            let modalData = {
                mode: 'add',
                dto: { OrganizationType: null },
            };

            DMUtils.openApplicationModal('organizationEditModal', modalData);
        },
        refreshOrganizations() {
            this.$store.dispatch('AdminOrganizationModule/getAllOrganizations');
        },
        toggleStatus() {
            this.$store
                .dispatch('AdminOrganizationModule/getOrganizationByID', this.selectedRow.rowData.OrganizationID)
                .then((response) => {
                    this.dto = response;
                    if (this.dto.Status === 'Active') {
                        this.dto.Status = 'Inactive';
                    } else {
                        this.dto.Status = 'Active';
                    }
                    this.$store.dispatch('AdminOrganizationModule/updateOrganization', this.dto);
                });
        },
        viewOrganization() {
            this.$store
                .dispatch('AdminOrganizationModule/getOrganizationByIDMyShyftAdmin', this.selectedRow.rowData.OrganizationID)
                .then((response) => {
                    let modalData = {
                        mode: 'edit',
                        dto: response,
                    };
                    DMUtils.openApplicationModal('organizationEditModal', modalData);
                });
        },
        viewOrganizationGlobalRates() {
            let modalData = {
                accountType: 'MyShyftUser',
                organizationID: this.organizationID
            };

            DMUtils.openApplicationModal('licenseGlobalRatesModal', modalData);
        },
        deleteOrganization() {
            this.$store.dispatch('AdminOrganizationModule/deleteOrganization', this.selectedRow.rowData.OrganizationID);
        },
        organizationTableActionOnClick(args) {
            if (args.item.text === 'Toggle Active Status') {
                const message = 'Are you sure you want to Toggle Active Status?';
                const title = 'Confirm';
                const confirmCallback = this.toggleStatus;

                DMUtils.openApplicationModal('dmConfirmModal', {
                    title: title,
                    message: message,
                    confirmCallback: confirmCallback,
                    confirmText: 'Yes',
                    denyText: 'Cancel',
                });
            } else if (args.item.text === 'View') {
                this.viewOrganization();
            } else if (args.item.text === 'Delete') {
                const message = 'Are you sure you want to delete this Organization?';
                const title = 'Confirm';
                const confirmCallback = this.deleteOrganization;

                DMUtils.openApplicationModal('dmConfirmModal', {
                    title: title,
                    message: message,
                    confirmCallback: confirmCallback,
                    confirmText: 'Yes',
                    denyText: 'Cancel',
                });
            } else if (args.item.text === 'Users') {
                this.$store
                    .dispatch('AdminOrganizationModule/getOrganizationByID', this.selectedRow.rowData.OrganizationID)
                    .then((response) => {
                        let modalData = {
                            dto: response,
                        };
                        DMUtils.openApplicationModal('organizationUserListModal', modalData);
                    });
            } else if (args.item.text === 'View Shifts') {
                this.$store.commit('NavigationModule/setShortcutOrganizationID', this.selectedRow.rowData.OrganizationID);
                this.$store.commit('NavigationModule/setActiveTemplate', 'shifRequestsMyshyftAdminPage');
            } else if (args.item.text === 'Old System Outstanding Balance') {
                this.$store
                    .dispatch('AdminOrganizationModule/getOrganizationInvoiceByOrganizationID', this.selectedRow.rowData.OrganizationID)
                    .then((response) => {
                        let modalData = {
                            dto: response,
                            OrganizationName: this.selectedRow.rowData.OrganizationName
                        };

                        modalData.dto.OrganizationID = this.selectedRow.rowData.OrganizationID;
                        DMUtils.openApplicationModal('organizationOldOutstandingBalanceModal', modalData);
                    });
            } else if (args.item.text === 'Contacts') {
                this.$store
                    .dispatch('AdminOrganizationModule/getOrganizationByID', this.selectedRow.rowData.OrganizationID)
                    .then((response) => {
                        let modalData = {
                            dto: response,
                            accountType: 'MyShyftUser',
                        };

                        DMUtils.openApplicationModal('organizationContactsModal', modalData);
                    })
            } else if (args.item.text === 'Global Desired Rates') {
                this.viewOrganizationGlobalRates();
            // } else if (args.item.text === 'Settings') {
            //     this.$store
            //         .dispatch('AdminOrganizationModule/getOrganizationByID', this.selectedRow.rowData.OrganizationID)
            //         .then((response) => {
            //             let modalData = {
            //                 dto: response,
            //             };
            //             DMUtils.openApplicationModal('organizationSettingsModal', modalData);
            //         });
            } else if (args.item.text === 'Buildings' || args.item.text === 'Patients') {
                this.$store
                    .dispatch('AdminOrganizationModule/getOrganizationByID', this.selectedRow.rowData.OrganizationID)
                    .then((response) => {
                        let modalData = {
                            dto: response,
                        };
                        DMUtils.openApplicationModal('organizationLocationListModal', modalData);
                    });
            }
        },
    },
    created() {
        let self = this;
        this.$store.dispatch('AdminOrganizationModule/getAllOrganizations');

        this.organizationTableColumns = [
            {
                type: 'template',
                width: '55px',
                template: function () {
                    return {
                        template: Vue.component('columnTemplate', {
                            template: `<ejs-dropdownbutton
                                                :items="grdRowActions"
                                                :select="select"
                                                iconCss="e-icons e-ellipsis-vert"
                                                cssClass="e-caret-hide">
                                           </ejs-dropdownbutton>`,
                            methods: {
                                select(args) {
                                    self.organizationTableActionOnClick(args);
                                },
                            },
                            computed: {
                                grdRowActions: function () {
                                    if (this.data.OrganizationCategory === 'Agency') {
                                        return self.agencyTableActions;
                                    } else {
                                        return self.organizationTableActions;
                                    }
                                },
                            },
                        }),
                    };
                },
            },   
            {
                key: 'OrganizationID',
                label: 'ID',
                width: '75px',
            },
            {
                key: 'OrganizationName',
                label: 'Organization Name',
            },
            {
                key: 'PrimaryCity',
                label: 'City',
                width: '200px',
            },
            {
                key: 'PrimaryState',
                label: 'State',
                width: '100px',
            },
            {
                key: 'OrganizationCategory',
                label: 'Category',
                width: '120px',
            },
            {
                key: 'NumberOfLocations',
                label: 'Locations',
                width: '120px',
                textAlign: 'Center',
            },
            {
                key: 'Status',
                label: 'Status',
                width: '100px',
                template: function () {
                    return {
                        template: Vue.component('columnTemplate', {
                            template: `<div :class="setColor">{{data.Status}}</div>`,
                            computed: {
                                setColor: function () {
                                    if (this.data.Status === 'Inactive') {
                                        return 'app-grid-status-orange';
                                    }

                                    if (this.data.Status === 'Active') {
                                        return 'app-grid-status-green';
                                    }     
                                },                             
                            },
                        }),
                    };
                },
            },
            {
                key: 'CreatedUtc',
                label: 'Registered On Date',
                type: 'date',
                width: '210px',
                formatter(column, organization) {
                    return moment.tz(organization.CreatedUtc, organization.myShyftAdminTimeZone).format("MM/DD/YYYY hh:mmA z"); 
                } 
            },
        ];
    },
};
</script>
