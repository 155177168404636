<template src="./shiftRequestsClocksModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'organization-requests-clocks-modal',
        mixins: [dmModalMixin],
        data() {
            return {
                searchString: '',
                dto: {},
                accountType: "",
                shiftClocksTableColumns: [],
                shiftClocksTableSortBy: {
                    columns: [
                        { field: 'ShiftClockInUtc', direction: 'Descending' },
                    ]
                },
                shiftClocksTableCustomClass: 'dm-bg-color-black',
                shiftClocksMyShyftAdminTableActions: [
                    {
                        text: 'Edit'
                    },
                    {
                        text: 'Force Clock Out'
                    },
                    {
                        text: 'Delete'
                    }
                ],
                selectedRow: null
            }
        },
        computed: {
            lblTitle() {
                return 'Clock Time List for ShiftID: ' + this.dto.ShiftID;
            },
            shiftClocksTableItems() {
                let shiftClocks = this.$store.state.ShiftModule.AllShiftClocks;
                this.totalHours = 0;

                if (shiftClocks){
                    shiftClocks.forEach((clock) => {               
                        clock.DisplayTimeZone = this.dto.LocalTimeZone;

                        if (clock.ShiftClockOutUtc){
                            this.totalHours += parseFloat(clock.duration);
                        }
                    });
                }

                this.totalHours = this.totalHours.toFixed(2);

                return shiftClocks;
            },
            shiftClockID() {
                return this.selectedRow.rowData.ShiftClockID;
            },
        },
        methods: {
            shiftClocksTableRowOnClick(args) {
                this.selectedRow = args;
            },
            addShiftClock(){
                 let modalData = {
                    mode: "add",
                    shiftID: this.dto.ShiftID,
                    confirmedHourlyRate: this.dto.ConfirmedHourlyRate,
                    hourlyRateMarkUp: this.dto.HourlyRateMarkUpPercentage,
                    timeZone: this.dto.LocalTimeZone,
                    dto: {},
                };

                DMUtils.openApplicationModal('shiftRequestsClocksEditModal', modalData);
            },
            editShiftClock(){
               this.$store
                    .dispatch('ShiftModule/getShiftClockByID', this.shiftClockID)
                        .then((response) => {
                               let modalData = {
                                mode: "edit",
                                shiftID: this.dto.ShiftID,
                                dto: response,
                                timeZone: this.dto.LocalTimeZone
                            };

                            DMUtils.openApplicationModal('shiftRequestsClocksEditModal', modalData);
                        });
            },
            deleteShiftClock(){
               this.$store
                    .dispatch('ShiftModule/deleteShiftClock', this.shiftClockID)
            },
            forceClockOut(){
                this.$store
                    .dispatch('ShiftModule/getShiftClockByID', this.shiftClockID)
                        .then((response) => {
                            this.$store.dispatch('ShiftModule/forceShiftClockOutMyShyftAdmin', response)
                        });
            },
            btnCloseOnClick(){
                this.closeModal();
            },
            shiftClocksMyShyftAdminTableActionOnClick(args) {
                if (args.item.text === 'Edit') {
                    this.editShiftClock();
                } else if (args.item.text === 'Force Clock Out') {
                    const message = 'Are you sure you want to Force Clock Out?';
                    const title = 'Confirm';
                    const confirmCallback = this.forceClockOut;

                    DMUtils.openApplicationModal('dmConfirmModal', {
                        title: title,
                        message: message,
                        confirmCallback: confirmCallback,
                        confirmText: 'Yes',
                        denyText: 'Cancel',
                    });
                } else if (args.item.text === 'Delete') {
                    const message = 'Are you sure you want to delete this Shift Clock?';
                    const title = 'Confirm';
                    const confirmCallback = this.deleteShiftClock;

                    DMUtils.openApplicationModal('dmConfirmModal', {
                        title: title,
                        message: message,
                        confirmCallback: confirmCallback,
                        confirmText: 'Yes',
                        denyText: 'Cancel',
                    });
                }      
            },          
        },
        created() {
            this.dto = DMUtils.copy(this.modalData.dto);
            this.accountType = DMUtils.copy(this.modalData.accountType);
            this.$store.dispatch('ShiftModule/getAllShiftClocksForShift', this.dto.ShiftID);
            if (this.accountType === "MyShyftUser"){
                this.shiftClocksTableColumns = [
                    {
                        type: 'iconTemplate',
                        iconName: 'ellipsis',
                        ellipsisActions: this.shiftClocksMyShyftAdminTableActions,
                        iconOnClick: this.shiftClocksMyShyftAdminTableActionOnClick,
                        width: '55px',
                    },
                    {
                        key: 'ShiftClockInUtc',
                        label: 'Clock In',
                        type: "date",
                        formatter(column, clock) {
                            return moment.tz(clock.ShiftClockInUtc, clock.DisplayTimeZone).format("MM/DD/YYYY hh:mmA z"); 
                        } 
                    },
                    {
                        key: 'ShiftClockOutUtc',
                        label: 'Clock Out',
                        type: "date",
                        formatter(column, clock) {
                            let date =  moment.tz(clock.ShiftClockOutUtc, clock.DisplayTimeZone).format("MM/DD/YYYY hh:mmA z");

                            if (date === 'Invalid date'){
                                return '';
                            }

                            return date;
                        } 
                    },
                    {
                        key: 'ClockOutType',
                        label: 'Clock Out Type'
                    },
                    {
                        key: 'duration',
                        label: 'Hours Worked',
                        textAlign: 'Right',
                        width: '140px'
                    },
                ];
            } else {
                 this.shiftClocksTableColumns = [
                    {
                        key: 'ShiftClockInUtc',
                        label: 'Clock In',
                        type: "date",
                        formatter(column, clock) {
                            return moment.tz(clock.ShiftClockInUtc, clock.DisplayTimeZone).format("MM/DD/YYYY hh:mmA z"); 
                        } 
                    },
                    {
                        key: 'ShiftClockOutUtc',
                        label: 'Clock Out',
                        type: "date",
                        formatter(column, clock) {
                            let date =  moment.tz(clock.ShiftClockOutUtc, clock.DisplayTimeZone).format("MM/DD/YYYY hh:mmA z");

                            if (date === 'Invalid date'){
                                return '';
                            }

                            return date;
                        } 
                    },
                    {
                        key: 'duration',
                        label: 'Hours Worked',
                        textAlign: 'Right',
                    },
                ];
            }
        },
    }
</script>
