import endpoints from '../../../js/endpoints';

const invoiceModule = {
    namespaced: true,
    state: () => ({
        AllInvoices: [],
        AllUnpaidInvoices: [],
        AllTransactions: [],
        AllTransactionsForShiftClock: [],
        AllFailedTransactions: [],
        AllInvoicesNumberOfRows: 0,
        AllTransactionsNumberOfRows: 0,
    }),
    mutations: {
        setInvoices(state, invoices) {
            state.AllInvoices = invoices;
        },
        setUnpaidInvoices(state, invoices) {
            state.AllUnpaidInvoices = invoices;
        },
        setTransactions(state, transactions) {
            state.AllTransactions = transactions;
        },
        setTransactionsForShiftClock(state, transactions) {
            state.AllTransactionsForShiftClock = transactions;
        },
        setFailedTransactions(state, transactions) {
            state.AllFailedTransactions = transactions;
        },
        setInvoicesNumberOfRows(state, count) {
            state.AllInvoicesNumberOfRows = count;
        },
        setTransactionsNumberOfRows(state, count) {
            state.AllTransactionsNumberOfRows = count;
        },
    },
    actions: {
        getInvoiceByIDMyShyftAdmin({}, invoiceID) {
            return endpoints.getInvoiceByIDMyShyftAdmin(invoiceID).then((response) => {
                return response;
            });
        },
        getAllInvoicesMyShyftAdmin({ commit }, paramsDto) {
            commit('setInvoices', []);
            commit('setInvoicesNumberOfRows', 0);

            return endpoints.getAllInvoicesMyShyftAdmin(paramsDto).then((response) => {
                commit('setInvoices', response.Rows);
                commit('setInvoicesNumberOfRows', response.NumberOfRows);
                return response;
            });
        },
        getAllInvoicesOrganization({ commit }, paramsDto) {
            commit('setInvoices', []);
            commit('setInvoicesNumberOfRows', 0);

            return endpoints.getAllInvoicesOrganization(paramsDto).then((response) => {
                commit('setInvoices', response.Rows);
                commit('setInvoicesNumberOfRows', response.NumberOfRows);
                return response;
            });
        },
        getAllUnpaidInvoicesOrganization({ commit }) {
            commit('setUnpaidInvoices', []);

            return endpoints.getAllUnpaidInvoicesOrganization().then((response) => {
                commit('setUnpaidInvoices', response);
            });
        },
        updatePaymentDetails({ dispatch }, dto) {
            return endpoints.updatePaymentDetails(dto).then(() => {
                dispatch('getAllInvoicesMyShyftAdmin');
            });
        },
        getAllPaymentMethodCodes() {
            return endpoints.getAllPaymentMethodCodes().then((response) => {
                return response;
            });
        },
        // Hidden for production launch, Cliff has mentioned reworking this into a new feature. ~Sammie 11/7/2022
        // generateInvoices({ dispatch }) {
        //     return endpoints.generateInvoices().then((response) => {
        //         dispatch('getAllInvoicesMyShyftAdmin');
        //         return response;
        //     });
        // },
        downloadInvoice({}, invoiceID) {
            return endpoints.downloadInvoice(invoiceID).then((response) => {
                return response;
            });
        },
        beginInvoicePayment({ commit }, invoiceIDs) {
            return endpoints.beginInvoicePayment(invoiceIDs).then((response) => {
                return response;
            });
        },
        completeInvoicePayment({ dispatch }, dto) {
            return endpoints.completeInvoicePayment(dto).then((response) => {
                dispatch('getAllInvoicesOrganization');
                return response;
            });
        },
        getAllTransactionsMyShyftAdmin({ commit }, paramsDto) {
            commit('setTransactions', []);
            commit('setTransactionsNumberOfRows', 0);

            return endpoints.getAllTransactionsMyShyftAdmin(paramsDto).then((response) => {
                commit('setTransactions', response.Rows);
                commit('setTransactionsNumberOfRows', response.NumberOfRows);
                return response;
            });
        },
        getAllTransactionsForShiftClockID({ commit }, shiftClockID) {
            commit('setTransactionsForShiftClock', []);

            return endpoints.getAllTransactionsForShiftClockID(shiftClockID).then((response) => {
                commit('setTransactionsForShiftClock', response);
            });
        },
        getAllTransferFailures({ commit }) {
            commit('setFailedTransactions', []);

            return endpoints.getAllTransferFailures().then((response) => {
                commit('setFailedTransactions', response);
            });
        },
        retryTransaction({ dispatch }, dto) {
            return endpoints.retryTransaction(dto).then((response) => {
                dispatch('getAllTransactionsMyShyftAdmin');
                return response;
            });
        },
    },
};

export default invoiceModule;
