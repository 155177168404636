<template src="./shiftRequestsCreateModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

export default {
    name: 'organization-request-shift-create-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            dto: {},
            shiftStart: null,
            shiftEnd: null,
            medicalLicenses: [],            
            typesOfCare: [],  
            locationNameList: [],
            locationFields: { text: 'LocationNameF', value: 'OrganizationLocationID' },
            filterType: 'Contains',
            allowFiltering: true,
            selectedMedicalLicenses:[],  
            selectedTypesOfCare: [],     
            offsetDifference: 0,   
            locationTimeZone: '',
            locationState: ''
        }
    },
    computed: {
        mode() {
            return this.modalData.mode;
        },
        organizationLocationID() {
            return this.dto.OrganizationLocationID;
        } ,
        lblTitle() {
            return 'Create a Caregiver Request';
        },
        contactHeadingText() {
            if (this.signedInUser.OrganizationCategory === 'Agency') {
                return "Agency Contact Information";
            } else {
                return "Who to report to upon arrival";
            }
        },
        signedInUser() {
            return this.$store.state.AuthorizationModule.SignedInUser;
        }
    },
    methods: {
        getDropdownValues() {
            this.locationNameList = [];
           
            this.$store.dispatch('OrganizationLocationModule/getAllOrganizationLocationNamesForOrganization', this.signedInUser.OrganizationID)
                .then((response) => {
                    this.locationNameList = response;
                    if (!this.dto.OrganizationLocationID && response.length > 0) {
                        this.dto.OrganizationLocationID = response[0].OrganizationLocationID;
                    }

                    this.$store
                        .dispatch('MedicalLicenseModule/getAllMedicalLicensesOrganizationLocation', this.dto.OrganizationLocationID)
                        .then(() => {
                            this.medicalLicenses = this.$store.state.MedicalLicenseModule.AllMedicalLicenses;                
                            if (this.mode === "duplicate"){
                                this.duplicateMedicalLicenses();
                            }
                        });
                });

            this.$store
                .dispatch('UserModule/getAllTypesOfCare')
                .then(() => {
                    this.typesOfCare = this.$store.state.UserModule.AllTypesOfCare;
                    if (this.mode === "duplicate"){
                        this.duplicateTypesOfCare();
                    }
                });
        },
        getOrganizationLocationTerminology(lowerCase, plural) {
            return app.organizationLocationTerminology(this.signedInUser.OrganizationCategory, lowerCase, plural)
        },
        getMedicalLicenses() {
            this.$store
                .dispatch('MedicalLicenseModule/getAllMedicalLicensesOrganizationLocation', this.dto.OrganizationLocationID)
                .then(() => {
                    this.medicalLicenses = this.$store.state.MedicalLicenseModule.AllMedicalLicenses;                
                });
        },
        duplicateMedicalLicenses(){
            this.dto.MedicalLicenses.forEach(license => {
                this.selectedMedicalLicenses.push(license.MedicalLicenseID);
            });
        },
        duplicateTypesOfCare(){
            this.dto.TypesOfCare.forEach(care => {
                this.selectedTypesOfCare.push(care.TypeOfCareID);
            });
        },
        getSelectedLicenses(){
            let selectedLicenses = [];

            this.medicalLicenses.forEach(license => {
                if (this.selectedMedicalLicenses.includes(license.MedicalLicenseID)){
                    let licenseDto = {
                        LicenseName: license.LicenseName,
                        MedicalLicenseID: license.MedicalLicenseID,
                    }
                    selectedLicenses.push(licenseDto);
                }
            });

            this.dto.MedicalLicenses = selectedLicenses;
        },
        getSelectedTypesOfCare(){
            let selectedTypesOfCare = [];

            this.typesOfCare.forEach(care => {
                if (this.selectedTypesOfCare.includes(care.TypeOfCareID)){
                    let typeOfCareDto = {
                        TypeOfCareName: care.TypeOfCareName,
                        TypeOfCareID: care.TypeOfCareID,
                    }
                    selectedTypesOfCare.push(typeOfCareDto);
                }
            });

            this.dto.TypesOfCare = selectedTypesOfCare;
        },
        onOrganizationLocationChange(args) {
            this.locationTimeZone = args.itemData.LocalTimeZone;
            this.setTimezoneOffsetDifference();

            if (this.locationState !== args.itemData.LocationState) {
                this.locationState = args.itemData.LocationState;

                if (this.dto.OrganizationLocationID > 0){
                    this.selectedMedicalLicenses = [];
                    this.getMedicalLicenses();
                }
            }
        },
        setTimezoneOffsetDifference() {
            let moment = require('moment-timezone');
            let deviceOffset = moment().utcOffset();
            let organizationOffset = moment().tz(this.locationTimeZone).utcOffset();
            this.offsetDifference = deviceOffset - organizationOffset;
        },
        btnSaveOnClick() {
            this.getSelectedLicenses();
            this.getSelectedTypesOfCare();  

            let moment = require('moment-timezone');
            this.dto.ShiftStartUtc = moment(this.shiftStart).add(this.offsetDifference, 'minutes').utc().format();
            this.dto.ShiftEndUtc = moment(this.shiftEnd).add(this.offsetDifference, 'minutes').utc().format();

            this.$store
                .dispatch('ShiftModule/createShift', this.dto)
                .then(() => {
                    this.shouldModalStayOpen = false;
                    this.successClose(this.dto);
                });
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.dto = DMUtils.copy(this.modalData.dto);
        this.getDropdownValues();

        if (this.mode === "duplicate"){
            this.locationTimeZone = this.dto.LocalTimeZone;
            this.locationState = this.dto.State;
            this.setTimezoneOffsetDifference();
            this.shiftStart = moment(this.dto.ShiftStartUtc).subtract(this.offsetDifference, 'minutes').utc().format();
            this.shiftEnd = moment(this.dto.ShiftEndUtc).subtract(this.offsetDifference, 'minutes').utc().format();
        }
    },
}
</script>
