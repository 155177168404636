<template src="./frequentlyAskedQuestionModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'frequently-asked-question-modal',
        mixins: [dmModalMixin],
        data() {
            return {
                frequentlyAskedQuestionsTableColumns: [],
                frequentlyAskedQuestionsTableSortBy: {
                    columns: [
                        { field: 'FrequentlyAskedQuestionID', direction: 'Descending' },
                    ]
                },
                frequentlyAskedQuestionsTableCustomClass: 'dm-bg-color-black',
                frequentlyAskedQuestionsTableActions: [
                    {
                        text: 'Toggle Status'
                    },
                    {
                        text: 'Edit'
                    },
                    {
                        text: 'Delete'
                    },
                ],
                selectedRow: null
            }
        },
        computed: {
            lblTitle() {
                return 'Frequently Asked Questions';
            },
            frequentlyAskedQuestionsTableItems() {
                return this.$store.state.FrequentlyAskedQuestionModule.AllFrequentlyAskedQuestions;
            },
            frequentlyAskedQuestionID() {
                return this.selectedRow.rowData.FrequentlyAskedQuestionID;
            },
            myShyftAdminTimeZone(){
                return this.$store.state.AdminModule.MyShyftAdminTimeZone;
            },
        },
        methods: {
            frequentlyAskedQuestionsTableRowOnClick(args) {
                this.selectedRow = args;
            },
            addFrequentlyAskedQuestion() {
                let modalData = {
                    mode: 'add',
                    dto: {
                        UserType: null
                    },
                };

                DMUtils.openApplicationModal('addFrequentlyAskedQuestionModal', modalData);
            },
            toggleStatus() {           
                this.$store
                    .dispatch('FrequentlyAskedQuestionModule/getByIDFrequentlyAskedQuestion', this.frequentlyAskedQuestionID)
                        .then((response) => {
                            this.dto = response;
                            if (this.dto.Status === "Active"){
                                this.dto.Status = "Inactive";
                            } else {
                                this.dto.Status = "Active";
                            }
                            this.$store.dispatch('FrequentlyAskedQuestionModule/updateFrequentlyAskedQuestion', this.dto);
                        });
            },
            editFrequentlyAskedQuestion() {
                this.$store.dispatch('FrequentlyAskedQuestionModule/getByIDFrequentlyAskedQuestion', this.frequentlyAskedQuestionID)
                    .then((response) => {
                        let modalData = {
                            mode: 'edit',
                            dto: response,
                        };

                        DMUtils.openApplicationModal('addFrequentlyAskedQuestionModal', modalData);
                    });  
            },
            deleteFrequentlyAskedQuestion() {
                this.$store.dispatch('FrequentlyAskedQuestionModule/deleteFrequentlyAskedQuestion', this.frequentlyAskedQuestionID);
            },
           frequentlyAskedQuestionsTableActionOnClick(args) {
                if (args.item.text === 'Toggle Status'){
                    this.toggleStatus();
                }
                else if (args.item.text === 'Edit') {
                    this.editFrequentlyAskedQuestion();
                }
                else if (args.item.text === 'Delete') {
                    const message = 'Are you sure you want to delete this Frequently Asked Question?';
                    const title = 'Confirm';
                    const confirmCallback = this.deleteFrequentlyAskedQuestion;

                    DMUtils.openApplicationModal('dmConfirmModal', {
                        title: title,
                        message: message,
                        confirmCallback: confirmCallback,
                        confirmText: 'Yes',
                        denyText: 'Cancel',
                    });
                }             
            },
            btnCloseOnClick(){
                this.closeModal();
            },
        },
        created() {
            this.$store.dispatch('FrequentlyAskedQuestionModule/getAllMyShyftAdminFrequentlyAskedQuestions');

            this.frequentlyAskedQuestionsTableColumns = [
                {
                    type: 'iconTemplate',
                    iconName: 'ellipsis',
                    ellipsisActions: this.frequentlyAskedQuestionsTableActions,
                    iconOnClick: this.frequentlyAskedQuestionsTableActionOnClick,
                    width: '55px',
                },
                {
                    key: 'FrequentlyAskedQuestionID',
                    label: 'ID',
                    width: '75px'
                },
                {
                    key: 'Question',
                    label: 'Question'
                },
                {
                    key: 'UserType',
                    label: 'Type',
                    width: '100px',
                },
                {
                    key: 'Status',
                    label: 'Status',
                    width: '100px',
                },
            ];           
        },
    }
</script>