<template src="./messagesShiftActiveChatPage.html"></template>

<script>
import messagesCard from '../messagesCard'
import messagesMixin from '../messagesMixin';

export default {
    name: 'messages-shift-active-chat-page',
    mixins: [
        messagesMixin
    ],
    components: {
        messagesCard
    },
    data() {
        return {
            endpointsMap: {
                Caregiver: {
                    getAllShiftsWithMessagesByShiftID: 'getAllShiftsWithMessagesByShiftIDCaregiver',
                },
                OrganizationAdmin: {
                    getAllShiftsWithMessagesByShiftID: 'getAllShiftsWithMessagesByShiftIDOrganization',
                },
                OrganizationEmployee: {
                    getAllShiftsWithMessagesByShiftID: 'getAllShiftsWithMessagesByShiftIDOrganization',
                },
            },
            selectedMessageID: 0
        };
    },
    computed: {
        signedInUser() {
            return this.$store.state.AuthorizationModule.SignedInUser;
        }
    },
    watch: {

    },
    methods: {
        getActiveChats(hideLoader) {
            let dto = {
                StartDateRangeUtc: this.dateValues && this.dateValues.length > 0 ? this.dateValues[0] : '',
                EndDateRangeUtc: this.dateValues && this.dateValues.length > 0 ? this.dateValues[1] : '',
                OrganizationID: this.selectedOrganizationID,
                SearchText: this.searchText,
            };

            endpoints[this.userRoleEndpoints.getAllShiftsWithMessagesByShiftID](dto, hideLoader)
                .then(response => {
                    response.forEach(s => {
                        let timezone = this.signedInUser.Role === 'Caregiver' ? moment.tz.guess() : this.signedInUser.LocalTimeZone;
                        this.formatActiveChatDateTimeDetails(s, s.ShiftStartUtc, s.MessageShownTextCreatedUtc, timezone);
                    });

                    this.activeChats = response;
                    if(this.selectedMessageID) {
                        let existingMessage = this.activeChats.filter(m => m.ShownMessageID === this.selectedMessageID)[0];
                        if(!existingMessage) {
                            this.$emit('messageChanged', {shiftID: null, caregiverID: null, shownMessageID: null});
                        }
                    }
                });
        },
        goToChat(shift) {
            shift.NumberOfShiftNewMessages = 0;

            DMUtils.localStorageSet(this.filtersLocalStorageName, JSON.stringify({
                searchText: this.searchText,
                dateValues: this.dateValues,
                selectedOrganizationID: this.selectedOrganizationID,
            }));

            if(this.isCaregiverOrganizationApp) {
                let caregiverID = this.signedInUser.Role === 'Caregiver' ? this.signedInUser.CaregiverID : shift.CaregiverID;
                this.$router.push('/activeChat/thread/' + shift.ShiftID + '/caregiver/' + caregiverID + '/message/' + shift.ShownMessageID);
            } else {
                this.selectedMessageID = shift.ShownMessageID;
                this.$emit('messageChanged', {shiftID: shift.ShiftID, caregiverID: shift.CaregiverID, shownMessageID: shift.ShownMessageID});
                setTimeout(() => {
                    app.getUnreadMessageCount(this.$store.state.AuthorizationModule.SignedInUser.Role);
                }, 2000);
            }
        },
        getOrganizationOptions() {
            endpoints.getOrganizations()
                .then(response => {
                    this.organizationOptions = DMUtils.getCboOptions(response, 'OrganizationID', 'OrganizationName');
                });
        },
    },
    created() {
        if(this.isCaregiverOrganizationApp) {
            this.getOrganizationOptions();
        }

        this.getActiveChats();

        this.newMessagesTimer = setInterval(() => {
            if(document.hasFocus()) { // Don't keep getting messages in the background
                this.getActiveChats(true);
            }
        }, 60 * 1000);
    },
    destroyed() {
        clearInterval(this.newMessagesTimer);
    }
};
</script>
