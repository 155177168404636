import endpoints from "../../js/endpoints";

const legalDocumentModule = {
    namespaced: true,
    state: () => ({
        AllLegalDocuments: []
    }),
    mutations: {
        setLegalDocuments(state, documents) {
            state.AllLegalDocuments = documents;
        },
    },
    actions: {
        getByIDLegalDocument({}, legalDocumentID) {
            return endpoints.getByIDLegalDocument(legalDocumentID)
                .then((response) => {
                    return response;
                });
        },   
        getByIDMyShyftAdminLegalDocument({}, legalDocumentID) {
            return endpoints.getByIDMyShyftAdminLegalDocument(legalDocumentID)
                .then((response) => {
                    return response;
                });
        }, 
        getAllMyShyftAdminLegalDocuments({commit}) {
            commit('setLegalDocuments', []);

            return endpoints.getAllMyShyftAdminLegalDocuments()
                .then((response) => {
                    commit('setLegalDocuments', response);
                    return response;
                });
        },      
        updateLegalDocument({dispatch}, dto) {
            return endpoints.updateLegalDocument(dto)
                .then(() => {
                    dispatch('getAllMyShyftAdminLegalDocuments');
                });
        },       
    },
};

export default legalDocumentModule;
