<template src="./caregiverPenaltiesModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin';

export default {
    name: 'caregiver-penalties-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            searchString: '',
            dto: {},
            caregiverPenaltyTableColumns: [],
            caregiverPenaltyTableSortBy: {
                columns: [{ field: 'CreatedUtc', direction: 'Descending' }],
            },
            caregiverPenaltyTableCustomClass: 'dm-bg-color-black',
            caregiverPenaltyTableActions: [
                {
                    text: 'Delete',
                },
            ],
            selectedRow: null,
        };
    },
    computed: {
        lblTitle() {
            return 'View Penalties: ' + this.dto.FirstLastName;
        },
        caregiverPenaltyTableItems() {
            let penalties = this.$store.state.AdminCaregiverModule.AllPenaltiesForCaregiver;
            let moment = require('moment-timezone');

            if (penalties) {
                penalties.forEach((penalty) => {
                    penalty.CreatedUtc = moment.tz(penalty.CreatedUtc, this.myShyftAdminTimeZone).format();
                    penalty.DeletedUtc = moment.tz(penalty.Deleted, this.myShyftAdminTimeZone).format();

                    if (penalty.DeletedUserID === -1) {
                        penalty.DeletedUserID = '';
                        penalty.DeletedUtc = '';
                    }
                });
            }

            return penalties;
        },
        myShyftAdminTimeZone() {
            return this.$store.state.AdminModule.MyShyftAdminTimeZone;
        },
    },
    methods: {
        caregiverPenaltyTableRowOnClick(args) {
            this.selectedRow = args;
        },
        deleteCaregiverPenalty() {
            let dto = {
                CaregiverPenaltyID: this.selectedRow.rowData.CaregiverPenaltyID,
                CaregiverID: this.selectedRow.rowData.CaregiverID,
            };

            this.$store.dispatch('AdminCaregiverModule/deleteCaregiverPenalty', dto);
        },
        btnCloseOnClick() {
            this.closeModal();
        },
        caregiverPenaltyTableActionOnClick(args) {
            if (args.item.text === 'Delete') {
                this.deleteCaregiverPenalty();
            }
        },
    },
    created() {
        this.dto = DMUtils.copy(this.modalData.dto);
        this.$store.dispatch('AdminCaregiverModule/getAllCaregiverPenaltiesForCaregiver', this.dto.CaregiverID);

        this.caregiverPenaltyTableColumns = [
            {
                type: 'iconTemplate',
                iconName: 'ellipsis',
                ellipsisActions: this.caregiverPenaltyTableActions,
                iconOnClick: this.caregiverPenaltyTableActionOnClick,
                width: '55px',
            },
            {
                key: 'ShiftID',
                label: 'Shift ID',
                width: '100px',
            },
            {
                key: 'ShiftStartDateUtc',
                label: 'Shift Start Time',
                type: 'date',
                width: '180px',
                formatter(column, penalty) {
                    return moment.tz(penalty.ShiftStartDateUtc, penalty.myShyftAdminTimeZone).format("MM/DD/YYYY hh:mmA"); 
                } 
            },
            {
                key: 'ShiftEndDateUtc',
                label: 'Shift End Time',
                type: 'date',
                width: '180px',
                formatter(column, penalty) {
                    return moment.tz(penalty.ShiftEndDateUtc, penalty.myShyftAdminTimeZone).format("MM/DD/YYYY hh:mmA"); 
                } 
            },
            {
                key: 'OrganizationName',
                label: 'Organization Name',
            },
            {
                key: 'PenaltyType',
                label: 'Penalty Type',
            },
            {
                key: 'CreatedUtc',
                label: 'Created On',
                type: 'date',
                width: '180px',
                formatter(column, penalty) {
                    return moment.tz(penalty.CreatedUtc, penalty.myShyftAdminTimeZone).format("MM/DD/YYYY hh:mmA"); 
                } 
            },
        ];
    },
};
</script>
