<template src="./organizationUserListModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'
import Vue from 'vue';

export default {
    name: 'organization-user-list-modal',   
    mixins: [dmModalMixin],
    data() {
        return {
            organizationUserTableColumns: [],
            organizationUserTableSortBy: {
                columns: [
                    { field: 'FirstLastName', direction: 'Ascending' },                
                ]
            },
            organizationUserTableCustomClass: 'dm-bg-color-black',
            organizationUserTableActions: [
                {
                    text: 'Toggle Active Status'
                },
                {
                    text: 'Edit'
                },
                {
                    text: 'Delete'
                },
            ],
            selectedRow: null,
            dto: {},
        }
    },
    computed: {
        lblTitle() {
            return 'User List for ' + this.modalData.dto.OrganizationName;
        },
        organizationUserTableItems() {       
            let organizationUsers = this.$store.state.UserModule.AllUsers
            
            if (organizationUsers !== undefined){
                organizationUsers.forEach(user => {
                    if (user.Role === "OrganizationAdmin"){
                        user.Role = "Admin";
                    } else if (user.Role === "OrganizationEmployee"){
                        user.Role = "Employee";
                    } else if (user.Role === "OrganizationCorporate"){
                        user.Role = "Corporate";
                    }
                });
            }
            
            return organizationUsers;
        },
        userID() {
            return this.selectedRow.rowData.UserID;
        },
    },
    methods: {
        organizationUserTableRowOnClick(args){
            this.selectedRow = args;
        },
        btnCloseOnClick() {
            this.closeModal();
        },
        toggleStatus() {           
            this.$store
                .dispatch('UserModule/getUserByID', this.selectedRow.rowData.UserID)
                    .then((response) => {
                        this.dto = response;
                        if (this.dto.Status === "Active"){
                            this.dto.Status = "Inactive";
                        } else {
                            this.dto.Status = "Active";
                        }
                        this.$store.dispatch('UserModule/updateUser', this.dto);
                    });
            },
        addOrganizationUser() {
            let modalData = {
                mode: 'add',
                organizationID: this.dto.OrganizationID,
                accountType: 'OrganizationUser',
                dto: {},
            };

            DMUtils.openApplicationModal('usersEditModal', modalData);
        },
        editOrganizationUser() {
            let modalData = {
                mode: 'edit',
                accountType: 'OrganizationUser',
                dto: {},
            };

            this.$store
                .dispatch('UserModule/getUserByID', this.userID)
                .then((response) => {
                    modalData.dto = response;
                    DMUtils.openApplicationModal('usersEditModal', modalData);
                });
        },
        deleteOrganizationUser() {
            let dto = {
                OrganizationID: this.dto.OrganizationID,
                UserID: this.userID,
            }
            this.$store.dispatch('UserModule/deleteUser', dto);
        },
        organizationUserTableActionOnClick(args) {
            if (args.item.text === 'Toggle Active Status') {
                this.toggleStatus();
            } else if (args.item.text === 'Edit') {
                this.editOrganizationUser();
            } 
            else if (args.item.text === 'Delete') {
                const message = 'Are you sure you want to delete this user?';
                const title = 'Confirm';
                const confirmCallback = this.deleteOrganizationUser;

                DMUtils.openApplicationModal('dmConfirmModal', {
                    title: title,
                    message: message,
                    confirmCallback: confirmCallback,
                    confirmText: 'Yes',
                    denyText: 'Cancel',
                });
            }
        },
    },
    created() {
        this.dto = DMUtils.copy(this.modalData.dto);
        this.$store.dispatch('UserModule/getAllUsers', this.dto.OrganizationID);

        this.organizationUserTableColumns = [
            {
                type: 'iconTemplate',
                iconName: 'ellipsis',
                ellipsisActions: this.organizationUserTableActions,
                iconOnClick: this.organizationUserTableActionOnClick,
                width: '55px',
            },
            {
                key: 'FirstLastName',
                label: 'Name',
            },
            {
                key: 'EmailAddress',
                label: 'Email Address',
            },
            {
                key: 'Role',
                label: 'Role',
            },
            {
                key: 'PhoneNumberF',
                label: 'Phone Number',
            },
            {
                key: 'Status',
                label: 'Status',
                width: '100px',
                template: function () {
                    return {
                        template: Vue.component('columnTemplate', {
                            template: `<div :class="setColor">{{data.Status}}</div>`,
                            computed: {
                                setColor: function () {
                                    if (this.data.Status === 'Inactive') {
                                        return 'app-grid-status-orange';
                                    }

                                    if (this.data.Status === 'Active') {
                                        return 'app-grid-status-green';
                                    }     
                                },                             
                            },
                        }),
                    };
                },
            },
        ];
    },
}
</script>
