 <template src="./shiftApplicantBlockConfirmModal.html"></template>
<script>
import Vue from 'vue'
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'shift-applicant-block-confirm-modal',
        mixins: [dmModalMixin],
        data() {
            return {
                removeAllFutureConfirmed: false
            }
        },
        computed: {
            title() {
                return this.modalData.title;
            },
            message() {
                return this.modalData.message;
            },
            futureShiftsMessage() {
                return this.modalData.futureShiftsMessage;
            },
            confirmText() {
                return this.modalData.confirmText || 'Yes';
            },
            denyText() {
                return this.modalData.denyText || 'Cancel';
            }
        },
        methods: {
            btnConfirmOnClick() {
                if (typeof this.modalData.confirmCallback === 'function') {
                    this.modalData.confirmCallback(this.removeAllFutureConfirmed);
                }
                
                this.closeModal();
            },
            btnCloseOnClick() {
                if (typeof this.modalData.denyCallback === 'function') {
                    this.modalData.denyCallback();
                }

                this.closeModal();
            }
        }
    }
</script> 