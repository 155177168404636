export default {
	data() {
        return {
           uploadBase64: '',
		   uploadFilename: '',
        }
    },
    methods: {
		fileExceedsSizeLimit(base64String) {
			let sizeInMb = this.getfileSize(base64String)
	
			if (sizeInMb > 2.0) {
				DMUtils.alertShow('The selected file is too large.', 'Information');
				return true;
			} 
	
			return false;
		},
		getfileSize(base64String) {
			let base64Length = base64String.split(',')[1].length;
			let sizeInBytes = base64Length * (3 / 4);
			let sizeInMb = sizeInBytes / 1048576;

			return sizeInMb
		},
		handleUploadImageFileType(file, maxHeight, maxWidth) {
			const promise = new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsArrayBuffer(file);
	
				reader.onload = () => {
					let blob = new Blob([reader.result]);
					let blobURL = window.URL.createObjectURL(blob);
		
					let image = new Image();
					image.src = blobURL;
	
					image.onload = () => {
						let canvas = document.createElement('canvas');		
						let ratio = 0;
		
						let width = image.width;
						let height = image.height;
		
						if (width > height) {
							ratio = width / height;
							if (width > maxWidth) {
								height = Math.round(maxWidth / ratio);
								width = maxWidth;
							}
						} else if (height > width) {
							ratio = height / width;
							if (height > maxHeight) {
								width = Math.round(maxHeight / ratio);
								height = maxHeight;
							}
						} else {
							width = maxHeight;
							height = maxHeight;
						}
		
						canvas.width = width;
						canvas.height = height;
						let ctx = canvas.getContext('2d');
						ctx.drawImage(image, 0, 0, width, height);
						let resized = canvas.toDataURL('image/jpeg');
		
						if (this.fileExceedsSizeLimit(resized) === false) {
							let originalFileName = file.name;
							let renamedFile = '';
							let fileExt = originalFileName.split('.').pop();
							if (originalFileName === fileExt) {
								renamedFile = originalFileName.concat('.jpeg');
							} else {
								renamedFile = originalFileName.replace(fileExt, 'jpeg');
							}

							const imageProperties = {ImageBase64: resized, ImageFileName: renamedFile}
							resolve(imageProperties)
						}
					};
				};
			})
			return promise;
		},
		handleUploadDocumentFileType(file, maxHeight, maxWidth) {
			const promise = new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsArrayBuffer(file);
	
				reader.onload = () => {
					let blob = new Blob([reader.result]);
					let blobURL = window.URL.createObjectURL(blob);
		
					let image = new Image();
					image.src = blobURL;
	
					image.onload = () => {
						let canvas = document.createElement('canvas');		
						let ratio = 0;
		
						let width = image.width;
						let height = image.height;
		
						if (width > height) {
							ratio = width / height;
							if (width > maxWidth) {
								height = Math.round(maxWidth / ratio);
								width = maxWidth;
							}
						} else if (height > width) {
							ratio = height / width;
							if (height > maxHeight) {
								width = Math.round(maxHeight / ratio);
								height = maxHeight;
							}
						} else {
							width = maxHeight;
							height = maxHeight;
						}

						let originalFileName = file.name;
						let renamedFile = '';
						let fileExt = originalFileName.split('.').pop();
						if (originalFileName === fileExt) {
							renamedFile = originalFileName.concat('.jpeg');
						} else {
							renamedFile = originalFileName.replace(fileExt, 'jpeg');
						}
		
						canvas.width = width;
						canvas.height = height;
						let ctx = canvas.getContext('2d');
						ctx.drawImage(image, 0, 0, width, height);
						let resized = canvas.toDataURL('image/jpeg');

						let blobBin = atob(resized.split(',')[1]);
						let array = [];
						for(var i = 0; i < blobBin.length; i++) {
							array.push(blobBin.charCodeAt(i));
						}

						let resizedBlob = new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
						var resizedFile = new File([resizedBlob], renamedFile, {type: 'image/jpeg'});

						let newFile = {
							name: renamedFile,
							rawFile: resizedFile,
							size: resizedBlob.size,
							type: 'jpeg',	
							validationMessages:{minSize:"",maxSize:""},
							statusCode: '1',
							status: 'Ready to Upload'		
						}

						const imageProperties = newFile;
						resolve(imageProperties)				
					};
				};
			})
			return promise;
		},
		handleUploadOtherFileType(file) {
			const promise = new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);

				reader.onload = (e) => {
					const base64String = e.target.result;
	
					if (this.fileExceedsSizeLimit(base64String) === false) {
						const imageProperties = {ImageBase64: base64String, ImageFileName: file.name};
						resolve(imageProperties)
					}	
				};
			});
			return promise;
		},
		proofFileSelected(e) {
			const file = e.target.files[0];
	
			if (file.type === 'image/jpeg' || file.type === 'image/png') {
				const maxHeight = 2000;
				const maxWidth = 2000;

				this.handleUploadImageFileType(file, maxHeight, maxWidth).then((imageProperties) => {
					this.dto.ProofImageBase64 = imageProperties.ImageBase64;
					this.dto.ProofImageFileName = imageProperties.ImageFileName;
				});
			} else if (file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
				this.handleUploadOtherFileType(file).then((imageProperties) => {
					this.dto.ProofImageBase64 = imageProperties.ImageBase64;
					this.dto.ProofImageFileName = imageProperties.ImageFileName;
				});
			} else {
				DMUtils.alertShow('This file type is not supported. Supported file types are .jpg, .png, .pdf, and .docx.', 'Information');
				return;
			}
		},
		profileFileSelected(e) {
			const file = e.target.files[0];
	
			if (file.type === 'image/jpeg' || file.type === 'image/png') {
				const maxHeight = 400;
				const maxWidth = 400;
				
				this.handleUploadImageFileType(file, maxHeight, maxWidth).then((imageProperties) => {
					this.dto.ProfileImageBase64 = imageProperties.ImageBase64;
					this.dto.ProfileImageFileName = imageProperties.ImageFileName;
				});
			} else {
				DMUtils.alertShow('This file type is not supported. Supported file types are .jpg, and .png.', 'Information');
				return;
			}
		},
		driverLicenseFileSelected(e) {
			const file = e.target.files[0];

			if (file.type === 'image/jpeg' || file.type === 'image/png') {
				const maxHeight = 2000;
				const maxWidth = 2000;

				this.handleUploadImageFileType(file, maxHeight, maxWidth).then((imageProperties) => {
					this.dto.DriversLicenseImageBase64 = imageProperties.ImageBase64;
					this.dto.DriversLicenseImageFileName = imageProperties.ImageFileName;
				});
			} else if (file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
				this.handleUploadOtherFileType(file).then((imageProperties) => {
					this.dto.DriversLicenseImageBase64 = imageProperties.ImageBase64;
					this.dto.DriversLicenseImageFileName = imageProperties.ImageFileName;
				});
			} else {
				DMUtils.alertShow('This file type is not supported. Supported file types are .jpg, .png, .pdf, and .docx.', 'Information');
				return;
			}
		},
		supportFileSelected(e) {
			const file = e.target.files[0];

			if (file.type === 'image/jpeg' || file.type === 'image/png') {
				const maxHeight = 1000;
				const maxWidth = 1000;

				this.handleUploadImageFileType(file, maxHeight, maxWidth).then((imageProperties) => {
					this.dto.SupportImageBase64 = imageProperties.ImageBase64;
					this.dto.SupportImageFileName = imageProperties.ImageFileName;
				});
			} else if (file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
				this.handleUploadOtherFileType(file).then((imageProperties) => {
					this.dto.SupportImageBase64 = imageProperties.ImageBase64;
					this.dto.SupportImageFileName = imageProperties.ImageFileName;
				});
			} else {
				DMUtils.alertShow('This file type is not supported. Supported file types are .jpg, .png, .pdf, and .docx.', 'Information');
				return;
			}
		},
		documentCenterFileSelected(e) {
			const file = e.fileData.rawFile;

			if (file.type === 'image/jpeg' || file.type === 'image/png') {
				const maxHeight = 2000;
				const maxWidth = 2000;

				this.handleUploadDocumentFileType(file, maxHeight, maxWidth).then((imageProperties) => {
					this.$refs.documentUpload.upload(imageProperties, true);
				});
			} 
		}
	}
}