<template src="./invoicesSelectionModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'invoice-selection-modal',
        mixins: [dmModalMixin],
        data() {
            return {
                invoiceID: 0,
                accountType: "",
                searchType: "",
                unpaidInvoicesTableColumns: [],
                unpaidInvoicesTableColumns: [],
                unpaidInvoicesTableSortBy: {
                    columns: [
                        { field: 'DueUtc', direction: 'Ascending' },
                    ]
                },
                selectedRow: null
            }
        },
        computed: {
            lblTitle() {
                return 'Select Invoices to Pay';
            },
            unpaidInvoicesTableItems() {
                let invoices = this.$store.state.InvoiceModule.AllUnpaidInvoices;
                let moment = require('moment-timezone');

                if (invoices){
                    invoices.forEach((invoice) => {                      
                        // invoice.ShiftStartLocalTime = moment.tz(invoice.ShiftStartUtc, invoice.OrganizationTimezone).format("MM DD YYYY, h:mm A"); 
                        // invoice.ShiftEndLocalTime = moment.tz(invoice.ShiftEndUtc, invoice.OrganizationTimezone).format("MM DD YYYY, h:mm A"); 
                    })
                };

                return invoices;
            },
        },
        methods: {
            unpaidInvoicesTableRowOnClick(args) {
                this.selectedRow = args;
            },
            checkoutInvoices(){
                let gridObj = this.$refs.unpaidInvoicesTable.$children[0]
                let selectedInvoices = gridObj.getSelectedRecords();
                let invoiceIDs = [];

                if (selectedInvoices){
                    selectedInvoices.forEach((invoice) => {
                        invoiceIDs.push(invoice.InvoiceID);
                    })
                }

                let modalData = {
                    dto: {},
                    callback: this.closeModal
                };

                this.$store
                    .dispatch('InvoiceModule/beginInvoicePayment', invoiceIDs)
                    .then((response) => {
                            modalData.dto = response;                     
                            DMUtils.openApplicationModal('invoicesPayModal', modalData);
                        });
            },
            btnCloseOnClick(){
                this.closeModal();
            },           
        },
        created() {
            this.invoiceID = this.modalData.invoiceID;

            this.$store.dispatch('InvoiceModule/getAllUnpaidInvoicesOrganization');
            
            this.unpaidInvoicesTableColumns = [
                 {
                    key: '',
                    label: '',
                    type: 'checkbox',
                    width: '100px'
                },
                {
                    key: 'InvoiceID',
                    label: 'Invoice ID',
                },
                {
                    key: 'InvoiceAmount',
                    label: 'Invoice Amount',
                    format: 'C2',
                    textAlign: 'Right',
                    width: '150px',
                },
                {
                    key: 'DueUtc',
                    label: 'Due On',
                    type: 'date',
                    format: "MM/dd/yyyy",
                    width: '180px',
                },
                {
                    key: 'CreatedUtc',
                    label: 'Created On',
                    type: 'date',
                    format: "MM/dd/yyyy",
                    width: '180px',
                },
            ];
        },
    }
</script>