<template src="./adminDashBoardCaregiverFirstShiftsModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'admin-dashboard-caregiver-first-shifts-modal',
        mixins: [dmModalMixin],
        data() {
            return {
                caregiverFirstShiftsStatus: ['','Complete', 'Incomplete'],
                caregiverFirstShiftsTableColumns: [],
                caregiverFirstShiftsTableSortBy: {
                    columns: [
                        { field: 'ShiftStartUtc', direction: 'Ascending' },
                    ]
                },
                caregiverFirstShiftsTablePageSettings: { pageSize: 300, pageSizes: [500, 300, 100, 50], pageCount: 4, direction: 'Ascending', columnName: 'ShiftStartUtc' },
                caregiverFirstShiftTableActions: [
                {
                    text: 'View Shift Details',
                },
                {
                    text: 'Toggle Status',
                }
            ],
            }
        },
        computed: {
            lblTitle() {
                return 'View Caregiver First Shifts';
            },
            caregiverFirstShiftsTableItems() {
                let caregiverFirstShifts = this.$store.state.AdminDashboardModule.CaregiverFirstShifts;
                if (caregiverFirstShifts) {
                    caregiverFirstShifts.forEach((caregiverFirstShift) => {
                        if (caregiverFirstShift.IsCompleted === true) {
                            caregiverFirstShift.Status = 'Complete'
                        }
                        else {
                            caregiverFirstShift.Status = 'Incomplete'
                        }
                    });
                }
                return  {
                    result: caregiverFirstShifts,
                    count: this.$store.state.AdminDashboardModule.CaregiverFirstShiftsNumberOfRows,
                }                   
            },
            paramsDto: {
                get() {
                    return this.$store.state.AdminDashboardModule.ParamsDto;
                },
                set(value) {
                    this.$store.commit('setParamsDto', value);
                },
            },
            toggleDto: {
                get() {
                    return this.$store.state.AdminDashboardModule.ToggleDto;
                },
                set(value) {
                    this.$store.commit('setToggleDto', value);
                },
            },
        },
        methods: {
            btnCloseOnClick(){
                this.closeModal();
            },
            scrollGridToTop() {
                this.$refs.caregiverFirstShiftsTable.$refs.grdTableList.getContent().children[0].scrollTop = 0;
            },
            searchCaregiverFirstShifts() {
                this.paramsDto.PageToLoad =
                this.$refs.caregiverFirstShiftsTable.$refs.grdTableList.ej2Instances.pagerModule.pagerObj.properties.currentPage;
                this.paramsDto.RowsPerPage = this.caregiverFirstShiftsTablePageSettings.pageSize;
                this.paramsDto.SortByColumn = this.caregiverFirstShiftsTablePageSettings.columnName;
                this.paramsDto.SortDescending = this.caregiverFirstShiftsTablePageSettings.direction === 'Ascending';
                this.scrollGridToTop();
                this.$store.dispatch('AdminDashboardModule/getAllCaregiverFirstShifts', this.paramsDto)
            },
            viewShiftDetails() {
                this.$store.dispatch('ShiftModule/getShiftByIDMyShyftAdmin', this.selectedRow.rowData.ShiftID)
                    .then((response) => {
                        let modalData = {
                            dto: response,
                            accountType: 'MyShyftUser',
                        };
                        DMUtils.openApplicationModal('shiftRequestsViewModal', modalData);
                    });
            },
            toggleStatus() {
                endpoints.getCaregiverFirstShiftByID(this.selectedRow.rowData.CaregiverFirstShiftID)
                    .then((response) => {
                        this.$store.dispatch('AdminDashboardModule/toggleCaregiverFirstShiftsStatus', response)
                    });
            },
            caregiverFirstShiftsTableActionOnClick(args) {
                if (args.item.text === 'View Shift Details') {
                    this.viewShiftDetails();
                } else if (args.item.text === 'Toggle Status') {
                    this.toggleStatus();
                }
            },
            caregiverFirstShiftsTableRowOnClick(args) {
                this.selectedRow = args;
            },
            caregiverFirstShiftsTableDataStateChange(state) {
                if (state.action.requestType === 'sorting' && state.action.direction && state.action.columnName) {
                    this.caregiverFirstShiftsTablePageSettings.direction = state.action.direction;
                    this.caregiverFirstShiftsTablePageSettings.columnName = state.action.columnName;
                    this.searchCaregiverFirstShifts();
                }

                if (state.action.requestType === 'paging') {
                    this.caregiverFirstShiftsTablePageSettings.pageSize = state.take;
                    this.searchCaregiverFirstShifts();
                }
            }
        },
        created() {
            this.caregiverFirstShiftsTableColumns = [
                {
                    type: 'iconTemplate',
                    iconName: 'ellipsis',
                    ellipsisActions: this.caregiverFirstShiftTableActions,
                    iconOnClick: this.caregiverFirstShiftsTableActionOnClick,
                    width: '55px',
                },
                {
                    key: 'UserID',
                    label: 'User ID',
                    width: '100px'
                },
                {
                    key: 'CaregiverFirstLastName',
                    label: 'Caregiver Name',
                },
                {
                    key: 'ShiftStartUtc',
                    label: 'Shift Start',
                    type: 'date',
                    width: '200px',
                    formatter(column, caregiverFirstShift) {
                        return moment.tz(caregiverFirstShift.ShiftStartUtc, caregiverFirstShift.LocalTimezone).format('MM/DD/YYYY hh:mmA z'); 
                    }
                },
                {
                    key: 'Status',
                    label: 'Status',
                    width: '135px'
                },
                {
                    key: 'ShiftID',
                    label: 'Shift ID',
                    width: '100px'
                },

            ];
        },
        mounted() {
        this.paramsDto.Status = '';
        this.paramsDto.SearchText = '';
        this.searchCaregiverFirstShifts();
    },
    }
</script>