<template src="./adminDashboardFailedPayoutModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'admin-dashboard-failed-modal',
        mixins: [dmModalMixin],
        data() {
            return {
                failedPayoutsTableColumns: [],
                failedPayoutsTableSortBy: {
                    columns: [
                        { field: 'UserID', direction: 'Ascending' },
                    ]
                },
            }
        },
        computed: {
            lblTitle() {
                return 'View Failed Payouts';
            },
            failedPayoutsTableItems() {
                return this.$store.state.AdminDashboardModule.FailedPayouts;
            },
        },
        methods: {
            btnViewMoreOnClick(){
                let failedPayouts = this.$store.state.AdminDashboardModule.FailedPayouts;
                let lastPayoutID = failedPayouts[failedPayouts.length - 1].ThirdPartyPayoutID;
                this.$store.dispatch('AdminDashboardModule/getMoreFailedPayouts', lastPayoutID);
            },
            btnCloseOnClick(){
                this.closeModal();
            },
        },
        created() {
            this.failedPayoutsTableColumns = [
                {
                    key: 'UserID',
                    label: 'User ID',
                    width: '135px'
                },
                {
                    key: 'CaregiverFirstLastName',
                    label: 'Caregiver Name'
                },
                {
                    key: 'TotalCaregiverAmount',
                    label: 'Amount Failed',
                    format: 'C2',
                    textAlign: 'right',
                    width: '150px'
                },
            ];
        },
    }
</script>