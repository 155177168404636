<template src="./shiftRequestsPage.html"></template>

<script>
import Vue from 'vue';

export default {
    name: 'shift-requests-page',
    data() {
        return {
            searchString: '',
            searchStartDate: '',
            searchEndDate: '',
            caregiverRatingMode: '',
            dto: {},
            paramsDto: {},
            locationNameList: [],
            locationFields: { text: 'LocationNameF', value: 'OrganizationLocationID' },
            filterType: 'Contains',
            allowFiltering: true,
            organizationPendingShiftsTableColumns: [],
            organizationHistoryShiftsTableColumns: [],
            organizationConfirmedShiftsTableColumns: [],
            organizationShiftsSharedTableSortBy: {
                columns: [{ field: 'ShiftStartUtc', direction: 'Descending' }],
            },
            organizationPendingShiftsTableActions: [
                {
                    text: 'View Request Details',
                },
                {
                    text: 'View Shift Applicants',
                },
                {
                    text: 'Duplicate Shift Request',
                },
                {
                    text: 'Duplicate Shift Request for Multiple Days',
                },
                {
                    text: 'View Shift History',
                },
                {
                    text: 'Delete',
                },
            ],
            organizationHistoryShiftsTableActions: [
                {
                    text: 'View Request Details',
                },
                {
                    text: 'View Shift Applicants',
                },
                {
                    text: 'Duplicate Shift Request',
                },
                {
                    text: 'Duplicate Shift Request for Multiple Days',
                },
                {
                    text: 'Add/Edit Caregiver Rating',
                },
                {
                    text: 'View Shift Clocks',
                },
                {
                    text: 'View Shift History',
                },
                {
                    text: 'View Chat',
                },
                {
                    text: 'Favorite Caregiver',
                },
                {
                    text: 'Block Caregiver',
                },
                {
                    text: 'Report Shift Issue',
                },
            ],
            organizationCurrentShiftsTableActions: [
                {
                    text: 'View Request Details',
                },
                {
                    text: 'View Shift Applicants',
                },
                {
                    text: 'Duplicate Shift Request',
                },
                {
                    text: 'Duplicate Shift Request for Multiple Days',
                },
                {
                    text: 'View Shift Clocks',
                },
                {
                    text: 'View Shift History',
                },
                {
                    text: 'View Chat',
                },
                {
                    text: 'Report Shift Issue',
                },
                {
                    text: 'Close',
                },
                {
                    text: 'Delete',
                },
            ],
            organizationCurrentOnDutyShiftsTableActions: [
                {
                    text: 'View Request Details',
                },
                {
                    text: 'View Shift Applicants',
                },
                {
                    text: 'Duplicate Shift Request',
                },
                {
                    text: 'Duplicate Shift Request for Multiple Days',
                },
                {
                    text: 'View Shift Clocks',
                },
                {
                    text: 'View Shift History',
                },
                {
                    text: 'View Chat',
                },
                {
                    text: 'Report Shift Issue',
                },
                {
                    text: 'Force Clock Out',
                },
                {
                    text: 'Delete',
                },
            ],
            organizationConfirmedShiftsTableActions: [
                {
                    text: 'View Request Details',
                },
                {
                    text: 'View Shift Applicants',
                },
                {
                    text: 'Duplicate Shift Request',
                },
                {
                    text: 'Duplicate Shift Request for Multiple Days',
                },
                {
                    text: 'View Shift History',
                },
                {
                    text: 'View Chat',
                },
                {
                    text: 'Delete',
                },
            ],
            organizationPendingShiftsTablePageSettings: { pageSize: 300, pageSizes: [500, 300, 100, 50], pageCount: 4, direction: 'Descending' },
            organizationConfirmedShiftsTablePageSettings: { pageSize: 300, pageSizes: [500, 300, 100, 50], pageCount: 4, direction: 'Descending' },
            organizationHistoryShiftsTablePageSettings: { pageSize: 300, pageSizes: [500, 300, 100, 50], pageCount: 4, direction: 'Descending' },
            selectedRow: null,
            hourlyRateMarkup: 0,
        };
    },
    computed: {
        organizationTimeZone() {
            return this.$store.state.AuthorizationModule.SignedInUser.LocalTimeZone;
        },
        signedInUser() {
            return this.$store.state.AuthorizationModule.SignedInUser;
        },
        organizationPendingShiftsTableItems() {
            let pendingShifts = this.$store.state.ShiftModule.PendingShifts;

            if (pendingShifts) {
                pendingShifts.forEach((shift) => {
                    shift.MedicalLicenseNames = '';
                    let licenses = shift.MedicalLicenses;
                    if (licenses) {
                        let licenseCounter = 0;
                        licenses.forEach((license) => {
                            shift.MedicalLicenseNames += license.LicenseName;
                            licenseCounter++;
                            if (licenses.length > licenseCounter) {
                                shift.MedicalLicenseNames += ', ';
                            }
                        });
                    }

                    if (shift.ConfirmedHourlyRate != -1) {
                        shift.ConfirmedHourlyRate = shift.ConfirmedHourlyRate * (1 + shift.HourlyRateMarkUpPercentage);
                    }
                });
            }

            return {
                result: pendingShifts,
                count: this.$store.state.ShiftModule.PendingShiftsNumberOfRows,
            };
        },
        organizationConfirmedShiftsTableItems() {
            let confirmedShifts = this.$store.state.ShiftModule.ConfirmedShifts;
            let moment = require('moment-timezone');

            if (confirmedShifts) {
                confirmedShifts.forEach((shift) => {
                    shift.MedicalLicenseNames = [];
                    let licenses = shift.MedicalLicenses;
                    if (licenses) {
                        let licenseCounter = 0;
                        licenses.forEach((license) => {
                            shift.MedicalLicenseNames += license.LicenseName;
                            licenseCounter++;
                            if (licenses.length > licenseCounter) {
                                shift.MedicalLicenseNames += ', ';
                            }
                        });
                    }

                    if (shift.ConfirmedHourlyRate != -1) {
                        shift.ConfirmedHourlyRate = shift.ConfirmedHourlyRate * (1 + shift.HourlyRateMarkUpPercentage);
                    }
                });
            }

            return {
                result: confirmedShifts,
                count: this.$store.state.ShiftModule.ConfirmedShiftsNumberOfRows,
            };
        },
        organizationHistoryShiftsTableItems() {
            let historyShifts = this.$store.state.ShiftModule.HistoryShifts;
            let moment = require('moment-timezone');

            if (historyShifts) {
                historyShifts.forEach((shift) => {
                    shift.MedicalLicenseNames = [];
                    let licenses = shift.MedicalLicenses;
                    if (licenses) {
                        let licenseCounter = 0;
                        licenses.forEach((license) => {
                            shift.MedicalLicenseNames += license.LicenseName;
                            licenseCounter++;
                            if (licenses.length > licenseCounter) {
                                shift.MedicalLicenseNames += ', ';
                            }
                        });
                    }

                    if (shift.ConfirmedHourlyRate === -1) {
                        shift.ConfirmedHourlyRate = 'N/A';
                    } else {
                        shift.ConfirmedHourlyRate = shift.ConfirmedHourlyRate * (1 + shift.HourlyRateMarkUpPercentage);
                    }
                });
            }

            return {
                result: historyShifts,
                count: this.$store.state.ShiftModule.HistoryShiftsNumberOfRows,
            };
        },
    },
    methods: {
        getLocationNamesForOrganization() {
            this.locationNameList = [];
           
            this.$store.dispatch('OrganizationLocationModule/getAllOrganizationLocationNamesForOrganization', this.signedInUser.OrganizationID)
                .then((response) => {
                    this.locationNameList = response;
                    if (!this.dto.OrganizationLocationID && response.length > 0) {
                        this.dto.OrganizationLocationID = response[0].OrganizationLocationID;
                    }
                });
        },
        getOrganizationLocationTerminology(lowerCase, plural) {
            return app.organizationLocationTerminology(this.signedInUser.OrganizationCategory, lowerCase, plural)
        },
        searchShifts() {
            let moment = require('moment-timezone');
            let UtcNow = moment.utc();

            let startDay = moment.utc(this.searchStartDate).startOf('day');
            let endDay = moment.utc(this.searchEndDate).endOf('day');
            let timeZoneOffset = moment.tz.zone(this.organizationTimeZone).utcOffset(UtcNow);

            this.paramsDto.StartDateRange = startDay.add(timeZoneOffset).format();
            this.paramsDto.EndDateRange = endDay.add(timeZoneOffset).format();

            try {
                let selectingID = this.$refs.organizationShiftTabs.ej2Instances.selectingID;
                if (selectingID === '0') {
                    this.paramsDto.PageToLoad = 1;
                    this.$refs.organizationPendingShiftsTable.$refs.grdTableList.ej2Instances.pagerModule.pagerObj.goToPage(1);
                    this.paramsDto.RowsPerPage = this.organizationPendingShiftsTablePageSettings.pageSize;
                    this.paramsDto.SortByColumn = this.organizationPendingShiftsTablePageSettings.columnName;
                    this.paramsDto.SortDescending = this.organizationPendingShiftsTablePageSettings.direction === 'Descending';
                    this.$store.dispatch('ShiftModule/getPendingShifts', this.paramsDto);
                    return;
                }

                if (selectingID === '1') {
                    this.paramsDto.PageToLoad = 1;
                    this.$refs.organizationConfirmedShiftsTable.$refs.grdTableList.ej2Instances.pagerModule.pagerObj.goToPage(1);
                    this.paramsDto.RowsPerPage = this.organizationConfirmedShiftsTablePageSettings.pageSize;
                    this.paramsDto.SortByColumn = this.organizationConfirmedShiftsTablePageSettings.columnName;
                    this.paramsDto.SortDescending =
                        this.organizationConfirmedShiftsTablePageSettings.direction === 'Descending';
                    this.$store.dispatch('ShiftModule/getConfirmedShifts', this.paramsDto);
                    return;
                }

                if (selectingID === '2') {
                    this.paramsDto.PageToLoad = 1;
                    this.$refs.organizationHistoryShiftsTable.$refs.grdTableList.ej2Instances.pagerModule.pagerObj.goToPage(1);
                    this.paramsDto.RowsPerPage = this.organizationHistoryShiftsTablePageSettings.pageSize;
                    this.paramsDto.SortByColumn = this.organizationHistoryShiftsTablePageSettings.columnName;
                    this.paramsDto.SortDescending =
                        this.organizationHistoryShiftsTablePageSettings.direction === 'Descending';
                    this.$store.dispatch('ShiftModule/getOrganizationHistoryShifts', this.paramsDto);
                    return;
                }
            } catch (error) {
                //Throwing the error away to avoid undefined selectingID
            }

            this.$store.dispatch('ShiftModule/getPendingShifts', this.paramsDto);
        },
        getCurrentTabList() {
            let moment = require('moment-timezone');
            let UtcNow = moment.utc();

            let startDay = moment.utc(this.searchStartDate).startOf('day');
            let endDay = moment.utc(this.searchEndDate).endOf('day');
            let timeZoneOffset = moment.tz.zone(this.organizationTimeZone).utcOffset(UtcNow);

            this.paramsDto.StartDateRange = startDay.add(timeZoneOffset).format();
            this.paramsDto.EndDateRange = endDay.add(timeZoneOffset).format();

            try {
                let selectingID = this.$refs.organizationShiftTabs.ej2Instances.selectingID;
                if (selectingID === '0') {
                    this.paramsDto.PageToLoad =
                        this.$refs.organizationPendingShiftsTable.$refs.grdTableList.ej2Instances.pagerModule.pagerObj.properties.currentPage;
                    this.paramsDto.RowsPerPage = this.organizationPendingShiftsTablePageSettings.pageSize;
                    this.paramsDto.SortByColumn = this.organizationPendingShiftsTablePageSettings.columnName;
                    this.paramsDto.SortDescending = this.organizationPendingShiftsTablePageSettings.direction === 'Descending';
                    this.$store.dispatch('ShiftModule/getPendingShifts', this.paramsDto);
                    return;
                }

                if (selectingID === '1') {
                    this.paramsDto.PageToLoad =
                        this.$refs.organizationConfirmedShiftsTable.$refs.grdTableList.ej2Instances.pagerModule.pagerObj.properties.currentPage;
                    this.paramsDto.RowsPerPage = this.organizationConfirmedShiftsTablePageSettings.pageSize;
                    this.paramsDto.SortByColumn = this.organizationConfirmedShiftsTablePageSettings.columnName;
                    this.paramsDto.SortDescending =
                        this.organizationConfirmedShiftsTablePageSettings.direction === 'Descending';
                    this.$store.dispatch('ShiftModule/getConfirmedShifts', this.paramsDto);
                    return;
                }

                if (selectingID === '2') {
                    this.paramsDto.PageToLoad =
                        this.$refs.organizationHistoryShiftsTable.$refs.grdTableList.ej2Instances.pagerModule.pagerObj.properties.currentPage;
                    this.paramsDto.RowsPerPage = this.organizationHistoryShiftsTablePageSettings.pageSize;
                    this.paramsDto.SortByColumn = this.organizationHistoryShiftsTablePageSettings.columnName;
                    this.paramsDto.SortDescending =
                        this.organizationHistoryShiftsTablePageSettings.direction === 'Descending';
                    this.$store.dispatch('ShiftModule/getOrganizationHistoryShifts', this.paramsDto);
                    return;
                }
            } catch (error) {
                //Throwing the error away to avoid undefined selectingID
            }

            this.$store.dispatch('ShiftModule/getPendingShifts', this.paramsDto);
        },
        organizationShiftsSharedTableRowOnClick(args) {
            this.selectedRow = args;
        },
        createShiftRequest() {
            let modalData = {
                mode: 'add',
                dto: [],
                organizationTimeZone: this.organizationTimeZone,
            };

            DMUtils.openApplicationModal('shiftRequestsCreateModal', modalData);
        },
        openShiftCalendar() {
            DMUtils.openApplicationModal('shiftRequestsCalendarModal');
        },
        viewShiftRequest() {
            this.$store
                .dispatch('ShiftModule/getShiftByIDOrganization', this.selectedRow.rowData.ShiftID)
                .then((response) => {
                    let modalData = {
                        dto: response,
                        accountType: 'Organization',
                    };
                    DMUtils.openApplicationModal('shiftRequestsViewModal', modalData);
                });
        },
        viewShiftApplicants(shiftID) {
            if (!shiftID){
                shiftID = this.selectedRow.rowData.ShiftID;
            }
            
            this.$store
                .dispatch('ShiftModule/getShiftByIDOrganization', shiftID)
                .then((response) => {
                    let modalData = {
                        mode: 'select',
                        dto: response,
                    };
                    DMUtils.openApplicationModal('shiftApplicantsModal', modalData);
                });
        },
        viewShiftClocks() {
            this.$store
                .dispatch('ShiftModule/getShiftByIDOrganization', this.selectedRow.rowData.ShiftID)
                .then((response) => {
                    let modalData = {
                        dto: response,
                        accountType: 'Organization',
                    };
                    DMUtils.openApplicationModal('shiftRequestsClocksModal', modalData);
                });
        },
        duplicateShiftRequest() {
            this.$store
                .dispatch('ShiftModule/getShiftByIDOrganization', this.selectedRow.rowData.ShiftID)
                .then((response) => {
                    let modalData = {
                        mode: 'duplicate',
                        dto: response,
                    };
                    DMUtils.openApplicationModal('shiftRequestsCreateModal', modalData);
                });
        },
        duplicateShiftRequestForMultipleDays() {
            let modalData = {
                shiftID: this.selectedRow.rowData.ShiftID,
                callback: this.searchShifts
            };
            DMUtils.openApplicationModal('shiftRequestsMultipleDaysModal', modalData);
        },
        viewShiftHistory() {
            this.$store
                .dispatch('ShiftModule/getShiftByIDOrganization', this.selectedRow.rowData.ShiftID)
                .then((response) => {
                    let modalData = {
                        dto: response,
                    };
                    DMUtils.openApplicationModal('shiftRequestsShiftHistoryModal', modalData);
                });
        },
        openCreateShiftSupportModal() {
            let modalData = {
                mode: 'ShiftSupport',
                shiftID: this.selectedRow.rowData.ShiftID,
            };

            DMUtils.openApplicationModal('createSupportRequestsModal', modalData);
        },
        viewChat() {
            let openModal = () => {
                let modalData = {
                    selectedShiftID: this.selectedRow.rowData.ShiftID,
                    selectedCaregiverID: this.selectedRow.rowData.ConfirmedCaregiverID,
                    selectedCaregiverName: this.selectedRow.rowData.CaregiverName,
                };

                DMUtils.openApplicationModal('messagesShiftThreadModal', modalData);
            };

            endpoints
                .getAllMessagesByShiftIDOrganization({
                    caregiverID: this.selectedRow.rowData.ConfirmedCaregiverID,
                    shiftID: this.selectedRow.rowData.ShiftID,
                })
                .then((response) => {
                    openModal();
                });
        },
        addEditCaregiverRating() {
            let modalData = {
                mode: 'add',
                ratingDto: {},
                shiftDto: {},
            };

            this.$store
                .dispatch('AdminCaregiverModule/getRatingByShiftID', this.selectedRow.rowData.ShiftID)
                .then((response) => {
                    modalData.ratingDto = response;

                    if (response.CaregiverID != 0) {
                        modalData.mode = 'edit';
                    }

                    this.$store
                        .dispatch('ShiftModule/getShiftByIDOrganization', this.selectedRow.rowData.ShiftID)
                        .then((response) => {
                            modalData.shiftDto = response;

                            DMUtils.openApplicationModal('shiftRequestsCaregiverRatingModal', modalData);
                        });
                });
        },
        deleteShiftRequest() {
            this.$store
                .dispatch('ShiftModule/getShiftByIDOrganization', this.selectedRow.rowData.ShiftID)
                .then((response) => {
                    let modalData = {
                        dto: response,
                        accountType: 'Organization',
                    };

                    modalData.dto.DeletedType = null;

                    DMUtils.openApplicationModal('shiftRequestsDeleteModal', modalData);
                });
        },
        closeShiftRequest() {
            this.$store
                .dispatch('ShiftModule/getShiftByIDOrganization', this.selectedRow.rowData.ShiftID)
                .then((response) => {
                    let modalData = {
                        mode: 'add',
                        shiftDto: response,
                        ratingDto: {},
                    };

                    DMUtils.openApplicationModal('shiftRequestsCaregiverRatingModal', modalData);
                });
        },
        forceClockOut() {
            this.$store
                .dispatch('ShiftModule/getShiftByIDOrganization', this.selectedRow.rowData.ShiftID)
                .then((response) => {
                    let modalData = {
                        mode: 'forceClockOut',
                        shiftDto: response,
                    };

                    DMUtils.openApplicationModal('shiftRequestsCaregiverRatingModal', modalData);
                });
        },
        blockCaregiver(args) {
            let dto = {
                CaregiverID: this.selectedRow.rowData.ConfirmedCaregiverID,
                RemoveAllFutureConfirmed: args
            };

            this.$store.dispatch('BlockedCaregiverModule/blockCaregiverOrganization', dto);
        },
        favoriteCaregiver() {
            let dto = {
                CaregiverID: this.selectedRow.rowData.ConfirmedCaregiverID,
            };

            this.$store.dispatch('FavoriteCaregiverModule/favoriteCaregiverOrganization', dto);
        },
        organizationPendingShiftsTableActionOnClick(args) {
            if (args.item.text === 'View Request Details') {
                this.viewShiftRequest();
            } else if (args.item.text === 'View Shift Applicants') {
                this.viewShiftApplicants();
            } else if (args.item.text === 'Duplicate Shift Request') {
                this.duplicateShiftRequest();
            } else if (args.item.text === 'Duplicate Shift Request for Multiple Days') {
                this.duplicateShiftRequestForMultipleDays();
            } else if (args.item.text === 'View Shift History') {
                this.viewShiftHistory();
            } else if (args.item.text === 'Delete') {
                this.deleteShiftRequest();
            }
        },
        organizationPendingShiftsTableDataStateChange(state) {
            if (state.action.requestType === 'sorting' && state.action.direction && state.action.columnName) {
                this.organizationPendingShiftsTablePageSettings.direction = state.action.direction;
                this.organizationPendingShiftsTablePageSettings.columnName = state.action.columnName;
                this.getCurrentTabList();
            }

            if (state.action.requestType === 'paging') {
                this.organizationPendingShiftsTablePageSettings.pageSize = state.take;
                this.getCurrentTabList();
            }
        },
        organizationHistoryShiftsTableActionOnClick(args) {
            if (args.item.text === 'View Request Details') {
                this.viewShiftRequest();
            } else if (args.item.text === 'View Shift Applicants') {
                this.viewShiftApplicants();
            } else if (args.item.text === 'Duplicate Shift Request') {
                this.duplicateShiftRequest();
            } else if (args.item.text === 'Duplicate Shift Request for Multiple Days') {
                this.duplicateShiftRequestForMultipleDays();
            } else if (args.item.text === 'Add/Edit Caregiver Rating') {
                this.addEditCaregiverRating();
            } else if (args.item.text === 'View Shift Clocks') {
                this.viewShiftClocks();
            } else if (args.item.text === 'View Shift History') {
                this.viewShiftHistory();
            } else if (args.item.text === 'View Chat') {
                this.viewChat();
            } else if (args.item.text === 'Favorite Caregiver') {
                const message = 'Are you sure you want to Favorite this Caregiver?';
                const title = 'Confirm';
                const confirmCallback = this.favoriteCaregiver;

                DMUtils.openApplicationModal('dmConfirmModal', {
                    title: title,
                    message: message,
                    confirmCallback: confirmCallback,
                    confirmText: 'Yes',
                    denyText: 'Cancel',
                });
            } else if (args.item.text === 'Block Caregiver') {
                endpoints.getFutureShiftsCaregiverConfirmed(this.selectedRow.rowData.ConfirmedCaregiverID)
                .then((response) => {
                    const message = 'Are you sure you want to Block this Caregiver?';
                    const futureShiftsMessage = `All future confirmed shifts:\n${response}`;
                    const title = 'Confirm';

                DMUtils.openApplicationModal('shiftApplicantBlockConfirmModal', {
                    title: title,
                    message: message,
                    futureShiftsMessage: futureShiftsMessage,
                    confirmCallback: this.blockCaregiver,
                    confirmText: 'Yes',
                    denyText: 'Cancel',
                });
                });
            } else if (args.item.text === 'Report Shift Issue') {
                this.openCreateShiftSupportModal();
            }
        },
        organizationHistoryShiftsTableDataStateChange(state) {
            if (state.action.requestType === 'sorting' && state.action.direction && state.action.columnName) {
                this.organizationHistoryShiftsTablePageSettings.direction = state.action.direction;
                this.organizationHistoryShiftsTablePageSettings.columnName = state.action.columnName;
                this.getCurrentTabList();
            }

            if (state.action.requestType === 'paging') {
                this.organizationHistoryShiftsTablePageSettings.pageSize = state.take;
                this.getCurrentTabList();
            }
        },
        organizationConfirmedShiftsTableActionOnClick(args) {
            if (args.item.text === 'View Request Details') {
                this.viewShiftRequest();
            } else if (args.item.text === 'View Shift Applicants') {
                this.viewShiftApplicants();
            } else if (args.item.text === 'Duplicate Shift Request') {
                this.duplicateShiftRequest();
            } else if (args.item.text === 'Duplicate Shift Request for Multiple Days') {
                this.duplicateShiftRequestForMultipleDays();
            } else if (args.item.text === 'View Shift Clocks') {
                this.viewShiftClocks();
            } else if (args.item.text === 'View Shift History') {
                this.viewShiftHistory();
            } else if (args.item.text === 'View Chat') {
                this.viewChat();
            } else if (args.item.text === 'Close') {
                this.closeShiftRequest();
            } else if (args.item.text === 'Force Clock Out') {
                this.forceClockOut();
            } else if (args.item.text === 'Report Shift Issue') {
                this.openCreateShiftSupportModal();
            } else if (args.item.text === 'Delete') {
                this.deleteShiftRequest();
            }
        },
        organizationConfirmedShiftsTableDataStateChange(state) {
            if (state.action.requestType === 'sorting' && state.action.direction && state.action.columnName) {
                this.organizationConfirmedShiftsTablePageSettings.direction = state.action.direction;
                this.organizationConfirmedShiftsTablePageSettings.columnName = state.action.columnName;
                this.getCurrentTabList();
            }

            if (state.action.requestType === 'paging') {
                this.organizationConfirmedShiftsTablePageSettings.pageSize = state.take;
                this.getCurrentTabList();
            }
        },
    },
    mounted() {
        this.$refs.organizationShiftTabs.ej2Instances.animation.next.effect = 'None';
        this.$refs.organizationShiftTabs.ej2Instances.animation.previous.effect = 'None';

        this.getCurrentTabList();
    },
    created() {
        let self = this;
        this.getLocationNamesForOrganization();

        this.$store.dispatch('ShiftModule/getHourlyRateMarkup').then((response) => {
            this.hourlyRateMarkup = response.HourlyRateMarkupPercentage;
        });

        this.organizationPendingShiftsTableColumns = [
            {
                type: 'iconTemplate',
                iconName: 'ellipsis',
                ellipsisActions: this.organizationPendingShiftsTableActions,
                iconOnClick: this.organizationPendingShiftsTableActionOnClick,
                width: '55px',
            },
            {
                key: 'ShiftID',
                label: 'ID',
                width: '110px',
                formatter: function (column, data) {
                    if (data.IsDuplicate) {
                        return (
                            ' <i v-if="data.IsDuplicate" class="fa fa-exclamation-circle brand-color-red"></i> ' +
                            data.ShiftID
                        );
                    }
                    return data.ShiftID;
                },
            },
            {
                key: 'LocationName',
                label: 'Location',
                width: '200px',
            },
            {
                key: 'ShiftStartUtc',
                label: 'Shift Start Time',
                width: '220px',
                formatter(column, data) {
                    return moment.tz(data.ShiftStartUtc, data.LocalTimeZone).format('MM/DD/YYYY hh:mmA z');
                }
            },
            {
                key: 'NumberOfApplicants',
                type: 'template',
                label: 'Applicants',
                textAlign: 'Center',
                width: '130px',
                template: function () {
                    return {
                        template: Vue.component('columnTemplate', {
                            template: `<ejs-button 
                                            @click.native="viewShiftApplicants"
                                            class='app-shifts-table-button'>
                                                  {{data.NumberOfApplicants}}
                                            </ejs-button>`,
                            methods: {
                                viewShiftApplicants: function () {
                                    self.viewShiftApplicants(this.data.ShiftID);
                                },
                            },
                        }),
                    };
                },
            },
            {
                key: 'ShiftStatus',
                type: 'template',
                label: 'Status',
                textAlign: 'Center',
                width: '120px',
                template: function () {
                    return {
                        template: Vue.component('columnTemplate', {
                            template: `<div
                                                :class="setColor">
                                                {{data.ShiftStatus}}
                                            </div>`,
                            computed: {
                                setColor: function () {
                                    if (this.data.ShiftStatus === 'Pending') {
                                        return 'app-grid-status-orange';
                                    }

                                    if (this.data.ShiftStatus === 'Assigned') {
                                        return 'app-grid-status-blue';
                                    }
                                },
                            },
                        }),
                    };
                },
            },
            {
                key: 'CaregiverName',
                label: 'Assigned Caregiver',
                width: '200px',
            },
            {
                key: 'MedicalLicenseNames',
                label: 'Licenses Requested',
                minWidth: '500px',
                allowSorting: false
            },
        ];
        this.organizationConfirmedShiftsTableColumns = [
            {
                type: 'template',
                width: '55px',
                template: function () {
                    return {
                        template: Vue.component('columnTemplate', {
                            template: `<ejs-dropdownbutton
                                                :items="grdRowActions"
                                                :select="select"
                                                iconCss="e-icons e-ellipsis-vert"
                                                cssClass="e-caret-hide">
                                           </ejs-dropdownbutton>`,
                            methods: {
                                select(args) {
                                    self.organizationConfirmedShiftsTableActionOnClick(args);
                                },
                            },
                            computed: {
                                grdRowActions: function () {
                                    if (this.data.ShiftStatus === 'On Duty') {
                                        return self.organizationCurrentOnDutyShiftsTableActions;
                                    } else if (this.data.ShiftStatus === 'Confirmed'){
                                        return self.organizationConfirmedShiftsTableActions;
                                    } else {
                                        return self.organizationCurrentShiftsTableActions;
                                    }
                                },
                            },
                        }),
                    };
                },
            },
            {
                key: 'ShiftID',
                label: 'ID',
                width: '75px',
            },
            {
                key: 'LocationName',
                label: 'Location',
                width: '200px',
            },
            {
                key: 'ShiftStartUtc',
                label: 'Shift Start Time',
                width: '220px',
                formatter(column, data) {
                    return moment.tz(data.ShiftStartUtc, data.LocalTimeZone).format('MM/DD/YYYY hh:mmA z');
                }
            },
            {
                key: 'ShiftEndUtc',
                label: 'Shift End Time',
                width: '220px',
                formatter(column, data) {
                    return moment.tz(data.ShiftEndUtc, data.LocalTimeZone).format('MM/DD/YYYY hh:mmA z');
                }
            },
            {
                type: 'template',
                label: 'Status',
                textAlign: 'Center',
                width: '120px',
                template: function () {
                    return {
                        template: Vue.component('columnTemplate', {
                            template: `<div
                                                :class="setColor">
                                                {{data.ShiftStatus}}
                                            </div>`,
                            computed: {
                                setColor: function () {
                                    if (this.data.ShiftStatus === 'Confirmed') {
                                        return 'app-grid-status-green';
                                    }

                                    if (this.data.ShiftStatus === 'Late') {
                                        return 'app-grid-status-red';
                                    }

                                    if (this.data.ShiftStatus === 'On Duty') {
                                        return 'app-grid-status-green';
                                    }

                                    if (this.data.ShiftStatus === 'Clocked Out') {
                                        return 'app-grid-status-blue';
                                    }
                                },
                            },
                        }),
                    };
                },
            },
            {
                key: 'CaregiverName',
                label: 'Caregiver Name',
                width: '200px',
            },
            {
                key: 'ConfirmedHourlyRate',
                label: 'Org. Hourly Rate',
                format: 'C2',
                textAlign: 'Right',
                width: '190px',
            },
            {
                key: 'MedicalLicenseNames',
                label: 'Licenses Requested',
                width: '400px',
                allowSorting: false
            },
            {
                key: 'ShiftInstructions',
                label: 'Instructions',
                width: '250px',
            },
        ];      
        this.organizationHistoryShiftsTableColumns = [
            {
                type: 'iconTemplate',
                iconName: 'ellipsis',
                ellipsisActions: this.organizationHistoryShiftsTableActions,
                iconOnClick: this.organizationHistoryShiftsTableActionOnClick,
                width: '55px',
            },
            {
                key: 'ShiftID',
                label: 'ID',
                width: '75px',
            },
            {
                key: 'LocationName',
                label: 'Location',
                width: '200px',
            },
            {
                key: 'ShiftStartUtc',
                label: 'Shift Start Time',
                width: '220px',
                formatter(column, data) {
                    return moment.tz(data.ShiftStartUtc, data.LocalTimeZone).format('MM/DD/YYYY hh:mmA z');
                }
            },
            {
                key: 'ShiftEndUtc',
                label: 'Shift End Time',
                width: '220px',
                formatter(column, data) {
                    return moment.tz(data.ShiftEndUtc, data.LocalTimeZone).format('MM/DD/YYYY hh:mmA z');
                }
            },
            {
                type: 'template',
                label: 'Status',
                textAlign: 'Center',
                width: '120px',
                template: function () {
                    return {
                        template: Vue.component('columnTemplate', {
                            template: `<div
                                                :class="setColor">
                                                {{data.ShiftStatus}}
                                            </div>`,
                            computed: {
                                setColor: function () {
                                    if (this.data.ShiftStatus === 'Resigned') {
                                        return 'app-grid-status-red';
                                    }

                                    if (this.data.ShiftStatus === 'Completed') {
                                        return 'app-grid-status-green';
                                    }

                                    if (this.data.ShiftStatus === 'Closed') {
                                        return 'app-grid-status-blue';
                                    }
                                    if (this.data.ShiftStatus === 'Deleted') {
                                        return 'app-grid-status-blue';
                                    }
                                },
                            },
                        }),
                    };
                },
            },
            {
                key: 'CaregiverName',
                label: 'Caregiver Name',
                width: '200px',
            },
            {
                key: 'CaregiverHoursWorked',
                label: 'Hours Worked',
                textAlign: 'Right',
                width: '130px',
                formatter(column, data) {
                    if (data.CaregiverHoursWorked === -1 || data.CaregiverHoursWorked === 'N/A') {
                        return 'N/A';
                    }
                    return parseFloat(data.CaregiverHoursWorked).toFixed(2);
                },
            },
            {
                key: 'ConfirmedHourlyRate',
                label: 'Org. Hourly Rate',
                textAlign: 'Right',
                width: '170px',
                formatter(column, data) {
                    if (data.ConfirmedHourlyRate === -1 || data.ConfirmedHourlyRate === 'N/A') {
                        return 'N/A';
                    }
                    return '$' + parseFloat(data.ConfirmedHourlyRate).toFixed(2);
                },
            },
            {
                key: 'MedicalLicenseNames',
                label: 'Licenses Requested',
                width: '400px',
                allowSorting: false
            },
        ];
    },
};
</script>
