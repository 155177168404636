<template src="./shiftRequestsViewProfileModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

export default {
    name: 'applicant-view-profile-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            shiftApplicantDto: {},
            caregiverDto: {},
            caregiverMedicalLicenses: [],
            caregiverOtherProofs: [],
            caregiverTBReport : {},
            caregiverOtherProofsColumn1: [],
            caregiverOtherProofsColumn2: [],
            caregiverRatingsTableColumns: [],
            caregiverRatingsTableSortBy: {
                columns: [
                    { field: 'CaregiverRatingID', direction: 'Descending' },
                ]
            },
        }
    },
    computed: {
        lblTitle() {
            return "View Profile: " + this.shiftApplicantDto.ApplicantFirstLastName;
        },
        caregiverRatingsTableItems() {            
            return this.$store.state.AdminCaregiverModule.AllRatingsForCaregiver;
        },
        myShyftAdminTimeZone(){
            return this.$store.state.AdminModule.MyShyftAdminTimeZone;
        },
        getCaregiverConfirmedHourlyRate(){
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                });

            return formatter.format(this.shiftApplicantDto.ShiftHourlyRate);
        },
        getOrganizationConfirmedHourlyRate(){          
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                });

            return formatter.format(this.shiftApplicantDto.OrganizationShiftHourlyRate);
        },
        getCaregiverAddress() {
            let address = "";

            address += this.caregiverDto.AddressLine1 ? this.caregiverDto.AddressLine1 + ", " : "";
            address += this.caregiverDto.AddressLine2 ? this.caregiverDto.AddressLine2 + ", " : "";
            address += this.caregiverDto.City ? this.caregiverDto.City + ", " : "";
            address += this.caregiverDto.State ? this.caregiverDto.State + " ": "";
            address += this.caregiverDto.Zipcode ? this.caregiverDto.Zipcode : "";

            return address;
        },
    },
    methods: {
        splitCaregiverProofs(caregiverProofs) {
            caregiverProofs.forEach((proof) => {
                let moment = require('moment-timezone');
                proof.ReportDate = moment.tz(proof.ReportDate, this.myShyftAdminTimeZone).format("M/D/YYYY");

                if (proof.ProofName === "TB"){
                    this.caregiverTBReport = proof;
                } else {
                    this.caregiverOtherProofs.push(proof);
                }
            });
            
            let spliceIndex = Math.ceil(this.caregiverOtherProofs.length / 2);

            this.caregiverOtherProofsColumn1 = this.caregiverOtherProofs.slice(0, spliceIndex);
            this.caregiverOtherProofsColumn2 = this.caregiverOtherProofs.slice(spliceIndex);
        },
        btnCloseOnClick() {
            this.closeModal();
        },
    },
    mounted() {
        this.$refs.shiftApplicantProfileTabs.ej2Instances.animation.next.effect = "None";
        this.$refs.shiftApplicantProfileTabs.ej2Instances.animation.previous.effect = "None";
    },
    created() {
        this.shiftApplicantDto = DMUtils.copy(this.modalData.shiftApplicantDto);
        this.caregiverDto = DMUtils.copy(this.modalData.caregiverDto);
        this.$store.dispatch('AdminCaregiverModule/getAllRatingsForCaregiver', this.caregiverDto.CaregiverID);
        this.$store.dispatch('ProofOfOwnershipModule/getAllCaregiverProofOfOwnershipsOrganization', this.caregiverDto.CaregiverID)
            .then((response) => {
                this.splitCaregiverProofs(response);
            });
        this.$store.dispatch('MedicalLicenseModule/getAllCaregiverMedicalLicensesForCaregiverOrganization', this.caregiverDto.CaregiverID)
            .then((response) => {
                this.caregiverMedicalLicenses = response;
            });

        this.caregiverRatingsTableColumns = [
            {
                key: 'OrganizationName',
                label: 'Organization Name',
                width: '200px'
            },
            {
                key: 'RatingComment',
                label: 'Comment',
                allowSorting: false,
                width: '300px'
            },
            {
                key: 'Rating',
                label: 'Rating',
                width: '75px'
            },
        ];
    },
}
</script>
