import endpoints from "../../js/endpoints";

const myShyftAdminModule = {
    namespaced: true,
    state: () => ({
        AllUsers: [],
        MyShyftAdminTimeZone: "America/Indiana/Indianapolis",
    }),
    mutations: {
        setUsers(state, users) {
            state.AllUsers = users;
        },
    },
    actions: {
        updateCaregiver({dispatch}, dto) {
            return endpoints.updateCaregiver(dto)
                .then(() => {
                    dispatch('getUsers');
                });
        },
        //FE Reference:
        getUsers({commit}) {
            return endpoints.getAllUsers()
                .then((response) => {
                    commit('setUsers', response);
                });
        },
        updateUser({dispatch}, dto) {
            return endpoints.updateUser(dto)
                .then(() => {
                    dispatch('getUsers');
                });
        },
        createUser({dispatch}, dto) {
            return endpoints.createUser(dto)
                .then(() => {
                    dispatch('getUsers');
                });
        },
        deleteUser({dispatch, state, commit}, userID) {
            return endpoints.getUserByID(userID)
                .then((response) => {
                    return endpoints.deleteUser(response)
                })
                .then(() => {
                    dispatch('getUsers');
                    return userID;
                });
        },
    },
};

export default myShyftAdminModule;
