<template src="./organizationEditModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'
import dmStates from '../../../../../dmFramework/dmJS/dmStates.js';
import imageUploadMixin from '../../../../js/imageUploadMixin';

export default {
    name: 'organization-edit-modal',
    mixins: [dmModalMixin, imageUploadMixin],
    data() {
        return {
            dto: {},
            allowFiltering: true,
            statusList: ['Active', 'Inactive'],
            stateList: dmStates,
            timezoneList: [],
            electronicMedicalRecordList: [],
            organizationCategoryList: [],
            discount: 0
        }
    },
    computed: {
        mode() {
            return this.modalData.mode;
        },
        lblTitle() {
            return this.modalData.mode === 'edit' ? 'Edit Organization: ' + this.modalData.dto.OrganizationName : 'Add Organization';
        },
        getFormattedUpdatedUtc(){                        
            return moment.tz(this.dto.UpdatedUtc, this.myShyftAdminTimeZone).format("M/D/YYYY");
        },
        getFormattedCreatedUtc(){
            return moment.tz(this.dto.CreatedUtc, this.myShyftAdminTimeZone).format("M/D/YYYY");
        },
        getFormattedOutstandingBalance(){
            let formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
            });        

            return formatter.format(this.dto.OutstandingBalance);
        },
        myShyftAdminTimeZone(){
            return this.$store.state.AdminModule.MyShyftAdminTimeZone;
        },
    },
    methods: {
        timeZoneFiltering: function(e) {
           let query = new Query();
            query = (e.text != "") ? query.where("Contains", e.text, true) : query;
            e.updateData(this.timezoneList, query);
        },
        electronicMedicalRecordFiltering: function(e) {
           let query = new Query();
            query = (e.text != "") ? query.where("Contains", e.text, true) : query;
            e.updateData(this.electronicMedicalRecordList, query);
        },
        organizationFiltering: function(e) {
           let query = new Query();
            query = (e.text != "") ? query.where("Contains", e.text, true) : query;
            e.updateData(this.organizationTypeList, query);
        },
        btnCopyPrimaryAddressOnClick() {
            this.dto.BillingAddress1 = this.dto.PrimaryAddress1;
            this.$refs.BillingAddress1.value = this.dto.PrimaryAddress1;

            this.dto.BillingAddress2 = this.dto.PrimaryAddress2;
            this.$refs.BillingAddress2.value = this.dto.PrimaryAddress2;

            this.dto.BillingCity = this.dto.PrimaryCity;
            this.$refs.BillingCity.value = this.dto.PrimaryCity;

            this.dto.BillingState = this.dto.PrimaryState;
            this.$refs.BillingState.value = this.dto.PrimaryState;

            this.dto.BillingZipcode = this.dto.PrimaryZipcode;
            this.$refs.BillingZipcode.value = this.dto.PrimaryZipcode;
        },
        getAllOrganizationCategories() {
            endpoints.getAllOrganizationCategories()
                .then(response => {
                    this.organizationCategoryList = response.Categories;
                });
        },
        getAllTimeZones() {       
            this.$store
                .dispatch('AuthorizationModule/getAllTimeZones')
                .then((response) => {
                    this.timezoneList = response;
                });
        },
        getAllElectronicMedicalRecords() {
            this.$store
                .dispatch('AdminOrganizationModule/getAllElectronicMedicalRecords')
                .then((response) => {
                    this.electronicMedicalRecordList = response;
                });
        },
        btnSaveOnClick() {
            if(!isNaN(this.discount)) {
                this.dto.DiscountPercent = this.discount / 100;
            } else {
                DMUtils.alertShow('Discount is not a valid number');
                return;
            }

            if (this.mode === 'edit') {
                this.$store
                    .dispatch('AdminOrganizationModule/updateOrganization', this.dto)
                    .then(() => {
                        this.shouldModalStayOpen = false;
                        this.successClose(this.dto);
                    });
            } else if (this.mode === 'add') {
                this.$store
                    .dispatch('AdminOrganizationModule/createOrganizationMyShyftAdmin', this.dto)
                    .then(() => {
                        this.shouldModalStayOpen = false;
                        this.successClose(this.dto);
                    });
            }
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.dto = DMUtils.copy(this.modalData.dto);
        this.discount = this.dto.DiscountPercent * 100;
        this.preBuiltDto = DMUtils.copy(this.modalData.dto);
        
        if (this.mode === 'add') {
            this.discount = 0;
        }

        this.getAllOrganizationCategories();
        this.getAllTimeZones();
        this.getAllElectronicMedicalRecords();
    },
}
</script>
