<template src="./caregiverPayoutsModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'caregiver-payouts-modal',
        mixins: [dmModalMixin],
        data() {
            return {
                searchString: '',
                dto: {},
                accountType: "",
                payoutsTableColumns: [],
                payoutsTableSortBy: {
                    columns: [
                        { field: 'CreatedUtc', direction: 'Descending' },
                    ]
                },
                payoutsTableCustomClass: 'dm-bg-color-black',
                payoutsTableActions: [
                    {
                        text: 'View Transactions'
                    }
                ],
                selectedRow: null
            }
        },
        computed: {
            lblTitle() {
                return 'View Recent Payouts: ' + this.dto.FirstLastName;
            },
            payoutsTableItems() {
                let payouts = this.$store.state.AdminCaregiverModule.RecentPayoutsForCaregiver;

                if (payouts){
                    payouts.forEach((payout) => {
                        payout.myShyftAdminTimeZone = this.myShyftAdminTimeZone;
                        if (payout.IsAutomaticPayout === true){
                            payout.PayoutType = 'Automatic';
                        } else {
                            payout.PayoutType = 'Manual';
                        }
                    });
                }

                return payouts;
            },
            myShyftAdminTimeZone(){
                return this.$store.state.AdminModule.MyShyftAdminTimeZone;
            },
        },
        methods: {
            payoutsTableRowOnClick(args) {
                this.selectedRow = args;
            },
            viewTransactions(){
                if (this.selectedRow.rowData.IsAutomaticPayout === false){
                    DMUtils.alertShow(
                        'You cannot view Transactions for a Manual Payout.'
                    );
                    return;
                };

                let modalData = {
                    dto: {
                        ThirdPartyPayoutID: this.selectedRow.rowData.ThirdPartyPayoutID,
                        FirstLastName: this.dto.FirstLastName,
                        UserID: this.dto.UserID
                    }
                };

                DMUtils.openApplicationModal('caregiverPayoutTransactionsModal', modalData);
            },          
            btnCloseOnClick(){
                this.closeModal();
            },
            payoutsTableActionOnClick(args) {
                if (args.item.text === 'View Transactions') {
                    this.viewTransactions();
                } 
            },           
        },
        created() {
            this.dto = DMUtils.copy(this.modalData.dto);
            this.$store.dispatch('AdminCaregiverModule/getRecentPayoutsForCaregiver', this.dto.UserID);
            
            this.payoutsTableColumns = [
                {
                    type: 'iconTemplate',
                    iconName: 'ellipsis',
                    ellipsisActions: this.payoutsTableActions,
                    iconOnClick: this.payoutsTableActionOnClick,
                    width: '55px',
                },
                {
                    key: 'PaymentAmount',
                    label: 'Payout Amount',
                    format: 'C2',
                    textAlign: 'Right',
                    width: '150px',
                },
                {
                    key: 'ThirdPartyPayoutID',
                    label: 'Payout ID',
                },
                {
                    key: 'Status',
                    label: 'Status',
                    width: '100px',
                },
                {
                    key: 'PayoutType',
                    label: 'Payout Type',
                    width: '150px',
                },
                {
                    key: 'CreatedUtc',
                    label: 'Created On',
                    type: "date",
                    width: '180px',
                    formatter(column, payout) {
                        return moment.tz(payout.CreatedUtc, payout.myShyftAdminTimeZone).format("MM/DD/YYYY hh:mmA"); 
                    } 
                },
            ];
        },
    }
</script>
