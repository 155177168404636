<template src="./organizationLocationsPage.html"></template>

<script>
    import Vue from 'vue';

    export default {
        name: 'organization-locations-page',
        data() {
            return {
                organizationLocationTableColumns: [],
                organizationLocationTableSortBy: {
                    columns: [
                        { field: 'CreatedUtc', direction: 'Descending' },
                    ]
                },
                organizationLocationTableCustomClass: 'dm-bg-color-black',
                organizationLocationTableActions: [
                    {
                        text: 'Edit'
                    }
                ],
                selectedRow: null
            }
        },
        computed: {
            organizationLocationTableItems() {       
                return this.$store.state.OrganizationLocationModule.AllOrganizationLocations                   
            },
            organizationLocationID() {
                return this.selectedRow.rowData.OrganizationLocationID;
            },   
            signedInUser() {
                return this.$store.state.AuthorizationModule.SignedInUser;
            },
        },
        methods: {
            organizationLocationTableRowOnClick(args){
                this.selectedRow = args;
            },   
            getOrganizationLocationTerminology(lowerCase, plural) {
                return app.organizationLocationTerminology(this.signedInUser.OrganizationCategory, lowerCase, plural)
            },
            addOrganizationLocation() {
                let modalData = {
                    mode: 'add',
                    dto: {
                        OrganizationID: this.signedInUser.OrganizationID,
                        OrganizationCategory: this.signedInUser.OrganizationCategory
                    },
                };

                DMUtils.openApplicationModal('organizationLocationEditModal', modalData);
            },
            editOrganizationLocation() {
                let dto = {
                    OrganizationLocationID: this.selectedRow.rowData.OrganizationLocationID,
                    OrganizationCategory: this.signedInUser.OrganizationCategory
                }
                
                let modalData = {
                    mode: 'edit',
                    dto: {},
                };

                this.$store
                    .dispatch('OrganizationLocationModule/getOrganizationLocationByID', dto)
                    .then((response) => {
                        modalData.dto = response;
                        modalData.dto.OrganizationCategory = this.signedInUser.OrganizationCategory
                        DMUtils.openApplicationModal('organizationLocationEditModal', modalData);
                    });
            },
            deleteOrganizationLocation() {
                let dto = {
                    OrganizationID: this.selectedRow.rowData.OrganizationID,
                    OrganizationLocationID: this.selectedRow.rowData.OrganizationLocationID,
                    RowVersion: this.selectedRow.rowData.RowVersion,
                }
                this.$store.dispatch('OrganizationLocationModule/deleteOrganizationLocation', dto);
            },
            organizationLocationTableActionOnClick(args) {
                if (args.item.text === 'Edit') {
                    this.editOrganizationLocation();
                }
            },
        },
        created() {
            this.$store.dispatch('OrganizationLocationModule/getAllOrganizationLocationsForOrganization', this.signedInUser);

            this.organizationLocationTableColumns = [
                {
                    type: 'iconTemplate',
                    iconName: 'ellipsis',
                    ellipsisActions: this.organizationLocationTableActions,
                    iconOnClick: this.organizationLocationTableActionOnClick,
                    width: '55px',
                },
                {
                    key: 'OrganizationLocationID',
                    label: 'ID',
                    width: '75px',
                },
                {
                    key: 'LocationNameF',
                    label: 'Name',
                },
                {
                    key: 'City',
                    label: 'City',
                },
                {
                    key: 'State',
                    label: 'State',
                    width: '100px'
                },
                {
                    key: 'CreatedUtc',
                    label: 'Created On',
                    width: '240px',
                    formatter(column, location) {
                        return moment.tz(location.CreatedUtc, location.LocalTimeZone).format("MM/DD/YYYY hh:mmA z"); 
                    }
                },        
            ];
        },
    }
</script>