<template src="./shiftRequestsCreateModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

export default {
    name: 'organization-request-create-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            dto: {},
            hourlyRateMarkup: 0,
            shiftStart: null,
            shiftEnd: null,
            clockIn: null,
            clockOut: null,
            medicalLicenses: [],            
            typesOfCare: [],  
            organizationNameList: [],
            organizationFields: { text: 'OrganizationName', value: 'OrganizationID' },
            caregiverNameList: [],
            caregiverFields: { text: 'FullName', value: 'CaregiverID' },
            locationNameList: [],
            locationFields: { text: 'LocationNameF', value: 'OrganizationLocationID' },
            filterType: 'Contains',
            allowFiltering: true,
            selectedMedicalLicenses:[],  
            selectedTypesOfCare: [],   
            offsetDifference: 0,      
            locationTimeZone: '',
            locationState: '' 
        }
    },
    computed: {
        lblTitle() {
            return 'Create a Shift Request';
        },
        organizationHourlyRate() {
            return '$' + (parseFloat(this.dto.ConfirmedHourlyRate) * (1 + this.hourlyRateMarkup)).toFixed(2);
        },
        getCaregiverAmount(){
            let timeWorked = (new Date(this.dto.ShiftClockOutUtc).getTime() - new Date(this.dto.ShiftClockInUtc).getTime()) / 1000;

            let timeWorkedMinutes = timeWorked / 60;
            let timeWorkedHours = timeWorkedMinutes / 60;

            if (this.dto.ShiftClockOutUtc === null || this.dto.ShiftClockInUtc === null) {
                return '$0.00'
            }

            return '$' + (timeWorkedHours * (parseFloat(this.dto.ConfirmedHourlyRate))).toFixed(2);
        },
        getOrganizationAmount(){
            let timeWorked = (new Date(this.dto.ShiftClockOutUtc).getTime() - new Date(this.dto.ShiftClockInUtc).getTime()) / 1000;

            let timeWorkedMinutes = timeWorked / 60;
            let timeWorkedHours = timeWorkedMinutes / 60;

            if (this.dto.ShiftClockOutUtc === null || this.dto.ShiftClockInUtc === null) {
                return '$0.00'
            }

            return '$' + (timeWorkedHours * (parseFloat(this.dto.ConfirmedHourlyRate * (1 + this.hourlyRateMarkup)))).toFixed(2);
        }
    },
    methods: {
        filtering: function(e) {
                let query = new Query();
                query = (e.text != "") ? query.where("Contains", e.text, true) : query;
        },
        getLocationNamesForOrganization(organizationID) {
            this.dto.OrganizationLocationID = 0;
            this.locationNameList = [];
            this.$store.dispatch('OrganizationLocationModule/getAllOrganizationLocationNamesForOrganization', organizationID)
                .then((response) => {
                    this.locationNameList = response;

                    if (!this.dto.OrganizationLocationID && response.length > 0) {
                        this.dto.OrganizationLocationID = response[0].OrganizationLocationID;
                    }
                });
        },
        getMedicalLicenses() {
            this.$store
                .dispatch('MedicalLicenseModule/getAllMedicalLicensesOrganizationLocation', this.dto.OrganizationLocationID)
                .then(() => {
                    this.medicalLicenses = this.$store.state.MedicalLicenseModule.AllMedicalLicenses;                
                });
        },
        selectedOrganizationChanged() {
            if (!this.dto.OrganizationID) {
                this.locationNameList = [];
            } else {
                this.getLocationNamesForOrganization(this.dto.OrganizationID);
            }
        },
        onOrganizationLocationChange(args) {
            this.locationTimeZone = args.itemData.LocalTimeZone;
            this.setTimezoneOffsetDifference();

            if (this.locationState !== args.itemData.LocationState) {
                this.locationState = args.itemData.LocationState;

                if (this.dto.OrganizationLocationID > 0){
                    this.selectedMedicalLicenses = [];
                    this.getMedicalLicenses();
                }
            }
        },
        setTimezoneOffsetDifference() {
            let moment = require('moment-timezone');
            let deviceOffset = moment().utcOffset();
            let organizationOffset = moment().tz(this.locationTimeZone).utcOffset();
            this.offsetDifference = deviceOffset - organizationOffset;
        },
        getSelectedLicenses(){
            let selectedLicenses = [];

            this.medicalLicenses.forEach(license => {
                if (this.selectedMedicalLicenses.includes(license.MedicalLicenseID)){
                    let licenseDto = {
                        LicenseName: license.LicenseName,
                        MedicalLicenseID: license.MedicalLicenseID,
                    }
                    selectedLicenses.push(licenseDto);
                }
            });

            this.dto.MedicalLicenses = selectedLicenses;
        },
        getSelectedTypesOfCare(){
            let selectedTypesOfCare = [];

            this.typesOfCare.forEach(care => {
                if (this.selectedTypesOfCare.includes(care.TypeOfCareID)){
                    let typeOfCareDto = {
                        TypeOfCareName: care.TypeOfCareName,
                        TypeOfCareID: care.TypeOfCareID,
                    }
                    selectedTypesOfCare.push(typeOfCareDto);
                }
            });

            this.dto.TypesOfCare = selectedTypesOfCare;
        },
        saveShiftCreation(){
            this.getSelectedLicenses();
            this.getSelectedTypesOfCare();  

            let moment = require('moment-timezone');
            this.dto.ShiftStartUtc = moment(this.shiftStart).add(this.offsetDifference, 'minutes').utc().format();
            this.dto.ShiftEndUtc = moment(this.shiftEnd).add(this.offsetDifference, 'minutes').utc().format();
            this.dto.ShiftClockInUtc = moment(this.clockIn).add(this.offsetDifference, 'minutes').utc().format();
            this.dto.ShiftClockOutUtc = moment(this.clockOut).add(this.offsetDifference, 'minutes').utc().format();

            this.$store
                .dispatch('ShiftModule/createShiftMyShyftAdmin', this.dto)
                .then(() => {
                    this.shouldModalStayOpen = false;
                    this.successClose(this.dto);
                });
        },
        btnSaveOnClick() {
            const message = 'Are you sure you want to create this shift? A Stripe transaction will automatically occur after shift creation.';
            const title = 'Confirm';
            const confirmCallback = this.saveShiftCreation;

            DMUtils.openApplicationModal('dmConfirmModal', {
                title: title,
                message: message,
                confirmCallback: confirmCallback,
                confirmText: 'Yes',
                denyText: 'Cancel',
            });
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {   
        this.dto = DMUtils.copy(this.modalData.dto);
        
        this.$store
            .dispatch('ShiftModule/getHourlyRateMarkup')
            .then((response) => {
                this.hourlyRateMarkup = response.HourlyRateMarkupPercentage;                
            });

        this.$store
            .dispatch('UserModule/getAllTypesOfCare')
            .then(() => {
                this.typesOfCare = this.$store.state.UserModule.AllTypesOfCare;                 
            });  

        this.$store.dispatch('AdminOrganizationModule/getAllOrganizationNames')
            .then((response) => {
                this.organizationNameList = response;
            });

        this.$store.dispatch('AdminCaregiverModule/getAllCaregiverNames')
            .then((response) => {
                this.caregiverNameList = response;
            });
    },
}
</script>
