<template src="./invoiceUpdatePaymentDetailsModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

export default {
    name: 'invoice-update-payment-details-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            dto: {},
            paymentMethodsList: []
        }
    },
    computed: {
        lblTitle() {
            return 'Update Payment for InvoiceID: ' + this.dto.InvoiceID;
        },
    },
    methods: {
        btnSaveOnClick() {
            this.$store
                .dispatch('InvoiceModule/updatePaymentDetails', this.dto)
                .then(() => {
                    this.shouldModalStayOpen = false;
                    this.successClose(this.dto);
                });
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.dto = DMUtils.copy(this.modalData.dto);
        
        this.$store.dispatch('InvoiceModule/getAllPaymentMethodCodes')
            .then((response) => {
                this.paymentMethodsList = response.PaymentMethods;
            });
    },
}
</script>
