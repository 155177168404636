<template src="./organizationEditModal.html"></template>

<script>
import dmModalMixin from '../../../../dmFramework/dmJS/dmModalMixin'
import dmStates from '../../../../dmFramework/dmJS/dmStates.js';

export default {
    name: 'organization-edit-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            dto: {},
            allowFiltering: true,
            filterType: 'Contains',
            statusList: ['Active', 'Inactive'],
            stateList: dmStates,
            electronicMedicalRecordList: [],
        }
    },
    computed: {
        mode() {
            return this.modalData.mode;
        },
        lblTitle() {
            return 'Edit Organization';
        }
    },
    methods: {
        timeZoneFiltering: function(e) {
           let query = new Query();
            query = (e.text != "") ? query.where("Contains", e.text, true) : query;
            e.updateData(this.timezoneList, query);
        },
        electronicMedicalRecordFiltering: function(e) {
           let query = new Query();
            query = (e.text != "") ? query.where("Contains", e.text, true) : query;
            e.updateData(this.electronicMedicalRecordList, query);
        },
        organizationFiltering: function(e) {
           let query = new Query();
            query = (e.text != "") ? query.where("Contains", e.text, true) : query;
            e.updateData(this.organizationTypeList, query);
        },
        fileSelected(e) {
            let reader = new FileReader();
            reader.readAsArrayBuffer(e.target.files[0]);
            reader.onload = () => {
                let blob = new Blob([reader.result]); 
                let blobURL = window.URL.createObjectURL(blob); 

                let image = new Image();
                image.src = blobURL;
                image.onload = () => {
                    let canvas = document.createElement('canvas');

                    let maxHeight = 400;
                    let maxWidth = 400;                  
                    let ratio = 0;

                    let width = image.width;
                    let height = image.height;

                    if (width > height) {
                        ratio = width / height;
                        if (width > maxWidth) {
                            height = Math.round(maxWidth / ratio);
                            width = maxWidth;
                        }
                    } else if (height > width) {
                        ratio = height / width;
                        if (height > maxHeight) {
                            width = Math.round(maxHeight / ratio);
                            height = maxHeight;
                        }
                    } else {
                        width = maxHeight;
                        height = maxHeight;
                    }
                    
                    canvas.width = width;
                    canvas.height = height;
                    let ctx = canvas.getContext("2d");
                    ctx.drawImage(image, 0, 0, width, height);                        
                    let resized = canvas.toDataURL("image/jpeg"); 

                    let base64Length = resized.length - 'data:image/jpeg;base64,'.length;
                    let sizeInBytes = base64Length * (3/4);
                    let sizeInMb = sizeInBytes / 1048576;

                    if (sizeInMb > 2.0){
                        DMUtils.alertShow(
                            'The selected file is too large.'
                        );
                        return;
                    }

                    this.dto.ProfileImageBase64 = resized;
                }
            };

            let originalFileName = e.target.files[0].name;
            let renamedFile = "";
            let fileExt = originalFileName.split('.').pop();
            if (originalFileName === fileExt){
                renamedFile = originalFileName.concat('.jpeg');
            } else {
                renamedFile = originalFileName.replace(fileExt,'jpeg');
            }

            this.dto.ProfileImageFileName = renamedFile;
        },
        btnSaveOnClick() {
            endpoints.updateOrganizationAsOrganization(this.dto)
                .then(() => {
                    this.shouldModalStayOpen = false;
                    this.successClose();
                });
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.dto = DMUtils.copy(this.modalData.dto);
        this.$store
            .dispatch('AdminOrganizationModule/getAllElectronicMedicalRecords')
            .then((response) => {
                this.electronicMedicalRecordList = response;
            });
        this.$store
            .dispatch('AuthorizationModule/getAllTimeZones')
            .then((response) => {
                this.timezoneList = response;
            });
    },
}
</script>
