<template src="./typesOfCareModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'types-of-care-modal',
        mixins: [dmModalMixin],
        data() {
            return {
                typesOfCareTableColumns: [],
                typesOfCareTableSortBy: {
                    columns: [
                        { field: 'TypeOfCareName', direction: 'Ascending' },
                    ]
                },
                typesOfCareTableCustomClass: 'dm-bg-color-black',
                typesOfCareTableActions: [
                    {
                        text: 'Delete'
                    },
                ],
                selectedRow: null
            }
        },
        computed: {
            lblTitle() {
                return 'Types of Care';
            },
            typesOfCareTableItems() {
                let typesOfCare = this.$store.state.UserModule.AllTypesOfCare;
                let moment = require('moment-timezone');

                if (typesOfCare){
                    typesOfCare.forEach((care) => {
                        care.CreatedUtc = moment.tz(care.CreatedUtc, this.myShyftAdminTimeZone).format("M/D/YYYY hh:mmA z");
                    });
                }

                return typesOfCare;
            },
            typeOfCareID() {
                return this.selectedRow.rowData.TypeOfCareID;
            },
            myShyftAdminTimeZone(){
                return this.$store.state.AdminModule.MyShyftAdminTimeZone;
            },
        },
        methods: {
            typesOfCareTableRowOnClick(args) {
                this.selectedRow = args;
            },
            btnCloseOnClick(){
                this.closeModal();
            },
            addTypeOfCare() {
                let modalData = {
                    mode: 'add',
                    dto: {},
                };

                DMUtils.openApplicationModal('addTypeOfCareModal', modalData);
            },
            deleteTypeOfCare() {
                this.$store.dispatch('UserModule/deleteTypeOfCare', this.typeOfCareID);
            },
            typesOfCareTableActionOnClick(args) {
                if (args.item.text === 'Edit') {
                    this.editTypeOfCare();
                }
                else if (args.item.text === 'Delete') {
                    const message = 'Are you sure you want to delete this Type of Care?';
                    const title = 'Confirm';
                    const confirmCallback = this.deleteTypeOfCare;

                    DMUtils.openApplicationModal('dmConfirmModal', {
                        title: title,
                        message: message,
                        confirmCallback: confirmCallback,
                        confirmText: 'Yes',
                        denyText: 'Cancel',
                    });
                }             
            },
        },
        created() {
            this.$store.dispatch('UserModule/getAllTypesOfCare');

            this.typesOfCareTableColumns = [
                {
                    type: 'iconTemplate',
                    iconName: 'ellipsis',
                    ellipsisActions: this.typesOfCareTableActions,
                    iconOnClick: this.typesOfCareTableActionOnClick,
                    width: '55px',
                },
                {
                    key: 'TypeOfCareName',
                    label: 'Name',
                },
            ];           
        },
    }
</script>