<template src="./legalDocumentModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'legal-document-modal',
        mixins: [dmModalMixin],
        data() {
            return {
                legalDocumentsTableColumns: [],
                legalDocumentsTableSortBy: {
                    columns: [
                        { field: 'Title', direction: 'Ascending' },
                    ]
                },
                legalDocumentsTableCustomClass: 'dm-bg-color-black',
                legalDocumentsTableActions: [
                    {
                        text: 'Edit'
                    },
                ],
                selectedRow: null
            }
        },
        computed: {
            lblTitle() {
                return 'Legal Documents';
            },
            legalDocumentsTableItems() {
                let legalDocuments = this.$store.state.LegalDocumentModule.AllLegalDocuments;    

                if (legalDocuments){
                    legalDocuments.forEach((document) => {
                        document.myShyftAdminTimeZone = this.myShyftAdminTimeZone;                      
                    });
                }

                return legalDocuments;
            },
            legalDocumentID() {
                return this.selectedRow.rowData.LegalDocumentID;
            },
            myShyftAdminTimeZone(){
                return this.$store.state.AdminModule.MyShyftAdminTimeZone;
            },
        },
        methods: {
            legalDocumentsTableRowOnClick(args) {
                this.selectedRow = args;
            },
            editLegalDocument() {
                this.$store.dispatch('LegalDocumentModule/getByIDMyShyftAdminLegalDocument', this.legalDocumentID)
                    .then((response) => {
                        let modalData = {
                            mode: 'edit',
                            dto: response,
                        };

                        DMUtils.openApplicationModal('editLegalDocumentModal', modalData);
                    });  
            },
            legalDocumentsTableActionOnClick(args) {
                if (args.item.text === 'Edit') {
                    this.editLegalDocument();
                }            
            },
            btnCloseOnClick(){
                this.closeModal();
            },
        },
        created() {
            this.$store.dispatch('LegalDocumentModule/getAllMyShyftAdminLegalDocuments');

            this.legalDocumentsTableColumns = [
                {
                    type: 'iconTemplate',
                    iconName: 'ellipsis',
                    ellipsisActions: this.legalDocumentsTableActions,
                    iconOnClick: this.legalDocumentsTableActionOnClick,
                    width: '55px',
                },
                {
                    key: 'LegalDocumentID',
                    label: 'ID',
                    width: '75px'
                },
                {
                    key: 'Title',
                    label: 'Title'
                },
                {
                    key: 'UpdatedUtc',
                    label: 'Last Updated On',
                    type: "date",
                    width: '200px',
                    formatter(column, document) {
                        return moment.tz(document.UpdatedUtc, document.myShyftAdminTimeZone).format("MM/DD/YYYY hh:mmA z"); 
                    }
                },
            ];           
        },
    }
</script>