x<template src="./adminDashboardPage.html"></template>

<script>
    import Vue from 'vue';
    import { ChartPlugin, ColumnSeries, Category } from "@syncfusion/ej2-vue-charts";
    import { AccumulationChartPlugin, PieSeries, AccumulationDataLabel } from "@syncfusion/ej2-vue-charts";

    export default {
        name: 'admin-dashboard-page',
        data() {
            return {
                refreshTimer: '',
                dashboardDto: {},
                datalabel: { 
                    visible: true, 
                    position:'Outside', 
                    name:'text',
                    template:  "<div id='templateWrap' style='border-radius: 3px; float: right;padding: 2px;line-height: 20px;text-align: center;'>"+ "<div style='color:black; font-family:Poppins; font-style: medium; font-size:14px;float: right;padding: 2px;line-height: 20px;text-align: center;padding-right:6px'><span>${point.text}</span></div></div>"
                },
                doughnutChartPalette: ["#48c064", "#EA7317", "#Ef5D60"],
                caregiverSignUpOverview: [],

                primaryXAxis: {
                    valueType: 'Category',
                },
                primaryYAxis: {
                    labelFormat: '{value}',
                    rangePadding: 'Additional',
                    minimum: 0,
                    lineStyle: { width: 0 },
                    majorTickLines: { width: 0 },
                    minorTickLines: { width: 0 }
                },
                barGraphPalette: ["#37bbca"],
                cornerRadius: {
                    topLeft: 10, topRight: 10
                }
            }
        },
        provide:{
            chart: [ColumnSeries, Category],
            accumulationchart: [PieSeries, AccumulationDataLabel]
        },
        computed: {
            failedPayoutStatus(){
                let failedPayoutCount = this.$store.state.AdminDashboardModule.FailedPayouts.length;

                if (failedPayoutCount === 1){
                    return 'There is ' + failedPayoutCount + ' Caregiver with a failed payout.';
                }
                
                return 'There are ' + failedPayoutCount + ' Caregivers with failed payouts.';
            },
            caregiverFirstShiftStatus() {
                let incompleteCaregiverFirstShifts = this.$store.state.AdminDashboardModule.IncompleteCaregiverFirstShifts;

                if (incompleteCaregiverFirstShifts === 1){
                    return 'There is ' + incompleteCaregiverFirstShifts + ' incomplete Caregiver First Shift.';
                }

                return 'There are ' + incompleteCaregiverFirstShifts + ' incomplete Caregiver First Shifts.';
            }
        },
        methods: { 
            viewFailedPayouts() {           
                DMUtils.openApplicationModal('adminDashboardFailedPayoutModal');
            },
            getFailedPayouts() {
                this.$store.dispatch('AdminDashboardModule/getFailedPayouts');
            },
            viewCaregiverFirstShifts() {    
                let modalData =  {
                    callback: this.dashboardRefresh
                };

                DMUtils.openApplicationModal('adminDashboardCaregiverFirstShiftsModal', modalData);
            },
            getIncompleteCaregiverFirstShifts() {
                this.$store.dispatch('AdminDashboardModule/getIncompleteCaregiverFirstShifts');
            },
            stopDashboardRefresh () {
                clearInterval(this.refreshTimer);
            },
            dashboardRefresh() {
                this.getFailedPayouts();
                this.getIncompleteCaregiverFirstShifts();

                this.$store.dispatch('AdminDashboardModule/getMyShyftAdminDashboard')
                    .then((response) => {
                        this.dashboardDto = response;    
                        
                        let totalCaregivers = this.dashboardDto.CaregiverSignUpOverview.TotalCaregivers;
                        let activeCaregivers = this.dashboardDto.CaregiverSignUpOverview.TotalActiveCaregivers;
                        let incompeteCaregivers = this.dashboardDto.CaregiverSignUpOverview.TotalIncompleteCaregivers;
                        let inactiveCaregivers = totalCaregivers - activeCaregivers - incompeteCaregivers;

                        this.caregiverSignUpOverview = [
                            {
                                'Title': 'Active Caregivers',
                                'Amount': activeCaregivers,
                                'text': 'Active -' + activeCaregivers
                            },
                            {
                                'Title': 'Inactive Caregivers',
                                'Amount': inactiveCaregivers,
                                'text': 'Inactive - ' + inactiveCaregivers
                            },
                            {
                                'Title': 'Incomplete Caregivers',
                                'Amount': incompeteCaregivers,
                                'text': 'Incomplete Sign Up - ' + incompeteCaregivers
                            }
                        ]
                    });    
            }
        },
        created() {
            //this.refreshTimer = setInterval(this.getFailedPayouts, 6000);
            this.dashboardRefresh();
        },
        beforeUnmount () {
            this.stopDashboardRefresh();
        }
    }
</script>