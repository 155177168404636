import endpoints from "../../../js/endpoints";

const userModule = {
    namespaced: true,
    state: () => ({
        AllUsers: [],
        AllTypesOfCare: [],
    }),
    mutations: {
        setUsers(state, users) {
            state.AllUsers = users;
        },
        setTypesOfCare(state, typesOfCare) {
            state.AllTypesOfCare = typesOfCare;
        },
    },
    actions: {
        getAllUsers({commit}, organizationID) {
            let endpoint = 'getAllUsers';
            commit('setUsers', []);

            if(this.state.AuthorizationModule.SignedInUser.Role === 'MyShyftAdmin' 
            || this.state.AuthorizationModule.SignedInUser.Role === 'MyShyftEmployee'
            || this.state.AuthorizationModule.SignedInUser.Role === 'MyShyftViewer') {
                if(!organizationID || organizationID < 0) {
                    endpoint = 'getAllUsersMyShyftAdmin';
                }
            }
            return endpoints[endpoint](organizationID)
                .then((response) => {
                    commit('setUsers', response);
                });
        },
        getUserByID({commit}, userID) {
            return endpoints.getUserByID(userID)
                .then((response) => {
                    return response;
                });
        },
        updateUser({dispatch}, dto) {
            return endpoints.updateUser(dto)
                .then(() => {
                    dispatch('getAllUsers', dto.OrganizationID);
                });
        },
        createMyShyftAdmin({dispatch}, dto) {
            return endpoints.createMyShyftAdmin(dto)
                .then(() => {
                    dispatch('getAllUsers', dto.OrganizationID);
                });
        },
        createOrganizationUser({dispatch}, dto) {
            return endpoints.createOrganizationUser(dto)
                .then(() => {
                    dispatch('getAllUsers', dto.OrganizationID);
                });
        },
        deleteUser({dispatch, state, commit}, dto) {
            return endpoints.getUserByID(dto.UserID)
                .then((response) => {
                    return endpoints.deleteUser(response)
                })
                .then(() => {
                    dispatch('getAllUsers', dto.OrganizationID);
                });
        },
        updateCaregiver({dispatch}, dto) {
            return endpoints.updateCaregiver(dto)
                .then(() => {
                    dispatch('getAllUsers');
                });
        },       
        getAllTypesOfCare({commit}) {
            commit('setTypesOfCare', []);

            return endpoints.getAllTypesOfCare()
                .then((response) => {
                    commit('setTypesOfCare', response);
                });
        },
        createTypeOfCare({dispatch}, dto) {
            return endpoints.createTypeOfCare(dto)
                .then(() => {
                    dispatch('getAllTypesOfCare');
                });
        },
        deleteTypeOfCare({dispatch}, typeOfCareID) {
            return endpoints.deleteTypeOfCare(typeOfCareID)
                .then(() => {
                    dispatch('getAllTypesOfCare');
                });
        },
    },
};

export default userModule;
