import endpoints from "../../../../js/endpoints";

const documentCenterModule = {
    namespaced: true,
    state: () => ({
        AllDocumentsForUser: []
    }),
    mutations: {
        setDocuments(state, documents) {
            state.AllDocumentsForUser = documents;
        },
    },
    actions: {
        getAllDocumentsForUser({commit}, userID) {
            commit('setDocuments', []);

            return endpoints.getAllDocumentsForUser(userID)
                .then((response) => {
                    commit('setDocuments', response);
                });
        },     
        uploadDocumentMyShyftAdmin({dispatch}, document) {
            return endpoints.uploadDocumentMyShyftAdmin(document)
                .then(() => {
                    dispatch('getAllDocumentsForUser', document.UserID);
                });
        },
        updateDocumentStatus({dispatch}, document) {
            return endpoints.updateDocumentStatus(document)
                .then(() => {
                    dispatch('getAllDocumentsForUser', document.UserID);
                });
        }, 
        deleteDocumentMyShyftAdmin({dispatch}, document) {
            return endpoints.deleteDocumentMyShyftAdmin(document)
                .then(() => {
                    dispatch('getAllDocumentsForUser', document.UserID);
                });
        }, 
        getDocumentCenterTypes({}) {
            return endpoints.getDocumentCenterTypes()
                .then((response) => {
                    return response;
                });
        },   
        getDocumentCenterStatuses({}) {
            return endpoints.getDocumentCenterStatuses()
                .then((response) => {
                    return response;
                });
        },   
        downloadDocumentMyShyftAdmin({}, documentID) {
            return endpoints.downloadDocumentMyShyftAdmin(documentID)
                .then((response) => {
                    return response;
                });
        }     
    },
};

export default documentCenterModule;
