<template src="./blockCaregiversModal.html"></template>

<script>
import dmModalMixin from '../../../../dmFramework/dmJS/dmModalMixin';

export default {
    name: 'block-caregivers-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            lblTitle: 'Blocked Caregivers',
            searchString: '',

            blockCaregiversTableColumns: [],
            blockCaregiversTableSortBy: {
                columns: [{ field: 'CreatedUtc', direction: 'Descending' }],
            },
            blockCaregiversTableActions: [
                {
                    text: 'Unblock',
                },
            ],
            dropElement: '#droparea',
            path: {
                saveUrl: window.apiRoot + '/OrganizationBlockedCaregiver/Import',
            },
            selectedRow: null,
        };
    },
    computed: {
        blockCaregiversTableItems() {
            let blockedCaregivers = this.$store.state.BlockedCaregiverModule.AllBlockedCaregivers;
            const filteredCaregivers = blockedCaregivers.filter((person) => {
                return person.LastName.toLowerCase().includes(this.searchString.toLowerCase());
            });
            return filteredCaregivers;
        },
    },
    methods: {
        blockCaregiversTableRowOnClick(args) {
            this.selectedRow = args;
        },
        uploadSuccess() {
            this.success = true;
        },
        uploadBefore(event, file, name) {
            event.currentRequest.setRequestHeader('Authorization', 'Bearer ' + DMUtils.getAuthToken());

            event.currentRequest.onreadystatechange = function (e) {
                if (event.currentRequest.readyState === XMLHttpRequest.DONE) {
                    var status = event.currentRequest.status;
                    if (status === 0 || (status >= 200 && status < 400)) {
                    } else {
                        DMUtils.alertShow(JSON.parse(e.currentTarget.response).Error, 'Error');
                    }
                }
            };
        },
        unblockCaregiver() {
            let dto = {
                CaregiverID: this.selectedRow.rowData.CaregiverID,
                OrganizationBlockedCaregiverID: this.selectedRow.rowData.OrganizationBlockedCaregiverID,
            };

            this.$store.dispatch('BlockedCaregiverModule/unblockCaregiverOrganization', dto);
        },
        blockCaregiversTableActionOnClick(args) {
            if (args.item.text === 'Unblock') {
                const message = 'Are you sure you want to Unlock this Caregiver?';
                const title = 'Confirm';
                const confirmCallback = this.unblockCaregiver;

                DMUtils.openApplicationModal('dmConfirmModal', {
                    title: title,
                    message: message,
                    confirmCallback: confirmCallback,
                    confirmText: 'Yes',
                    denyText: 'Cancel',
                });
            }
        },
        btnExportCSVOnClick() {
            this.$store.dispatch('BlockedCaregiverModule/exportBlockedCaregiversOrganization').then((response) => {
                DMUtils.downloadAjaxResponseAsFile(response);
            });
        },
        btnDownloadTemplateCSVOnClick() {
            this.$store.dispatch('BlockedCaregiverModule/exportBlockedCaregiversTemplate').then((response) => {
                DMUtils.downloadAjaxResponseAsFile(response);
            });
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.$store.dispatch('BlockedCaregiverModule/getAllBlockedCaregiversForOrganization', 0);

        this.blockCaregiversTableColumns = [
            {
                type: 'iconTemplate',
                iconName: 'ellipsis',
                ellipsisActions: this.blockCaregiversTableActions,
                iconOnClick: this.blockCaregiversTableActionOnClick,
                width: '55px',
            },
            {
                key: 'FirstLastName',
                label: 'Caregiver',
            },
            {
                key: 'CreatedUtc',
                label: 'Blocked On',
                type: 'date',
                format: 'MM/dd/yyyy',
                width: '180px',
            },
        ];
    },
};
</script>
