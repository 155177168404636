<template src="./signInPage.html"></template>

<script>
import dmStates from '../../../../../dmFramework/dmJS/dmStates.js';
import agencySignUpImage from '../../../../images/agencySignUp.png'
import organizationSignUpImage from '../../../../images/organizationSignUp.png'

    export default {
        name: 'sign-in-page',
        
        data() {
            return {
                showForgotPassword: false,
                showOrganizationCategorySelection: false,
                showSignUpPage1: false,
                showSignUpPage2: false,
                showMobileVerification: false,
                dto: {},
                allowFiltering: false,
                filterType: 'Contains',
                stateList: dmStates,
                organizationTypeList: [],
                timezoneList: [],
                organizationTypeList: [],
                recaptchaSuccess: false,
                agencySignUpImage: agencySignUpImage,
                organizationSignUpImage: organizationSignUpImage
            }
        },
        methods: {
            signIn() {
                this.$store.dispatch('AuthorizationModule/signIn', this.dto)
                    .then(response => {
                        let userRole = response.Role;
                        app.getUnreadMessageCountAndStartTimer(userRole);
                        this.$store.dispatch('AuthorizationModule/checkAccountVerification')
                    });
            },
            async signUp() {
                if (this.recaptchaSuccess === false){
                    DMUtils.alertShow(
                        'You must complete the reCAPTCHA in order to Sign Up'
                    );

                    return;
                }

                await this.getLatitudeLongitude();
                this.$store.dispatch('AdminOrganizationModule/createOrganization', this.dto)
                    .then(() => {
                        this.signIn()                 
                    });
            },
            nextSignUp() {       
                 this.$store.dispatch('AdminOrganizationModule/validateOrganizationSignUp', this.dto)
                    .then(() => {
                        this.showSignUpPage1 = false;
                        this.showSignUpPage2 = true;
                    });
            },
            getAllOrganizationTypes() {
                endpoints.getAllOrganizationTypes(this.dto.OrganizationCategory)
                    .then(response => {
                        this.organizationTypeList = response;
                    });
            },
            selectOrganization() {     
                this.dto.OrganizationCategory = 'Facility';  
                this.showSignUpPage1 = true;
                this.showOrganizationCategorySelection = false;
                this.getAllOrganizationTypes();
            },
            selectAgency() {     
                this.dto.OrganizationCategory = 'Agency';  
                this.showSignUpPage1 = true;
                this.showOrganizationCategorySelection = false;
                this.getAllOrganizationTypes();
            },
            sendNewPassword() {
                this.$store
                    .dispatch('AuthorizationModule/sendResetPasswordEmail', this.dto.EmailAddress)
                    .then((response) => {
                        DMUtils.alertShow(
                            response.Message
                        );
                    });
            },
            getLatitudeLongitude() {
                let addressString = "";

                if (this.dto.PrimaryAddress2 === ""){
                    addressString = this.dto.PrimaryAddress1 + '%20' + this.dto.PrimaryCity + '%20' + this.dto.PrimaryState;
                }
                else {
                    addressString = this.dto.PrimaryAddress1 + '%20' + this.dto.PrimaryAddress2 + '%20' + this.dto.PrimaryCity + '%20' + this.dto.PrimaryState;
                }

                let url = 'https://maps.googleapis.com/maps/api/geocode/json?address='+ addressString + '&key=' + window.googleAPIKey;

                return axios({
                    method: 'GET',
                    url: url
                }).then((response) => {
                    if (response.data.status === "OK"){
                        this.dto.PrimaryLatitude = response.data.results[0].geometry.location.lat;
                        this.dto.PrimaryLongitude = response.data.results[0].geometry.location.lng;
                    }
                    return response.data;
                })
            },
            filtering: function(e) {
                let query = new Query();
                query = (e.text != "") ? query.where("Contains", e.text, true) : query;
                e.updateData(this.timezoneList, query);
            },
        },
        created() {
            this.$store
                .dispatch('AuthorizationModule/getAllTimeZones')
                .then((response) => {
                    this.timezoneList = response;
                });
        },
        mounted() {
            window.onRecaptchaSubmit = (token) => {
                this.$store.dispatch('AuthorizationModule/validateRecaptchaResponse', token)
                    .then((response) => {
                        this.recaptchaSuccess = response.isSuccessful;
                    });
            }

            grecaptcha.render('app-recaptcha');
        }
    }
</script>
