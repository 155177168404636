import endpoints from "../../../js/endpoints";

const adminOrganizationModule = {
    namespaced: true,
    state: () => ({
        AllOrganizations: [],
        AllOrganizationContactEntrys: [],
    }),
    mutations: {
        setOrganizations(state, facilites) {
            state.AllOrganizations = facilites;
        },
        setOrganizationContactEntrys(state, entrys) {
            state.AllOrganizationContactEntrys = entrys;
        },
    },
    actions: {
        getAllOrganizations({commit}) {
            commit('setOrganizations', []);

            return endpoints.getAllOrganizations()
                .then((response) => {                  
                    commit('setOrganizations', response);
                });
        },
        getAllOrganizationNames() {
            return endpoints.getAllOrganizationNames()
                .then((response) => {                  
                    return response;
                });
        },
        getAllOrganizationTypes() {
            return endpoints.getAllOrganizationTypes()
                .then((response) => {                  
                    return response;
                });
        },
        getOrganizationByID({}, organizationID) {
            return endpoints.getOrganizationByID(organizationID)
            .then((response) => {
                return response
            });
        },
        getOrganizationByIDMyShyftAdmin({}, organizationID) {
            return endpoints.getOrganizationByIDMyShyftAdmin(organizationID)
            .then((response) => {
                return response
            });
        },
        updateOrganization({dispatch}, dto) {
            return endpoints.updateOrganization(dto)
                .then(() => {
                    dispatch('getAllOrganizations');
                });
        },
        createOrganizationMyShyftAdmin({dispatch}, dto) {
            return endpoints.createOrganizationMyShyftAdmin(dto)
                .then(() => {
                    dispatch('getAllOrganizations');
                });
        },
        createOrganization({}, dto) {
            return endpoints.createOrganization(dto)
                .then((response) => {
                    return response;
                });
        },
        deleteOrganization({dispatch}, organizationID) {
            return endpoints.getOrganizationByID(organizationID)
                .then((response) => {
                    return endpoints.deleteOrganization(response)
                })
                .then(() => {
                    dispatch('getAllOrganizations');
                    return organizationID;
                });
        },     
        getOrganizationInvoiceByOrganizationID({}, organizationID) {
            return endpoints.getOrganizationInvoiceByOrganizationID(organizationID)
            .then((response) => {
                return response
            });
        },
        updateOrganizationInvoice({}, organizationInvoice) {
            return endpoints.updateOrganizationInvoice(organizationInvoice)
            .then((response) => {
                return response
            });
        },
        getAllOrganizationContactsForOrganization({commit}, organizationID) {
            commit('setOrganizationContactEntrys', []);

            return endpoints.getAllOrganizationContactsForOrganization(organizationID)
                .then((response) => {                  
                    commit('setOrganizationContactEntrys', response);
                });
        },
        getOrganizationContactByID({}, organizationContactID) {
            return endpoints.getOrganizationContactByID(organizationContactID)
            .then((response) => {
                return response
            });
        },
        getAllOrganizationContactStatusTypes() {
            return endpoints.getAllOrganizationContactStatusTypes()
                .then((response) => {                  
                    return response;
                });
        },
        createOrganizationContact({dispatch}, dto) {
            return endpoints.createOrganizationContact(dto)
                .then(() => {
                    dispatch('getAllOrganizationContactsForOrganization', dto.OrganizationID);                                            
                });
        },
        updateOrganizationContact({dispatch}, dto) {
            return endpoints.updateOrganizationContact(dto)
                .then(() => {
                    dispatch('getAllOrganizationContactsForOrganization', dto.OrganizationID);
                });
        },
        deleteOrganizationContact({dispatch}, organizationContactID) {
            let organizationContact = []
            return endpoints.getOrganizationContactByID(organizationContactID)
                .then((response) => {
                    organizationContact = response;
                    return endpoints.deleteOrganizationContact(response)
                })
                .then(() => {
                    dispatch('getAllOrganizationContactsForOrganization', organizationContact.OrganizationID);
                });
        }, 
        validateOrganizationSignUp({}, dto){
            return endpoints.validateOrganizationSignUp(dto)
                .then((response) => {
                    return response;
                });
        },   
        getAllElectronicMedicalRecords() {
            return endpoints.getAllElectronicMedicalRecords()
                .then((response) => {                  
                    return response;
                });
        },   
        getElectronicMedicalRecordByID({}, electronicMedicalRecordID) {
            return endpoints.getElectronicMedicalRecordByID(electronicMedicalRecordID)
            .then((response) => {
                return response
            });
        }, 
    },
};

export default adminOrganizationModule;
