<template src="./csvExportModal.html"></template>

<script>
    import dmModalMixin from '../../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'csv-export-modal',
        mixins: [dmModalMixin],
        data() {
            return {
                searchStartDate: '',
                searchEndDate: '',
                lblTitle: 'CSV Exports',
                exportMyShyftAdminList: [
                    "Shifts",
                    "Shift Clocks",
                    "Transfers",
                    "Invoices",
                    "Caregivers",
                    "Caregiver Medical Licenses",
                    "Caregiver License and Org. Types",
                    "Organizations",
                    "Organization Users and Contacts",
                    "Organization Locations"
                ],
                exportOrganizationList: [
                    "Shifts"
                ],
                paramsDto: {},
            }
        },
        computed: {
            accountType() {
                return this.modalData.accountType;
            },
            myShyftAdminTimeZone(){
                return this.$store.state.AdminModule.MyShyftAdminTimeZone;
            },
            organizationLocalTimeZone(){
                return this.$store.state.AuthorizationModule.SignedInUser.LocalTimeZone;
            }
        },
        methods: {
            btnDownloadOnClick() {
                let dispatchString = "";
                if (this.paramsDto.ExportType === 'Shifts' && (this.accountType === 'MyShyftUser')){
                    dispatchString = 'CsvExportModule/exportShiftsMyShyftAdmin';
                } else if (this.paramsDto.ExportType === 'Shifts' && this.accountType === 'OrganizationUser'){
                    dispatchString = 'CsvExportModule/exportShiftsOrganization';
                } else if (this.paramsDto.ExportType === 'Shift Clocks'){
                    dispatchString = 'CsvExportModule/exportShiftClocksMyShyftAdmin';
                } else if (this.paramsDto.ExportType === 'Transfers'){
                    dispatchString = 'CsvExportModule/exportTransactions';
                } else if (this.paramsDto.ExportType === 'Invoices'){
                    dispatchString = 'CsvExportModule/exportInvoices';
                } else if (this.paramsDto.ExportType === 'Caregivers'){
                    dispatchString = 'CsvExportModule/exportCaregivers';
                } else if (this.paramsDto.ExportType === 'Caregiver Medical Licenses'){
                    dispatchString = 'CsvExportModule/exportCaregiverMedicalLicenses';
                } else if (this.paramsDto.ExportType === 'Caregiver License and Org. Types'){
                    dispatchString = 'CsvExportModule/exportCaregiverLicenseSummaryMyShyftAdmin';
                } else if (this.paramsDto.ExportType === 'Organizations') {
                    dispatchString = 'CsvExportModule/exportOrganizations';
                } else if (this.paramsDto.ExportType === 'Organization Users and Contacts') {
                    dispatchString = 'CsvExportModule/exportOrganizationsUsersAndContacts';
                } else if (this.paramsDto.ExportType === 'Organization Locations') {
                    dispatchString = 'CsvExportModule/exportOrganizationLocations';
                };

                let moment = require('moment-timezone');
                let utcNow = moment.utc();

                let startDay = moment.utc(this.searchStartDate).startOf('day');
                let endDay = moment.utc(this.searchEndDate).endOf('day');                  
                let timeZoneOffset = moment.tz.zone(this.myShyftAdminTimeZone).utcOffset(utcNow);

                if (this.accountType === 'OrganizationUser'){
                    timeZoneOffset = moment.tz.zone(this.organizationLocalTimeZone).utcOffset(utcNow);
                }

                this.paramsDto.StartDateRange = startDay.add(timeZoneOffset).format();
                this.paramsDto.EndDateRange = endDay.add(timeZoneOffset).format();

                this.$store.dispatch(dispatchString, this.paramsDto)
                    .then((response) => {
                        DMUtils.downloadAjaxResponseAsFile(response);                                                                        
                    });
            },
            btnCancelOnClick() {
                this.closeModal();
            },
        },
    }
</script>
