<template src="./caregiverDocumentCenterEditModal.html"></template>

<script>
import dmModalMixin from '../../../../../../dmFramework/dmJS/dmModalMixin'
import imageUploadMixin from '../../../../../js/imageUploadMixin';

export default {
    name: 'caregiver-document-center-modal',
    mixins: [dmModalMixin, imageUploadMixin],
    data() {
        return {
            documentCenterTypes: [],
            documentTypeFields: { text: 'DocumentTypeName', value: 'DocumentCenterTypeID' },
            documentCenterTypeID: null,
            userID: null,
            dropElement: '#droparea',
        }
    },
    computed: {
        path() {
            let saveUrl;
            if (this.documentCenterTypeID === null) {
                saveUrl = window.apiRoot + '/DocumentCenter/UploadMyShyftAdmin?UserID=' + this.userID +'&DocumentCenterTypeID=0'
            } else {
                saveUrl = window.apiRoot + '/DocumentCenter/UploadMyShyftAdmin?UserID=' + this.userID +'&DocumentCenterTypeID=' + this.documentCenterTypeID
            }

            return {
                saveUrl: saveUrl
            }
        },
        mode() {
            return this.modalData.mode;
        },
        lblTitle() {
            return 'Add Document';
        },    
    },
    methods: {
        uploadSuccess() {
            this.success = true;
            this.$store.dispatch(
                'DocumentCenterModule/getAllDocumentsForUser',
                this.userID
            );
        },
        uploadBefore(event, file, name) {
            event.currentRequest.setRequestHeader('Authorization', 'Bearer ' + DMUtils.getAuthToken());
            event.currentRequest.onreadystatechange = function (e) {
                if (event.currentRequest.readyState === XMLHttpRequest.DONE) {
                    var status = event.currentRequest.status;
                    if (status === 0 || (status >= 200 && status < 400)) {
                    } else {
                        DMUtils.alertShow(JSON.parse(e.currentTarget.response).Error, 'Error');
                    }
                }
            };
        },
        btnCancelOnClick() {         
            this.closeModal();
        },
    },
    created() {
        this.userID = this.modalData.userID;
        this.$store
            .dispatch('DocumentCenterModule/getDocumentCenterTypes')
            .then((response) => {
                this.documentCenterTypes = response;
        });
    }
}
</script>
