<template src="./editLicenseGlobalRatesModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

export default {
    name: 'edit-license-global-rate-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            dto: {},
            licenseList: [],
            licenseListFields: { text: 'MedicalLicenseName', value: 'MedicalLicenseID' },
            filterType: 'Contains',
            allowFiltering: true
        }
    },
    computed: {
        mode() {
            return this.modalData.mode;
        },
        lblTitle() {
            return this.modalData.mode === 'edit' ? 'Edit License Rate' : 'Add License Rate';
        },
    },
    methods: {
        filtering: function(e) {
           let query = new Query();
            query = (e.text != "") ? query.where("Contains", e.text, true) : query;
            e.updateData(this.licenseList, query);
        },
        btnSaveOnClick() {
            this.dto.OrganizationID = this.modalData.organizationID;
            if (this.mode === 'add') {
                this.$store
                    .dispatch('LicenseGlobalRatesModule/createOrganizationGlobalRate', this.dto)
                    .then(() => {
                        this.shouldModalStayOpen = false;
                        this.successClose(this.dto);
                    });
            } else if (this.mode === 'edit') {
                this.$store
                    .dispatch('LicenseGlobalRatesModule/updateOrganizationGlobalRate', this.dto)
                    .then(() => {
                        this.shouldModalStayOpen = false;
                        this.successClose(this.dto);
                    });
            }
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.dto = DMUtils.copy(this.modalData.dto);

        this.$store
            .dispatch('MedicalLicenseModule/getAllMedicalLicensesMyShyftAdmin')
            .then(() => {
                this.licenseList = this.$store.state.MedicalLicenseModule.AllMedicalLicenses;
            });    
    },
}
</script>
