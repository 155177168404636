<template src="./medicalLicenseModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'medical-license-modal',
        mixins: [dmModalMixin],
        data() {
            return {
                medicalLicensesTableColumns: [],
                medicalLicensesTableSortBy: {
                    columns: [
                        { field: 'MedicalLicenseName', direction: 'Ascending' },
                    ]
                },
                medicalLicensesTableCustomClass: 'dm-bg-color-black',
                medicalLicensesTableActions: [
                    {
                        text: 'Edit'
                    },
                    {
                        text: 'Delete'
                    },
                ],
                selectedRow: null
            }
        },
        computed: {
            lblTitle() {
                return 'Professional Licenses';
            },
            medicalLicensesTableItems() {
                let medicalLicenses = this.$store.state.MedicalLicenseModule.AllMedicalLicenses;
                let moment = require('moment-timezone');

                if (medicalLicenses){
                    medicalLicenses.forEach((license) => {
                        license.CreatedUtc = moment.tz(license.CreatedUtc, this.myShyftAdminTimeZone).format("M/D/YYYY hh:mmA z");
                        
                        license.StateNames = "";
                        let states = license.MedicalLicenseStates;
                        
                        if (states){
                        let stateCounter = 0;
                            states.forEach(state => {
                                license.StateNames += state;
                                stateCounter++;
                                if (states.length > stateCounter){
                                    license.StateNames += ", ";
                                 }
                            });
                        }                      
                    });
                }
            
                return medicalLicenses;
            },
            medicalLicenseID() {
                return this.selectedRow.rowData.MedicalLicenseID;
            },
            medicalLicenseName() {
                return this.selectedRow.rowData.MedicalLicenseName;
            },
            medicalLicenseStates() {
                return this.selectedRow.rowData.MedicalLicenseStates;
            },
            myShyftAdminTimeZone(){
                return this.$store.state.AdminModule.MyShyftAdminTimeZone;
            },
        },
        methods: {
            medicalLicensesTableRowOnClick(args) {
                this.selectedRow = args;
            },
            btnCloseOnClick(){
                this.closeModal();
            },
            addMedicalLicense() {
                let modalData = {
                    mode: 'add',
                    dto: {},
                };

                DMUtils.openApplicationModal('addMedicalLicenseModal', modalData);
            },
            editMedicalLicense() {
                let modalData = {
                    mode: 'edit',
                    dto: {
                        MedicalLicenseID: this.medicalLicenseID,
                        MedicalLicenseName: this.medicalLicenseName,
                        MedicalLicenseStates: this.medicalLicenseStates,
                    },
                };
                
                DMUtils.openApplicationModal('addMedicalLicenseModal', modalData);
            },
            deleteMedicalLicense() {
                this.$store.dispatch('MedicalLicenseModule/deleteMedicalLicense', this.medicalLicenseID);
            },
            medicalLicensesTableActionOnClick(args) {
                if (args.item.text === 'Edit') {
                    this.editMedicalLicense();
                }
                else if (args.item.text === 'Delete') {
                    const message = 'Are you sure you want to delete this Professional License?';
                    const title = 'Confirm';
                    const confirmCallback = this.deleteMedicalLicense;

                    DMUtils.openApplicationModal('dmConfirmModal', {
                        title: title,
                        message: message,
                        confirmCallback: confirmCallback,
                        confirmText: 'Yes',
                        denyText: 'Cancel',
                    });
                }             
            },
        },
        created() {
            this.$store.dispatch('MedicalLicenseModule/getAllMedicalLicensesMyShyftAdmin');

            this.medicalLicensesTableColumns = [
                {
                    type: 'iconTemplate',
                    iconName: 'ellipsis',
                    ellipsisActions: this.medicalLicensesTableActions,
                    iconOnClick: this.medicalLicensesTableActionOnClick,
                    width: '55px',
                },
                {
                    key: 'MedicalLicenseName',
                    label: 'Name',
                },
                {
                    key: 'StateNames',
                    label: 'States',
                    width: '250px'
                },
            ];           
        },
    }
</script>