<template src="./emailVerification.html"></template>

<script>
import dmStates from '../../../../../dmFramework/dmJS/dmStates.js';

    export default {
        name: 'email-verification-page',
        
        data() {
            return {
                isEmailValidationSuccessful: false,
                dto: {},
            }
        },
        methods: {
            
        },
        created() {
            let token = this.$route.params.token;

            let dto = {
                VerificationCode: token
            }
            
            this.$store.dispatch('AuthorizationModule/verifyUserEmailAddress', dto)
                .then((response) => {
                    this.isEmailValidationSuccessful = response.VerifiedSuccessfully;
                })
        },        
    }
</script>
