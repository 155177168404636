import { CLS_BACKGROUND_COLOR_PICKER } from '@syncfusion/ej2-vue-richtexteditor';
import endpointsMessages from '../messagesComponents/messagesEndpoints';

export default Object.assign(
    {
        // #region Authorization

        signIn(user) {
            let dto = {
                EmailAddress: user.EmailAddress,
                Password: user.Password,
                AppName: 'MyShyftAdmin-Organization-WebApp',
            };

            if (!dto.EmailAddress) {
                dto.EmailAddress = '';
            }

            if (!dto.Password) {
                dto.Password = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Auth/SignIn',
                data: dto,
            }).then((response) => {
                DMUtils.setAuthToken(response.AuthToken);
                return response;
            });
        },
        signInWithAuthToken(authToken) {
            DMUtils.setAuthToken(authToken);

            return DMUtils.http({
                method: 'POST',
                url: '/Auth/SignInWithAuthToken',
            })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    DMUtils.clearAuthToken();

                    return Promise.reject(error);
                });
        },
        signOut() {
            return DMUtils.http({
                method: 'POST',
                url: '/Auth/SignOut',
                data: {
                    DeviceToken: '',
                },
            }).then((response) => {
                DMUtils.clearAuthToken();
                return response;
            });
        },
        resetPasswordFromToken(resetPassword) {
            let dto = {
                NewPassword: resetPassword.NewPassword,
                NewPasswordConfirm: resetPassword.NewPasswordConfirm,
                ResetToken: resetPassword.ResetToken,
            };

            if (!dto.NewPassword) {
                dto.NewPassword = '';
            }

            if (!dto.NewPasswordConfirm) {
                dto.NewPasswordConfirm = '';
            }

            if (!dto.ResetToken) {
                dto.ResetToken = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Auth/UpdatePasswordFromToken',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        sendResetPasswordEmail(emailAddress) {
            if (!emailAddress) {
                emailAddress = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Auth/SendResetPasswordEmail',
                data: { EmailAddress: emailAddress },
            }).then((response) => {
                return response;
            });
        },

        // #endregion Authorization

        // #region Google-Recaptcha-API

        validateRecaptchaResponse(recaptchaToken) {
            return DMUtils.http({
                method: 'GET',
                url: '/Api/ValidateRecaptchaResponse',
                params: { RecaptchaToken: recaptchaToken },
            }).then((response) => {
                return response;
            });
        },

        // #endregion Google-Recaptcha-API

        // #region Admin-Organization

        getAllOrganizations() {
            return DMUtils.http({
                method: 'GET',
                url: '/Organization/GetAll',
                params: { IncludeInactive: true },
            }).then((response) => {
                return response;
            });
        },
        getAllOrganizationNames() {
            return DMUtils.http({
                method: 'GET',
                url: '/Organization/GetAllOrganizationNames',
            }).then((response) => {
                return response;
            });
        },
        getOrganizationByID(organizationID, hideLoader) {
            return DMUtils.http(
                {
                    method: 'GET',
                    url: '/Organization/GetByID',
                    params: { OrganizationID: organizationID },
                },
                hideLoader
            ).then((response) => {
                return response;
            });
        },
        getOrganizationByIDMyShyftAdmin(organizationID, hideLoader) {
            return DMUtils.http(
                {
                    method: 'GET',
                    url: '/Organization/GetByIDMyShyftAdmin',
                    params: { OrganizationID: organizationID },
                },
                hideLoader
            ).then((response) => {
                return response;
            });
        },
        validateOrganizationSignUp(organization) {
            let dto = {
                OrganizationName: organization.OrganizationName,
                OrganizationCategory: organization.OrganizationCategory,
                OrganizationTypeID: organization.OrganizationTypeID,
                LocalTimeZone: organization.LocalTimeZone,
                PrimaryAddress1: organization.PrimaryAddress1,
                PrimaryAddress2: organization.PrimaryAddress2,
                PrimaryCity: organization.PrimaryCity,
                PrimaryState: organization.PrimaryState,
                PrimaryZipcode: organization.PrimaryZipcode,
                PrimaryLatitude: organization.PrimaryLatitude,
                PrimaryLongitude: organization.PrimaryLongitude,
            };

            if (!dto.OrganizationName) {
                dto.OrganizationName = '';
            }

            if (!dto.OrganizationCategory) {
                dto.OrganizationCategory = '';
            }

            if (!dto.OrganizationTypeID) {
                dto.OrganizationTypeID = -1;
            }

            if (!dto.LocalTimeZone) {
                dto.LocalTimeZone = '';
            }

            if (!dto.PrimaryAddress1) {
                dto.PrimaryAddress1 = '';
            }

            if (!dto.PrimaryAddress2) {
                dto.PrimaryAddress2 = '';
            }

            if (!dto.PrimaryCity) {
                dto.PrimaryCity = '';
            }

            if (!dto.PrimaryState) {
                dto.PrimaryState = '';
            }

            if (!dto.PrimaryZipcode) {
                dto.PrimaryZipcode = '';
            }

            if (!dto.PrimaryLatitude) {
                dto.PrimaryLatitude = 0;
            }

            if (!dto.PrimaryLongitude) {
                dto.PrimaryLongitude = 0;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Organization/ValidateSignUp',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        createOrganization(organization) {
            let dto = {
                FirstName: organization.FirstName,
                LastName: organization.LastName,
                EmailAddress: organization.EmailAddress,
                PhoneNumber: organization.PhoneNumber,
                Password: organization.Password,
                PasswordConfirm: organization.PasswordConfirm,
                OrganizationName: organization.OrganizationName,
                OrganizationCategory: organization.OrganizationCategory,
                OrganizationTypeID: organization.OrganizationTypeID,
                ReferredBy: organization.ReferredBy,
                LocalTimeZone: organization.LocalTimeZone,
                PrimaryAddress1: organization.PrimaryAddress1,
                PrimaryAddress2: organization.PrimaryAddress2,
                PrimaryCity: organization.PrimaryCity,
                PrimaryState: organization.PrimaryState,
                PrimaryZipcode: organization.PrimaryZipcode,
                PrimaryLatitude: organization.PrimaryLatitude,
                PrimaryLongitude: organization.PrimaryLongitude,
            };

            if (!dto.FirstName) {
                dto.FirstName = '';
            }

            if (!dto.LastName) {
                dto.LastName = '';
            }

            if (!dto.EmailAddress) {
                dto.EmailAddress = '';
            }

            if (!dto.PhoneNumber) {
                dto.PhoneNumber = '';
            }

            if (!dto.Password) {
                dto.Password = '';
            }

            if (!dto.PasswordConfirm) {
                dto.PasswordConfirm = '';
            }

            if (!dto.OrganizationName) {
                dto.OrganizationName = '';
            }

            if (!dto.OrganizationCategory) {
                dto.OrganizationCategory = '';
            }

            if (!dto.OrganizationTypeID) {
                dto.OrganizationTypeID = -1;
            }

            if (!dto.ReferredBy) {
                dto.ReferredBy = '';
            }

            if (!dto.LocalTimeZone) {
                dto.LocalTimeZone = '';
            }

            if (!dto.PrimaryAddress1) {
                dto.PrimaryAddress1 = '';
            }

            if (!dto.PrimaryAddress2) {
                dto.PrimaryAddress2 = '';
            }

            if (!dto.PrimaryCity) {
                dto.PrimaryCity = '';
            }

            if (!dto.PrimaryState) {
                dto.PrimaryState = '';
            }

            if (!dto.PrimaryZipcode) {
                dto.PrimaryZipcode = '';
            }

            if (!dto.PrimaryLatitude) {
                dto.PrimaryLatitude = 0;
            }

            if (!dto.PrimaryLongitude) {
                dto.PrimaryLongitude = 0;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Organization/Create',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        createOrganizationMyShyftAdmin(organization) {
            let dto = {
                OrganizationName: organization.OrganizationName,
                OrganizationCategory: organization.OrganizationCategory,
                OrganizationPhoneNumber: organization.OrganizationPhoneNumber,
                CreditLimit: organization.CreditLimit,
                DiscountPercent : organization.DiscountPercent,
                Description: organization.Description,
                ReferredBy: organization.ReferredBy,
                LocalTimeZone: organization.LocalTimeZone,
                PrimaryAddress1: organization.PrimaryAddress1,
                PrimaryAddress2: organization.PrimaryAddress2,
                PrimaryCity: organization.PrimaryCity,
                PrimaryState: organization.PrimaryState,
                PrimaryZipcode: organization.PrimaryZipcode,
                BillingAddress1: organization.BillingAddress1,
                BillingAddress2: organization.BillingAddress2,
                BillingCity: organization.BillingCity,
                BillingState: organization.BillingState,
                BillingZipcode: organization.BillingZipcode,
                ElectronicMedicalRecordID: organization.ElectronicMedicalRecordID,
                ProfileImageFileName: organization.ProfileImageFileName,
                ProfileImageBase64: organization.ProfileImageBase64,
            };

            if (!dto.OrganizationName) {
                dto.OrganizationName = '';
            }

            if (!dto.OrganizationCategory) {
                dto.OrganizationCategory = '';
            }

            if (!dto.OrganizationPhoneNumber) {
                dto.OrganizationPhoneNumber = '';
            }
  
            if (!dto.CreditLimit) {
                dto.CreditLimit = 0;
            }

            if (!dto.DiscountPercent) {
                dto.DiscountPercent = 0;
            }

            if (!dto.Description) {
                dto.Description = '';
            }

            if (!dto.ReferredBy) {
                dto.ReferredBy = '';
            }

            if (!dto.LocalTimeZone) {
                dto.LocalTimeZone = '';
            }

            if (!dto.PrimaryAddress1) {
                dto.PrimaryAddress1 = '';
            }

            if (!dto.PrimaryAddress2) {
                dto.PrimaryAddress2 = '';
            }

            if (!dto.PrimaryCity) {
                dto.PrimaryCity = '';
            }

            if (!dto.PrimaryState) {
                dto.PrimaryState = '';
            }

            if (!dto.PrimaryZipcode) {
                dto.PrimaryZipcode = '';
            }

            if (!dto.BillingAddress1) {
                dto.BillingAddress1 = '';
            }

            if (!dto.BillingAddress2) {
                dto.BillingAddress2 = '';
            }

            if (!dto.BillingCity) {
                dto.BillingCity = '';
            }

            if (!dto.BillingState) {
                dto.BillingState = '';
            }

            if (!dto.BillingZipcode) {
                dto.BillingZipcode = '';
            }
            
            if (!dto.ElectronicMedicalRecordID) {
                dto.ElectronicMedicalRecordID = 0;
            }

            if (!dto.ProfileImageFileName) {
                dto.ProfileImageFileName = '';
            }

            if (!dto.ProfileImageBase64) {
                dto.ProfileImageBase64 = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Organization/CreateMyShyftAdmin',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        updateOrganization(organization) {
            let dto = {
                OrganizationName: organization.OrganizationName,
                OrganizationPhoneNumber: organization.OrganizationPhoneNumber,
                Description: organization.Description,
                ReferredBy: organization.ReferredBy,
                LocalTimeZone: organization.LocalTimeZone,
                PrimaryAddress1: organization.PrimaryAddress1,
                PrimaryAddress2: organization.PrimaryAddress2,
                PrimaryCity: organization.PrimaryCity,
                PrimaryState: organization.PrimaryState,
                PrimaryZipcode: organization.PrimaryZipcode,
                BillingAddress1: organization.BillingAddress1,
                BillingAddress2: organization.BillingAddress2,
                BillingCity: organization.BillingCity,
                BillingState: organization.BillingState,
                BillingZipcode: organization.BillingZipcode,
                ElectronicMedicalRecordID: organization.ElectronicMedicalRecordID,
                InvoiceNotes: organization.InvoiceNotes,
                NextInvoiceNotes: organization.NextInvoiceNotes,
                ProfileImageFileName: organization.ProfileImageFileName,
                ProfileImageBase64: organization.ProfileImageBase64,
                OrganizationID: organization.OrganizationID,
                CreditLimit: organization.CreditLimit,
                Status: organization.Status,
                RowVersion: organization.RowVersion,
                DiscountPercent: organization.DiscountPercent
            };

            if (!dto.OrganizationName) {
                dto.OrganizationName = '';
            }

            if (!dto.OrganizationPhoneNumber) {
                dto.OrganizationPhoneNumber = '';
            }

            if (!dto.Description) {
                dto.Description = '';
            }

            if (!dto.ReferredBy) {
                dto.ReferredBy = '';
            }

            if (!dto.LocalTimeZone) {
                dto.LocalTimeZone = '';
            }

            if (!dto.PrimaryAddress1) {
                dto.PrimaryAddress1 = '';
            }

            if (!dto.PrimaryAddress2) {
                dto.PrimaryAddress2 = '';
            }

            if (!dto.PrimaryCity) {
                dto.PrimaryCity = '';
            }

            if (!dto.PrimaryState) {
                dto.PrimaryState = '';
            }

            if (!dto.PrimaryZipcode) {
                dto.PrimaryZipcode = '';
            }

            if (!dto.BillingAddress1) {
                dto.BillingAddress1 = '';
            }

            if (!dto.BillingAddress2) {
                dto.BillingAddress2 = '';
            }

            if (!dto.BillingCity) {
                dto.BillingCity = '';
            }

            if (!dto.BillingState) {
                dto.BillingState = '';
            }

            if (!dto.BillingZipcode) {
                dto.BillingZipcode = '';
            }

            if (!dto.OrganizationID) {
                dto.OrganizationID = -1;
            }

            if (!dto.CreditLimit) {
                dto.CreditLimit = 0;
            }

            if (!dto.ElectronicMedicalRecordID) {
                dto.ElectronicMedicalRecordID = 0;
            }

            if (!dto.InvoiceNotes) {
                dto.InvoiceNotes = '';
            }

            if (!dto.NextInvoiceNotes) {
                dto.NextInvoiceNotes = '';
            }

            if (!dto.Status) {
                dto.Status = '';
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            if (!dto.ProfileImageFileName) {
                dto.ProfileImageFileName = '';
            }

            if (!dto.ProfileImageBase64) {
                dto.ProfileImageBase64 = '';
            }

            if (!dto.DiscountPercent) {
                dto.DiscountPercent = 0;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Organization/UpdateMyShyftAdmin',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        updateOrganizationAsOrganization(organization) {
            let dto = {
                OrganizationName: organization.OrganizationName,
                Description: organization.Description,
                BillingAddress1: organization.BillingAddress1,
                BillingAddress2: organization.BillingAddress2,
                BillingCity: organization.BillingCity,
                BillingState: organization.BillingState,
                BillingZipcode: organization.BillingZipcode,
                ElectronicMedicalRecordID: organization.ElectronicMedicalRecordID,
                ProfileImageFileName: organization.ProfileImageFileName,
                ProfileImageBase64: organization.ProfileImageBase64,
                RowVersion: organization.RowVersion,
            };

            if (!dto.OrganizationName) {
                dto.OrganizationName = '';
            }

            if (!dto.Description) {
                dto.Description = '';
            }

            if (!dto.BillingAddress1) {
                dto.BillingAddress1 = '';
            }

            if (!dto.BillingAddress2) {
                dto.BillingAddress2 = '';
            }

            if (!dto.BillingCity) {
                dto.BillingCity = '';
            }

            if (!dto.BillingState) {
                dto.BillingState = '';
            }

            if (!dto.BillingZipcode) {
                dto.BillingZipcode = '';
            }

            if (!dto.ElectronicMedicalRecordID) {
                dto.ElectronicMedicalRecordID = 0;
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            if (!dto.ProfileImageFileName) {
                dto.ProfileImageFileName = '';
            }

            if (!dto.ProfileImageBase64) {
                dto.ProfileImageBase64 = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Organization/UpdateOrganization',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        deleteOrganization(organization) {
            let dto = {
                OrganizationID: organization.OrganizationID,
                RowVersion: organization.RowVersion,
            };

            if (!dto.OrganizationID) {
                dto.OrganizationID = -1;
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Organization/Delete',
                data: dto,
            }).then((response) => {
                return response;
            });
        },

        // #endregion Admin-Organization

        // #region Users

        checkAccountVerification() {
            return DMUtils.http({
                method: 'GET',
                url: '/User/ValidateAccountDesktop',
            });
        },
        getAllUsers(organizationID) {
            return DMUtils.http({
                method: 'GET',
                url: '/User/GetAll',
                params: { OrganizationID: organizationID },
            }).then((response) => {
                return response;
            });
        },
        getAllUsersMyShyftAdmin() {
            return DMUtils.http({
                method: 'GET',
                url: '/User/GetAllMyShyftAdmins',
            }).then((response) => {
                return response;
            });
        },
        getUserByID(userID, hideLoader) {
            return DMUtils.http(
                {
                    method: 'GET',
                    url: '/User/GetByID',
                    params: { UserID: userID },
                },
                hideLoader
            ).then((response) => {
                return response;
            });
        },
        getUserByIDWithOrganization(userID) {
            return DMUtils.http(
                {
                    method: 'GET',
                    url: '/User/GetByIDWithOrganization',
                    params: { UserID: userID },
                },
            ).then((response) => {
                return response;
            });
        },
        createMyShyftAdmin(user) {
            let dto = {
                OrganizationID: user.OrganizationID,
                EmailAddress: user.EmailAddress,
                FirstName: user.FirstName,
                PreferredName: user.PreferredName,
                Pronoun: user.Pronoun,
                MiddleName: user.MiddleName,
                LastName: user.LastName,
                Password: user.Password,
                PhoneNumber: user.PhoneNumber,
                Role: user.Role,
            };

            if (!dto.OrganizationID) {
                dto.OrganizationID = -1;
            }

            if (!dto.EmailAddress) {
                dto.EmailAddress = '';
            }

            if (!dto.FirstName) {
                dto.FirstName = '';
            }

            if (!dto.PreferredName) {
                dto.PreferredName = '';
            }

            if (!dto.Pronoun) {
                dto.Pronoun = '';
            }

            if (!dto.MiddleName) {
                dto.MiddleName = '';
            }

            if (!dto.LastName) {
                dto.LastName = '';
            }

            if (!dto.Password) {
                dto.Password = '';
            }

            if (!dto.PhoneNumber) {
                dto.PhoneNumber = '';
            }

            if (!dto.Role) {
                dto.Role = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/User/CreateMyShyftAdmin',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        createOrganizationUser(user) {
            let dto = {
                OrganizationID: user.OrganizationID,
                EmailAddress: user.EmailAddress,
                FirstName: user.FirstName,
                PreferredName: user.PreferredName,
                Pronoun: user.Pronoun,
                MiddleName: user.MiddleName,
                LastName: user.LastName,
                Password: user.Password,
                PhoneNumber: user.PhoneNumber,
                Role: user.Role,
            };

            if (!dto.OrganizationID) {
                dto.OrganizationID = -1;
            }

            if (!dto.EmailAddress) {
                dto.EmailAddress = '';
            }

            if (!dto.FirstName) {
                dto.FirstName = '';
            }

            if (!dto.PreferredName) {
                dto.PreferredName = '';
            }

            if (!dto.Pronoun) {
                dto.Pronoun = '';
            }

            if (!dto.MiddleName) {
                dto.MiddleName = '';
            }

            if (!dto.LastName) {
                dto.LastName = '';
            }

            if (!dto.Password) {
                dto.Password = '';
            }

            if (!dto.PhoneNumber) {
                dto.PhoneNumber = '';
            }

            if (!dto.Role) {
                dto.Role = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/User/CreateOrganizationUser',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        updateUser(user) {
            let dto = {
                OrganizationID: user.OrganizationID,
                UserID: user.UserID,
                EmailAddress: user.EmailAddress,
                FirstName: user.FirstName,
                PreferredName: user.PreferredName,
                Pronoun: user.Pronoun,
                MiddleName: user.MiddleName,
                LastName: user.LastName,
                RowVersion: user.RowVersion,
                PhoneNumber: user.PhoneNumber,
                Role: user.Role,
                Status: user.Status,
            };

            if (!dto.OrganizationID) {
                dto.OrganizationID = -1;
            }

            if (!dto.UserID) {
                dto.UserID = -1;
            }

            if (!dto.EmailAddress) {
                dto.EmailAddress = '';
            }

            if (!dto.FirstName) {
                dto.FirstName = '';
            }

            if (!dto.PreferredName) {
                dto.PreferredName = '';
            }

            if (!dto.Pronoun) {
                dto.Pronoun = '';
            }

            if (!dto.MiddleName) {
                dto.MiddleName = '';
            }

            if (!dto.LastName) {
                dto.LastName = '';
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            if (!dto.PhoneNumber) {
                dto.PhoneNumber = '';
            }

            if (!dto.Role) {
                dto.Role = '';
            }

            if (!dto.Status) {
                dto.Status = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/User/Update',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        deleteUser(user) {
            let dto = {
                OrganizationID: user.OrganizationID,
                UserID: user.UserID,
                EmailAddress: user.EmailAddress,
                FirstName: user.FirstName,
                PreferredName: user.PreferredName,
                MiddleName: user.MiddleName,
                LastName: user.LastName,
                RowVersion: user.RowVersion,
                PhoneNumber: user.PhoneNumber,
                Role: user.Role,
                Status: user.Status,
                Pronoun: user.Pronoun
            };

            if (!dto.OrganizationID) {
                dto.OrganizationID = -1;
            }

            if (!dto.UserID) {
                dto.UserID = -1;
            }

            if (!dto.EmailAddress) {
                dto.EmailAddress = '';
            }

            if (!dto.FirstName) {
                dto.FirstName = '';
            }

            if (!dto.PreferredName) {
                dto.PreferredName = '';
            }

            if (!dto.MiddleName) {
                dto.MiddleName = '';
            }

            if (!dto.LastName) {
                dto.LastName = '';
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            if (!dto.PhoneNumber) {
                dto.PhoneNumber = '';
            }

            if (!dto.Role) {
                dto.Role = '';
            }

            if (!dto.Status) {
                dto.Status = '';
            }

            if (!dto.Pronoun) {
                dto.Pronoun = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/User/Delete',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        updatePassword(user) {
            let dto = {
                CurrentPassword: user.CurrentPassword,
                NewPassword: user.NewPassword,
                NewPasswordConfirm: user.NewPasswordConfirm,
            };

            if (!dto.CurrentPassword) {
                dto.CurrentPassword = '';
            }

            if (!dto.NewPassword) {
                dto.NewPassword = '';
            }

            if (!dto.NewPasswordConfirm) {
                dto.NewPasswordConfirm = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Auth/UpdatePassword',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        getUserProfile(hideLoader, hideErrorMessage) {
            return DMUtils.http(
                {
                    method: 'GET',
                    url: '/User/GetProfile',
                },
                hideLoader,
                hideErrorMessage
            );
        },
        verifyUserEmailAddress(user) {
            let dto = {
                VerificationCode: user.VerificationCode,
            };

            if (!dto.VerificationCode) {
                dto.VerificationCode = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/User/VerifyEmail',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        sendEmailVerificationCode() {
            return DMUtils.http({
                method: 'GET',
                url: '/User/SendEmailVerificationCode ',
            }).then((response) => {
                return response;
            });
        },
        getUserPronouns() {
            return DMUtils.http({
                method: 'GET',
                url: '/User/GetUserPronouns',
            }).then((response) => {
                return response;
            });
        },
        getUserAccountStatusCodes() {
            return DMUtils.http({
                method: 'GET',
                url: '/User/GetAccountStatusCodes',
            });
        },
        getAllUsersForNotificationBroadcast(data) {
            return DMUtils.http({
                method: 'POST',
                url: '/User/GetAllForNotificationBroadcast',
                data: data
            });
        },

        // #endregion Users

        // #region Notification

        updateNotificationSettings(notification) {
            let dto = {
                EmailNotifications: notification.EmailNotifications,
                SmsNotifications: notification.SmsNotifications,
                PushNotifications: notification.PushNotifications,
            };

            if (dto.EmailNotifications === undefined) {
                dto.EmailNotifications = true;
            }

            if (dto.SmsNotifications === undefined) {
                dto.SmsNotifications = true;
            }

            if (dto.PushNotifications === undefined) {
                dto.PushNotifications = true;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Notification/UpdateNotificationSettings',
                data: dto,
            });
        },

        // #endregion Notification

        // #region Caregivers
        getAllCaregivers(paramsDto) {
            if (paramsDto === undefined) {
                paramsDto = {};
            }

            let dto = {
                SearchText: paramsDto.SearchText,
                StartDateRange: paramsDto.StartDateRange,
                EndDateRange: paramsDto.EndDateRange,
                Status: paramsDto.Status,
                State: paramsDto.State,
                ShowDeleted: paramsDto.ShowDeleted,
                RowsPerPage: paramsDto.RowsPerPage,
                PageToLoad: paramsDto.PageToLoad,
                SortByColumn: paramsDto.SortByColumn,
                SortDescending: paramsDto.SortDescending,
            };

            if (!dto.SearchText) {
                dto.SearchText = '';
            }

            if (!dto.StartDateRange || dto.StartDateRange === 'Invalid date') {
                dto.StartDateRange = '';
            }

            if (!dto.EndDateRange || dto.EndDateRange === 'Invalid date') {
                dto.EndDateRange = '';
            }

            if (!dto.Status){
                dto.Status = [];
            }

            if (!dto.State) {
                dto.State = [];
            }

            if (!dto.ShowDeleted) {
                dto.ShowDeleted = false;
            }

            if (!dto.RowsPerPage) {
                dto.RowsPerPage = 1000;
            }

            if (!dto.PageToLoad) {
                dto.PageToLoad = 1;
            }

            if (!dto.SortByColumn) {
                dto.SortByColumn = '';
            }

            if (dto.SortDescending === undefined) {
                dto.SortDescending = true;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Caregiver/GetAll',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        getAllCaregiverNames() {
            return DMUtils.http({
                method: 'GET',
                url: '/Caregiver/GetAllNames',
            }).then((response) => {
                return response;
            });
        },
        getAllCaregiverEditableStatuses() {
            return DMUtils.http({
                method: 'GET',
                url: '/Caregiver/GetEditableCaregiverStatusCodes',
            }).then((response) => {
                return response;
            });
        },
        getAllCaregiverStatuses() {
            return DMUtils.http({
                method: 'GET',
                url: '/Caregiver/GetAllCaregiverStatusCodes',
            }).then((response) => {
                return response;
            });
        },
        getAllIncompleteCaregivers(paramsDto) {
            if (paramsDto === undefined) {
                paramsDto = {};
            }

            let dto = {
                SearchText: paramsDto.SearchText,
                StartDateRange: paramsDto.StartDateRange,
                EndDateRange: paramsDto.EndDateRange,
                RowsPerPage: paramsDto.RowsPerPage,
                PageToLoad: paramsDto.PageToLoad,
                SortByColumn: paramsDto.SortByColumn,
                SortDescending: paramsDto.SortDescending,
            };

            if (!dto.SearchText) {
                dto.SearchText = '';
            }

            if (!dto.StartDateRange || dto.StartDateRange === 'Invalid date') {
                dto.StartDateRange = '';
            }

            if (!dto.EndDateRange || dto.EndDateRange === 'Invalid date') {
                dto.EndDateRange = '';
            }

            if (!dto.RowsPerPage) {
                dto.RowsPerPage = 1000;
            }

            if (!dto.PageToLoad) {
                dto.PageToLoad = 1;
            }

            if (!dto.SortByColumn) {
                dto.SortByColumn = '';
            }

            if (dto.SortDescending === undefined) {
                dto.SortDescending = true;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Caregiver/GetIncompleteSignups',
                data: dto
            }).then((response) => {
                return response;
            });
        },
        getAllPagesByUserIDMyShyftAdmin(userID) {
            return DMUtils.http({
                method: 'GET',
                url: '/Caregiver/GetAllPagesByUserIDMyShyftAdmin',
                params: { UserID: userID },
            }).then((response) => {
                return response;
            });
        },
        getCaregiverByIDMyShyftAdmin(userID) {
            return DMUtils.http({
                method: 'GET',
                url: '/Caregiver/GetByIDMyShyftAdmin',
                params: { UserID: userID },
            }).then((response) => {
                return response;
            });
        },
        getCaregiverByIDOrganization(userID) {
            return DMUtils.http({
                method: 'GET',
                url: '/Caregiver/GetByIDOrganization',
                params: { UserID: userID },
            }).then((response) => {
                return response;
            });
        },
        updateCaregiver(caregiver) {
            let dto = {
                FirstName: caregiver.FirstName,
                MiddleName: caregiver.MiddleName,
                PreferredName: caregiver.PreferredName,
                LastName: caregiver.LastName,
                Pronoun: caregiver.Pronoun,
                EmailAddress: caregiver.EmailAddress,
                PhoneNumber: caregiver.PhoneNumber,
                SocialSecurityNumber: caregiver.SocialSecurityNumber,
                CaregiverID: caregiver.CaregiverID,
                State: caregiver.State,
                UserID: caregiver.UserID,
                ReferredBy: caregiver.ReferredBy,
                DriversLicenseNumber: caregiver.DriversLicenseNumber,
                DriversLicenseState: caregiver.DriversLicenseState,
                AddressLine1: caregiver.AddressLine1,
                AddressLine2: caregiver.AddressLine2,
                Zipcode: caregiver.Zipcode,
                City: caregiver.City,
                BirthDate: caregiver.BirthDate,
                AboutYou: caregiver.AboutYou,
                Education: caregiver.Education,
                DriversLicenseImageFileName: caregiver.DriversLicenseImageFileName,
                DriversLicenseImageBase64: caregiver.DriversLicenseImageBase64,
                ProfileImageFileName: caregiver.ProfileImageFileName,
                ProfileImageBase64: caregiver.ProfileImageBase64,
                SignatureImageBase64: caregiver.SignatureImageBase64,
                SignatureImageFileName: caregiver.SignatureImageFileName,
                LiabilityInsuranceInsurer: caregiver.LiabilityInsuranceInsurer,
                LiabilityInsuranceNumber: caregiver.LiabilityInsuranceNumber,
                LiabilityInsuranceName: caregiver.LiabilityInsuranceName,
                RowVersion: caregiver.RowVersion,
                Status: caregiver.Status,
                EmergencyContactFirstName: caregiver.EmergencyContactFirstName,
                EmergencyContactLastName: caregiver.EmergencyContactLastName,
                EmergencyContactEmailAddress: caregiver.EmergencyContactEmailAddress,
                EmergencyContactPhoneNumber: caregiver.EmergencyContactPhoneNumber,
            };

            if (!dto.FirstName) {
                dto.FirstName = '';
            }

            if (!dto.MiddleName) {
                dto.MiddleName = '';
            }

            if (!dto.PreferredName) {
                dto.PreferredName = '';
            }

            if (!dto.LastName) {
                dto.LastName = '';
            }

            if (!dto.Pronoun) {
                dto.Pronoun = '';
            }

            if (!dto.EmailAddress) {
                dto.EmailAddress = '';
            }

            if (!dto.PhoneNumber) {
                dto.PhoneNumber = '';
            }

            if (!dto.SocialSecurityNumber) {
                dto.SocialSecurityNumber = '';
            }

            if (!dto.CaregiverID) {
                dto.CaregiverID = -1;
            }

            if (!dto.UserID) {
                dto.UserID = -1;
            }

            if (!dto.ReferredBy) {
                dto.ReferredBy = '';
            }

            if (!dto.DriversLicenseNumber) {
                dto.DriversLicenseNumber = '';
            }

            if (!dto.AddressLine1) {
                dto.AddressLine1 = '';
            }

            if (!dto.AddressLine2) {
                dto.AddressLine2 = '';
            }

            if (!dto.State) {
                dto.State = '';
            }

            if (!dto.City) {
                dto.City = '';
            }

            if (!dto.BirthDate) {
                dto.BirthDate = '';
            }

            if (!dto.AboutYou) {
                dto.AboutYou = '';
            }

            if (!dto.Education) {
                dto.Education = '';
            }

            if (!dto.DriversLicenseImageBase64) {
                dto.DriversLicenseImageBase64 = '';
            }

            if (!dto.DriversLicenseImageFileName) {
                dto.DriversLicenseImageFileName = '';
            }

            if (!dto.SignatureImageBase64) {
                dto.SignatureImageBase64 = '';
            }

            if (!dto.SignatureImageFileName) {
                dto.SignatureImageFileName = '';
            }

            if (!dto.ProfileImageFileName) {
                dto.ProfileImageFileName = '';
            }

            if (!dto.ProfileImageBase64) {
                dto.ProfileImageBase64 = '';
            }

            if (!dto.LiabilityInsuranceInsurer) {
                dto.LiabilityInsuranceInsurer = '';
            }

            if (!dto.LiabilityInsuranceNumber) {
                dto.LiabilityInsuranceNumber = '';
            }

            if (!dto.LiabilityInsuranceName) {
                dto.LiabilityInsuranceName = '';
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            if (!dto.Status) {
                dto.Status = '';
            }

            if (!dto.EmergencyContactFirstName) {
                dto.EmergencyContactFirstName = '';
            }

            if (!dto.EmergencyContactLastName) {
                dto.EmergencyContactLastName = '';
            }

            if (!dto.EmergencyContactEmailAddress) {
                dto.EmergencyContactEmailAddress = '';
            }

            if (!dto.EmergencyContactPhoneNumber) {
                dto.EmergencyContactPhoneNumber = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Caregiver/UpdateMyShyftAdmin',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        deleteCaregiver(caregiver) {
            let dto = {
                CaregiverID: caregiver.CaregiverID,
                UserID: caregiver.UserID,
                RowVersion: caregiver.RowVersion
            };
         
            if (!dto.CaregiverID) {
                dto.CaregiverID = -1;
            }

            if (!dto.UserID) {
                dto.UserID = -1;
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }
           
            return DMUtils.http({
                method: 'POST',
                url: '/Caregiver/Delete',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        recoverCaregiver(caregiver) {
            let dto = {
                CaregiverID: caregiver.CaregiverID,
                UserID: caregiver.UserID,
                RowVersion: caregiver.RowVersion
            };
         
            if (!dto.CaregiverID) {
                dto.CaregiverID = -1;
            }

            if (!dto.UserID) {
                dto.UserID = -1;
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }
           
            return DMUtils.http({
                method: 'POST',
                url: '/Caregiver/Recover',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        sendReferenceEmail(caregiverReference) {
            let dto = {
                ReferenceID: caregiverReference.ReferenceID
            };
         
            if (!dto.ReferenceID) {
                dto.ReferenceID = -1;
            }
           
            return DMUtils.http({
                method: 'POST',
                url: '/Caregiver/SendReferenceEmail',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        validateCaregiverCreateAccount(user) {          
            let dto = {
                UserID: user.UserID,
                FirstName: user.FirstName,
                MiddleName: user.MiddleName,
                LastName: user.LastName,
                PreferredName: user.PreferredName,
                EmailAddress: user.EmailAddress,
                PhoneNumber: user.PhoneNumber,
                Pronoun: user.Pronoun,
                RowVersion: user.RowVersion
            };

            if (!dto.UserID){
                dto.UserID = -1;
            }
    
            if (!dto.FirstName) {
                dto.AboutYou = '';
            }
    
            if (!dto.MiddleName) {
                dto.MiddleName = '';
            }
    
            if (!dto.LastName) {
                dto.LastName = '';
            }
    
            if (!dto.PreferredName) {
                dto.PreferredName = '';
            }
    
            if (!dto.EmailAddress) {
                dto.EmailAddress = '';
            }
    
            if (!dto.PhoneNumber) {
                dto.PhoneNumber = '';
            }
    
            if (!dto.Pronoun) {
                dto.Pronoun = '';
            }

            if (!dto.RowVersion) {
                dto.RowVersion = [];
            }
    
            return DMUtils.http({
                method: 'POST',
                url: '/Caregiver/SaveCaregiverCreateAccountPageMyShyftAdmin',
                data: dto,
            });
        },
        validateCaregiverProofOfIdentity(user) {
            let infoPageDto = {
                ReferredBy: user.ReferredBy,
                SocialSecurityNumber: user.SocialSecurityNumber,
                DriversLicenseNumber: user.DriversLicenseNumber,
                DriversLicenseState: user.DriversLicenseState,
                BirthDate: user.BirthDate,
                ProfileImageBase64: user.ProfileImageBase64,
                ProfileImageFileName: user.ProfileImageFileName,
                DriversLicenseImageBase64: user.DriversLicenseImageBase64,
                DriversLicenseImageFileName: user.DriversLicenseImageFileName,
                ProofsOfOwnership: user.ProofsOfOwnership
            };

            let dto = {
                CaregiversUserID: user.CaregiversUserID,
                InfoPageDto: infoPageDto,
            };          
        
            if (!dto.CaregiversUserID){
                dto.CaregiversUserID = -1;
            }
    
            if (!dto.InfoPageDto.ReferredBy) {
                dto.InfoPageDto.ReferredBy = '';
            }
    
            if (!dto.InfoPageDto.SocialSecurityNumber) {
                dto.InfoPageDto.SocialSecurityNumber = '';
            }
    
            if (!dto.InfoPageDto.DriversLicenseNumber) {
                dto.InfoPageDto.DriversLicenseNumber = '';
            }
    
            if (!dto.InfoPageDto.DriversLicenseState) {
                dto.InfoPageDto.DriversLicenseState = '';
            }
    
            if (!dto.InfoPageDto.BirthDate) {
                dto.InfoPageDto.BirthDate = null;
            }
    
            if (!dto.InfoPageDto.ProfileImageBase64) {
                dto.InfoPageDto.ProfileImageBase64 = '';
            }
    
            if (!dto.InfoPageDto.ProfileImageFileName) {
                dto.InfoPageDto.ProfileImageFileName = '';
            }
    
            if (!dto.InfoPageDto.DriversLicenseImageBase64) {
                dto.InfoPageDto.DriversLicenseImageBase64 = '';
            }
    
            if (!dto.InfoPageDto.DriversLicenseImageFileName) {
                dto.InfoPageDto.DriversLicenseImageFileName = '';
            }
    
            if (!dto.InfoPageDto.ProofsOfOwnership) {
                dto.InfoPageDto.ProofsOfOwnership = [];
            }
    
            return DMUtils.http({
                method: 'POST',
                url: '/Caregiver/SaveCaregiverInfoPageMyShyftAdmin',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
    
        validateCaregiverReferences(user) {
            let referencePageDto = [];   
    
            user.forEach(ref => {
                let dto = {
                    ReferenceFirstName: ref.ReferenceFirstName,
                    ReferenceLastName: ref.ReferenceLastName,
                    Relationship: ref.Relationship,
                    ReferenceEmailAddress: ref.ReferenceEmailAddress,
                    ReferencePhoneNumber: ref.ReferencePhoneNumber
                };                     
    
                if (!dto.ReferenceFirstName) {
                    dto.ReferenceFirstName = '';
                }
    
                if (!dto.ReferenceLastName) {
                    dto.ReferenceLastName = '';
                }
    
                if (!dto.Relationship) {
                    dto.Relationship ='';
                }
    
                if (!dto.ReferenceEmailAddress) {
                    dto.ReferenceEmailAddress = '';
                }
    
                if (!dto.ReferencePhoneNumber) {
                    dto.ReferencePhoneNumber = '';
                }
    
                referencePageDto.push(dto);
            });

            let dto = {
                CaregiversUserID: user.CaregiversUserID,
                ReferencePageDtos: referencePageDto,
            };    

            if (!dto.CaregiversUserID){
                dto.CaregiversUserID = -1;
            }
    
            return DMUtils.http({
                method: 'POST',
                url: '/Caregiver/SaveCaregiverReferencePageMyShyftAdmin',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        validateCaregiverLicenseCategory(user) {
            let licensePageDto = {
                HourlyRate: user.HourlyRate,
                MedicalLicenses: []
            };
    
            if(!licensePageDto.HourlyRate) {
                licensePageDto.HourlyRate = 0;
            }
    
            if (user.MedicalLicenses){
                user.MedicalLicenses.forEach(ref => {
                    let licenseDto = {
                        MedicalLicenseNumber: ref.MedicalLicenseNumber,
                        LicenseExpirationDate: DMUtils.formatDateInput(ref.LicenseExpirationDate),
                        MedicalLicenseID: ref.MedicalLicenseID,
                        MedicalLicenseState: ref.MedicalLicenseState
                    };
        
                    if (!licenseDto.MedicalLicenseNumber) {
                        licenseDto.MedicalLicenseNumber = '';
                    }
        
                    if (!licenseDto.LicenseExpirationDate) {
                        licenseDto.LicenseExpirationDate = null;
                    }
        
                    if(!licenseDto.MedicalLicenseState) {
                        licenseDto.MedicalLicenseState = '';
                    }
        
                    if (!licenseDto.MedicalLicenseID) {
                        licenseDto.MedicalLicenseID = 0;
                    }
        
                    licensePageDto.MedicalLicenses.push(licenseDto);
                });
            }
            
            let dto = {
                CaregiversUserID: user.CaregiversUserID,
                LicensePageDto: licensePageDto
            }

            if (!dto.CaregiversUserID){
                dto.CaregiversUserID = -1;
            }
    
            return DMUtils.http({
                method: 'POST',
                url: '/Caregiver/SaveCaregiverLicenseCategoryPageMyShyftAdmin',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
    
        validateCaregiverExperienceAndInsurance(user) {
            let experiencePageDto = {
                LiabilityInsuranceInsurer: user.LiabilityInsuranceInsurer,
                LiabilityInsuranceNumber: user.LiabilityInsuranceNumber,
                LiabilityInsuranceName: user.LiabilityInsuranceName,
                WorkExperiences: []
            };
    
            if (!experiencePageDto.LiabilityInsuranceInsurer) {
                experiencePageDto.LiabilityInsuranceInsurer = '';
            }
    
            if (!experiencePageDto.LiabilityInsuranceNumber) {
                experiencePageDto.LiabilityInsuranceNumber = '';
            }
    
            if (!experiencePageDto.LiabilityInsuranceName) {
                experiencePageDto.LiabilityInsuranceName = '';
            }
    
            if (user.WorkExperiences){
                user.WorkExperiences.forEach(we => {
                    let workExperienceDto = {
                        WorkExperienceYear: we.WorkExperienceYear,
                        WorkExperienceMonth: we.WorkExperienceMonth,
                        WorkExperienceType: we.WorkExperienceType
                    };
        
                    experiencePageDto.WorkExperiences.push(workExperienceDto);
                });
            }

            let dto = {
                CaregiversUserID: user.CaregiversUserID,
                ExperiencePageDto: experiencePageDto
            }

            if (!dto.CaregiversUserID){
                dto.CaregiversUserID = -1;
            }
    
            return DMUtils.http({
                method: 'POST',
                url: '/Caregiver/SaveCaregiverExperienceAndInsurancePageMyShyftAdmin',
                data: dto,
            });
        },
    
        validateCaregiverAboutYou(user) {
            let aboutPageDto = {
                AboutYou: user.AboutYou,
                Education: user.Education,
                AddressLine1: user.AddressLine1,
                AddressLine2: user.AddressLine2,
                State: user.State,
                Zipcode: user.Zipcode,
                City: user.City
            };
    
            if (!aboutPageDto.AboutYou) {
                aboutPageDto.AboutYou = '';
            }
    
            if (!aboutPageDto.Education) {
                aboutPageDto.Education = '';
            }
    
            if (!aboutPageDto.AddressLine1) {
                aboutPageDto.AddressLine1 = '';
            }
    
            if (!aboutPageDto.AddressLine2) {
                aboutPageDto.AddressLine2 = '';
            }
    
            if (!aboutPageDto.State) {
                aboutPageDto.State = '';
            }
    
            if (!aboutPageDto.Zipcode) {
                aboutPageDto.Zipcode = '';
            }
    
            if (!aboutPageDto.City) {
                aboutPageDto.City = '';
            }

            let dto = {
                CaregiversUserID: user.CaregiversUserID,
                AboutPageDto: aboutPageDto
            }
    
            return DMUtils.http({
                method: 'POST',
                url: '/Caregiver/SaveCaregiverAboutYouPageMyShyftAdmin',
                data: dto,
            });
        },

        // #endregion Caregivers

        // #region CaregiverReferences

        getAllReferencesForCaregiver(caregiverID) {
            return DMUtils.http({
                method: 'GET',
                url: '/CaregiverReference/GetAll',
                params: { CaregiverID: caregiverID },
            }).then((response) => {
                return response;
            });
        },
        getAllReferencesRelationshipTypes() {
            return DMUtils.http({
                method: 'GET',
                url: '/CaregiverReference/GetAllCaregiverRelationshipCodes',
            }).then((response) => {
                return response;
            });
        },
        getReferenceByID(referenceID) {
            return DMUtils.http({
                method: 'GET',
                url: '/CaregiverReference/GetByID',
                params: { ReferenceID: referenceID },
            }).then((response) => {
                return response;
            });
        },
        updateReference(caregiverReference) {
            let dto = {
                CaregiverID: caregiverReference.CaregiverID,
                ReferenceFirstName: caregiverReference.ReferenceFirstName,
                ReferenceLastName: caregiverReference.ReferenceLastName,
                Relationship: caregiverReference.Relationship,
                ReferenceEmailAddress: caregiverReference.ReferenceEmailAddress,
                ReferencePhoneNumber: caregiverReference.ReferencePhoneNumber,
                ReferenceID: caregiverReference.ReferenceID,
                ReferenceNotes: caregiverReference.ReferenceNotes,
                RowVersion: caregiverReference.RowVersion,
            };

            if (!dto.CaregiverID) {
                dto.CaregiverID = -1;
            }

            if (!dto.ReferenceFirstName) {
                dto.ReferenceFirstName = '';
            }

            if (!dto.ReferenceLastName) {
                dto.ReferenceLastName = '';
            }

            if (!dto.Relationship) {
                dto.Relationship = '';
            }

            if (!dto.ReferenceEmailAddress) {
                dto.ReferenceEmailAddress = '';
            }

            if (!dto.ReferencePhoneNumber) {
                dto.ReferencePhoneNumber = '';
            }

            if (!dto.ReferenceID) {
                dto.ReferenceID = -1;
            }

            if (!dto.ReferenceNotes) {
                dto.ReferenceNotes = '';
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CaregiverReference/Update',
                data: dto,
            }).then((response) => {
                return response;
            });
        },

        createReference(caregiverReference) {
            let dto = {
                CaregiverID: caregiverReference.CaregiverID,
                ReferenceFirstName: caregiverReference.ReferenceFirstName,
                ReferenceLastName: caregiverReference.ReferenceLastName,
                Relationship: caregiverReference.Relationship,
                ReferenceEmailAddress: caregiverReference.ReferenceEmailAddress,
                ReferencePhoneNumber: caregiverReference.ReferencePhoneNumber,
                ReferenceNotes: caregiverReference.ReferenceNotes,
            };

            if (!dto.CaregiverID) {
                dto.CaregiverID = -1;
            }

            if (!dto.ReferenceFirstName) {
                dto.ReferenceFirstName = '';
            }

            if (!dto.ReferenceLastName) {
                dto.ReferenceLastName = '';
            }

            if (!dto.Relationship) {
                dto.Relationship = '';
            }

            if (!dto.ReferenceEmailAddress) {
                dto.ReferenceEmailAddress = '';
            }

            if (!dto.ReferencePhoneNumber) {
                dto.ReferencePhoneNumber = '';
            }

            if (!dto.ReferenceNotes) {
                dto.ReferenceNotes = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CaregiverReference/Create',
                data: dto,
            }).then((response) => {
                return response;
            });
        },

        // #endregion CaregiverReferences

        // #region CaregiverWorkExperiences

        getAllCaregiverWorkExperienceTypes() {
            return DMUtils.http({
                method: 'GET',
                url: '/Caregiver/GetWorkExperienceTypes',
            }).then((response) => {
                return response;
            });
        },

        updateCaregiverWorkExperience(caregiverWorkExperience) {
            let dto = {
                CaregiverWorkExperienceID: caregiverWorkExperience.CaregiverWorkExperienceID,
                WorkExperienceType: caregiverWorkExperience.WorkExperienceType,
                WorkExperienceYear: caregiverWorkExperience.WorkExperienceYear,
                WorkExperienceMonth: caregiverWorkExperience.WorkExperienceMonth,
            };

            if (!dto.CaregiverWorkExperienceID) {
                dto.CaregiverWorkExperienceID = -1;
            }

            if (!dto.WorkExperienceType) {
                dto.WorkExperienceType = '';
            }

            if (!dto.WorkExperienceYear) {
                dto.WorkExperienceYear = 0;
            }

            if (!dto.WorkExperienceMonth) {
                dto.WorkExperienceMonth = 0;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CaregiverWorkExperience/Update',
                data: dto,
            }).then((response) => {
                return response;
            });
        },

        createCaregiverWorkExperience(caregiverWorkExperience) {
            let dto = {
                CaregiverID: caregiverWorkExperience.CaregiverID,
                WorkExperienceType: caregiverWorkExperience.WorkExperienceType,
                WorkExperienceYear: caregiverWorkExperience.WorkExperienceYear,
                WorkExperienceMonth: caregiverWorkExperience.WorkExperienceMonth,
            };

            if (!dto.CaregiverID) {
                dto.CaregiverID = -1;
            }

            if (!dto.WorkExperienceType) {
                dto.WorkExperienceType = '';
            }

            if (!dto.WorkExperienceYear) {
                dto.WorkExperienceYear = 0;
            }

            if (!dto.WorkExperienceMonth) {
                dto.WorkExperienceMonth = 0;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CaregiverWorkExperience/Create',
                data: dto,
            }).then((response) => {
                return response;
            });
        },

        // #endregion CaregiverWorkExperiences

        // #region CaregiverRatings

        getAllRatingsForCaregiver(caregiverID) {
            return DMUtils.http({
                method: 'GET',
                url: '/CaregiverRating/GetAllForCaregiver',
                params: { CaregiverID: caregiverID },
            }).then((response) => {
                return response;
            });
        },
        getAllRatingsForCaregiverMyShyftAdmin(caregiverID) {
            return DMUtils.http({
                method: 'GET',
                url: '/CaregiverRating/GetAllForCaregiverMyShyftAdmin',
                params: { CaregiverID: caregiverID },
            }).then((response) => {
                return response;
            });
        },
        getRatingByShiftID(shiftID) {
            return DMUtils.http({
                method: 'GET',
                url: '/CaregiverRating/GetByShiftID',
                params: { ShiftID: shiftID },
            }).then((response) => {
                return response;
            });
        },
        getRatingByID(ratingID) {
            return DMUtils.http({
                method: 'GET',
                url: '/CaregiverRating/GetByID',
                params: { CaregiverRatingID: ratingID },
            }).then((response) => {
                return response;
            });
        },
        getRatingByIDMyShyftAdmin(ratingID) {
            return DMUtils.http({
                method: 'GET',
                url: '/CaregiverRating/GetByIDMyShyftAdmin',
                params: { CaregiverRatingID: ratingID },
            }).then((response) => {
                return response;
            });
        },
        createCaregiverRating(caregiverRating) {
            let dto = {
                ShiftID: caregiverRating.ShiftID,
                RatingComment: caregiverRating.RatingComment,
                Rating: caregiverRating.Rating,
            };

            if (!dto.ShiftID) {
                dto.ShiftID = -1;
            }

            if (!dto.RatingComment) {
                dto.RatingComment = '';
            }

            if (!dto.Rating) {
                dto.Rating = -1;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CaregiverRating/Create',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        updateCaregiverRating(caregiverRating) {
            let dto = {
                CaregiverRatingID: caregiverRating.CaregiverRatingID,
                ShiftID: caregiverRating.ShiftID,
                RatingComment: caregiverRating.RatingComment,
                Rating: caregiverRating.Rating,
                RowVersion: caregiverRating.RowVersion,
            };

            if (!dto.CaregiverRatingID) {
                dto.CaregiverRatingID = -1;
            }

            if (!dto.ShiftID) {
                dto.ShiftID = -1;
            }

            if (!dto.RatingComment) {
                dto.RatingComment = '';
            }

            if (!dto.Rating) {
                dto.Rating = -1;
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CaregiverRating/Update',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        deleteCaregiverRating(caregiverRating) {
            let dto = {
                CaregiverRatingID: caregiverRating.CaregiverRatingID,
                RowVersion: caregiverRating.RowVersion,
            };

            if (!dto.CaregiverRatingID) {
                dto.CaregiverRatingID = -1;
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CaregiverRating/Delete',
                data: dto,
            }).then((response) => {
                return response;
            });
        },

        // #endregion CaregiverRatings

        // #region Contact Us

        sendContactUsForm(user) {
            let dto = {
                Name: user.Name,
                PhoneNumber: user.PhoneNumber,
                EmailAddress: user.EmailAddress,
                Message: user.Message,
            };

            if (!dto.Name) {
                dto.Name = '';
            }

            if (!dto.PhoneNumber) {
                dto.PhoneNumber = '';
            }

            if (!dto.EmailAddress) {
                dto.EmailAddress = '';
            }

            if (!dto.Message) {
                dto.Message = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/ContactUs/SubmitForm',
                data: dto,
            }).then((response) => {
                return response;
            });
        },

        // #endregion Contact Us

        // #region ShiftApplicants

        getShiftApplicantByID(shiftApplicantID) {
            return DMUtils.http({
                method: 'GET',
                url: '/ShiftApplicant/GetByID',
                params: { ShiftApplicantID: shiftApplicantID },
            }).then((response) => {
                return response;
            });
        },
        getAllShiftApplicants(shiftID, organizationID) {
            return DMUtils.http({
                method: 'GET',
                url: '/ShiftApplicant/GetAllForShift',
                params: { ShiftID: shiftID, OrganizationID: organizationID },
            }).then((response) => {
                return response;
            });
        },
        assignShiftApplicant(applicant) {
            let dto = {
                ShiftApplicantID: applicant.ShiftApplicantID,
                RowVersion: applicant.RowVersion,
            };

            if (!dto.ShiftApplicantID) {
                dto.ShiftApplicantID = -1;
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/ShiftApplicant/OrganizationAssign',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        unassignShiftApplicant(applicant) {
            let dto = {
                ShiftApplicantID: applicant.ShiftApplicantID,
                RowVersion: applicant.RowVersion,
            };

            if (!dto.ShiftApplicantID) {
                dto.ShiftApplicantID = -1;
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/ShiftApplicant/OrganizationUnassign',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        unconfirmShiftApplicant(applicant) {
            let dto = {
                ShiftApplicantID: applicant.ShiftApplicantID,
                RowVersion: applicant.RowVersion,
            };

            if (!dto.ShiftApplicantID) {
                dto.ShiftApplicantID = -1;
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/ShiftApplicant/OrganizationUnconfirm',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        declineShiftApplicant(applicant) {
            let dto = {
                ShiftApplicantID: applicant.ShiftApplicantID,
                DeclineMessage: applicant.DeclineMessage,
                RowVersion: applicant.RowVersion,
            };

            if (!dto.ShiftApplicantID) {
                dto.ShiftApplicantID = -1;
            }

            if (!dto.DeclineMessage) {
                dto.DeclineMessage = '';
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/ShiftApplicant/OrganizationDecline',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        getFutureShiftsCaregiverConfirmed(caregiverID) {
            return DMUtils.http({
                method: 'GET',
                url: '/ShiftApplicant/GetFutureShiftsCaregiverConfirmed',
                params: { CaregiverID: caregiverID },
            }).then((response) => {
                return response;
            });
        },
        // #endregion ShiftApplicants

        // #region Shifts

        getShiftByIDMyShyftAdmin(shiftID) {
            return DMUtils.http({
                method: 'GET',
                url: '/Shift/GetByIDMyShyftAdmin',
                params: { ShiftID: shiftID },
            }).then((response) => {
                return response;
            });
        },
        getShiftByIDOrganization(shiftID, hideLoader) {
            return DMUtils.http(
                {
                    method: 'GET',
                    url: '/Shift/GetByIDOrganization',
                    params: { ShiftID: shiftID },
                },
                hideLoader
            ).then((response) => {
                return response;
            });
        },
        getOrganizationHistoryShifts(paramsDto) {
            if (paramsDto === undefined) {
                paramsDto = {};
            }

            let dto = {
                OrganizationLocationID: paramsDto.OrganizationLocationID,
                StartDateRange: paramsDto.StartDateRange,
                EndDateRange: paramsDto.EndDateRange,
                RowsPerPage: paramsDto.RowsPerPage,
                PageToLoad: paramsDto.PageToLoad,
                SortByColumn: paramsDto.SortByColumn,
                SortDescending: paramsDto.SortDescending,
            };

            if (!dto.OrganizationLocationID) {
                dto.OrganizationLocationID = -1;
            }

            if (!dto.StartDateRange || dto.StartDateRange === 'Invalid date') {
                dto.StartDateRange = '';
            }

            if (!dto.EndDateRange || dto.EndDateRange === 'Invalid date') {
                dto.EndDateRange = '';
            }

            if (!dto.RowsPerPage) {
                dto.RowsPerPage = 1000;
            }

            if (!dto.PageToLoad) {
                dto.PageToLoad = 1;
            }

            if (!dto.SortByColumn) {
                dto.SortByColumn = '';
            }

            if (dto.SortDescending === undefined) {
                dto.SortDescending = true;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Shift/GetOrganizationHistory',
                params: { ShowDeleted: paramsDto.ShowDeleted },
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        getOrganizationPendingShifts(paramsDto) {
            if (paramsDto === undefined) {
                paramsDto = {};
            }

            let dto = {
                OrganizationLocationID: paramsDto.OrganizationLocationID,
                StartDateRange: paramsDto.StartDateRange,
                EndDateRange: paramsDto.EndDateRange,
                RowsPerPage: paramsDto.RowsPerPage,
                PageToLoad: paramsDto.PageToLoad,
                SortByColumn: paramsDto.SortByColumn,
                SortDescending: paramsDto.SortDescending,
            };

            if (!dto.OrganizationLocationID) {
                dto.OrganizationLocationID = -1;
            }

            if (!dto.StartDateRange || dto.StartDateRange === 'Invalid date') {
                dto.StartDateRange = '';
            }

            if (!dto.EndDateRange || dto.EndDateRange === 'Invalid date') {
                dto.EndDateRange = '';
            }

            if (!dto.RowsPerPage) {
                dto.RowsPerPage = 1000;
            }

            if (!dto.PageToLoad) {
                dto.PageToLoad = 1;
            }

            if (!dto.SortByColumn) {
                dto.SortByColumn = '';
            }

            if (dto.SortDescending === undefined) {
                dto.SortDescending = true;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Shift/GetOrganizationPending',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        getOrganizationConfirmedShifts(paramsDto) {
            if (paramsDto === undefined) {
                paramsDto = {};
            }

            let dto = {
                OrganizationLocationID: paramsDto.OrganizationLocationID,
                StartDateRange: paramsDto.StartDateRange,
                EndDateRange: paramsDto.EndDateRange,
                RowsPerPage: paramsDto.RowsPerPage,
                PageToLoad: paramsDto.PageToLoad,
                SortByColumn: paramsDto.SortByColumn,
                SortDescending: paramsDto.SortDescending,
            };

            if (!dto.OrganizationLocationID) {
                dto.OrganizationLocationID = -1;
            }

            if (!dto.StartDateRange || dto.StartDateRange === 'Invalid date') {
                dto.StartDateRange = '';
            }

            if (!dto.EndDateRange || dto.EndDateRange === 'Invalid date') {
                dto.EndDateRange = '';
            }

            if (!dto.RowsPerPage) {
                dto.RowsPerPage = 1000;
            }

            if (!dto.PageToLoad) {
                dto.PageToLoad = 1;
            }

            if (!dto.SortByColumn) {
                dto.SortByColumn = '';
            }

            if (dto.SortDescending === undefined) {
                dto.SortDescending = true;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Shift/GetOrganizationConfirmed',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        getAllShiftsMyShyftAdmin(paramsDto) {
            if (paramsDto === undefined) {
                paramsDto = {};
            }

            let dto = {
                OrganizationID: paramsDto.OrganizationID,
                ShiftStatus: paramsDto.ShiftStatus,
                SearchText: paramsDto.SearchText,
                StartDateRange: paramsDto.StartDateRange,
                EndDateRange: paramsDto.EndDateRange,
                State: paramsDto.State,
                ShowDeleted: paramsDto.ShowDeleted,
                RowsPerPage: paramsDto.RowsPerPage,
                PageToLoad: paramsDto.PageToLoad,
                SortByColumn: paramsDto.SortByColumn,
                SortDescending: paramsDto.SortDescending,
            };

            if (!dto.OrganizationID) {
                dto.OrganizationID = -1;
            }

            if (!dto.ShiftStatus) {
                dto.ShiftStatus = [];
            }

            if (!dto.SearchText) {
                dto.SearchText = '';
            }

            if (!dto.StartDateRange || dto.StartDateRange === 'Invalid date') {
                dto.StartDateRange = '';
            }

            if (!dto.EndDateRange || dto.EndDateRange === 'Invalid date') {
                dto.EndDateRange = '';
            }

            if (!dto.State) {
                dto.State = [];
            }

            if (!dto.ShowDeleted) {
                dto.ShowDeleted = false;
            }

            if (!dto.RowsPerPage) {
                dto.RowsPerPage = 1000;
            }

            if (!dto.PageToLoad) {
                dto.PageToLoad = 1;
            }

            if (!dto.SortByColumn) {
                dto.SortByColumn = '';
            }

            if (dto.SortDescending === undefined) {
                dto.SortDescending = true;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Shift/GetAllMyShyftAdmin',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        getOrganizationCalendar(paramsDto) {
            if (paramsDto === undefined) {
                paramsDto = {};
            }

            let startDateRange = paramsDto.StartDateRange;
            let endDateRange = paramsDto.EndDateRange;

            if (!startDateRange || startDateRange === 'Invalid date') {
                startDateRange = '';
            }

            if (!endDateRange || endDateRange === 'Invalid date') {
                endDateRange = '';
            }          

            return DMUtils.http({
                method: 'POST',
                url: '/Shift/GetOrganizationCalendar',
                params: { StartDateRange: startDateRange, EndDateRange: endDateRange },
            }).then((response) => {
                return response;
            });
        },
        createShift(shift) {
            let dto = {
                OrganizationLocationID: shift.OrganizationLocationID,
                MedicalLicenses: shift.MedicalLicenses,
                TypesOfCare: shift.TypesOfCare,
                ShiftInstructions: shift.ShiftInstructions,
                ArrivalContactName: shift.ArrivalContactName,
                ArrivalContactPhoneNumber: shift.ArrivalContactPhoneNumber,
                ShiftStartUtc: shift.ShiftStartUtc,
                ShiftEndUtc: shift.ShiftEndUtc,
                IsMultiLocation: shift.IsMultiLocation
            };

            if (!dto.OrganizationLocationID) {
                dto.OrganizationLocationID = -1;
            }

            if (!dto.MedicalLicenses) {
                dto.MedicalLicenses = [];
            }

            if (!dto.TypesOfCare) {
                dto.TypesOfCare = [];
            }

            if (!dto.ShiftInstructions) {
                dto.ShiftInstructions = '';
            }

            if (!dto.ArrivalContactName) {
                dto.ArrivalContactName = '';
            }

            if (!dto.ArrivalContactPhoneNumber) {
                dto.ArrivalContactPhoneNumber = '';
            }

            if (!dto.ShiftStartUtc) {
                dto.ShiftStartUtc = '';
            }

            if (!dto.ShiftEndUtc) {
                dto.ShiftEndUtc = '';
            }
            
            if (!dto.IsMultiLocation) {
                dto.IsMultiLocation = false;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Shift/Create',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        createShiftMyShyftAdmin(shift) {
            let dto = {
                OrganizationID: shift.OrganizationID,
                OrganizationLocationID: shift.OrganizationLocationID,
                MedicalLicenses: shift.MedicalLicenses,
                TypesOfCare: shift.TypesOfCare,
                ConfirmedCaregiverID: shift.ConfirmedCaregiverID,
                ConfirmedHourlyRate: shift.ConfirmedHourlyRate,
                ShiftStartUtc: shift.ShiftStartUtc,
                ShiftEndUtc: shift.ShiftEndUtc,
                ShiftClockInUtc: shift.ShiftClockInUtc,
                ShiftClockOutUtc: shift.ShiftClockOutUtc,
            };

            if (!dto.OrganizationID) {
                dto.OrganizationID = -1;
            }

            if (!dto.OrganizationLocationID) {
                dto.OrganizationLocationID = -1;
            }

            if (!dto.MedicalLicenses) {
                dto.MedicalLicenses = [];
            }

            if (!dto.TypesOfCare) {
                dto.TypesOfCare = [];
            }

            if (!dto.ConfirmedCaregiverID) {
                dto.ConfirmedCaregiverID = -1;
            }

            if (!dto.ConfirmedHourlyRate) {
                dto.ConfirmedHourlyRate = 0;
            }

            if (!dto.ShiftStartUtc) {
                dto.ShiftStartUtc = '';
            }

            if (!dto.ShiftEndUtc) {
                dto.ShiftEndUtc = '';
            }

            if (!dto.ShiftClockInUtc) {
                dto.ShiftClockInUtc = '';
            }

            if (!dto.ShiftClockOutUtc) {
                dto.ShiftClockOutUtc = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Shift/CreateMyShyftAdmin',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        deleteShift(shift) {
            let dto = {
                ShiftID: shift.ShiftID,
                DeletedType: shift.DeletedType,
                RowVersion: shift.RowVersion,
            };

            if (!dto.ShiftID) {
                dto.ShiftID = -1;
            }

            if (!dto.DeletedType) {
                dto.DeletedType = '';
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Shift/Delete',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        getAllDeletedTypeCodes() {
            return DMUtils.http({
                method: 'GET',
                url: '/Shift/GetAllDeletedTypeCodes',
            }).then((response) => {
                return response;
            });
        },
        getHourlyRateMarkup() {
            return DMUtils.http({
                method: 'GET',
                url: '/Shift/GetHourlyRateMarkupPercentage',
            }).then((response) => {
                return response;
            });
        },
        getAllForInvoiceIDMyShyftAdmin(invoiceID) {
            return DMUtils.http({
                method: 'GET',
                url: '/Shift/GetAllForInvoiceIDMyShyftAdmin',
                params: { InvoiceID: invoiceID },
            }).then((response) => {
                return response;
            });
        },
        getAllForInvoiceIDOrganization(invoiceID) {
            return DMUtils.http({
                method: 'GET',
                url: '/Shift/GetAllForInvoiceIDOrganization',
                params: { InvoiceID: invoiceID },
            }).then((response) => {
                return response;
            });
        },
        getNotInvoicedMyShyftAdmin(paramsDto) {
            if (paramsDto === undefined) {
                paramsDto = {};
            }

            let dto = {
                OrganizationID: paramsDto.OrganizationID,
                ShiftStatus: paramsDto.ShiftStatus,
                SearchText: paramsDto.SearchText,
                StartDateRange: paramsDto.StartDateRange,
                EndDateRange: paramsDto.EndDateRange,
                State: paramsDto.State,
                ShowDeleted: paramsDto.ShowDeleted,
                RowsPerPage: paramsDto.RowsPerPage,
                PageToLoad: paramsDto.PageToLoad,
                SortByColumn: paramsDto.SortByColumn,
                SortDescending: paramsDto.SortDescending,
            };

            if (!dto.OrganizationID) {
                dto.OrganizationID = -1;
            }

            if (!dto.ShiftStatus) {
                dto.ShiftStatus = [];
            }

            if (!dto.SearchText) {
                dto.SearchText = '';
            }

            if (!dto.StartDateRange || dto.StartDateRange === 'Invalid date') {
                dto.StartDateRange = '';
            }

            if (!dto.EndDateRange || dto.EndDateRange === 'Invalid date') {
                dto.EndDateRange = '';
            }

            if (!dto.State) {
                dto.State = [];
            }
            if (!dto.ShowDeleted) {
                dto.ShowDeleted = false;
            }

            if (!dto.RowsPerPage) {
                dto.RowsPerPage = 1000;
            }

            if (!dto.PageToLoad) {
                dto.PageToLoad = 1;
            }

            if (!dto.SortByColumn) {
                dto.SortByColumn = '';
            }

            if (dto.SortDescending === undefined) {
                dto.SortDescending = true;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Shift/GetNotInvoicedMyShyftAdmin',
                data: dto
            }).then((response) => {
                return response;
            });
        },
        getNotInvoicedOrganization() {
            return DMUtils.http({
                method: 'GET',
                url: '/Shift/GetNotInvoicedOrganization',
            }).then((response) => {
                return response;
            });
        },

        createShiftMultipleDays(shiftsDto) {
            let dto = {
                ShiftID: shiftsDto.ShiftID,
                ShiftDates: shiftsDto.ShiftDates
            };

            if(!dto.ShiftID) {
                dto.ShiftID = -1;
            }

            if(!dto.ShiftDates) {
                dto.ShiftDates = [];
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Shift/CreateMultiple',
                data: dto
            });
        },

        // #endregion Shifts

        // #region ShiftHistory

        getShiftHistoryByID(shiftHistoryID) {
            return DMUtils.http({
                method: 'GET',
                url: '/ShiftHistory/GetByID',
                params: { ShiftHistoryID: shiftHistoryID },
            }).then((response) => {
                return response;
            });
        },

        getAllShiftHistoryForShift(shiftID) {
            return DMUtils.http({
                method: 'GET',
                url: '/ShiftHistory/GetAllForShift',
                params: { ShiftID: shiftID },
            }).then((response) => {
                return response;
            });
        },

        // #endregion ShiftHistory

        // #region ShiftClocks

        getShiftClockByID(shiftClockID) {
            return DMUtils.http({
                method: 'GET',
                url: '/ShiftClock/GetByID',
                params: { ShiftClockID: shiftClockID },
            }).then((response) => {
                return response;
            });
        },
        getAllShiftClocksForShift(shiftID) {
            return DMUtils.http({
                method: 'GET',
                url: '/ShiftClock/GetAllForShift',
                params: { ShiftID: shiftID },
            }).then((response) => {
                return response;
            });
        },
        createShiftClock(shiftClock) {
            let dto = {
                ShiftID: shiftClock.ShiftID,
                ShiftClockOutUtc: shiftClock.ShiftClockOutUtc,
                ShiftClockInUtc: shiftClock.ShiftClockInUtc,
            };

            if (!dto.ShiftID) {
                dto.ShiftID = -1;
            }

            if (!dto.ShiftClockOutUtc) {
                dto.ShiftClockOutUtc = '';
            }

            if (!dto.ShiftClockInUtc) {
                dto.ShiftClockInUtc = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/ShiftClock/Create',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        updateShiftClock(shiftClock) {
            let dto = {
                ShiftClockID: shiftClock.ShiftClockID,
                ShiftClockOutUtc: shiftClock.ShiftClockOutUtc,
                ShiftClockInUtc: shiftClock.ShiftClockInUtc,
                RowVersion: shiftClock.RowVersion,
            };

            if (!dto.ShiftClockID) {
                dto.ShiftClockID = -1;
            }

            if (!dto.ShiftClockOutUtc) {
                dto.ShiftClockOutUtc = '';
            }

            if (!dto.ShiftClockInUtc) {
                dto.ShiftClockInUtc = '';
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/ShiftClock/Update',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        deleteShiftClock(shiftClock) {
            let dto = {
                ShiftClockID: shiftClock.ShiftClockID,
                RowVersion: shiftClock.RowVersion,
            };

            if (!dto.ShiftClockID) {
                dto.ShiftClockID = -1;
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/ShiftClock/Delete',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        forceShiftClockOutByID(shiftClock) {
            let dto = {
                ShiftID: shiftClock.ShiftID,
                Rating: shiftClock.Rating,
                RatingComment: shiftClock.RatingComment,
            };

            if (!dto.ShiftID) {
                dto.ShiftID = -1;
            }

            if (!dto.Rating) {
                dto.Rating = 0;
            }

            if (!dto.RatingComment) {
                dto.RatingComment = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/ShiftClock/ForceClockOut',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        forceShiftClockOutMyShyftAdmin(shiftClock) {
            let dto = {
                ShiftID: shiftClock.ShiftID,
                ShiftClockID: shiftClock.ShiftClockID,
                RowVersion: shiftClock.RowVersion,
            };

            if (!dto.ShiftID) {
                dto.ShiftID = -1;
            }

            if (!dto.ShiftClockID) {
                dto.ShiftClockID = -1;
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/ShiftClock/ForceClockOutMyShyftAdmin',
                data: dto,
            }).then((response) => {
                return response;
            });
        },

        // #endregion ShiftClocks

        // #region OrganizationContacts

        getOrganizationContactByID(organizationContactID) {
            return DMUtils.http({
                method: 'GET',
                url: '/OrganizationContact/GetByID',
                params: { OrganizationContactID: organizationContactID },
            }).then((response) => {
                return response;
            });
        },
        getAllOrganizationContactsForOrganization(organizationID) {
            return DMUtils.http({
                method: 'GET',
                url: '/OrganizationContact/GetAllForOrganization',
                params: { OrganizationID: organizationID },
            }).then((response) => {
                return response;
            });
        },
        getAllOrganizationContactStatusTypes() {
            return DMUtils.http({
                method: 'GET',
                url: '/OrganizationContact/GetAllTypes',
            }).then((response) => {
                return response;
            });
        },
        createOrganizationContact(contact) {
            let dto = {
                OrganizationID: contact.OrganizationID,
                Name: contact.Name,
                Title: contact.Title,
                EmailAddress: contact.EmailAddress,
                PhoneNumber: contact.PhoneNumber,
                Status: contact.Status,
                ContactNotes: contact.ContactNotes,
                SendInvoiceEmail: contact.SendInvoiceEmail,
            };

            if (!dto.OrganizationID) {
                dto.OrganizationID = -1;
            }

            if (!dto.Name) {
                dto.Name = '';
            }

            if (!dto.Title) {
                dto.Title = '';
            }

            if (!dto.EmailAddress) {
                dto.EmailAddress = '';
            }

            if (!dto.PhoneNumber) {
                dto.PhoneNumber = '';
            }

            if (!dto.Status) {
                dto.Status = '';
            }

            if (!dto.ContactNotes) {
                dto.ContactNotes = '';
            }

            if (!dto.SendInvoiceEmail) {
                dto.SendInvoiceEmail = false;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/OrganizationContact/Create',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        updateOrganizationContact(contact) {
            let dto = {
                OrganizationContactID: contact.OrganizationContactID,
                Name: contact.Name,
                Title: contact.Title,
                EmailAddress: contact.EmailAddress,
                PhoneNumber: contact.PhoneNumber,
                Status: contact.Status,
                ContactNotes: contact.ContactNotes,
                SendInvoiceEmail: contact.SendInvoiceEmail,
                RowVersion: contact.RowVersion,
            };

            if (!dto.OrganizationContactID) {
                dto.OrganizationContactID = -1;
            }

            if (!dto.OrganizationID) {
                dto.OrganizationID = -1;
            }

            if (!dto.Name) {
                dto.Name = '';
            }

            if (!dto.Title) {
                dto.Title = '';
            }

            if (!dto.EmailAddress) {
                dto.EmailAddress = '';
            }

            if (!dto.PhoneNumber) {
                dto.PhoneNumber = '';
            }

            if (!dto.Status) {
                dto.Status = '';
            }

            if (!dto.ContactNotes) {
                dto.ContactNotes = '';
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            if (!dto.SendInvoiceEmail) {
                dto.SendInvoiceEmail = false;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/OrganizationContact/Update',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        deleteOrganizationContact(contact) {
            let dto = {
                OrganizationContactID: contact.OrganizationContactID,
                Name: contact.Name,
                Title: contact.Title,
                EmailAddress: contact.EmailAddress,
                PhoneNumber: contact.PhoneNumber,
                Status: contact.Status,
                ContactNotes: contact.ContactNotes,
                SendInvoiceEmail: contact.SendInvoiceEmail,
                RowVersion: contact.RowVersion,
            };

            if (!dto.OrganizationContactID) {
                dto.OrganizationContactID = -1;
            }

            if (!dto.OrganizationID) {
                dto.OrganizationID = -1;
            }

            if (!dto.Name) {
                dto.Name = '';
            }

            if (!dto.Title) {
                dto.Title = '';
            }

            if (!dto.EmailAddress) {
                dto.EmailAddress = '';
            }

            if (!dto.PhoneNumber) {
                dto.PhoneNumber = '';
            }

            if (!dto.Status) {
                dto.Status = '';
            }

            if (!dto.ContactNotes) {
                dto.ContactNotes = '';
            }

            if (!dto.SendInvoiceEmail) {
                dto.SendInvoiceEmail = false;
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/OrganizationContact/Delete',
                data: dto,
            }).then((response) => {
                return response;
            });
        },

        // #endregion OrganizationContact

        // #region MedicalLicenses
        getAllMedicalLicensesMyShyftAdmin(state) {
            let params = {
                state: state
            };

            return DMUtils.http({
                method: 'GET',
                url: '/MedicalLicense/GetAllMyShyftAdmin',
                params: params
            }).then((response) => {
                return response;
            });
        },
        getAllMedicalLicensesCaregiver() {
            return DMUtils.http({
                method: 'GET',
                url: '/MedicalLicense/GetAllCaregiver',
            }).then((response) => {
                return response;
            });
        },
        getAllMedicalLicensesOrganization() {
            return DMUtils.http({
                method: 'GET',
                url: '/MedicalLicense/GetAllOrganization',
            }).then((response) => {
                return response;
            });
        },
        getAllMedicalLicensesOrganizationLocation(organizationLocationID) {
            return DMUtils.http({
                method: 'GET',
                url: '/MedicalLicense/GetAllForOrganizationLocation',
                params: { OrganizationLocationID: organizationLocationID },
            }).then((response) => {
                return response;
            });
        },     
        updateMedicalLicense(Contact) {
            let dto = {
                MedicalLicenseID: Contact.MedicalLicenseID,
                MedicalLicenseName: Contact.MedicalLicenseName,
                MedicalLicenseStates: Contact.MedicalLicenseStates,
            };

            if (!dto.MedicalLicenseID) {
                dto.MedicalLicenseID = -1;
            }

            if (!dto.MedicalLicenseName) {
                dto.MedicalLicenseName = '';
            }

            if (!dto.MedicalLicenseStates) {
                dto.MedicalLicenseStates = [];
            }

            return DMUtils.http({
                method: 'POST',
                url: '/MedicalLicense/Update',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        createMedicalLicense(Contact) {
            let dto = {
                MedicalLicenseName: Contact.MedicalLicenseName,
                MedicalLicenseStates: Contact.MedicalLicenseStates,
            };

            if (!dto.MedicalLicenseName) {
                dto.MedicalLicenseName = '';
            }

            if (!dto.MedicalLicenseStates) {
                dto.MedicalLicenseStates = [];
            }

            return DMUtils.http({
                method: 'POST',
                url: '/MedicalLicense/Create',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        deleteMedicalLicense(medicalLicenseID) {
            let dto = {
                MedicalLicenseID: medicalLicenseID,
            };

            if (!dto.MedicalLicenseID) {
                dto.MedicalLicenseID = -1;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/MedicalLicense/Delete',
                data: dto,
            }).then((response) => {
                return response;
            });
        },

        // #endregion MedicalLicenses

        // #region CaregiverMedicalLicenses

        getCaregiverMedicalLicenseByID(caregiverMedicalLicenseID) {
            return DMUtils.http({
                method: 'GET',
                url: '/CaregiverMedicalLicense/GetByID',
                params: { CaregiverMedicalLicenseID: caregiverMedicalLicenseID },
            }).then((response) => {
                return response;
            });
        },
        getAllCaregiverMedicalLicensesForCaregiverMyShyftAdmin(caregiverID) {
            return DMUtils.http({
                method: 'GET',
                url: '/CaregiverMedicalLicense/GetAllForCaregiverMyShyftAdmin',
                params: { CaregiverID: caregiverID },
            }).then((response) => {
                return response;
            });
        },
        getAllCaregiverMedicalLicensesForCaregiverOrganization(caregiverID) {
            return DMUtils.http({
                method: 'GET',
                url: '/CaregiverMedicalLicense/GetAllForCaregiverOrganization',
                params: { CaregiverID: caregiverID },
            }).then((response) => {
                return response;
            });
        },
        updateCaregiverMedicalLicense(caregiverMedicalLicense) {
            let dto = {
                CaregiverMedicalLicenseID: caregiverMedicalLicense.CaregiverMedicalLicenseID,
                MedicalLicenseID: caregiverMedicalLicense.MedicalLicenseID,
                MedicalLicenseNumber: caregiverMedicalLicense.MedicalLicenseNumber,
                LicenseExpirationDate: caregiverMedicalLicense.LicenseExpirationDate,
                MedicalLicenseState: caregiverMedicalLicense.MedicalLicenseState,
                RowVersion: caregiverMedicalLicense.RowVersion,
            };

            if (!dto.CaregiverMedicalLicenseID) {
                dto.CaregiverMedicalLicenseID = -1;
            }

            if (!dto.MedicalLicenseID) {
                dto.MedicalLicenseID = -1;
            }

            if (!dto.MedicalLicenseNumber) {
                dto.MedicalLicenseNumber = '';
            }

            if (!dto.LicenseExpirationDate) {
                dto.LicenseExpirationDate = '';
            }

            if (!dto.MedicalLicenseState) {
                dto.MedicalLicenseState = '';
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CaregiverMedicalLicense/Update',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        createCaregiverMedicalLicenseMyShyftAdmin(caregiverMedicalLicense) {
            let dto = {
                MedicalLicenseID: caregiverMedicalLicense.MedicalLicenseID,
                CaregiverID: caregiverMedicalLicense.CaregiverID,
                MedicalLicenseNumber: caregiverMedicalLicense.MedicalLicenseNumber,
                LicenseExpirationDate: caregiverMedicalLicense.LicenseExpirationDate,
                MedicalLicenseState: caregiverMedicalLicense.MedicalLicenseState,
            };

            if (!dto.MedicalLicenseID) {
                dto.MedicalLicenseID = -1;
            }

            if (!dto.CaregiverID) {
                dto.CaregiverID = -1;
            }

            if (!dto.MedicalLicenseNumber) {
                dto.MedicalLicenseNumber = '';
            }

            if (!dto.LicenseExpirationDate) {
                dto.LicenseExpirationDate = '';
            }

            if (!dto.MedicalLicenseState) {
                dto.MedicalLicenseState = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CaregiverMedicalLicense/CreateMyShyftAdmin',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        approveCaregiverMedicalLicense(caregiverMedicalLicense) {
            let dto = {
                CaregiverMedicalLicenseID: caregiverMedicalLicense.CaregiverMedicalLicenseID,
                RowVersion: caregiverMedicalLicense.RowVersion,
            };

            if (!dto.CaregiverMedicalLicenseID) {
                dto.CaregiverMedicalLicenseID = -1;
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CaregiverMedicalLicense/Approve',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        denyCaregiverMedicalLicense(caregiverMedicalLicense) {
            let dto = {
                CaregiverMedicalLicenseID: caregiverMedicalLicense.CaregiverMedicalLicenseID,
                RowVersion: caregiverMedicalLicense.RowVersion,
            };

            if (!dto.CaregiverMedicalLicenseID) {
                dto.CaregiverMedicalLicenseID = -1;
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CaregiverMedicalLicense/Deny',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        // #endregion CaregiverMedicalLicenses

        // #region CaregiverProofOfOwnership

        getCaregiverProofOfOwnershipByID(caregiverProofOfOwnershipID) {
            return DMUtils.http({
                method: 'GET',
                url: '/CaregiverProofOfOwnership/GetByID',
                params: { CaregiverProofOfOwnershipID: caregiverProofOfOwnershipID },
            }).then((response) => {
                return response;
            });
        },
        getAllCaregiverProofOfOwnershipsMyShyftAdmin(caregiverID) {
            return DMUtils.http({
                method: 'GET',
                url: '/CaregiverProofOfOwnership/GetAllMyShyftAdmin',
                params: { CaregiverID: caregiverID },
            }).then((response) => {
                return response;
            });
        },
        getAllCaregiverProofOfOwnershipsOrganization(caregiverID) {
            return DMUtils.http({
                method: 'GET',
                url: '/CaregiverProofOfOwnership/GetAllOrganization',
                params: { CaregiverID: caregiverID },
            }).then((response) => {
                return response;
            });
        },
        getAllCaregiverProofOfOwnershipTypes() {
            return DMUtils.http({
                method: 'GET',
                url: '/CaregiverProofOfOwnership/GetAllTypes',
            }).then((response) => {
                return response;
            });
        },
        getCaregiverGetAllTypesSignUp() {
            return DMUtils.http({
                method: 'GET',
                url: '/CaregiverProofOfOwnership/GetAllTypesSignUp',
            }).then((response) => {
                return response;
            });
        },
        updateCaregiverProofOfOwnership(caregiverProofOfOwnership) {
            let dto = {
                CaregiverProofOfOwnershipID: caregiverProofOfOwnership.CaregiverProofOfOwnershipID,
                ProofImageBase64: caregiverProofOfOwnership.ProofImageBase64,
                ProofImageFileName: caregiverProofOfOwnership.ProofImageFileName,
                ProofName: caregiverProofOfOwnership.ProofName,
                ProofType: caregiverProofOfOwnership.ProofType,
                ReportDate: caregiverProofOfOwnership.ReportDate,
                RowVersion: caregiverProofOfOwnership.RowVersion,
            };

            if (!dto.CaregiverProofOfOwnershipID) {
                dto.CaregiverProofOfOwnershipID = -1;
            }

            if (!dto.ProofImageBase64) {
                dto.ProofImageBase64 = '';
            }

            if (!dto.ProofImageFileName) {
                dto.ProofImageFileName = '';
            }

            if (!dto.ProofName) {
                dto.ProofName = '';
            }

            if (!dto.ProofType) {
                dto.ProofType = '';
            }

            if (!dto.ReportDate) {
                dto.ReportDate = '';
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CaregiverProofOfOwnership/Update',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        createCaregiverProofOfOwnershipMyShyftAdmin(caregiverProofOfOwnership) {
            let dto = {
                CaregiverID: caregiverProofOfOwnership.CaregiverID,
                ProofImageBase64: caregiverProofOfOwnership.ProofImageBase64,
                ProofImageFileName: caregiverProofOfOwnership.ProofImageFileName,
                ProofName: caregiverProofOfOwnership.ProofName,
                ProofType: caregiverProofOfOwnership.ProofType,
                ReportDate: caregiverProofOfOwnership.ReportDate,
            };

            if (!dto.CaregiverID) {
                dto.CaregiverID = -1;
            }

            if (!dto.ProofImageBase64) {
                dto.ProofImageBase64 = '';
            }

            if (!dto.ProofImageFileName) {
                dto.ProofImageFileName = '';
            }

            if (!dto.ProofName) {
                dto.ProofName = '';
            }

            if (!dto.ProofType) {
                dto.ProofType = '';
            }

            if (!dto.ReportDate) {
                dto.ReportDate = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CaregiverProofOfOwnership/Create',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        approveCaregiverProofOfOwnership(caregiverProofOfOwnership) {
            let dto = {
                CaregiverProofOfOwnershipID: caregiverProofOfOwnership.CaregiverProofOfOwnershipID,
                RowVersion: caregiverProofOfOwnership.RowVersion,
            };

            if (!dto.CaregiverProofOfOwnershipID) {
                dto.CaregiverProofOfOwnershipID = -1;
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CaregiverProofOfOwnership/Approve',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        denyCaregiverProofOfOwnership(caregiverProofOfOwnership) {
            let dto = {
                CaregiverProofOfOwnershipID: caregiverProofOfOwnership.CaregiverProofOfOwnershipID,
                RowVersion: caregiverProofOfOwnership.RowVersion,
            };

            if (!dto.CaregiverProofOfOwnershipID) {
                dto.CaregiverProofOfOwnershipID = -1;
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CaregiverProofOfOwnership/Deny',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        // #endregion CaregiverProofOfOwnership

        // #region TypesOfCare

        getAllTypesOfCare() {
            return DMUtils.http({
                method: 'GET',
                url: '/TypeOfCare/GetAll',
            }).then((response) => {
                return response;
            });
        },
        createTypeOfCare(Contact) {
            let dto = {
                TypeOfCareName: Contact.TypeOfCareName,
            };

            if (!dto.TypeOfCareName) {
                dto.TypeOfCareName = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/TypeOfCare/Create',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        deleteTypeOfCare(typeOfCareID) {
            let dto = {
                TypeOfCareID: typeOfCareID,
            };

            if (!dto.TypeOfCareID) {
                dto.TypeOfCareID = -1;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/TypeOfCare/Delete',
                data: dto,
            }).then((response) => {
                return response;
            });
        },

        // #endregion TypesOfCare

        // #region Invoices

        getAllInvoicesMyShyftAdmin(paramsDto) {
            if (paramsDto === undefined) {
                paramsDto = {};
            }

            let dto = {
                OrganizationID: paramsDto.OrganizationID,
                StartDateRange: paramsDto.StartDateRange,
                EndDateRange: paramsDto.EndDateRange,
                RowsPerPage: paramsDto.RowsPerPage,
                PageToLoad: paramsDto.PageToLoad,
                SortByColumn: paramsDto.SortByColumn,
                SortDescending: paramsDto.SortDescending,
            };

            if (!dto.OrganizationID) {
                dto.OrganizationID = -1;
            }

            if (!dto.StartDateRange || dto.StartDateRange === 'Invalid date') {
                dto.StartDateRange = '';
            }

            if (!dto.EndDateRange || dto.EndDateRange === 'Invalid date') {
                dto.EndDateRange = '';
            }

            if (!dto.RowsPerPage) {
                dto.RowsPerPage = 1000;
            }

            if (!dto.PageToLoad) {
                dto.PageToLoad = 1;
            }

            if (!dto.SortByColumn) {
                dto.SortByColumn = '';
            }

            if (dto.SortDescending === undefined) {
                dto.SortDescending = true;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Invoice/GetAllMyShyftAdmin',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        getAllInvoicesOrganization(paramsDto) {
            if (paramsDto === undefined) {
                paramsDto = {};
            }

            let dto = {
                StartDateRange: paramsDto.StartDateRange,
                EndDateRange: paramsDto.EndDateRange,
                RowsPerPage: paramsDto.RowsPerPage,
                PageToLoad: paramsDto.PageToLoad,
                SortByColumn: paramsDto.SortByColumn,
                SortDescending: paramsDto.SortDescending,
            };

            if (!dto.StartDateRange || dto.StartDateRange === 'Invalid date') {
                dto.StartDateRange = '';
            }

            if (!dto.EndDateRange || dto.EndDateRange === 'Invalid date') {
                dto.EndDateRange = '';
            }

            if (!dto.RowsPerPage) {
                dto.RowsPerPage = 1000;
            }

            if (!dto.PageToLoad) {
                dto.PageToLoad = 1;
            }

            if (!dto.SortByColumn) {
                dto.SortByColumn = '';
            }

            if (dto.SortDescending === undefined) {
                dto.SortDescending = true;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Invoice/GetAllOrganization',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        getAllUnpaidInvoicesOrganization() {
            return DMUtils.http({
                method: 'GET',
                url: '/Invoice/GetAllUnpaidOrganization',
            }).then((response) => {
                return response;
            });
        },
        downloadInvoice(invoiceID) {
            return DMUtils.http({
                method: 'GET',
                url: '/Invoice/DownloadPDF',
                params: { InvoiceID: invoiceID },
                returnsFile: true,
            }).then((response) => {
                return response;
            });
        },
        updatePaymentDetails(paymentDetails) {
            let dto = {
                InvoiceID: paymentDetails.InvoiceID,
                OrganizationID: paymentDetails.OrganizationID,
                PaymentNumber: paymentDetails.PaymentNumber,
                PaymentMethod: paymentDetails.PaymentMethod,
                PaidUtc: paymentDetails.PaidUtc,
            };

            if (!dto.InvoiceID) {
                dto.InvoiceID = -1;
            }

            if (!dto.OrganizationID) {
                dto.OrganizationID = -1;
            }

            if (!dto.PaymentMethod) {
                dto.PaymentMethod = '';
            }

            if (!dto.PaidUtc || dto.PaidUtc === 'Invalid date') {
                dto.PaidUtc = '';
            }

            if (!dto.PaymentNumber) {
                dto.PaymentNumber = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Invoice/UpdatePaymentDetails',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        getAllPaymentMethodCodes() {
            return DMUtils.http({
                method: 'GET',
                url: '/Invoice/GetAllPaymentMethodCodes',
            }).then((response) => {
                return response;
            });
        },
        getInvoiceByIDMyShyftAdmin(invoiceID) {
            return DMUtils.http({
                method: 'GET',
                url: '/Invoice/GetByIDMyShyftAdmin',
                params: { InvoiceID: invoiceID },
            }).then((response) => {
                return response;
            });
        },
        generateInvoices() {
            return DMUtils.http({
                method: 'GET',
                url: '/Invoice/GenerateInvoices',
            }).then((response) => {
                return response;
            });
        },
        beginInvoicePayment(invoiceIDs) {
            let dto = {
                InvoiceIDs: invoiceIDs,
            };

            if (!dto.InvoiceIDs) {
                dto.InvoiceIDs = [];
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Invoice/BeginPayment',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        completeInvoicePayment(invoices) {
            let dto = {
                InvoiceIDs: invoices.InvoiceIDs,
                PaymentIntentID: invoices.PaymentIntentID,
            };

            if (!dto.InvoiceIDs) {
                dto.InvoiceIDs = [];
            }

            if (!dto.PaymentIntentID) {
                dto.PaymentIntentID = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Invoice/CompletePayment',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        // #endregion Invoices

        // #region Transactions

        getAllTransactionsForShiftClockID(shiftClockID) {
            return DMUtils.http({
                method: 'GET',
                url: '/Transaction/GetAllForShiftClock',
                params: { ShiftClockID: shiftClockID },
            }).then((response) => {
                return response;
            });
        },
        getAllTransactionsMyShyftAdmin(paramsDto) {
            if (paramsDto === undefined) {
                paramsDto = {};
            }

            let dto = {
                CaregiverID: paramsDto.CaregiverID,
                OrganizationID: paramsDto.OrganizationID,
                SearchText: paramsDto.SearchText,
                StartDateRange: paramsDto.StartDateRange,
                EndDateRange: paramsDto.EndDateRange,
                RowsPerPage: paramsDto.RowsPerPage,
                PageToLoad: paramsDto.PageToLoad,
                SortByColumn: paramsDto.SortByColumn,
                SortDescending: paramsDto.SortDescending,
            };

            if (!dto.CaregiverID) {
                dto.CaregiverID = -1;
            }

            if (!dto.OrganizationID) {
                dto.OrganizationID = -1;
            }

            if (!dto.SearchText) {
                dto.SearchText = '';
            }

            if (!dto.StartDateRange || dto.StartDateRange === 'Invalid date') {
                dto.StartDateRange = '';
            }

            if (!dto.EndDateRange || dto.EndDateRange === 'Invalid date') {
                dto.EndDateRange = '';
            }

            if (!dto.RowsPerPage) {
                dto.RowsPerPage = 1000;
            }

            if (!dto.PageToLoad) {
                dto.PageToLoad = 1;
            }

            if (!dto.SortByColumn) {
                dto.SortByColumn = '';
            }

            if (dto.SortDescending === undefined) {
                dto.SortDescending = true;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Transaction/GetAllMyShyftAdmin',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        getTransactionsForPayoutID(dto) {
            return DMUtils.http({
                method: 'GET',
                url: '/Transaction/GetAllForPayoutIDMyShyftAdmin',
                params: {
                    ThirdPartyPayoutID: dto.ThirdPartyPayoutID,
                    UserID: dto.UserID,
                },
            }).then((response) => {
                return response;
            });
        },
        getAllTransferFailures() {
            return DMUtils.http({
                method: 'GET',
                url: '/Transaction/GetAllTransferFailures',
            }).then((response) => {
                return response;
            });
        },
        getAllPayoutFailures() {
            return DMUtils.http({
                method: 'GET',
                url: '/Transaction/GetAllPayoutFailures',
            }).then((response) => {
                return response;
            });
        },
        retryTransaction(retryDto) {
            let dto = {
                TransactionID: retryDto.TransactionID,
                RowVersion: retryDto.RowVersion,
            };

            if (!dto.TransactionID) {
                dto.TransactionID = -1;
            }

            if (!dto.RowVersion) {
                dto.RowVersion = [];
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Transaction/Retry',
                data: dto,
            }).then((response) => {
                return response;
            });
        },

        // #endregion Transactions

        // #region OrganizationBlockedCaregivers

        getAllBlockedCaregiversForOrganization(organizationID) {
            return DMUtils.http({
                method: 'GET',
                url: '/OrganizationBlockedCaregiver/GetAll',
                params: { OrganizationID: organizationID },
            }).then((response) => {
                return response;
            });
        },
        blockCaregiverOrganization(caregiver) {
            let dto = {
                CaregiverID: caregiver.CaregiverID,
                RemoveAllFutureConfirmed: caregiver.RemoveAllFutureConfirmed
            };

            if (!dto.CaregiverID) {
                dto.CaregiverID = -1;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/OrganizationBlockedCaregiver/Create',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        unblockCaregiverOrganization(caregiver) {
            let dto = {
                CaregiverID: caregiver.CaregiverID,
                OrganizationBlockedCaregiverID: caregiver.OrganizationBlockedCaregiverID,
            };

            if (!dto.CaregiverID) {
                dto.CaregiverID = -1;
            }

            if (!dto.OrganizationBlockedCaregiverID) {
                dto.OrganizationBlockedCaregiverID = -1;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/OrganizationBlockedCaregiver/Delete',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        importBlockedCaregiversOrganization(file) {
            return DMUtils.http({
                method: 'POST',
                url: '/OrganizationBlockedCaregiver/Import',
                data: file,
            }).then((response) => {
                return response;
            });
        },
        exportBlockedCaregiversOrganization() {
            return DMUtils.http({
                method: 'GET',
                url: '/OrganizationBlockedCaregiver/Export',
                returnsFile: true,
            }).then((response) => {
                return response;
            });
        },
        exportBlockedCaregiversTemplate() {
            return DMUtils.http({
                method: 'GET',
                url: '/OrganizationBlockedCaregiver/ExportTemplate',
                returnsFile: true,
            }).then((response) => {
                return response;
            });
        },

        // #endregion OrganizationBlockedCaregivers

        // #region OrganizationFavoriteCaregivers

        getAllFavoriteCaregiversForOrganization(organizationID) {
            return DMUtils.http({
                method: 'GET',
                url: '/OrganizationFavoriteCaregiver/GetAll',
                params: { OrganizationID: organizationID },
            }).then((response) => {
                return response;
            });
        },
        favoriteCaregiverOrganization(caregiver) {
            let dto = {
                CaregiverID: caregiver.CaregiverID,
            };

            if (!dto.CaregiverID) {
                dto.CaregiverID = -1;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/OrganizationFavoriteCaregiver/Create',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        removeFavoriteCaregiverOrganization(caregiver) {
            let dto = {
                OrganizationFavoriteCaregiverID: caregiver.OrganizationFavoriteCaregiverID,
            };

            if (!dto.OrganizationFavoriteCaregiverID) {
                dto.OrganizationFavoriteCaregiverID = -1;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/OrganizationFavoriteCaregiver/Delete',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        // #endregion OrganizationFavoriteCaregivers

        // #region Frequently Asked Questions

        getByIDFrequentlyAskedQuestion(frequentlyAskedQuestionID) {
            return DMUtils.http({
                method: 'GET',
                url: '/FrequentlyAskedQuestion/GetByID',
                params: { FrequentlyAskedQuestionID: frequentlyAskedQuestionID },
            }).then((response) => {
                return response;
            });
        },
        getAllFrequentlyAskedQuestions(userType) {
            return DMUtils.http({
                method: 'GET',
                url: '/FrequentlyAskedQuestion/GetAll',
                params: { UserType: userType },
            }).then((response) => {
                return response;
            });
        },
        getAllMyShyftAdminFrequentlyAskedQuestions() {
            return DMUtils.http({
                method: 'GET',
                url: '/FrequentlyAskedQuestion/GetAllMyShyftAdmin',
            }).then((response) => {
                return response;
            });
        },
        getFrequentlyAskedQuestionsUserTypes() {
            return DMUtils.http({
                method: 'GET',
                url: '/FrequentlyAskedQuestion/GetAllTypes',
            }).then((response) => {
                return response;
            });
        },
        createFrequentlyAskedQuestion(faq) {
            let dto = {
                Question: faq.Question,
                Answer: faq.Answer,
                UserType: faq.UserType,
            };

            if (!dto.Question) {
                dto.Question = '';
            }

            if (!dto.Answer) {
                dto.Answer = '';
            }

            if (!dto.UserType) {
                dto.UserType = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/FrequentlyAskedQuestion/Create',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        updateFrequentlyAskedQuestion(faq) {
            let dto = {
                FrequentlyAskedQuestionID: faq.FrequentlyAskedQuestionID,
                Question: faq.Question,
                Answer: faq.Answer,
                UserType: faq.UserType,
                Status: faq.Status,
                RowVersion: faq.RowVersion,
            };

            if (!dto.FrequentlyAskedQuestionID) {
                dto.FrequentlyAskedQuestionID = -1;
            }

            if (!dto.Question) {
                dto.Question = '';
            }

            if (!dto.Answer) {
                dto.Answer = '';
            }

            if (!dto.UserType) {
                dto.UserType = '';
            }

            if (!dto.Status) {
                dto.Status = '';
            }

            if (!dto.RowVersion) {
                dto.RowVersion = [];
            }

            return DMUtils.http({
                method: 'POST',
                url: '/FrequentlyAskedQuestion/Update',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        deleteFrequentlyAskedQuestion(faq) {
            let dto = {
                FrequentlyAskedQuestionID: faq.FrequentlyAskedQuestionID,
                Question: faq.Question,
                Answer: faq.Answer,
                UserType: faq.UserType,
                Status: faq.Status,
                RowVersion: faq.RowVersion,
            };

            if (!dto.FrequentlyAskedQuestionID) {
                dto.FrequentlyAskedQuestionID = -1;
            }

            if (!dto.Question) {
                dto.Question = '';
            }

            if (!dto.Answer) {
                dto.Answer = '';
            }

            if (!dto.UserType) {
                dto.UserType = '';
            }

            if (!dto.Status) {
                dto.Status = '';
            }

            if (!dto.RowVersion) {
                dto.RowVersion = [];
            }

            return DMUtils.http({
                method: 'POST',
                url: '/FrequentlyAskedQuestion/Delete',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        // #endregion Frequently Asked Questions

        // #region Legal Documents

        getByIDLegalDocument(legalDocumentID) {
            return DMUtils.http({
                method: 'GET',
                url: '/LegalDocument/GetByID',
                params: { LegalDocumentID: legalDocumentID },
            }).then((response) => {
                return response;
            });
        },
        getByIDMyShyftAdminLegalDocument(legalDocumentID) {
            return DMUtils.http({
                method: 'GET',
                url: '/LegalDocument/GetByIDMyShyftAdmin',
                params: { LegalDocumentID: legalDocumentID },
            }).then((response) => {
                return response;
            });
        },
        getAllMyShyftAdminLegalDocuments() {
            return DMUtils.http({
                method: 'GET',
                url: '/LegalDocument/GetAllMyShyftAdmin',
            }).then((response) => {
                return response;
            });
        },
        updateLegalDocument(document) {
            let dto = {
                LegalDocumentID: document.LegalDocumentID,
                Title: document.Title,
                Content: document.Content,
                RowVersion: document.RowVersion,
            };

            if (!dto.LegalDocumentID) {
                dto.LegalDocumentID = -1;
            }

            if (!dto.Title) {
                dto.Title = '';
            }

            if (!dto.Content) {
                dto.Content = '';
            }

            if (!dto.RowVersion) {
                dto.RowVersion = [];
            }

            return DMUtils.http({
                method: 'POST',
                url: '/LegalDocument/Update',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        // #endregion Legal Documents

        // #region Dashboard

        getOrganizationDashboard() {
            return DMUtils.http({
                method: 'GET',
                url: '/Dashboard/GetOrganization',
            }).then((response) => {
                return response;
            });
        },
        getMyShyftAdminDashboard() {
            return DMUtils.http({
                method: 'GET',
                url: '/Dashboard/GetMyShyftAdmin',
            }).then((response) => {
                return response;
            });
        },

        // #endregion Dashboard

        // #region Checkr Controller

        getCheckrReportByUserID(userID) {
            return DMUtils.http({
                method: 'GET',
                url: '/Checkr/GetReportByUserID',
                params: { UserID: userID },
            }).then((response) => {
                return response;
            });
        },

        createCheckrReport(backgroundCheck) {
            let dto = {
                UserID: backgroundCheck.UserID,
            };

            if (!dto.UserID) {
                dto.UserID = -1;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/Checkr/Create',
                data: dto,
            }).then((response) => {
                return response;
            });
        },

        // #endregion Checkr Controller

        // #region Stripe Controller
        getRecentPayoutsForCaregiver(userID) {
            return DMUtils.http({
                method: 'GET',
                url: '/Stripe/GetPayoutsForCaregiver',
                params: { UserID: userID },
            }).then((response) => {
                return response;
            });
        },

        // #endregion Stripe Controller

        // #region CaregiverPenalties

        getAllCaregiverPenaltiesForCaregiver(caregiverID) {
            return DMUtils.http({
                method: 'GET',
                url: '/CaregiverPenalty/GetAllForCaregiverID',
                params: { CaregiverID: caregiverID },
            }).then((response) => {
                return response;
            });
        },
        deleteCaregiverPenalty(penalty) {
            let dto = {
                CaregiverPenaltyID: penalty.CaregiverPenaltyID,
            };

            if (!dto.CaregiverPenaltyID) {
                dto.CaregiverPenaltyID = -1;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CaregiverPenalty/Delete',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        // #endregion CaregiverPenalties

        // #region TimeZones

        getAllTimeZones() {
            return DMUtils.http({
                method: 'GET',
                url: '/TimeZone/GetAll',
            }).then((response) => {
                return response;
            });
        },
        // #endregion TimeZones

        // #region EletronicMedicalRecords

        getAllElectronicMedicalRecords() {
            return DMUtils.http({
                method: 'GET',
                url: '/ElectronicMedicalRecord/GetAll',
            }).then((response) => {
                return response;
            });
        },
        getElectronicMedicalRecordByID(electronicMedicalRecordID) {
            return DMUtils.http({
                method: 'GET',
                url: '/ElectronicMedicalRecord/GetByID',
                params: { ElectronicMedicalRecordsID: electronicMedicalRecordID },
            }).then((response) => {
                return response;
            });
        },
        // #endregion EletronicMedicalRecords

        // #region GeneralSupport

        getAllGeneralSupportRequestsMyShyftAdmin(paramsDto) {
            let dto = {
                RowsPerPage: paramsDto.RowsPerPage,
                PageToLoad: paramsDto.PageToLoad,
                SortByColumn: paramsDto.SortByColumn,
                SortDescending: paramsDto.SortDescending,
            };

            if (!dto.RowsPerPage) {
                dto.RowsPerPage = 1000;
            }

            if (!dto.PageToLoad) {
                dto.PageToLoad = 1;
            }

            if (!dto.SortByColumn) {
                dto.SortByColumn = '';
            }

            if (dto.SortDescending === undefined) {
                dto.SortDescending = true;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/GeneralSupport/GetAllMyShyftAdmin',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        getAllGeneralSupportRequestsOrganization(paramsDto) {
            let dto = {
                RowsPerPage: paramsDto.RowsPerPage,
                PageToLoad: paramsDto.PageToLoad,
                SortByColumn: paramsDto.SortByColumn,
                SortDescending: paramsDto.SortDescending,
            };

            if (!dto.RowsPerPage) {
                dto.RowsPerPage = 1000;
            }

            if (!dto.PageToLoad) {
                dto.PageToLoad = 1;
            }

            if (!dto.SortByColumn) {
                dto.SortByColumn = '';
            }

            if (dto.SortDescending === undefined) {
                dto.SortDescending = true;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/GeneralSupport/GetAllOrganization',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        getGeneralSupportRequestByID(generalSupportID) {
            return DMUtils.http({
                method: 'GET',
                url: '/GeneralSupport/GetByID',
                params: { GeneralSupportID: generalSupportID },
            }).then((response) => {
                return response;
            });
        },
        updateGeneralSupportRequest(generalSupport) {
            let dto = {
                GeneralSupportID: generalSupport.GeneralSupportID,
                SupportStatus: generalSupport.SupportStatus,
                RowVersion: generalSupport.RowVersion,
            };

            if (!dto.GeneralSupportID) {
                dto.GeneralSupportID = -1;
            }

            if (!dto.SupportStatus) {
                dto.SupportStatus = '';
            }

            if (!dto.RowVersion) {
                dto.RowVersion = [];
            }

            return DMUtils.http({
                method: 'POST',
                url: '/GeneralSupport/Update',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        createGeneralSupportRequest(generalSupport) {
            let dto = {
                SupportTitle: generalSupport.SupportTitle,
                SupportMessage: generalSupport.SupportMessage,
                SupportImageFileName: generalSupport.SupportImageFileName,
                SupportImageBase64: generalSupport.SupportImageBase64,
            };

            if (!dto.SupportTitle) {
                dto.SupportTitle = '';
            }

            if (!dto.SupportMessage) {
                dto.SupportMessage = '';
            }

            if (!dto.SupportImageFileName) {
                dto.SupportImageFileName = '';
            }

            if (!dto.SupportImageBase64) {
                dto.SupportImageBase64 = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/GeneralSupport/Create',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        // #endregion GeneralSupport

        // #region ShiftSupport

        getAllShiftSupportRequestsMyShyftAdmin(paramsDto) {
            let dto = {
                ShiftSupportType: paramsDto.ShiftSupportType,
                RowsPerPage: paramsDto.RowsPerPage,
                PageToLoad: paramsDto.PageToLoad,
                SortByColumn: paramsDto.SortByColumn,
                SortDescending: paramsDto.SortDescending,
            };

            if (!dto.ShiftSupportType){
                dto.ShiftSupportType = '';
            }

            if (!dto.RowsPerPage) {
                dto.RowsPerPage = 1000;
            }

            if (!dto.PageToLoad) {
                dto.PageToLoad = 1;
            }

            if (!dto.SortByColumn) {
                dto.SortByColumn = '';
            }

            if (dto.SortDescending === undefined) {
                dto.SortDescending = true;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/ShiftSupport/GetAllMyShyftAdmin',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        getAllShiftSupportRequestsOrganization(paramsDto) {
            let dto = {
                ShiftSupportType: paramsDto.ShiftSupportType,
                RowsPerPage: paramsDto.RowsPerPage,
                PageToLoad: paramsDto.PageToLoad,
                SortByColumn: paramsDto.SortByColumn,
                SortDescending: paramsDto.SortDescending,
            };

            if (!dto.ShiftSupportType){
                dto.ShiftSupportType = '';
            }

            if (!dto.RowsPerPage) {
                dto.RowsPerPage = 1000;
            }

            if (!dto.PageToLoad) {
                dto.PageToLoad = 1;
            }

            if (!dto.SortByColumn) {
                dto.SortByColumn = '';
            }

            if (dto.SortDescending === undefined) {
                dto.SortDescending = true;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/ShiftSupport/GetAllOrganization',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        getShiftSupportRequestByID(shiftSupportID) {
            return DMUtils.http({
                method: 'GET',
                url: '/ShiftSupport/GetByID',
                params: { ShiftSupportID: shiftSupportID },
            }).then((response) => {
                return response;
            });
        },
        getShiftSupportTypes() {
            return DMUtils.http({
                method: 'GET',
                url: '/ShiftSupport/GetAllSupportTypeCodes ',
            }).then((response) => {
                return response;
            });
        },
        updateShiftSupportRequest(organizationInvoice) {
            let dto = {
                ShiftSupportID: organizationInvoice.ShiftSupportID,
                SupportStatus: organizationInvoice.SupportStatus,
                RowVersion: organizationInvoice.RowVersion,
            };

            if (!dto.ShiftSupportID) {
                dto.ShiftSupportID = -1;
            }

            if (!dto.SupportStatus) {
                dto.SupportStatus = '';
            }

            if (!dto.RowVersion) {
                dto.RowVersion = [];
            }

            return DMUtils.http({
                method: 'POST',
                url: '/ShiftSupport/Update',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        createShiftSupportRequest(organizationInvoice) {
            let dto = {
                ShiftID: organizationInvoice.ShiftID,
                ShiftSupportType: organizationInvoice.ShiftSupportType,
                SupportMessage: organizationInvoice.SupportMessage,
            };

            if (!dto.ShiftID) {
                dto.ShiftID = -1;
            }

            if (!dto.ShiftSupportType) {
                dto.ShiftSupportType = '';
            }

            if (!dto.SupportMessage) {
                dto.SupportMessage = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/ShiftSupport/Create',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        // #endregion ShiftSupport

        // #region NotificationHistory
        getNotificationHistoryOrganization(params) {
            let dto = {
                StartDateRange: params.StartDateRange,
                EndDateRange: params.EndDateRange,
            };

            if (!dto.StartDateRange) {
                dto.StartDateRange = '';
            }

            if (!dto.EndDateRange) {
                dto.EndDateRange = '';
            }

            return DMUtils.http({
                method: 'GET',
                url: '/NotificationHistory/GetAllOrganization',
                params: { StartDateRange: dto.StartDateRange, EndDateRange: dto.EndDateRange },
            }).then((response) => {
                return response;
            });
        },
        getNotificationHistoryMyShyftAdmin(params) {
            let dto = {
                StartDateRange: params.StartDateRange,
                EndDateRange: params.EndDateRange,
                GetMyShyftAdminMessages: params.GetMyShyftAdminMessages,
            };

            if (!dto.StartDateRange || dto.StartDateRange === 'Invalid date') {
                dto.StartDateRange = '';
            }

            if (!dto.EndDateRange || dto.EndDateRange === 'Invalid date') {
                dto.EndDateRange = '';
            }

            if (!dto.GetMyShyftAdminMessages) {
                dto.GetMyShyftAdminMessages = false;
            }

            return DMUtils.http({
                method: 'GET',
                url: '/NotificationHistory/GetAllMyShyftAdmin',
                params: {
                    StartDateRange: dto.StartDateRange,
                    EndDateRange: dto.EndDateRange,
                    GetMyShyftAdminMessages: dto.GetMyShyftAdminMessages,
                },
            }).then((response) => {
                return response;
            });
        },

        // #endregion NotificationHistory

        // #region CaregiverHistory
        getCaregiverHistoryForCaregiverID(caregiverID) {
            return DMUtils.http({
                method: 'GET',
                url: '/CaregiverHistory/GetAllForUser',
                params: { CaregiverID: caregiverID },
            }).then((response) => {
                return response;
            });
        },
        // #endregion CaregiverHistory

        // #region CSV Exports
        exportShiftsMyShyftAdmin(params) {
            let dto = {
                StartDateRange: params.StartDateRange,
                EndDateRange: params.EndDateRange,
            };

            if (!dto.StartDateRange || dto.StartDateRange === 'Invalid date') {
                dto.StartDateRange = '';
            }

            if (!dto.EndDateRange || dto.EndDateRange === 'Invalid date') {
                dto.EndDateRange = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CsvExport/DownloadPostedShiftsMyShyftAdminCsv',
                data: dto,
                returnsFile: true,
            }).then((response) => {
                return response;
            });
        },
        exportShiftsOrganization(params) {
            let dto = {
                StartDateRange: params.StartDateRange,
                EndDateRange: params.EndDateRange,
            };

            if (!dto.StartDateRange || dto.StartDateRange === 'Invalid date') {
                dto.StartDateRange = '';
            }

            if (!dto.EndDateRange || dto.EndDateRange === 'Invalid date') {
                dto.EndDateRange = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CsvExport/DownloadPostedShiftsOrganizationCsv',
                data: dto,
                returnsFile: true,
            }).then((response) => {
                return response;
            });
        },
        exportTransactions(params) {
            let dto = {
                CreatedStartRange: params.StartDateRange,
                CreatedEndRange: params.EndDateRange,
            };

            if (!dto.CreatedStartRange || dto.CreatedStartRange === 'Invalid date') {
                dto.CreatedStartRange = '';
            }

            if (!dto.CreatedEndRange || dto.CreatedEndRange === 'Invalid date') {
                dto.CreatedEndRange = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CsvExport/DownloadTransactionsCsv',
                data: dto,
                returnsFile: true,
            }).then((response) => {
                return response;
            });
        },
        exportInvoices(params) {
            let dto = {
                CreatedStartRange: params.StartDateRange,
                CreatedEndRange: params.EndDateRange,
            };

            if (!dto.CreatedStartRange || dto.CreatedStartRange === 'Invalid date') {
                dto.CreatedStartRange = '';
            }

            if (!dto.CreatedEndRange || dto.CreatedEndRange === 'Invalid date') {
                dto.CreatedEndRange = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CsvExport/DownloadInvoicesCsv',
                data: dto,
                returnsFile: true,
            }).then((response) => {
                return response;
            });
        },
        exportCaregivers(params) {
            let dto = {
                CreatedStartRange: params.StartDateRange,
                CreatedEndRange: params.EndDateRange,
            };

            if (!dto.CreatedStartRange || dto.CreatedStartRange === 'Invalid date') {
                dto.CreatedStartRange = '';
            }

            if (!dto.CreatedEndRange || dto.CreatedEndRange === 'Invalid date') {
                dto.CreatedEndRange = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CsvExport/DownloadCaregiversCsv',
                data: dto,
                returnsFile: true,
            }).then((response) => {
                return response;
            });
        },
        exportCaregiverMedicalLicenses(params) {
            let dto = {
                CreatedStartRange: params.StartDateRange,
                CreatedEndRange: params.EndDateRange,
            };

            if (!dto.CreatedStartRange || dto.CreatedStartRange === 'Invalid date') {
                dto.CreatedStartRange = '';
            }

            if (!dto.CreatedEndRange || dto.CreatedEndRange === 'Invalid date') {
                dto.CreatedEndRange = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CsvExport/DownloadCaregiverMedicalLicenseCsv',
                data: dto,
                returnsFile: true,
            }).then((response) => {
                return response;
            });
        },
        exportOrganizations(params) {
            let dto = {
                CreatedStartRange: params.StartDateRange,
                CreatedEndRange: params.EndDateRange,
            };

            if (!dto.CreatedStartRange || dto.CreatedStartRange === 'Invalid date') {
                dto.CreatedStartRange = '';
            }

            if (!dto.CreatedEndRange || dto.CreatedEndRange === 'Invalid date') {
                dto.CreatedEndRange = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CsvExport/DownloadOrganizationsCsv',
                data: dto,
                returnsFile: true,
            }).then((response) => {
                return response;
            });
        },
        exportOrganizationsUsersAndContacts(params) {
            let dto = {
                CreatedStartRange: params.StartDateRange,
                CreatedEndRange: params.EndDateRange,
            };

            if (!dto.CreatedStartRange || dto.CreatedStartRange === 'Invalid date') {
                dto.CreatedStartRange = '';
            }

            if (!dto.CreatedEndRange || dto.CreatedEndRange === 'Invalid date') {
                dto.CreatedEndRange = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CsvExport/DownloadOrganizationsUsersAndContactsCsv',
                data: dto,
                returnsFile: true,
            }).then((response) => {
                return response;
            });
        },
        exportOrganizationLocations(params) {
            let dto = {
                CreatedStartRange: params.StartDateRange,
                CreatedEndRange: params.EndDateRange,
            };

            if (!dto.CreatedStartRange || dto.CreatedStartRange === 'Invalid date') {
                dto.CreatedStartRange = '';
            }

            if (!dto.CreatedEndRange || dto.CreatedEndRange === 'Invalid date') {
                dto.CreatedEndRange = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CsvExport/DownloadOrganizationLocationsCsv',
                data: dto,
                returnsFile: true,
            }).then((response) => {
                return response;
            });
        },
        exportShiftClocksMyShyftAdmin(params) {
            let dto = {
                CreatedStartRange: params.StartDateRange,
                CreatedEndRange: params.EndDateRange,
            };

            if (!dto.CreatedStartRange || dto.CreatedStartRange === 'Invalid date') {
                dto.CreatedStartRange = '';
            }

            if (!dto.CreatedEndRange || dto.CreatedEndRange === 'Invalid date') {
                dto.CreatedEndRange = '';
            }
            
            return DMUtils.http({
                method: 'POST',
                url: '/CsvExport/DownloadShiftClocksCsv',
                data: dto,
                returnsFile: true,
            }).then((response) => {
                return response;
            });
        },
        exportCaregiverLicenseSummaryMyShyftAdmin(params) {
            let dto = {
                CreatedStartRange: params.StartDateRange,
                CreatedEndRange: params.EndDateRange,
            };

            if (!dto.CreatedStartRange || dto.CreatedStartRange === 'Invalid date') {
                dto.CreatedStartRange = '';
            }

            if (!dto.CreatedEndRange || dto.CreatedEndRange === 'Invalid date') {
                dto.CreatedEndRange = '';
            }
            
            return DMUtils.http({
                method: 'POST',
                url: '/CsvExport/DownloadCaregiverLicenseAndOrganizationTypeCsv',
                data: dto,
                returnsFile: true,
            }).then((response) => {
                return response;
            });
        },
        // #endregion CSV Exports

        //#region Organization Invoice
        getOrganizationInvoiceByOrganizationID(organizationID) {
            return DMUtils.http({
                method: 'GET',
                url: '/OrganizationInvoice/GetByID',
                params: { OrganizationID: organizationID },
            }).then((response) => {
                return response;
            });
        },
        updateOrganizationInvoice(organizationInvoice) {
            let dto = {
                OrganizationInvoiceID: organizationInvoice.OrganizationInvoiceID,
                OrganizationID: organizationInvoice.OrganizationID,
                OutstandingBalance: organizationInvoice.OutstandingBalance,
                RowVersion: organizationInvoice.RowVersion,
            };

            if (!dto.OrganizationInvoiceID) {
                dto.OrganizationInvoiceID = -1;
            }

            if (!dto.OrganizationID) {
                dto.OrganizationID = -1;
            }

            if (!dto.OutstandingBalance) {
                dto.OutstandingBalance = 0;
            }

            if (!dto.RowVersion) {
                dto.RowVersion = [];
            }

            return DMUtils.http({
                method: 'POST',
                url: '/OrganizationInvoice/Update',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        //#endregion Organization Invoice

        // #region OrganizationGlobalRates
        getAllOrganizationGlobalRatesForOrganization() {
            return DMUtils.http({
                method: 'GET',
                url: '/OrganizationGlobalRate/GetAll',
            }).then((response) => {
                return response;
            });
        },
        getAllOrganizationGlobalRatesForOrganizationMyShyftAdmin(organizationID) {
            return DMUtils.http({
                method: 'GET',
                url: '/OrganizationGlobalRate/GetAll',
                params: { OrganizationID: organizationID },
            }).then((response) => {
                return response;
            });
        },
        getOrganizationGlobalRateByID(globalRateIDs) {
            return DMUtils.http({
                method: 'GET',
                url: '/OrganizationGlobalRate/GetByID',
                params: { OrganizationGlobalRateID: globalRateIDs.organizationGlobalRateID, OrganizationID: globalRateIDs.organizationID},
            }).then((response) => {
                return response;
            });
        },
        updateOrganizationGlobalRate(globalRate) {
            let dto = {
                OrganizationGlobalRateID: globalRate.OrganizationGlobalRateID,
                MaxDesiredRate: globalRate.MaxDesiredRate,
                RowVersion: globalRate.RowVersion,
            };

            if (!dto.OrganizationGlobalRateID) {
                dto.OrganizationGlobalRateID = -1;
            }

            if (!dto.MaxDesiredRate) {
                dto.MaxDesiredRate = 0;
            }

            if (!dto.RowVersion) {
                dto.RowVersion = [];
            }

            return DMUtils.http({
                method: 'POST',
                url: '/OrganizationGlobalRate/Update',
                data: dto,
                params: { OrganizationID: globalRate.OrganizationID }
            }).then((response) => {
                return response;
            });
        },
        createOrganizationGlobalRate(globalRate) {
            let dto = {
                MedicalLicenseID: globalRate.MedicalLicenseID,
                MaxDesiredRate: globalRate.MaxDesiredRate,
            };

            if (!dto.MedicalLicenseID) {
                dto.MedicalLicenseID = 0;
            }

            if (!dto.MaxDesiredRate) {
                dto.MaxDesiredRate = 0;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/OrganizationGlobalRate/Create',
                data: dto,
                params: { OrganizationID: globalRate.OrganizationID }
            }).then((response) => {
                return response;
            });
        },
        deleteOrganizationGlobalRate(globalRate) {
            let dto = {
                OrganizationGlobalRateID: globalRate.OrganizationGlobalRateID,
                RowVersion: globalRate.RowVersion,
            };

            if (!dto.OrganizationGlobalRateID) {
                dto.OrganizationGlobalRateID = -1;
            }

            if (!dto.RowVersion) {
                dto.RowVersion = [];
            }

            return DMUtils.http({
                method: 'POST',
                url: '/OrganizationGlobalRate/Delete',
                data: dto,
            }).then((response) => {
                return response;
            });
        },

        // #endregion OrganizationGlobalRates
        
        //#region Notification Broadcast

        getNotificationBroadcastTypes() {
            return DMUtils.http({
                method: 'GET',
                url: '/NotificationBroadcast/GetAllBroadcastTypes',
            });
        },

        sendNotificationBroadcast(message) {
            let dto = {
                BroadcastType: message.BroadcastType,
                Message: message.Message,
                Link: message.Link,
                UserIDs: message.UserIDs
            }

            if (!dto.BroadcastType) {
                dto.BroadcastType = "";
            }

            if (!dto.Message) {
                dto.Message = "";
            }

            if (!dto.Link) {
                dto.Link = "";
            }

            if (!dto.UserIDs) {
                dto.UserIDs = [];
            }

            return DMUtils.http({
                method: 'POST',
                url: '/NotificationBroadcast/Send',
                data: dto
            });
        },

        sendAllNotificationBroadcast(message) {
            let dto = {
                BroadcastType: message.BroadcastType,
                Message: message.Message,
                Link: message.Link,
                UserIDs: message.UserIDs,
                UserRoles: message.UserRoles,
                Status: message.Status,
                City: message.City,
                States: message.States,
                MedicalLicenseIDs: message.MedicalLicenseIDs,
                OrganizationCategory: message.OrganizationCategory,
                UserName: message.UserName
            }

            if (!dto.BroadcastType) {
                dto.BroadcastType = "";
            }

            if (!dto.Message) {
                dto.Message = "";
            }

            if (!dto.Link) {
                dto.Link = "";
            }

            if (!dto.UserIDs) {
                dto.UserIDs = [];
            }

            if (!dto.UserRoles) {
                dto.UserRoles = [];
            }

            if (!dto.Status) {
                dto.Status = "";
            }

            if (!dto.City) {
                dto.City = "";
            }
            
            if (!dto.States) {
                dto.States = [];
            }

            if (!dto.MedicalLicenseIDs) {
                dto.MedicalLicenseIDs = [];
            }

            if (!dto.OrganizationCategory) {
                dto.OrganizationCategory = "";
            }

            if (!dto.UserName) {
                dto.UserName = "";
            }

            return DMUtils.http({
                method: 'POST',
                url: '/NotificationBroadcast/SendAll',
                data: dto
            });
        },

        //#endregion Notification Broadcast

        //#region Document Center

        getDocumentCenterTypes() {
            return DMUtils.http({
                method: 'GET',
                url: '/DocumentCenterType/GetDocumentTypesForDropdown',
            });
        },
        getDocumentCenterStatuses() {
            return DMUtils.http({
                method: 'GET',
                url: '/DocumentCenter/GetValidDocumentStatusTypes',
            });
        },
        getAllDocumentsForUser(userID) {
            return DMUtils.http({
                method: 'GET',
                url: '/DocumentCenter/GetAllDetailsMyShyftAdmin',
                params: { UserID: userID },
            }).then((response) => {
                return response;
            });
        },
        updateDocumentStatus(document) {
            let dto = {
                DocumentID: document.DocumentID,
                ApprovalStatus: document.ApprovalStatus
            }

            if (!dto.DocumentID) {
                dto.DocumentID = -1;
            }

            if (!dto.ApprovalStatus) {
                dto.ApprovalStatus = "";
            }

            return DMUtils.http({
                method: 'POST',
                url: '/DocumentCenter/UpdateStatus',
                data: dto
            });
        }, 
        deleteDocumentMyShyftAdmin(document) {
            let dto = {
                DocumentID: document.DocumentID,
            }

            if (!dto.DocumentID) {
                dto.DocumentID = -1;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/DocumentCenter/DeleteMyShyftAdmin',
                data: dto
            });
        }, 
        uploadDocumentMyShyftAdmin(document) {
            return DMUtils.http({
                method: 'POST',
                url: '/DocumentCenter/UploadMyShyftAdmin',
                params: { UserID: document.UserID, DocumentCenterTypeID: document.DocumentCenterTypeID },
                data: document.file
            });
        },
        downloadDocumentMyShyftAdmin(documentID) {
            return DMUtils.http({
                method: 'GET',
                url: '/DocumentCenter/DownloadMyShyftAdmin',
                params: { DocumentID : documentID },
                returnsFile: true,
            });
        },

        //#endregion Document Center

        //#region Organization Locations

        getOrganizationLocationTypes() {
            return DMUtils.http({
                method: 'GET',
                url: '/OrganizationLocation/GetAllOrganizationLocationTypeCodes',
            }).then((response) => {
                return response;
            });
        },
        getOrganizationLocationBuildingByID(organizationLocationID) {
            return DMUtils.http({
                method: 'GET',
                url: '/OrganizationLocation/GetBuildingByID',
                params: { OrganizationLocationID: organizationLocationID },
            }).then((response) => {
                return response;
            });
        },
        getOrganizationLocationPatientByIDOrganization(organizationLocationID) {
            return DMUtils.http({
                method: 'GET',
                url: '/OrganizationLocation/GetPatientByIDOrganization',
                params: { OrganizationLocationID: organizationLocationID },
            }).then((response) => {
                return response;
            });
        },
        getOrganizationLocationPatientByIDMyShyftAdmin(organizationLocationID) {
            return DMUtils.http({
                method: 'GET',
                url: '/OrganizationLocation/GetPatientByIDMyShyftAdmin',
                params: { OrganizationLocationID: organizationLocationID },
            }).then((response) => {
                return response;
            });
        },
        getAllOrganizationLocationBuildingsForOrganization(organizationID) {
            return DMUtils.http({
                method: 'GET',
                url: '/OrganizationLocation/GetAllBuildingsForOrganization',
                params: { OrganizationID: organizationID },
            }).then((response) => {
                return response;
            });
        },
        getAllOrganizationLocationPatientsForOrganization() {
            return DMUtils.http({
                method: 'GET',
                url: '/OrganizationLocation/GetAllPatientsForOrganizationAsOrganization'            
            }).then((response) => {
                return response;
            });
        },
        getAllOrganizationLocationPatientsForOrganizationMyShyftAdmin(organizationID, includeDeleted) {
            if (includeDeleted == null) {
                includeDeleted = false;
            }

            return DMUtils.http({
                method: 'GET',
                url: '/OrganizationLocation/GetAllPatientsForOrganizationMyShyftAdmin',
                params: { OrganizationID: organizationID, IncludeDeleted: includeDeleted },
            }).then((response) => {
                return response;
            });
        },
        getAllOrganizationLocationNamesForOrganization(organizationID) {
            return DMUtils.http({
                method: 'GET',
                url: '/OrganizationLocation/GetAllNamesForOrganization',
                params: { OrganizationID: organizationID }
            }).then((response) => {
                return response;
            });
        },
        createOrganizationLocationBuilding(location) {
            let dto = {
                OrganizationID: location.OrganizationID,
                LocationName: location.LocationName,
                LocationPhoneNumber: location.LocationPhoneNumber,
                LocationType: location.LocationType,
                OrganizationTypeID: location.OrganizationTypeID,
                Address1: location.Address1,
                Address2: location.Address2,
                City: location.City,
                State: location.State,
                Zipcode: location.Zipcode,
                Latitude: location.Latitude,
                Longitude: location.Longitude,
                LocalTimeZone: location.LocalTimeZone,
                NumberOfBeds: location.NumberOfBeds,
                BuildingDescription : location.BuildingDescription 
            }

            if (!dto.OrganizationID) {
                dto.OrganizationID = -1;
            }

            if (!dto.LocationName) {
                dto.LocationName = "";
            }

            if (!dto.LocationPhoneNumber) {
                dto.LocationPhoneNumber = "";
            }

            if (!dto.LocationType) {
                dto.LocationType = "";
            }

            if (!dto.OrganizationTypeID) {
                dto.OrganizationTypeID = -1;
            }

            if (!dto.Address1) {
                dto.Address1 = "";
            }

            if (!dto.Address2) {
                dto.Address2 = "";
            }

            if (!dto.City) {
                dto.City = "";
            }

            if (!dto.State) {
                dto.State = "";
            }

            if (!dto.Zipcode) {
                dto.Zipcode = "";
            }

            if (!dto.Latitude) {
                dto.Latitude = 0;
            }

            if (!dto.Longitude) {
                dto.Longitude = 0;
            }

            if (!dto.LocalTimeZone) {
                dto.LocalTimeZone = "";
            }

            if (!dto.NumberOfBeds) {
                dto.NumberOfBeds = 0;
            }

            if (!dto.BuildingDescription) {
                dto.BuildingDescription = "";
            }

            return DMUtils.http({
                method: 'POST',
                url: '/OrganizationLocation/CreateBuilding',
                data: dto
            });
        }, 
        createOrganizationLocationPatientOrganization(location) {
            let dto = {
                OrganizationID: location.OrganizationID,
                LocationName: location.LocationName,
                LocationPhoneNumber: location.LocationPhoneNumber,
                LocationType: location.LocationType,
                OrganizationTypeID: location.OrganizationTypeID,
                Address1: location.Address1,
                Address2: location.Address2,
                City: location.City,
                State: location.State,
                Zipcode: location.Zipcode,
                Latitude: location.Latitude,
                Longitude: location.Longitude,
                LocalTimeZone: location.LocalTimeZone,
                PatientFirstName: location.PatientFirstName,
                PatientLastName: location.PatientLastName,
                PatientNotes: location.PatientNotes,
                LiftingDescription: location.LiftingDescription,
                PetDescription: location.PetDescription,
                NumberOfPets: location.NumberOfPets,
                IsSmoker: location.IsSmoker,
                HasMultiplePatients: location.HasMultiplePatients
            }

            if (!dto.OrganizationID) {
                dto.OrganizationID = -1;
            }

            if (!dto.LocationName) {
                dto.LocationName = "";
            }

            if (!dto.LocationPhoneNumber) {
                dto.LocationPhoneNumber = "";
            }

            if (!dto.LocationType) {
                dto.LocationType = "";
            }

            if (!dto.OrganizationTypeID) {
                dto.OrganizationTypeID = -1;
            }

            if (!dto.Address1) {
                dto.Address1 = "";
            }

            if (!dto.Address2) {
                dto.Address2 = "";
            }

            if (!dto.City) {
                dto.City = "";
            }

            if (!dto.State) {
                dto.State = "";
            }

            if (!dto.Zipcode) {
                dto.Zipcode = "";
            }

            if (!dto.Latitude) {
                dto.Latitude = 0;
            }

            if (!dto.Longitude) {
                dto.Longitude = 0;
            }

            if (!dto.LocalTimeZone) {
                dto.LocalTimeZone = "";
            }

            if (!dto.PatientFirstName) {
                dto.PatientFirstName = "";
            }

            if (!dto.PatientLastName) {
                dto.PatientLastName = "";
            }

            if (!dto.LiftingDescription) {
                dto.LiftingDescription = "";
            }

            if (!dto.PetDescription) {
                dto.PetDescription = "";
            }

            if (!dto.NumberOfPets) {
                dto.NumberOfPets = 0;
            }

            if (!dto.IsSmoker) {
                dto.IsSmoker = false;
            }
            
            if (!dto.PatientNotes) {
                dto.PatientNotes = '';
            }

            if (!dto.HasMultiplePatients) {
                dto.HasMultiplePatients = false;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/OrganizationLocation/CreatePatientOrganization',
                data: dto
            });
        }, 
        createOrganizationLocationPatientMyShyftAdmin(location) {
            let dto = {
                OrganizationID: location.OrganizationID,
                LocationName: location.LocationName,
                LocationPhoneNumber: location.LocationPhoneNumber,
                LocationType: location.LocationType,
                OrganizationTypeID: location.OrganizationTypeID,
                Address1: location.Address1,
                Address2: location.Address2,
                City: location.City,
                State: location.State,
                Zipcode: location.Zipcode,
                Latitude: location.Latitude,
                Longitude: location.Longitude,
                LocalTimeZone: location.LocalTimeZone,
                PatientNotes: location.PatientNotes,
                LiftingDescription: location.LiftingDescription,
                PetDescription: location.PetDescription,
                NumberOfPets: location.NumberOfPets,
                IsSmoker: location.IsSmoker,
                HasMultiplePatients: location.HasMultiplePatients
            }

            if (!dto.OrganizationID) {
                dto.OrganizationID = -1;
            }

            if (!dto.LocationName) {
                dto.LocationName = "";
            }

            if (!dto.LocationPhoneNumber) {
                dto.LocationPhoneNumber = "";
            }

            if (!dto.LocationType) {
                dto.LocationType = "";
            }

            if (!dto.OrganizationTypeID) {
                dto.OrganizationTypeID = -1;
            }

            if (!dto.Address1) {
                dto.Address1 = "";
            }

            if (!dto.Address2) {
                dto.Address2 = "";
            }

            if (!dto.City) {
                dto.City = "";
            }

            if (!dto.State) {
                dto.State = "";
            }

            if (!dto.Zipcode) {
                dto.Zipcode = "";
            }

            if (!dto.Latitude) {
                dto.Latitude = 0;
            }

            if (!dto.Longitude) {
                dto.Longitude = 0;
            }

            if (!dto.LocalTimeZone) {
                dto.LocalTimeZone = "";
            }
   
            if (!dto.PatientNotes) {
                dto.PatientNotes = '';
            }

            if (!dto.LiftingDescription) {
                dto.LiftingDescription = "";
            }

            if (!dto.PetDescription) {
                dto.PetDescription = "";
            }

            if (!dto.NumberOfPets) {
                dto.NumberOfPets = 0;
            }

            if (!dto.IsSmoker) {
                dto.IsSmoker = false;
            }

            if (!dto.HasMultiplePatients) {
                dto.HasMultiplePatients = false;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/OrganizationLocation/CreatePatientMyShyftAdmin',
                data: dto
            });
        },
        updateOrganizationLocationBuilding(location) {
            let dto = {
                OrganizationID: location.OrganizationID,
                OrganizationLocationID: location.OrganizationLocationID,
                BuildingID: location.BuildingID,
                LocationName: location.LocationName,
                LocationPhoneNumber: location.LocationPhoneNumber,
                LocationType: location.LocationType,
                OrganizationTypeID: location.OrganizationTypeID,
                Address1: location.Address1,
                Address2: location.Address2,
                City: location.City,
                State: location.State,
                Zipcode: location.Zipcode,
                Latitude: location.Latitude,
                Longitude: location.Longitude,
                LocalTimeZone: location.LocalTimeZone,
                RowVersion: location.RowVersion,
                NumberOfBeds: location.NumberOfBeds,
                BuildingDescription: location.BuildingDescription
            }

            if (!dto.OrganizationID) {
                dto.OrganizationID = -1;
            }

            if (!dto.OrganizationLocationID) {
                dto.OrganizationLocationID = -1;
            }

            if (!dto.BuildingID) {
                dto.BuildingID = -1;
            }

            if (!dto.LocationName) {
                dto.LocationName = "";
            }

            if (!dto.LocationPhoneNumber) {
                dto.LocationPhoneNumber = "";
            }

            if (!dto.LocationType) {
                dto.LocationType = "";
            }

            if (!dto.OrganizationTypeID) {
                dto.OrganizationTypeID = -1;
            }

            if (!dto.Address1) {
                dto.Address1 = "";
            }

            if (!dto.Address2) {
                dto.Address2 = "";
            }

            if (!dto.City) {
                dto.City = "";
            }

            if (!dto.State) {
                dto.State = "";
            }

            if (!dto.Zipcode) {
                dto.Zipcode = "";
            }

            if (!dto.Latitude) {
                dto.Latitude = 0;
            }

            if (!dto.Longitude) {
                dto.Longitude = 0;
            }

            if (!dto.LocalTimeZone) {
                dto.LocalTimeZone = "";
            }

            if (!dto.RowVersion) {
                dto.RowVersion = "";
            }

            if (!dto.NumberOfBeds) {
                dto.NumberOfBeds = 0;
            }

            if (!dto.BuildingDescription) {
                dto.BuildingDescription = "";
            }

            return DMUtils.http({
                method: 'POST',
                url: '/OrganizationLocation/UpdateBuilding',
                data: dto
            });
        }, 
        updateOrganizationLocationPatientOrganization(location) {
            let dto = {
                OrganizationID: location.OrganizationID,
                PatientID: location.PatientID,
                OrganizationLocationID: location.OrganizationLocationID,
                LocationName: location.LocationName,
                LocationPhoneNumber: location.LocationPhoneNumber,
                LocationType: location.LocationType,
                OrganizationTypeID: location.OrganizationTypeID,                
                Address1: location.Address1,
                Address2: location.Address2,
                City: location.City,
                State: location.State,
                Zipcode: location.Zipcode,
                Latitude: location.Latitude,
                Longitude: location.Longitude,
                LocalTimeZone: location.LocalTimeZone,
                PatientFirstName: location.PatientFirstName,
                PatientLastName: location.PatientLastName,
                PatientNotes: location.PatientNotes,
                LiftingDescription: location.LiftingDescription,
                PetDescription: location.PetDescription,
                NumberOfPets: location.NumberOfPets,
                IsSmoker: location.IsSmoker,
                HasMultiplePatients: location.HasMultiplePatients,
                RowVersion: location.RowVersion
            }

            if (!dto.OrganizationID) {
                dto.OrganizationID = -1;
            }

            if (!dto.PatientID) {
                dto.PatientID = -1;
            }

            if (!dto.OrganizationLocationID) {
                dto.OrganizationLocationID = -1;
            }

            if (!dto.LocationName) {
                dto.LocationName = "";
            }

            if (!dto.LocationPhoneNumber) {
                dto.LocationPhoneNumber = "";
            }

            if (!dto.LocationType) {
                dto.LocationType = "";
            }

            if (!dto.OrganizationTypeID) {
                dto.OrganizationTypeID = -1;
            }

            if (!dto.Address1) {
                dto.Address1 = "";
            }

            if (!dto.Address2) {
                dto.Address2 = "";
            }

            if (!dto.City) {
                dto.City = "";
            }

            if (!dto.State) {
                dto.State = "";
            }

            if (!dto.Zipcode) {
                dto.Zipcode = "";
            }

            if (!dto.Latitude) {
                dto.Latitude = 0;
            }

            if (!dto.Longitude) {
                dto.Longitude = 0;
            }

            if (!dto.LocalTimeZone) {
                dto.LocalTimeZone = "";
            }

            if (!dto.PatientFirstName) {
                dto.PatientFirstName = "";
            }

            if (!dto.PatientLastName) {
                dto.PatientLastName = "";
            }
            
            if (!dto.PatientNotes) {
                dto.PatientNotes = '';
            }

            if (!dto.LiftingDescription) {
                dto.LiftingDescription = "";
            }

            if (!dto.PetDescription) {
                dto.PetDescription = "";
            }

            if (!dto.NumberOfPets) {
                dto.NumberOfPets = 0;
            }

            if (!dto.IsSmoker) {
                dto.IsSmoker = false;
            }

            if (!dto.HasMultiplePatients) {
                dto.HasMultiplePatients = false;
            }
           
            if (!dto.RowVersion) {
                dto.RowVersion = [];
            }

            return DMUtils.http({
                method: 'POST',
                url: '/OrganizationLocation/UpdatePatientOrganization',
                data: dto
            });
        },  
        updateOrganizationLocationPatientMyShyftAdmin(location) {
            let dto = {
                OrganizationID: location.OrganizationID,
                PatientID: location.PatientID,
                OrganizationLocationID: location.OrganizationLocationID,
                LocationName: location.LocationName,
                LocationPhoneNumber: location.LocationPhoneNumber,
                LocationType: location.LocationType,
                OrganizationTypeID: location.OrganizationTypeID,                
                Address1: location.Address1,
                Address2: location.Address2,
                City: location.City,
                State: location.State,
                Zipcode: location.Zipcode,
                Latitude: location.Latitude,
                Longitude: location.Longitude,
                LocalTimeZone: location.LocalTimeZone,
                PatientNotes: location.PatientNotes,
                LiftingDescription: location.LiftingDescription,
                PetDescription: location.PetDescription,
                NumberOfPets: location.NumberOfPets,
                IsSmoker: location.IsSmoker,
                HasMultiplePatients: location.HasMultiplePatients,
                RowVersion: location.RowVersion
            }

            if (!dto.OrganizationID) {
                dto.OrganizationID = -1;
            }

            if (!dto.PatientID) {
                dto.PatientID = -1;
            }

            if (!dto.OrganizationLocationID) {
                dto.OrganizationLocationID = -1;
            }

            if (!dto.LocationName) {
                dto.LocationName = "";
            }

            if (!dto.LocationPhoneNumber) {
                dto.LocationPhoneNumber = "";
            }

            if (!dto.LocationType) {
                dto.LocationType = "";
            }

            if (!dto.OrganizationTypeID) {
                dto.OrganizationTypeID = -1;
            }

            if (!dto.Address1) {
                dto.Address1 = "";
            }

            if (!dto.Address2) {
                dto.Address2 = "";
            }

            if (!dto.City) {
                dto.City = "";
            }

            if (!dto.State) {
                dto.State = "";
            }

            if (!dto.Zipcode) {
                dto.Zipcode = "";
            }

            if (!dto.Latitude) {
                dto.Latitude = 0;
            }

            if (!dto.Longitude) {
                dto.Longitude = 0;
            }

            if (!dto.LocalTimeZone) {
                dto.LocalTimeZone = "";
            }
            
            if (!dto.PatientNotes) {
                dto.PatientNotes = '';
            }

            if (!dto.LiftingDescription) {
                dto.LiftingDescription = "";
            }

            if (!dto.PetDescription) {
                dto.PetDescription = "";
            }

            if (!dto.NumberOfPets) {
                dto.NumberOfPets = 0;
            }

            if (!dto.IsSmoker) {
                dto.IsSmoker = false;
            }

            if (!dto.HasMultiplePatients) {
                dto.HasMultiplePatients = false;
            }
           
            if (!dto.RowVersion) {
                dto.RowVersion = [];
            }

            return DMUtils.http({
                method: 'POST',
                url: '/OrganizationLocation/UpdatePatientMyShyftAdmin',
                data: dto
            });
        },   
        deleteOrganizationLocation(location) {
            let dto = {
                OrganizationLocationID: location.OrganizationLocationID,
                RowVersion: location.RowVersion
            }

            if (!dto.OrganizationLocationID) {
                dto.OrganizationLocationID = -1;
            }

            if (!dto.RowVersion) {
                dto.RowVersion = [];
            }

            return DMUtils.http({
                method: 'POST',
                url: '/OrganizationLocation/Delete',
                data: dto
            });
        }, 
        //#endregion Organization Locations

        //#region Organization Types
        getAllOrganizationCategories() {
            return DMUtils.http({
                method: 'GET',
                url: '/OrganizationType/GetAllCategories',
            }).then((response) => {
                return response;
            });
        },
        getAllOrganizationTypes(category) {
            return DMUtils.http({
                method: 'GET',
                url: '/OrganizationType/GetAll',
                params: { Category: category }
            }).then((response) => {
                return response;
            });
        },
        //#endregion Organization Types

        //#region CaregiverOrganizationTypes
        getAllCaregiverOrganizationTypesForCaregiverMyShyftAdmin(caregiverID) {
            return DMUtils.http({
                method: 'GET',
                url: '/CaregiverOrganizationType/GetAllForCaregiverMyShyftAdmin',
                params: { CaregiverID: caregiverID },
            }).then((response) => {
                return response;
            });
        },
        toggleStatusCaregiverOrganizationType(caregiverOrganizationType) {
            let dto = {
                CaregiverOrganizationTypeID: caregiverOrganizationType.CaregiverOrganizationTypeID,
                OrganizationTypeID: caregiverOrganizationType.OrganizationTypeID,
                CaregiverID: caregiverOrganizationType.CaregiverID,
                RowVersion: caregiverOrganizationType.RowVersion
            }

            if (!dto.CaregiverOrganizationTypeID) {
                dto.CaregiverOrganizationTypeID = -1;
            }
            
            if (!dto.OrganizationTypeID) {
                dto.OrganizationTypeID = -1;
            }

            if (!dto.CaregiverID) {
                dto.CaregiverID = -1;
            }

            if (!dto.RowVersion) {
                dto.RowVersion = '';
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CaregiverOrganizationType/ToggleStatus',
                data: dto,
            }).then((response) => {
                return response;
            });
        },

        //#endregion CaregiverOrganizationTypes

        //#region CaregiverFirstShifts
        getAllIncompleteCaregiverFirstShifts() {
            return DMUtils.http({
                method: 'GET',
                url: '/CaregiverFirstShift/GetAllIncomplete',
            }).then((response) => {
                return response;
            });
        },
        getAllCaregiverFirstShifts(paramsDto) {
            if (paramsDto === undefined) {
                paramsDto = {};
            }

            let dto = {
                Status: paramsDto.Status,
                SearchText: paramsDto.SearchText,
                RowsPerPage: paramsDto.RowsPerPage,
                PageToLoad: paramsDto.PageToLoad,
                SortByColumn: paramsDto.SortByColumn,
                SortDescending: paramsDto.SortDescending,
            };

            if (!dto.SearchText) {
                dto.SearchText = '';
            }
            if (!dto.Status) {
                dto.Status = "";
            }

            if (!dto.RowsPerPage) {
                dto.RowsPerPage = 1000;
            }

            if (!dto.PageToLoad) {
                dto.PageToLoad = 1;
            }

            if (!dto.SortByColumn) {
                dto.SortByColumn = '';
            }

            if (dto.SortDescending === undefined) {
                dto.SortDescending = true;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CaregiverFirstShift/GetAll',
                data: dto,
            }).then((response) => {
                return response;
            });
        },
        getCaregiverFirstShiftByID(caregiverFirstShiftID) {
            return DMUtils.http({
                method: 'GET',
                url: '/CaregiverFirstShift/GetByIDToggle',
                params: { CaregiverFirstShiftID: caregiverFirstShiftID },
            }).then((response) => {
                return response;
            });
        },
        toggleCaregiverFirstShiftsStatus(toggleDto) {
            if (toggleDto === undefined) {
                toggleDto = {};
            }
            let dto = {
                IsCompleted: toggleDto.IsCompleted,
                CaregiverFirstShiftID: toggleDto.CaregiverFirstShiftID,
                RowVersion: toggleDto.RowVersion
            };
            if (!dto.IsCompleted) {
                dto.IsCompleted = false;
            }

            if (!dto.CaregiverFirstShiftID) {
                dto.CaregiverFirstShiftID = 0;
            }

            if (!dto.RowVersion) {
                dto.RowVersion = 1;
            }

            return DMUtils.http({
                method: 'POST',
                url: '/CaregiverFirstShift/ToggleStatus',
                data: dto,
            }).then((response) => {
                return response;
            });
        }
        //#endregion CaregiverFirstShifts
    },
    
    endpointsMessages
);
