<template src="./licenseGlobalRatesModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'license-global-rates-modal',
        mixins: [dmModalMixin],
        data() {
            
            return {
                userRole: this.$store.state.AuthorizationModule.SignedInUser.Role,
                licenseGlobalRatesTableColumns: [],
                organizationAdminLicenseGlobalRatesTableColumns: [],
                myShyftAdminLicenseGlobalRatesTableColumns: [],
                licenseGlobalRatesTableSortBy: {
                    columns: [
                        { field: 'MedicalLicenseName', direction: 'Ascending' },
                    ]
                },
                licenseGlobalRatesTableCustomClass: 'dm-bg-color-black',
                selectedRow: null
            }
        },
        computed: {
            lblTitle() {
                return 'Professional License Global Desired Rates';
            },
            licenseGlobalRatesTableItems() {
                return this.$store.state.LicenseGlobalRatesModule.AllGlobalRates;
            },
            organizationGlobalRateID() {
                return this.selectedRow.rowData.OrganizationGlobalRateID;
            },
            organizationID() {
                return this.selectedRow.rowData.OrganizationID;
            },
            accountType() {
                return this.modalData.accountType;
            },
            licenseGlobalRatesTableActions() {
                if (this.userRole === 'MyShyftAdmin' || this.userRole === 'MyShyftEmployee') {
                    return [{text: 'Edit'}, {text: 'Delete'},]
                }

                return []
            }
        },
        methods: {
            licenseGlobalRatesTableRowOnClick(args) {
                this.selectedRow = args;
            },
            btnCloseOnClick(){
                this.closeModal();
            },        
            addLicenseRate() {
                let modalData = {
                    mode: 'add',
                    dto: {},
                    organizationID: this.modalData.organizationID
                };

                DMUtils.openApplicationModal('editLicenseGlobalRatesModal', modalData);
            },   
            editLicenseRate() {
                let modalData = {
                    mode: 'edit',
                    dto: {},
                    organizationID: this.modalData.organizationID
                };

               let globalRateIDs = {
                    organizationGlobalRateID: this.organizationGlobalRateID,
                    organizationID: this.modalData.organizationID
               }
                
                this.$store
                    .dispatch('LicenseGlobalRatesModule/getOrganizationGlobalRateByID', globalRateIDs)
                        .then((response) => {
                            modalData.dto = response;                     
                            DMUtils.openApplicationModal('editLicenseGlobalRatesModal', modalData);
                    });                
            },
            deleteLicenseRate() {
                let dto = {
                    OrganizationGlobalRateID: this.organizationGlobalRateID,
                    OrganizationID: this.modalData.organizationID,
                    RowVersion: this.selectedRow.rowData.RowVersion,           
                };

                if (this.accountType === 'MyShyftUser'){
                    this.$store.dispatch('LicenseGlobalRatesModule/deleteOrganizationGlobalRateMyShyftAdmin', dto);
                } else {
                    this.$store.dispatch('LicenseGlobalRatesModule/deleteOrganizationGlobalRate', dto);
                }
            },
            licenseGlobalRatesTableActionOnClick(args) {
                if (args.item.text === 'Edit') {
                    this.editLicenseRate();
                } else if (args.item.text == 'Delete'){
                    const message = 'Are you sure you want to delete this License Rate?';
                    const title = 'Confirm';
                    const confirmCallback = this.deleteLicenseRate;

                    DMUtils.openApplicationModal('dmConfirmModal', {
                        title: title,
                        message: message,
                        confirmCallback: confirmCallback,
                        confirmText: 'Yes',
                        denyText: 'Cancel',
                    });                
                }           
            },
        },
        created() {
            if (this.accountType === 'MyShyftUser'){
                this.$store.dispatch('LicenseGlobalRatesModule/getAllOrganizationGlobalRatesForOrganizationMyShyftAdmin', this.modalData.organizationID);
            } else {
                this.$store.dispatch('LicenseGlobalRatesModule/getAllOrganizationGlobalRatesForOrganization');
            }

            this.organizationAdminLicenseGlobalRatesTableColumns = 
            [
                {
                    key: 'MedicalLicenseName',
                    label: 'License Name',
                },
                {
                    key: 'MaxDesiredRate',
                    label: 'Max Desired Organization Hourly Rate',
                    format: 'C2',
                    textAlign: 'Right',
                    width: '290px'
                },
            ]

            this.myShyftAdminLicenseGlobalRatesTableColumns = 
            [
                {
                    type: 'iconTemplate',
                    iconName: 'ellipsis',
                    ellipsisActions: this.licenseGlobalRatesTableActions,
                    iconOnClick: this.licenseGlobalRatesTableActionOnClick,
                    width: '55px',
                },
                {
                    key: 'MedicalLicenseName',
                    label: 'License Name',
                },
                {
                    key: 'MaxDesiredRate',
                    label: 'Max Desired Organization Hourly Rate',
                    format: 'C2',
                    textAlign: 'Right',
                    width: '290px'
                },
            ]

            this.licenseGlobalRatesTableColumns = [             
                {
                    key: 'MedicalLicenseName',
                    label: 'License Name',
                },
                {
                    key: 'MaxDesiredRate',
                    label: 'Max Desired Organization Hourly Rate',
                    format: 'C2',
                    textAlign: 'Right',
                    width: '290px'
                },
            ];           
        },
    }
</script>