<template src="./viewShiftsForInvoiceModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'invoice-view-shifts-modal',
        mixins: [dmModalMixin],
        data() {
            return {
                invoiceID: 0,
                accountType: "",
                searchType: "",
                invoiceShiftsOrganizationTableColumns: [],
                invoiceShiftsMyShyftAdminTableColumns: [],
                invoiceShiftsTableSortBy: {
                    columns: [
                        { field: 'ShiftID', direction: 'Ascending' },
                    ]
                },
                selectedRow: null,
                hourlyRateMarkup: 0
            }
        },
        computed: {
            lblTitle() {
                if (this.searchType === "Invoice"){
                    return 'Shifts for Invoice ID: ' + this.invoiceID;
                } else {
                    return 'Shifts Not Invoiced';
                }
            },
            invoiceShiftsTableItems() {
                let shifts = [];
                let moment = require('moment-timezone');

                if (this.searchType === "Invoice"){
                    shifts = this.$store.state.ShiftModule.AllShiftsForInvoice;
                } else {
                    shifts = this.$store.state.ShiftModule.AllShiftsNotInvoiced;
                }

                return shifts;
            },
            userRole() {
                return this.$store.state.AuthorizationModule.SignedInUser.Role;
            }
        },
        methods: {
            invoiceShiftsTableRowOnClick(args) {
                this.selectedRow = args;
            },
            btnCloseOnClick(){
                this.closeModal();
            },           
        },
        created() {
            let self = this;

            this.$store
                .dispatch('ShiftModule/getHourlyRateMarkup')
                .then((response) => {
                    this.hourlyRateMarkup = response.HourlyRateMarkupPercentage;
                });

            this.invoiceID = this.modalData.invoiceID;
            this.accountType = this.modalData.accountType;
            this.searchType = this.modalData.searchType;
            if (this.userRole === 'MyShyftAdmin' || this.userRole === 'MyShyftEmployee' || this.userRole === 'MyShyftViewer'){
                if (this.searchType === "Invoice"){
                    this.$store.dispatch('ShiftModule/getAllForInvoiceIDMyShyftAdmin', this.invoiceID);
                }
                else {
                    this.$store.dispatch('ShiftModule/getNotInvoicedMyShyftAdmin');
                }
            }
            else {
                if (this.searchType === "Invoice"){
                    this.$store.dispatch('ShiftModule/getAllForInvoiceIDOrganization', this.invoiceID);
                }
                else {
                    this.$store.dispatch('ShiftModule/getNotInvoicedOrganization');
                }
            }
            this.invoiceShiftsMyShyftAdminTableColumns = [
                {
                    key: 'ShiftID',
                    label: 'Shift ID',
                    width: '100px'
                },
                {
                    key: 'CaregiverName',
                    label: 'Caregiver Name',
                },
                {
                    key: 'TotalOrganizationAmountForInvoiceID',
                    label: 'Org. Amount',
                    format: 'C2',
                    textAlign: 'Right',
                    width: '150px',
                },
                {
                    key: 'TotalCaregiverAmountForInvoiceID',
                    label: 'Caregiver Amount',
                    format: 'C2',
                    textAlign: 'Right',
                    width: '160px',
                },
                {
                    key: 'TotalIncomeAmountForInvoiceID',
                    label: 'Income Amount',
                    format: 'C2',
                    textAlign: 'Right',
                    width: '150px',
                },
                {
                    key: 'ShiftStartUtc',
                    label: 'Shift Start Time',
                    type: 'date',
                    width: '200px',
                    formatter(column, shift) {
                        return moment.tz(shift.ShiftStartUtc, shift.LocalTimeZone).format("MM/DD/YYYY hh:mmA z"); 
                    }
                },
                {
                    key: 'ShiftEndUtc',
                    label: 'Shift End Time',
                    type: 'date',
                    width: '200px',
                    formatter(column, shift) {
                        return moment.tz(shift.ShiftEndUtc, shift.LocalTimeZone).format("MM/DD/YYYY hh:mmA z"); 
                    }
                },
            ];
            this.invoiceShiftsOrganizationTableColumns = [
                {
                    key: 'ShiftID',
                    label: 'Shift ID',
                    width: '100px'
                },
                {
                    key: 'CaregiverName',
                    label: 'Caregiver',
                },
                {
                    key: 'TotalOrganizationAmountForInvoiceID',
                    label: 'Invoiced Amount',
                    format: 'C2',
                    textAlign: 'Right',
                    width: '150px',
                },
                {
                    key: 'ConfirmedHourlyRate',
                    label: 'Caregiver Hourly Rate',
                    format: 'C2',
                    textAlign: 'Right',
                    width: '190px',
                },
                {
                    key: 'ConfirmedHourlyRate',
                    label: 'Org. Hourly Rate',
                    format: 'C2',
                    textAlign: 'Right',
                    width: '170px',
                    formatter(column, data) {
                        return '$' + (parseFloat(data.ConfirmedHourlyRate) * (1 + self.hourlyRateMarkup)).toFixed(2);
                    }
                },
                {
                    key: 'ShiftStartUtc',
                    label: 'Shift Start Time',
                    type: 'date',
                    width: '200px',
                    formatter(column, shift) {
                        return moment.tz(shift.ShiftStartUtc, shift.LocalTimeZone).format("MM/DD/YYYY hh:mmA z"); 
                    }
                },
                {
                    key: 'ShiftEndUtc',
                    label: 'Shift End Time',
                    type: 'date',
                    width: '200px',
                    formatter(column, shift) {
                        return moment.tz(shift.ShiftEndUtc, shift.LocalTimeZone).format("MM/DD/YYYY hh:mmA z"); 
                    }
                },
            ];
        },
    }
</script>