import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import authorizationModule from '../views/shared/authorization/Authorization.store.js';
import navigationModule from '../../dmFramework/dmJS/dmNavigationModule.store.js';
import modalModule from '../../dmFramework/dmJS/dmModalModule.store.js';
import adminModule from '../views/admin/admin.store.js';
import menusModule from '../views/menus/menu.store.js';
import adminDashboardModule from '../views/admin/adminDashboard/adminDashboard.store.js';
import organizationDashboardModule from '../views/organization/organizationDashboard/organizationDashboard.store.js';
import adminOrganizationModule from '../views/admin/organization/organization.store.js';
import adminCaregiverModule from '../views/admin/caregiver/caregiver.store.js';
import blockedCaregiverModule from '../views/menus/blockedCaregivers.store';
import favoriteCaregiverModule from '../views/menus/favoriteCaregivers.store';
import userModule from '../views/shared/users/users.store.js';
import frequentlyAskedQuestionModule from '../views/menus/frequentlyAskedQuestion.store';
import legalDocumentModule from '../views/menus/legalDocument.store';
import medicalLicenseModule from '../views/menus/medicalLicenses.store.js';
import proofOfOwnershipsModule from '../views/menus/proofOfOwnerships.store.js';
import shiftModule from '../views/shared/shifts/shifts.store.js';
import invoiceModule from '../views/shared/invoices/invoices.store.js';
import supportModule from '../views/shared/supportRequests/supportRequests.store';
import csvExportModule from '../views/menus/exportCSVs.store';
import licenseGlobalRatesModule from '../views/shared/organizationGlobalRates/licenseGlobalRates.store';
import documentCenterModule from '../views/admin/caregiver/caregiverDocumentCenter/documentCenter.store.js';
import organizationLocationsModule from '../views/shared/organizationLocations/organizationLocations.store.js';
Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        AuthorizationModule:            authorizationModule,
        NavigationModule:               navigationModule,
        ModalModule:                    modalModule,
        AdminDashboardModule:           adminDashboardModule,
        OrganizationDashboardModule:    organizationDashboardModule,
        AdminOrganizationModule:        adminOrganizationModule,
        AdminCaregiverModule:           adminCaregiverModule,
        BlockedCaregiverModule:         blockedCaregiverModule,
        FavoriteCaregiverModule:        favoriteCaregiverModule,
        AdminModule:                    adminModule,
        MenusModule:                    menusModule,
        UserModule:                     userModule,
        ShiftModule:                    shiftModule,
        FrequentlyAskedQuestionModule:  frequentlyAskedQuestionModule,
        LegalDocumentModule:            legalDocumentModule,
        MedicalLicenseModule:           medicalLicenseModule,
        ProofOfOwnershipModule:         proofOfOwnershipsModule,
        InvoiceModule:                  invoiceModule,
        SupportModule:                  supportModule,
        CsvExportModule:                csvExportModule,
        LicenseGlobalRatesModule:       licenseGlobalRatesModule,
        DocumentCenterModule:           documentCenterModule,
        OrganizationLocationModule:     organizationLocationsModule
    },
    state: {
        localStoragePrefix: 'myshyft-admin-users-',
    },
});

export default store;
