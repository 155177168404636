import endpoints from "../../js/endpoints";

const frequentlyAskedQuestionModule = {
    namespaced: true,
    state: () => ({
        AllFrequentlyAskedQuestions: [],
        AllFrequentlyAskedQuestionUserTypes: []
    }),
    mutations: {
        setFrequentlyAskedQuestions(state, faqs) {
            state.AllFrequentlyAskedQuestions = faqs;
        },
        setFrequentlyAskedQuestionUserTypes(state, types) {
            state.AllFrequentlyAskedQuestionUserTypes = types;
        },
    },
    actions: {
        getByIDFrequentlyAskedQuestion({}, frequentlyAskedQuestionID) {
            return endpoints.getByIDFrequentlyAskedQuestion(frequentlyAskedQuestionID)
                .then((response) => {
                    return response;
                });
        },   
        getAllFrequentlyAskedQuestions({commit}, userType) {
            commit('setFrequentlyAskedQuestions', []);

            return endpoints.getAllFrequentlyAskedQuestions(userType)
                .then((response) => {
                    commit('setFrequentlyAskedQuestions', response);
                    return response;
                });
        },   
        getAllMyShyftAdminFrequentlyAskedQuestions({commit}) {
            commit('setFrequentlyAskedQuestions', []);

            return endpoints.getAllMyShyftAdminFrequentlyAskedQuestions()
                .then((response) => {
                    commit('setFrequentlyAskedQuestions', response);
                    return response;
                });
        },   
        getFrequentlyAskedQuestionsUserTypes({commit}) {
            commit('setFrequentlyAskedQuestionUserTypes', []);

            return endpoints.getFrequentlyAskedQuestionsUserTypes()
                .then((response) => {
                    commit('setFrequentlyAskedQuestionUserTypes', response);
                    return response;
                });
        },    
        createFrequentlyAskedQuestion({dispatch}, dto) {
            return endpoints.createFrequentlyAskedQuestion(dto)
                .then(() => {
                    dispatch('getAllMyShyftAdminFrequentlyAskedQuestions');
                });
        },
        updateFrequentlyAskedQuestion({dispatch}, dto) {
            return endpoints.updateFrequentlyAskedQuestion(dto)
                .then(() => {
                    dispatch('getAllMyShyftAdminFrequentlyAskedQuestions');
                });
        },
        deleteFrequentlyAskedQuestion({dispatch}, frequentlyAskedQuestionID) {
            return endpoints.getByIDFrequentlyAskedQuestion(frequentlyAskedQuestionID)
                .then((response) => {
                    return endpoints.deleteFrequentlyAskedQuestion(response)
                })
                .then(() => {
                    dispatch('getAllMyShyftAdminFrequentlyAskedQuestions');
                });
        },           
    },
};

export default frequentlyAskedQuestionModule;
