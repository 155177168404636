<template>
    <div
        @click="card.onDetailsClick(card.chat)"
        class="messages-card dm-flex-row dm-margin-bottom-sm">
        <div class="messages-card-unread-messages dm-flex-column dm-justify-content-center dm-position-relative">
            <div v-if="card.unreadMessages > 0" class="unread-messages-icon">
                <div class="unread-message-count">{{ card.unreadMessages > 99 ? '99+' : card.unreadMessages }}</div>
            </div>
        </div>


        <div class="dm-flex-column dm-flex-1 dm-overflow-hidden">
            <div
                class="dm-flex-row dm-margin-bottom-sm">
                <div class="">
                    <div class="app-card-image" :style="'background-image: url(' + card.image + ')'"></div>
                </div>
                <div class="app-card-info dm-flex-column dm-justify-content-center">
                    <div class="dm-flex-row">
                        <div class="dm-flex-1 dm-text-ellipsis">
                            <b class="app-padding-bottom-xs" v-html="card.info1"></b>
                        </div>

                        <span class="app-padding-bottom-xs dm-padding-left-sm app-text-grey" v-html="card.info2"></span>
                    </div>
                    <span class="app-padding-bottom-xs" v-html="card.info3"></span>
                </div>
            </div>

            <div
                class="dm-flex-column">
                <span class="app-text-grey dm-text-ellipsis" v-html="card.info4"></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'messages-card',
    props: ['card'],
    data() {
        return {}
    }
}
</script>