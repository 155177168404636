<template src="./resetPassword.html"></template>

<script>
import dmStates from '../../../../../dmFramework/dmJS/dmStates.js';

    export default {
        name: 'reset-password-page',
        
        data() {
            return {
                dto: {},
                message: ''
            }
        },
        methods: {
            updatePassword() {
                this.$store.dispatch('AuthorizationModule/resetPasswordFromToken', this.dto)
                    .then(() => {
                        this.message = 'You password has reset successfully.';
                    });
            },
            sendNewPassword() {
                this.$store
                    .dispatch('AuthorizationModule/sendResetPasswordEmail', this.dto.EmailAddress)
                    .then((response) => {
                        DMUtils.alertShow(
                            response.Message
                        );
                    });
            },
        },     
        created() {
            this.dto.ResetToken = this.$route.params.token;             
        },        
    }
</script>
