<template src="./caregiverPage.html"></template>

<script>
import Vue from 'vue';
import dmStates from '../../../../../dmFramework/dmJS/dmStates.js';

export default {
    name: 'admin-caregivers-page',
    data() {
        return {
            searchStartDate: '',
            searchEndDate: '',
            statusList: [],
            stateList: dmStates,
            caregiversTableColumns: [],
            caregiversTableSortBy: {
                columns: [{ field: 'CreatedUtc', direction: 'Descending' }],
            },
            caregiversTablePageSettings: { pageSize: 300, pageSizes: [500, 300, 100, 50], pageCount: 4, direction: 'Descending' },
            caregiversTableCustomClass: 'dm-bg-color-black',
            caregiversTableActions: [
                {
                    text: 'View Profile',
                },
                {
                    text: 'View Chat',
                },
                {
                    text: 'View Recent Payouts',
                },
                {
                    text: 'View Penalties',
                },
                { 
                    text: 'View History',
                },
                {
                    text: 'Delete',
                },
            ],
            caregiversDeletedTableActions: [
                {
                    text: 'Toggle Active Status',
                },
                {
                    text: 'View Profile',
                },
                {
                    text: 'View Chat',
                },
                {
                    text: 'View Recent Payouts',
                },
                {
                    text: 'View History',
                },
                {
                    text: 'Recover',
                },
            ],
            incompleteCaregiversTableActions: [
                {
                    text: 'View'
                }
            ],
            selectedRow: null,
        };
    },
    computed: {
        caregiversTableItems() {
            let caregivers = this.$store.state.AdminCaregiverModule.AllCaregivers;

            if (caregivers) {
                caregivers.forEach((caregiver) => {
                    caregiver.myShyftAdminTimeZone = this.myShyftAdminTimeZone;
                    if (caregiver.IsDeleted === true) {
                        caregiver.Status = 'Deleted';
                    }
                });
            }

            return {
                result: caregivers,
                count: this.$store.state.AdminCaregiverModule.AllCaregiversNumberOfRows,
            };
        },
        userID() {
            return this.selectedRow.rowData.UserID;
        },
        myShyftAdminTimeZone() {
            return this.$store.state.AdminModule.MyShyftAdminTimeZone;
        },
        paramsDto: {
            get() {
                return this.$store.state.AdminCaregiverModule.ParamsDto;
            },
            set(value) {
                this.$store.commit('setParamsDto', value);
            },
        },
    },
    methods: {
        scrollGridToTop() {
            this.$refs.caregiversTable.$refs.grdTableList.getContent().children[0].scrollTop = 0;
        },
        caregiversTableRowOnClick(args) {
            this.selectedRow = args;
        },
        searchCaregivers() {
            let moment = require('moment-timezone');
            let utcNow = moment.utc();

            let startDay = moment.utc(this.searchStartDate).startOf('day');
            let endDay = moment.utc(this.searchEndDate).startOf('day').endOf('day');
            let timeZoneOffset = moment.tz.zone(this.myShyftAdminTimeZone).utcOffset(utcNow);

            this.paramsDto.StartDateRange = startDay.add(timeZoneOffset).format();
            this.paramsDto.EndDateRange = endDay.add(timeZoneOffset).format();
            this.paramsDto.PageToLoad =
                this.$refs.caregiversTable.$refs.grdTableList.ej2Instances.pagerModule.pagerObj.properties.currentPage;
            this.paramsDto.RowsPerPage = this.caregiversTablePageSettings.pageSize;
            this.paramsDto.SortByColumn = this.caregiversTablePageSettings.columnName;
            this.paramsDto.SortDescending = this.caregiversTablePageSettings.direction === 'Descending';

            this.scrollGridToTop();

            this.$store.dispatch('AdminCaregiverModule/getAllCaregivers', this.paramsDto);
        },
        viewCaregiver() {
            this.$store.dispatch('AdminCaregiverModule/getCaregiverByIDMyShyftAdmin', this.userID).then((response) => {
                let modalData = {
                    dto: response,
                };
                DMUtils.openApplicationModal('caregiverProfileModal', modalData);
            });
        },
        viewIncompleteCaregiver() {                                        
                this.$store.dispatch('AdminCaregiverModule/getAllPagesByUserIDMyShyftAdmin', this.selectedRow.rowData.UserID).then((response) => {   
                    let modalData = {
                        caregiverPages: response,
                        userID: this.selectedRow.rowData.UserID,
                        paramsDto: this.paramsDto
                    };
                    DMUtils.openApplicationModal('incompleteCaregiversEditModal', modalData);
                });              
            },    
        viewChat() {
            let modalData = {
                selectedCaregiverID: this.selectedRow.rowData.CaregiverID,
                selectedCaregiverName: this.selectedRow.rowData.FirstLastName,
            };

            DMUtils.openApplicationModal('messagesAdminThreadModal', modalData);
        },
        viewRecentPayouts() {
            let modalData = {
                dto: {
                    UserID: this.selectedRow.rowData.UserID,
                    FirstLastName: this.selectedRow.rowData.FirstLastName,
                },
            };
            DMUtils.openApplicationModal('caregiverPayoutsModal', modalData);
        },
        viewPenalties() {
            let modalData = {
                dto: {
                    CaregiverID: this.selectedRow.rowData.CaregiverID,
                    FirstLastName: this.selectedRow.rowData.FirstLastName,
                },
            };
            DMUtils.openApplicationModal('caregiverPenaltiesModal', modalData);
        },
        viewHistory() {
            let modalData = {
                dto: {
                    CaregiverID: this.selectedRow.rowData.CaregiverID,
                    FirstLastName: this.selectedRow.rowData.FirstLastName,
                },
            };
            DMUtils.openApplicationModal('caregiverHistoryModal', modalData);
        },
        deleteCaregiver() {
            this.$store.dispatch('AdminCaregiverModule/deleteCaregiver', this.userID);
        },
        recoverCaregiver() {
            this.$store.dispatch('AdminCaregiverModule/recoverCaregiver', this.userID);
        },
        caregiversTableActionOnClick(args) {
            if (args.item.text === 'View Profile') {
                this.viewCaregiver();
            } else if (args.item.text === 'View') {
                this.viewIncompleteCaregiver();
            } else if (args.item.text === 'View Chat') {
                this.viewChat();
            } else if (args.item.text === 'View Recent Payouts') {
                this.viewRecentPayouts();
            } else if (args.item.text === 'View Penalties') {
                this.viewPenalties();
            } else if (args.item.text === 'View History') {
                this.viewHistory();
            } else if (args.item.text === 'Delete') {
                const message = 'Are you sure you want to delete this Caregiver?';
                const title = 'Confirm';
                const confirmCallback = this.deleteCaregiver;

                DMUtils.openApplicationModal('dmConfirmModal', {
                    title: title,
                    message: message,
                    confirmCallback: confirmCallback,
                    confirmText: 'Yes',
                    denyText: 'Cancel',
                });
            } else if (args.item.text === 'Recover') {
                const message = 'Are you sure you want to recover this Caregiver?';
                const title = 'Confirm';
                const confirmCallback = this.recoverCaregiver;

                DMUtils.openApplicationModal('dmConfirmModal', {
                    title: title,
                    message: message,
                    confirmCallback: confirmCallback,
                    confirmText: 'Yes',
                    denyText: 'Cancel',
                });
            }
        },
        caregiversTableDataStateChange(state) {
            if (state.action.requestType === 'sorting' && state.action.direction && state.action.columnName) {
                this.caregiversTablePageSettings.direction = state.action.direction;
                this.caregiversTablePageSettings.columnName = state.action.columnName;
                this.searchCaregivers();
            }

            if (state.action.requestType === 'paging') {
                this.caregiversTablePageSettings.pageSize = state.take;
                this.searchCaregivers();
            }
        },
    },
    created() {
        this.caregiversTablePageSettings.direction = 'Descending';
        this.$store
            .dispatch('AdminCaregiverModule/getAllCaregiverStatuses')
            .then((response) => {
                this.statusList = response;
        });

        let self = this;
        this.caregiversTableColumns = [
            {
                type: 'template',
                width: '55px',
                template: function () {
                    return {
                        template: Vue.component('columnTemplate', {
                            template: `<ejs-dropdownbutton
											:items="grdRowActions"
											:select="select"
											iconCss="e-icons e-ellipsis-vert"
											cssClass="e-caret-hide">
									</ejs-dropdownbutton>`,
                            methods: {
                                select(args) {
                                    self.caregiversTableActionOnClick(args);
                                },
                            },
                            computed: {
                                grdRowActions: function () {
                                    if (this.data.IsDeleted === true) {
                                        return self.caregiversDeletedTableActions;
                                    } else if (this.data.IsIncomplete === true) {
                                        return self.incompleteCaregiversTableActions;
                                    } else {
                                        return self.caregiversTableActions;
                                    }
                                },
                            },
                        }),
                    };
                },
            },
            {
                key: 'UserID',
                label: 'ID',
                width: '75px',
            },
            {
                key: 'FirstLastName',
                label: 'Name',
                minWidth: '200px',
            },
            {
                key: 'EmailAddress',
                label: 'Email Address',
                minWidth: '200px',
            },
            {
                key: 'PhoneNumberF',
                label: 'Phone Number',
                width: '150px'
            },
            {
                key: 'City',
                label: 'City',
                width: '200px',
                allowSorting: false
            },
            {
                key: 'State',
                label: 'State',
                width: '100px',
                allowSorting: false
            },
            {
                key: 'Status',
                label: 'Status',
                width: '120px',
                allowSorting: false,
                template: function () {
                    return {
                        template: Vue.component('columnTemplate', {
                            template: `<div :class="setColor">{{data.Status}}</div>`,
                            computed: {
                                setColor: function () {
                                    if (this.data.Status === 'Inactive' 
                                     || this.data.Status === 'Suspended'
                                     || this.data.Status === 'Deactivated'
                                     || this.data.Status === 'Disqualified'
                                     || this.data.Status === 'Banned'
                                     || this.data.Status === 'Duplicate'
                                    ) {
                                        return 'app-grid-status-orange';
                                    }

                                    if (this.data.Status === 'Active') {
                                        return 'app-grid-status-green';
                                    }   

                                    if (this.data.Status === 'Incomplete' || this.data.Status === 'Deleted') {
                                        return 'app-grid-status-red';
                                    }   
                                },                             
                            },
                        }),
                    };
                },
            },
            {
                key: 'CreatedUtc',
                label: 'Signed Up Date',
                type: 'date',
                width: '200px',
                formatter(column, caregiver) {
                    return moment.tz(caregiver.CreatedUtc, caregiver.myShyftAdminTimeZone).format("MM/DD/YYYY hh:mmA z"); 
                }
            },
        ];
    },
    mounted() {
        this.paramsDto.Status = "";
        this.paramsDto.SearchText = "";
        this.paramsDto.State = "";
        this.searchCaregivers();
    },
};
</script>
