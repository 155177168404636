<template src="./caregiverProofOfOwnershipEditModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin';
import imageUploadMixin from '../../../../js/imageUploadMixin.js'

export default {
    name: 'caregivers-proof-of-ownership-edit-modal',
    mixins: [dmModalMixin, imageUploadMixin],
    data() {
        return {
            dto: {},
            proofNames: [],
        };
    },
    computed: {
        mode() {
            return this.modalData.mode;
        },
        lblTitle() {
            return this.modalData.mode === 'edit' ? 'Edit: ' + this.getFormattedProofName : 'Add Document';
        },
        myShyftAdminTimeZone() {
            return this.$store.state.AdminModule.MyShyftAdminTimeZone;
        },
        proofTypes() {
            let selectedProof = this.proofNames.find((proof) => {
                return proof.Name === this.dto.ProofName;
            });

            if (selectedProof) {
                return selectedProof.Types;
            }

            return [];
        },
        getFormattedCreatedUtc() {
            let moment = require('moment-timezone');
            return moment.tz(this.dto.CreatedUtc, this.myShyftAdminTimeZone).format('M/D/YYYY');
        },
        getFormattedUpdatedUtc() {
            let moment = require('moment-timezone');
            return moment.tz(this.dto.UpdatedUtc, this.myShyftAdminTimeZone).format('M/D/YYYY');
        },
        getReportDate() {
            let moment = require('moment-timezone');
            let utcNow = moment.utc();
            let timeZoneOffset = moment.tz.zone(this.myShyftAdminTimeZone).utcOffset(utcNow);

            this.dto.ReportDate = moment.utc(this.dto.ReportDate).add(timeZoneOffset).format();
        },
        getFormattedProofName() {
            let formattedName = this.modalData.dto.ProofName;
            if (this.modalData.dto.ProofType === '' || this.modalData.dto.ProofType === null) {
                return formattedName;
            }

            formattedName = formattedName + ' - ' + this.modalData.dto.ProofType;

            return formattedName;
        },
    },
    methods: {
        btnSaveOnClick() {
            if (this.mode === 'edit') {
                this.$store.dispatch('ProofOfOwnershipModule/updateCaregiverProofOfOwnership', this.dto).then(() => {
                    this.shouldModalStayOpen = false;
                    this.successClose(this.dto);
                });
            } else if (this.mode === 'add') {
                this.$store
                    .dispatch('ProofOfOwnershipModule/createCaregiverProofOfOwnershipMyShyftAdmin', this.dto)
                    .then(() => {
                        this.shouldModalStayOpen = false;
                        this.successClose(this.dto);
                    });
            }
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.dto = DMUtils.copy(this.modalData.dto);
        this.$store.dispatch('ProofOfOwnershipModule/getAllCaregiverProofOfOwnershipTypes').then((response) => {
            this.proofNames = response;
        });
    },
};
</script>
