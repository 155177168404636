<template src="./messagesAdminActiveChatPage.html"></template>

<script>
import messagesCard from '../messagesCard'
import messagesMixin from '../messagesMixin';
import logo from '../../images/logo.png'

export default {
    name: 'messages-admin-active-chat-page',
    mixins: [
        messagesMixin
    ],
    components: {
        messagesCard
    },
    data() {
        return {
            endpointsMap: {
                Caregiver: {
                    getAllMessages: 'getAllAdminMessagesCaregiver',
                },
                MyShyftAdmin: {
                    getAllMessages: 'getAllAdminMessagesMyShyftAdmin',
                },
                MyShyftEmployee: {
                    getAllMessages: 'getAllAdminMessagesMyShyftAdmin',
                },
                MyShyftViewer: {
                    getAllMessages: 'getAllAdminMessagesMyShyftAdmin',
                }
            },
            selectedMessageID: 0,
            logo: logo
        };
    },
    watch: {

    },
    methods: {
        getActiveChats(hideLoader) {
            let dto = {
                StartDateRangeUtc: this.dateValues && this.dateValues.length > 0 ? this.dateValues[0] : '',
                EndDateRangeUtc: this.dateValues && this.dateValues.length > 0 ? this.dateValues[1] : '',
                CaregiverID: this.selectedCaregiverFilterID,
                SearchText: this.searchText,
            };

            endpoints[this.userRoleEndpoints.getAllMessages](dto, hideLoader)
                .then(response => {
                    response.forEach(s => {
                        if(this.isCaregiverOrganizationApp) {
                            s.ProfileImageBase64 = logo;
                        }
                        let timezone = moment.tz.guess();
                        this.formatActiveChatDateTimeDetails(s, s.MessageCreatedUtc, s.MessageCreatedUtc, timezone);
                    });

                    this.activeChats = response;

                    if(this.selectedMessageID) {
                        let existingMessage = this.activeChats.filter(m => m.AdminMessageID === this.selectedMessageID)[0];
                        if(!existingMessage) {
                            this.selectedMessageID = null;
                            this.$emit('messageChanged', {caregiverID: null, shownMessageID: null});
                        }
                    }
                });
        },
        goToChat(message) {
            message.NumberOfUnreadMessages = 0;

            DMUtils.localStorageSet(this.filtersLocalStorageName, JSON.stringify({
                searchText: this.searchText,
                dateValues: this.dateValues,
                selectedOrganizationID: this.selectedOrganizationID,
            }));

            if(this.isCaregiverOrganizationApp) {
                this.$router.push('/adminChat/message/' + message.AdminMessageID);
            } else {
                this.selectedMessageID = message.AdminMessageID;
                this.$emit('messageChanged', {caregiverID: message.CaregiverID, shownMessageID: message.AdminMessageID});
                setTimeout(() => {
                    app.getUnreadMessageCount(this.$store.state.AuthorizationModule.SignedInUser.Role);
                }, 2000);
            }
        },
        startChat() {
            this.$router.push('/adminChat/message/' + undefined);
        },
        getCaregiverOptions() {
            endpoints.getAdminMessagedCaregivers()
                .then(response => {
                    this.caregiverOptions = DMUtils.getCboOptions(response, 'CaregiverID', 'CaregiverName');
                });
        },
    },
    created() {
        if(this.isCaregiverOrganizationApp) {

        } else {
            this.getCaregiverOptions();
        }

        this.getActiveChats();

        this.newMessagesTimer = setInterval(() => {
            if(document.hasFocus()) { // Don't keep getting messages in the background
                this.getActiveChats(true);
            }
        }, 60 * 1000);
    },
    destroyed() {
        clearInterval(this.newMessagesTimer);
    }
};
</script>
