<template src="./caregiverHistoryModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'caregiver-history-modal',
        mixins: [dmModalMixin],
        data() {
            return {
                searchString: '',
                dto: {},
                caregiverHistoryTableColumns: [],
                caregiverHistoryTableSortBy: {
                    columns: [
                        { field: 'CreatedUtc', direction: 'Descending' },
                    ]
                },
                selectedRow: null
            }
        },
        computed: {
            lblTitle() {
                return 'View Caregiver History: ' + this.dto.FirstLastName;
            },
            caregiverHistoryTableItems() {
                let history = this.$store.state.AdminCaregiverModule.AllCaregiverHistory;

                if (history){
                    history.forEach((entry) => {
                        entry.myShyftAdminTimeZone = this.myShyftAdminTimeZone;
                    });
                }

                return history;
            },
            myShyftAdminTimeZone(){
                return this.$store.state.AdminModule.MyShyftAdminTimeZone;
            },
        },
        methods: {
            caregiverHistoryTableRowOnClick(args) {
                this.selectedRow = args;
            },                  
            btnCloseOnClick(){
                this.closeModal();
            },         
        },
        created() {
            this.dto = DMUtils.copy(this.modalData.dto);
            this.$store.dispatch('AdminCaregiverModule/getCaregiverHistoryForCaregiverID', this.dto.CaregiverID);
            
            this.caregiverHistoryTableColumns = [
                {
                    key: 'CaregiverHistoryID',
                    label: 'ID',
                    width: '100px',
                },
                {
                    key: 'LastEditFirstLastName',
                    label: 'Last Edited By',
                    width: '200px'
                },
                {
                    key: 'Description',
                    label: 'Description'
                },
                {
                    key: 'CreatedUtc',
                    label: 'Created On',
                    type: "date",
                    width: '220px',
                    formatter(column, history) {
                        return moment.tz(history.CreatedUtc, history.myShyftAdminTimeZone).format("MM/DD/YYYY hh:mmA"); 
                    }
                },
            ];
        },
    }
</script>
