<template src="./shiftRequestsDeleteModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

export default {
    name: 'shift-requests-delete-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            dto: {},
            accountType: ""
        }
    },
    computed: {
        lblTitle() {
            return 'Delete a Shift';
        },
        formatShiftHourlyRate(){
            let formatted = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',               
            });
            
            return formatted.format(this.dto.ShiftHourlyRate)
        },
        deletedTypes (){
            return this.$store.state.ShiftModule.AllShiftDeletedTypes.DeletedTypes;
        }    
    },
    methods: {
        deleteShift(){
            if (this.accountType === "MyShyftUser"){
                this.$store
                    .dispatch('ShiftModule/deleteShiftMyShyftAdmin', this.dto)
                    .then(() => {
                        this.shouldModalStayOpen = false;
                        this.successClose(this.dto);
                    });
            }
            else {
                this.$store
                    .dispatch('ShiftModule/deleteShiftOrganization', this.dto)
                    .then(() => {
                        this.shouldModalStayOpen = false;
                        this.successClose(this.dto);
                    });
            }
        },
        btnSaveOnClick() {
            const message = 'Are you sure you want to Delete this Shift?';
            const title = 'Confirm';

            DMUtils.openApplicationModal('dmConfirmModal', {
                title: title,
                message: message,
                confirmCallback:  this.deleteShift,
                confirmText: 'Yes',
                denyText: 'Cancel',
            });          
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.dto = DMUtils.copy(this.modalData.dto);
        this.accountType = DMUtils.copy(this.modalData.accountType);
        this.$store.dispatch('ShiftModule/getAllDeletedTypeCodes');
    },
}
</script>
