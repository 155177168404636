<template src="./caregiverProofOfOwnershipViewModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin';

export default {
    name: 'caregivers-proof-of-ownership-view-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            dto: {},
            proofNames: [],
        };
    },
    computed: {
        lblTitle() {
            return 'View: ' + this.getFormattedProofName;
        },
        myShyftAdminTimeZone() {
            return this.$store.state.AdminModule.MyShyftAdminTimeZone;
        },
        proofTypes() {
            let selectedProof = this.proofNames.find((proof) => {
                return proof.Name === this.dto.ProofName;
            });

            if (selectedProof) {
                return selectedProof.Types;
            }

            return [];
        },
        getFormattedCreatedUtc() {
            let moment = require('moment-timezone');
            return moment.tz(this.dto.CreatedUtc, this.myShyftAdminTimeZone).format('M/D/YYYY');
        },
        getFormattedUpdatedUtc() {
            let moment = require('moment-timezone');
            return moment.tz(this.dto.UpdatedUtc, this.myShyftAdminTimeZone).format('M/D/YYYY');
        },
        getReportDate() {
            let moment = require('moment-timezone');
            return moment.tz(this.dto.ReportDate, this.myShyftAdminTimeZone).format('M/D/YYYY');
        },
        getFormattedProofName() {
            let formattedName = this.modalData.dto.ProofName;
            if (this.modalData.dto.ProofType === '' || this.modalData.dto.ProofType === null) {
                return formattedName;
            }

            formattedName = formattedName + ' - ' + this.modalData.dto.ProofType;

            return formattedName;
        },
    },
    methods: {
        approveProofOfOwnership() {
            this.$store.dispatch('ProofOfOwnershipModule/approveCaregiverProofOfOwnership', this.dto);
            this.closeModal();
        },
        denyProofOfOwnership() {
            this.$store.dispatch('ProofOfOwnershipModule/denyCaregiverProofOfOwnership', this.dto);
            this.closeModal();
        },
        btnApproveOnClick() {
            const message = 'Are you sure you want to Approve this Document?';
            const title = 'Confirm';
            const confirmCallback = this.approveProofOfOwnership;

            DMUtils.openApplicationModal('dmConfirmModal', {
                title: title,
                message: message,
                confirmCallback: confirmCallback,
                confirmText: 'Yes',
                denyText: 'Cancel',
            });
        },
        btnDenyOnClick() {
            const message = 'Are you sure you want to Deny this Document?';
            const title = 'Confirm';
            const confirmCallback = this.denyProofOfOwnership;

            DMUtils.openApplicationModal('dmConfirmModal', {
                title: title,
                message: message,
                confirmCallback: confirmCallback,
                confirmText: 'Yes',
                denyText: 'Cancel',
            });
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.dto = DMUtils.copy(this.modalData.dto);
    },
};
</script>
