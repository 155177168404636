<template src="./notificationHistoryModal.html"></template>

<script>
    import dmModalMixin from '../../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'notification-history-modal',
        mixins: [dmModalMixin],
        data() {
            return {
                searchString: '',
                searchStartDate: '',
                searchEndDate: '',
                paramsDto: {},
                lblTitle: 'Notifications',                
                notificationHistoryMyShyftAdminTableColumns: [],
                notificationHistoryOrganizationTableColumns: [],
                notificationHistoryTableSortBy: {
                    columns: [
                        { field: 'CreatedUtc', direction: 'Descending' },
                    ]
                },
                selectedRow: null
            }            
        },
        computed: {
            notificationHistoryTableItems() {
                let notificationHistory = this.$store.state.MenusModule.AllNotificationHistory;            
                
                let filteredNotificationHistory = notificationHistory.filter(
                    (notification) => {
                        return notification.MessageBody.toLowerCase().includes(this.searchString.toLowerCase());           
                    }
                )

                if (filteredNotificationHistory) {
                    filteredNotificationHistory.forEach((history) => {
                        history.myShyftAdminTimeZone = this.myShyftAdminTimeZone;                   
                    });
                }

                return filteredNotificationHistory;

            },
            myShyftAdminTimeZone(){
                return this.$store.state.AdminModule.MyShyftAdminTimeZone;
            },
            accountType() {
                return this.modalData.accountType;
            },
            signedInUser() {
                return this.$store.state.AuthorizationModule.SignedInUser;
            }
        },
        methods: {
            notificationHistoryTableRowOnClick(args) {
                this.selectedRow = args;
            },   
            searchNotificationHistory() {
                let moment = require('moment-timezone');
                let utcNow = moment.utc();

                let startDay = moment.utc(this.searchStartDate).startOf('day');
                let endDay = moment.utc(this.searchEndDate).endOf('day');   
                let timeZoneOffset = moment.tz.zone(this.myShyftAdminTimeZone).utcOffset(utcNow);

                this.paramsDto.StartDateRange = startDay.add(timeZoneOffset).format();
                this.paramsDto.EndDateRange = endDay.add(timeZoneOffset).format();              

                if (this.accountType === 'MyShyftUser'){
                    this.$store.dispatch('MenusModule/getNotificationHistoryMyShyftAdmin', this.paramsDto)
                } else {
                    this.$store.dispatch('MenusModule/getNotificationHistoryOrganization', this.paramsDto)
                }            
            },                                
            btnCancelOnClick() {
                this.closeModal();
            }, 
        },
        created() {
            if (this.accountType === 'MyShyftUser'){
                this.$store.dispatch('MenusModule/getNotificationHistoryMyShyftAdmin', this.paramsDto)
            } else {
                this.$store.dispatch('MenusModule/getNotificationHistoryOrganization', this.paramsDto)
            }

            this.notificationHistoryMyShyftAdminTableColumns = [
                {
                    key: 'FirstLastName',
                    label: "User's Name",
                    width: '200px'
                },
                {
                    key: 'OrganizationName',
                    label: "Organization Name",
                    width: '200px'
                },
                {
                    key: 'MessageBody',
                    label: 'Message',
                },
                {
                    key: 'CreatedUtc',
                    label: 'Created On',
                    type: "date",
                    width: '200px',
                    formatter(column, notification) {
                        return moment.tz(notification.CreatedUtc, notification.myShyftAdminTimeZone).format("MM/DD/YYYY hh:mmA z"); 
                    }
                },
            ];

            this.notificationHistoryOrganizationTableColumns = [
                {
                    key: 'FirstLastName',
                    label: "User's Name",
                    width: '200px'
                },
                {
                    key: 'MessageBody',
                    label: 'Message',
                },
                {
                    key: 'CreatedUtc',
                    label: 'Created On',
                    type: "date",
                    width: '200px',
                    formatter(column, notification) {
                        return moment.tz(notification.CreatedUtc, this.signedInUser.LocalTimeZone).format("MM/DD/YYYY hh:mmA z"); 
                    }
                },
            ];
        },
    }
</script>
