<template src="./shiftRequestsViewModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

export default {
    name: 'organization-requests-view-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            shiftDto: {},
        }
    },
    computed: {
        mode() {
            return this.modalData.mode;
        },
        accountType() {
            return this.modalData.accountType;
        },
        signedInUser() {
            return this.$store.state.AuthorizationModule.SignedInUser;
        },
        organizationPrimaryAddress() {
            let address = "";

            if (this.shiftDto.OrganizationAddress2 === ""){
                address =   this.shiftDto.Address1 + ", " + 
                            this.shiftDto.Address2 + ", " + 
                            this.shiftDto.City + ", " + 
                            this.shiftDto.State + " " +
                            this.shiftDto.Zipcode;
            } else {
                address =   this.shiftDto.Address1 + ", " + 
                            this.shiftDto.City + ", " + 
                            this.shiftDto.State + " " +
                            this.shiftDto.Zipcode;
            }

            if (address === ', , ,'){
                address = '';
            }

            return address;
        },
        lblTitle() {
            return 'Shift Request: ' + this.shiftDto.ShiftID;
        },
        contactLabelText() {
            if (this.signedInUser.OrganizationCategory === 'Agency' || this.shiftDto.OrganizationCategory === 'Agency') {
                return "Agency";
            } else {
                return "Arrival";
            }
        },
        getConfirmedHourlyRate() {
            if (this.shiftDto.AssignedHourlyRate === -1)
            {
                return '';
            }
            else
            {
                this.shiftDto.AssignedHourlyRate =  this.shiftDto.AssignedHourlyRate * (1 +  this.shiftDto.HourlyRateMarkUpPercentage);
                
                var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    });

                return formatter.format(this.shiftDto.AssignedHourlyRate);
            }
        },
        getMaxDesiredHourlyRate() {   
            if (this.shiftDto.MaxDesiredOrganizationRate === 0)
            {
                return 'None';
            }
            else
            {         
                var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    });

                return formatter.format(this.shiftDto.MaxDesiredOrganizationRate);
            }
        },
        getConfirmedCaregiverName() {
            if (this.shiftDto.CaregiverName === "")
            {
                return 'Shift is not Assigned';
            }
            else
            {
                return this.shiftDto.CaregiverName;
            }
        },
        getShiftStartLocalTime(){
            let moment = require('moment-timezone');

            return moment.tz(this.shiftDto.ShiftStartUtc, this.shiftDto.LocalTimeZone).format("M/D/YYYY hh:mmA z"); 
        },
        getShiftEndLocalTime(){
            let moment = require('moment-timezone');

            return moment.tz(this.shiftDto.ShiftEndUtc, this.shiftDto.LocalTimeZone).format("M/D/YYYY hh:mmA z"); 
        },
        getShiftCreatedLocalTime(){
            let moment = require('moment-timezone');

            return moment.tz(this.shiftDto.CreatedUtc, this.shiftDto.LocalTimeZone).format("M/D/YYYY hh:mmA z"); 
        },
        getShiftStatus(){
            if (this.shiftDto.ShiftStatus === "Deleted"){
                return "Deleted - " + this.shiftDto.DeletedType;
            } else {
                return this.shiftDto.ShiftStatus;
            }
        },
        getCaregiverStatusLabel(){
            if (this.shiftDto.CaregiverApplicationStatus === "Applied" || this.shiftDto.CaregiverApplicationStatus === "Assigned" || !this.shiftDto.CaregiverApplicationStatus){
                    return "Assigned";
                } else {
                    return "Confirmed";
                }
        },
        getLicensesRequested(){
            let licensesRequested = this.shiftDto.MedicalLicenses;
            let outputString = "";
            let counter = 0;

            licensesRequested.forEach(license => {
                if (counter === licensesRequested.length - 1){
                    outputString += license.LicenseName;
                } else{
                    outputString += license.LicenseName + ", ";
                }
                counter++;
            });

            return outputString;
        },
        getTypesOfCareRequested(){
            let typesOfCareRequested = this.shiftDto.TypesOfCare;
            let outputString = "";
            let counter = 0;

            typesOfCareRequested.forEach(care => {
                if (counter === typesOfCareRequested.length - 1){
                    outputString += care.TypeOfCareName;
                } else{
                    outputString += care.TypeOfCareName + ", ";
                }
                counter++;
            });

            return outputString;
        },
        getFormattedMultiLocation(){
            if (this.shiftDto.IsMultiLocation === true) {
                return "Yes";
            } else {
                return "No";
            }
        }
    },
    methods: {
        getOrganizationLocationTerminology(lowerCase, plural) {
            return app.organizationLocationTerminology(this.shiftDto.OrganizationCategory, lowerCase, plural)
        },
        btnCloseOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.shiftDto = DMUtils.copy(this.modalData.dto);
    },
}
</script>
