<template src="./incompleteCaregiversEditModal.html"></template>

<script>
    import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'
    import states from '../../../../../dmFramework/dmJS/dmStates'
    import Vue from 'vue'

    export default {
        name: 'incomplete-caregivers-edit-modal',
        mixins: [dmModalMixin],
        data() {
            let self = this;
            return {
                userID: 0,
                userDto: {},
                dto: {},
                paramsDto: {},
                currentSignUpPageName: '',
                currentSignUpPageNumber: 0,
                refreshGetAllCaregivers: false,
                pronounOptions: [],
                signUpPages: ['Create Account', 'Info', 'Reference', 'License Category', 'Experience and Insurance', 'About You'],
                profilePicturePreview: function() {
                    return {
                        template: Vue.component('profilePicturePreview', {
                            template: `<div class="container">
                                            <div class="dm-padding-sm">
                                                <div>
                                                    <div class="app-card-image" :style="'background-image: url(' + imgSrc + '); background-size: contain;'">
                                                    </div>
                                                    <div style="color: #777; font-size: 12px; padding-top: 5px;">{{ fileSize }}</div>
                                                </div>
                                                <span @click="remove" class="e-icons e-file-remove-btn" title="Remove"></span>
                                            </div>
                                    </div>`,
                            data: function() {
                                return {}
                            },
                            methods: {
                                remove() {
                                    self.$refs.uploadProfilePicture.clearAll();
                                    self.dto.CaregiverInfoPageDto.ProfileImageBase64 = '';
                                    self.dto.CaregiverInfoPageDto.ProfileImageFileName = '';
                                }
                            },
                            computed: {
                                imgSrc: function() {
                                    return URL.createObjectURL(this.data.rawFile);
                                },
                                fileSize: function() {
                                    if(self.dto.CaregiverInfoPageDto.ProfileImageBase64) {
                                        return app.formatBytes(self.dto.CaregiverInfoPageDto.ProfileImageBase64);
                                    } else {
                                        return '';
                                    }
                                }
                            }
                        })
                    }
                },
                stateOptions: states,
                proofTypes: [],
                selectedProofTypesNotRequired: [],
                proofTypesNotRequiredOptions: [],
                profileImageFileName: '',
                driversLicenseImageFileName: '',
                relationshipOptions: [],
                licenses: [],
                hourlyRateMarkupPercentage: 0,
                experiences: [],
            }
        },       
        computed: {
            lblTitle() {
                return 'Incomplete Caregiver: ' + this.userDto.FirstLastName;
            },
            markupAmount() {
                if (!this.dto.CaregiverLicenseCategoryPageDto.HourlyRate || isNaN(this.dto.CaregiverLicenseCategoryPageDto.HourlyRate)) {
                    return '';
                } else {
                    return (parseFloat(this.dto.CaregiverLicenseCategoryPageDto.HourlyRate) * (1 + this.hourlyRateMarkupPercentage)).toFixed(2);
                }
            },
            myShyftAdminTimeZone() {
                return this.$store.state.AdminModule.MyShyftAdminTimeZone;
            },
        },  
        methods: {
            nextPage() {
                this.currentSignUpPageName = this.signUpPages[this.currentSignUpPageNumber + 1];
                this.currentSignUpPageNumber = this.currentSignUpPageNumber + 1;   
                
                this.getCaregiverPages();                            
            },
            previousPage() {
                this.currentSignUpPageName = this.signUpPages[this.currentSignUpPageNumber - 1];
                this.currentSignUpPageNumber = this.currentSignUpPageNumber - 1;

                if (this.currentSignUpPageName === 'Create Account'){
                    this.getUserDto();
                } else {
                    this.getCaregiverPages();
                }
            },
            btnPreviousOnClick(){
                const message = 'Any changes will not be saved. Are you sure you want to continue?';
                const title = 'Confirm';
                const confirmCallback = this.previousPage;

                DMUtils.openApplicationModal('dmConfirmModal', {
                    title: title,
                    message: message,
                    confirmCallback: confirmCallback,
                    confirmText: 'Yes',
                    denyText: 'Cancel',
                });               
            },
            btnSaveOnClick(){     
                if (this.currentSignUpPageName === 'Create Account'){ 
                    this.refreshGetAllCaregivers = true;           
                    this.$store
                        .dispatch('AdminCaregiverModule/validateCaregiverCreateAccount', this.userDto)
                        .then(() => {                    
                            this.nextPage();                
                        });
                } else if (this.currentSignUpPageName === 'Info'){
                    let proofTypes = this.proofTypes
                        .filter(p => p.Required || this.selectedProofTypesNotRequired.includes(p.Name))
                        .map(p => { return {
                            ProofName: p.Name,
                            ProofType: p.ProofType || '',
                            ProofImageBase64: p.ProofImageBase64 || '',
                            ProofImageFileName: p.ProofImageFileName || '',
                            ReportDate: p.ReportDate || ''
                        }});

                    this.dto.CaregiverInfoPageDto.ProofsOfOwnership = proofTypes;
                    this.dto.CaregiverInfoPageDto.CaregiversUserID = this.userDto.UserID;
                    this.$store
                        .dispatch('AdminCaregiverModule/validateCaregiverProofOfIdentity', this.dto.CaregiverInfoPageDto)
                        .then(() => {                                                       
                            let tbTest = proofTypes.filter(p => p.ProofName === 'TB')[0];
                            let oneYearAgo = moment().subtract(1, 'year');
                            if (moment(tbTest.ReportDate) < oneYearAgo) {
                                DMUtils.alertShow('Your TB test is more than a year old.', 'Warning', this.nextPage());
                            } else {
                                this.nextPage();
                            }                                                                       
                        });
                } else if (this.currentSignUpPageName === 'Reference'){
                    this.dto.CaregiverReferenceDtos.CaregiversUserID = this.userDto.UserID;

                    this.$store
                        .dispatch('AdminCaregiverModule/validateCaregiverReferences', this.dto.CaregiverReferenceDtos)
                        .then(() => {                    
                            this.nextPage();                
                        });
                } else if (this.currentSignUpPageName === 'License Category'){                   
                    let dto = {
                        HourlyRate: this.dto.CaregiverLicenseCategoryPageDto.HourlyRate,
                        MedicalLicenses: []
                    };

                    this.licenses.forEach(l => {
                        if(l.Owned) {
                            l.states.forEach(s => {
                                dto.MedicalLicenses.push(Object.assign(s,{
                                    MedicalLicenseID: l.MedicalLicenseID
                                }));
                            });
                        }
                    });

                    Object.assign(this.dto.CaregiverLicenseCategoryPageDto, dto);
                    this.dto.CaregiverLicenseCategoryPageDto.CaregiversUserID = this.userDto.UserID;

                    this.$store
                        .dispatch('AdminCaregiverModule/validateCaregiverLicenseCategory', this.dto.CaregiverLicenseCategoryPageDto)
                        .then(() => {                    
                            this.nextPage();                         
                        });
                } else if (this.currentSignUpPageName === 'Experience and Insurance'){
                    this.dto.CaregiverExperienceAndInsurancePageDto.CaregiversUserID = this.userDto.UserID;

                    this.dto.CaregiverExperienceAndInsurancePageDto.WorkExperiences = this.experiences.filter(e => e.Owned);
                    this.dto.CaregiverExperienceAndInsurancePageDto.WorkExperiences.forEach(e => {
                        if (e.WorkExperienceYear === '') {
                            e.WorkExperienceYear = 0;
                        }
                    });

                    this.$store
                        .dispatch('AdminCaregiverModule/validateCaregiverExperienceAndInsurance', this.dto.CaregiverExperienceAndInsurancePageDto)
                        .then(() => {                    
                            this.nextPage();                    
                        });
                } else if (this.currentSignUpPageName === 'About You'){
                    this.dto.CaregiverAboutYouPageDto.CaregiversUserID = this.userDto.UserID;
                    this.refreshGetAllCaregivers = true;           

                    this.$store
                        .dispatch('AdminCaregiverModule/validateCaregiverAboutYou', this.dto.CaregiverAboutYouPageDto)
                        .then(() => { 
                            this.btnCloseOnClick();
                        });
                }
            },
            btnCloseOnClick() {
                if (this.refreshGetAllCaregivers) {
                    this.$store.dispatch('AdminCaregiverModule/getAllCaregivers', this.paramsDto);
                }
                this.closeModal();
            },
            getUserDto() {
                this.$store
                    .dispatch('UserModule/getUserByID', this.userID)
                    .then((response) => {                    
                        this.userDto = response;             
                    });
            },
            getCaregiverPages() {
                this.$store
                    .dispatch('AdminCaregiverModule/getAllPagesByUserIDMyShyftAdmin', this.userDto.UserID)
                    .then((response) => {
                        this.dto = response;

                        if (!this.dto.CaregiverReferenceDtos || this.dto.CaregiverReferenceDtos.length === 0) {
                            this.dto.CaregiverReferenceDtos = [{},{}];
                        }
                    });
            },
            getUserPronounList() {
                endpoints.getUserPronouns()
                    .then((response) => {
                        this.pronounOptions = response;
                    });
            },
            profileImageEnlarge() {
                let modalData = {
                    imageFileName: this.dto.CaregiverInfoPageDto.ProfileImageFileName,
                    imageBase64: this.dto.CaregiverInfoPageDto.ProfileImageBase64
                }                    
                DMUtils.openApplicationModal('viewImageModal', modalData);
            },
            profilePictureFileChange(uploader) {
                let reader = new FileReader();
                let blob = new Blob([uploader.filesData[0].rawFile], {type: uploader.filesData[0].rawFile.type});

                reader.addEventListener('load', () => {
                    app.resizeImage(reader, this.dto.CaregiverInfoPageDto, 'ProfileImageBase64', 400);
                    let fileName = uploader.filesData[0].name.replace(/\.[^/.]+$/, '.jpeg');
                    this.dto.CaregiverInfoPageDto.ProfileImageFileName = fileName;
                }, false);

                reader.readAsDataURL(blob);
            },
            uploadIDCardFileChange(uploader) {
                let reader = new FileReader();
                let blob = new Blob([uploader.filesData[0].rawFile], {type: uploader.filesData[0].rawFile.type});

                reader.addEventListener('load', () => {
                    if (uploader.filesData[0].type !== 'pdf') {
                        app.resizeImage(reader, this.dto.CaregiverInfoPageDto, 'DriversLicenseImageBase64', 2000);
                        this.driversLicenseImageFileName = '';
                        this.dto.CaregiverInfoPageDto.DriversLicenseImageFileName = uploader.filesData[0].name.replace(/\.[^/.]+$/, '.jpeg');
                    } else {
                        if (app.fileSizeTooLarge(uploader.filesData[0].size)) {
                            DMUtils.alertShow(
                                'The selected file is too large.'
                            );
                        } else {
                            this.dto.CaregiverInfoPageDto.DriversLicenseImageBase64 = reader.result;
                            this.driversLicenseImageFileName = '';
                            this.dto.CaregiverInfoPageDto.DriversLicenseImageFileName = uploader.filesData[0].name;
                        }
                    }
                }, false);

                reader.readAsDataURL(blob);
            },
            uploadIDCardFileRemove(uploader) {
                this.dto.CaregiverInfoPageDto.DriversLicenseImageBase64 = '';
                this.dto.CaregiverInfoPageDto.DriversLicenseImageFileName = '';
            },
            driversLicenseImageEnlarge() {
                let modalData = {
                    imageFileName: this.dto.CaregiverInfoPageDto.DriversLicenseImageFileName,
                    imageBase64: this.dto.CaregiverInfoPageDto.DriversLicenseImageBase64
                }                    
                DMUtils.openApplicationModal('viewImageModal', modalData);
            },
            downloadDriversLicense(file){
                let a = document.createElement('a');
                document.body.appendChild(a);
                a.href = file.DriversLicenseImageBase64;
                a.download = file.DriversLicenseImageFileName;
                a.click();
            },
            uploadFileChange(uploader, proofType) {
                let reader = new FileReader();
                let blob = new Blob([uploader.filesData[0].rawFile], {type: uploader.filesData[0].rawFile.type});

                reader.addEventListener('load', () => {
                    if (uploader.filesData[0].type !== 'pdf') {
                        app.resizeImage(reader, proofType, 'ProofImageBase64', 2000);
                        proofType.ProofImageFileName = '';
                        proofType.ProofImageFileName = uploader.filesData[0].name.replace(/\.[^/.]+$/, '.jpeg');
                    } else {
                        if (app.fileSizeTooLarge(uploader.filesData[0].size)) {
                            DMUtils.alertShow(
                                'The selected file is too large.'
                            );
                        } else {
                            proofType.ProofImageBase64 = reader.result;
                            proofType.ProofImageFileName = '';
                            proofType.ProofImageFileName = uploader.filesData[0].name;
                        }
                    }
                }, false);

                reader.readAsDataURL(blob);
            },
            uploadFileRemove(uploader, proofType) {
                proofType.ProofImageBase64 = '';
                proofType.ProofImageFileName = '';
            },
            proofImageEnlarge(event, proofType) {
                let modalData = {
                    imageFileName: proofType.ProofImageFileName,
                    imageBase64: proofType.ProofImageBase64
                }                    
                DMUtils.openApplicationModal('viewImageModal', modalData);
            },
            downloadProof(file){
                 let a = document.createElement('a');
                document.body.appendChild(a);
                a.href = file.ProofImageBase64;
                a.download = file.ProofImageFileName;
                a.click();
            },
            getProofOfOwnerships() {
                let getSavedProofs = (user) => {
                    if (user.ProofsOfOwnership) {
                        if (user.ProofsOfOwnership.length > 0) {
                            this.proofTypes.forEach(p => {
                                let savedProofType = this.dto.CaregiverInfoPageDto.ProofsOfOwnership.filter(poo => poo.ProofName === p.Name)[0];
                                if (savedProofType) {
                                    savedProofType.ProofImageFileName = savedProofType.ProofImageFileName;
                                    Object.assign(p, savedProofType);
                                }
                            });

                            this.selectedProofTypesNotRequired = this.proofTypes.filter(p => !p.Required).map(p => p.ProofName);
                        }
                    }
                };
                endpoints.getCaregiverGetAllTypesSignUp()
                    .then((response) => {
                        response.forEach(pt => {
                            pt.options = DMUtils.getCboOptions(pt.Types);
                            pt.ProofImageFileName = '';
                        });
                        this.proofTypes = DMUtils.sortObjectArray(response, 'Required').reverse();
                        this.proofTypesNotRequiredOptions = DMUtils.getCboOptions(this.proofTypes.filter(p => !p.Required).map(p => p.Name));

                        if (this.dto.CaregiverInfoPageDto.ProofsOfOwnership) {
                            getSavedProofs(this.dto.CaregiverInfoPageDto);
                            this.driversLicenseImageFileName = this.dto.DriversLicenseImageFileName;
                            this.profileImageFileName = this.dto.ProfileImageFileName;
                        } else {
                            let dtoWatcher = this.$watch('dto', (user) => {
                                if(user.ProofsOfOwnership) {
                                    getSavedProofs(user);
                                    this.driversLicenseImageFileName = this.dto.CaregiverInfoPageDtoDrivers.LicenseImageFileName;
                                    this.profileImageFileName = this.dto.CaregiverInfoPageDto.ProfileImageFileName;
                                    dtoWatcher = null;
                                }
                            });
                        }
                    });
            },
            getRelationshipOptions() {
                endpoints.getAllReferencesRelationshipTypes()
                    .then((response) => {
                        this.relationshipOptions = response.Relationships;
                    }); 
            },
            addAnotherState(license) {
                license.states.push({});
            },
            ownedChange(license) {
                if(license.Owned) {
                    license.states.push({});
                } else {
                    license.states = [];
                }
            },
            removeState(license, index) {
                license.states.splice(index, 1);
            },
            getMedicalLicenses() {
                let getSavedMedicalLicenses = (user) => {
                    if (user.MedicalLicenses && user.MedicalLicenses.length > 0) {
                        this.licenses.forEach(l => {
                            let savedMedicalLicenses = user.MedicalLicenses.filter(ml => ml.MedicalLicenseID === l.MedicalLicenseID);
                            if (savedMedicalLicenses.length > 0) {
                                l.Owned = true;
                                l.states = savedMedicalLicenses;
                            }
                        });
                    }
                };

                endpoints.getAllMedicalLicensesCaregiver()
                    .then(response => {
                        response.forEach(l => {
                            l.Owned = false;
                            l.states = [];
                        });
                        this.licenses = response;

                        if (this.dto.CaregiverLicenseCategoryPageDto.MedicalLicenses) {
                            getSavedMedicalLicenses(this.dto.CaregiverLicenseCategoryPageDto);
                        } else {
                            let dtoWatcher = this.$watch('dto', (user) => {
                                if (user.MedicalLicenses) {
                                    getSavedMedicalLicenses(user);
                                    dtoWatcher = null;
                                }
                            });
                        }
                    });
            },
            getHourlyRateMarkup() {
                endpoints.getHourlyRateMarkup()
                    .then(response => {
                        this.hourlyRateMarkupPercentage = response.HourlyRateMarkupPercentage;
                    });
            },
            openURL(url) {
                app.openURL(url);
            },
            getWorkExperiences() {
                let getSavedWorkExperiences = (user) => {
                    if(user.WorkExperiences && user.WorkExperiences.length > 0) {
                        this.experiences.forEach(we => {
                            let savedWorkExperience = user.WorkExperiences.filter(w => w.WorkExperienceType === we.WorkExperienceType)[0];
                            if(savedWorkExperience) {
                                savedWorkExperience.WorkExperienceYear = savedWorkExperience.WorkExperienceYear.toString();
                                savedWorkExperience.Owned = true;
                                Object.assign(we, savedWorkExperience);
                            }
                        });
                    }
                };

                endpoints.getAllCaregiverWorkExperienceTypes()
                    .then(response => {
                        response.forEach(e => {
                            this.experiences.push({
                                WorkExperienceType: e,
                                Owned: false,
                                WorkExperienceYear: 0,
                                WorkExperienceMonth: 0
                            });
                        });

                        if (this.dto.CaregiverExperienceAndInsurancePageDto.WorkExperiences) {
                            getSavedWorkExperiences(this.dto.CaregiverExperienceAndInsurancePageDto);
                        } else {
                            let dtoWatcher = this.$watch('dto', (user) => {
                                getSavedWorkExperiences(user);
                                dtoWatcher = null;
                            });
                        }
                    });
            }
        },
        created() {
            this.dto = DMUtils.copy(this.modalData.caregiverPages); 
            this.userID = this.modalData.userID;
            this.getUserDto();
            this.paramsDto = DMUtils.copy(this.modalData.paramsDto);
            
            if (this.dto.LastPageName === 'Legal' || this.dto.LastPageName === 'Signature'){
                this.currentSignUpPageName = 'About You';
            } else if (this.dto.LastPageName === ''){
                this.currentSignUpPageName = 'Create Account';
                this.getUserDto();
            } else {
                this.currentSignUpPageName = this.dto.LastPageName;
            }
            
            this.currentSignUpPageNumber = this.signUpPages.indexOf(this.currentSignUpPageName);   
                      
            this.getUserPronounList();
            this.getProofOfOwnerships();

            if (!this.dto.CaregiverReferenceDtos || this.dto.CaregiverReferenceDtos.length === 0) {
                this.dto.CaregiverReferenceDtos = [{},{}];
            }
             
            this.getRelationshipOptions();
            this.getMedicalLicenses();
            this.getHourlyRateMarkup();
            this.getWorkExperiences();
        },
    }
</script>
