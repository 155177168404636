import endpoints from "../../../js/endpoints";

const organizationLocationsModule = {
    namespaced: true,
    state: () => ({
        AllOrganizationLocations: []
    }),
    mutations: {
        setOrganizationLocations(state, locations) {
            state.AllOrganizationLocations = locations;
        }
    },
    actions: {
        getAllOrganizationLocationsForOrganization({commit}, dto) {
            let endpoint = 'getAllOrganizationLocationBuildingsForOrganization';
            commit('setOrganizationLocations', []);

            if (dto.OrganizationCategory === 'Agency') {                
                if (this.state.AuthorizationModule.SignedInUser.Role === 'MyShyftAdmin' 
                    || this.state.AuthorizationModule.SignedInUser.Role === 'MyShyftEmployee'
                    || this.state.AuthorizationModule.SignedInUser.Role === 'MyShyftViewer') 
                {
                    endpoint = 'getAllOrganizationLocationPatientsForOrganizationMyShyftAdmin';
                }
                else 
                {
                    endpoint = 'getAllOrganizationLocationPatientsForOrganization'
                }
            }

            return endpoints[endpoint](dto.OrganizationID)
                .then((response) => {
                    commit('setOrganizationLocations', response);
                });
        },
        getOrganizationLocationByID({}, dto) {
            let endpoint = 'getOrganizationLocationBuildingByID';
            if (dto.OrganizationCategory === 'Agency') {                
                if (this.state.AuthorizationModule.SignedInUser.Role === 'MyShyftAdmin' 
                    || this.state.AuthorizationModule.SignedInUser.Role === 'MyShyftEmployee'
                    || this.state.AuthorizationModule.SignedInUser.Role === 'MyShyftViewer') 
                {
                    endpoint = 'getOrganizationLocationPatientByIDMyShyftAdmin';
                }
                else 
                {
                    endpoint = 'getOrganizationLocationPatientByIDOrganization'
                }
            }

            return endpoints[endpoint](dto.OrganizationLocationID)
                .then((response) => {
                    return response;
                });
        },
        createOrganizationLocation({dispatch}, dto) {
            let endpoint = 'createOrganizationLocationBuilding';
            
            if (dto.OrganizationCategory === 'Agency') {                
                if (this.state.AuthorizationModule.SignedInUser.Role === 'MyShyftAdmin' 
                    || this.state.AuthorizationModule.SignedInUser.Role === 'MyShyftEmployee'
                    || this.state.AuthorizationModule.SignedInUser.Role === 'MyShyftViewer') 
                {
                    endpoint = 'createOrganizationLocationPatientMyShyftAdmin';
                }
                else 
                {
                    endpoint = 'createOrganizationLocationPatientOrganization'
                }
            }

            return endpoints[endpoint](dto)
                .then(() => {
                    dispatch('getAllOrganizationLocationsForOrganization', dto);
                });
        },
        updateOrganizationLocation({dispatch}, dto) {
            let endpoint = 'updateOrganizationLocationBuilding';
            
            if (dto.OrganizationCategory === 'Agency') {                
                if (this.state.AuthorizationModule.SignedInUser.Role === 'MyShyftAdmin' 
                    || this.state.AuthorizationModule.SignedInUser.Role === 'MyShyftEmployee'
                    || this.state.AuthorizationModule.SignedInUser.Role === 'MyShyftViewer') 
                {
                    endpoint = 'updateOrganizationLocationPatientMyShyftAdmin';
                }
                else 
                {
                    endpoint = 'updateOrganizationLocationPatientOrganization'
                }
            }

            return endpoints[endpoint](dto)
                .then(() => {
                    dispatch('getAllOrganizationLocationsForOrganization', dto);
                });
        },
        deleteOrganizationLocation({dispatch}, dto) {
            return endpoints.deleteOrganizationLocation(dto)
                .then(() => {
                    dispatch('getAllOrganizationLocationsForOrganization', dto);
                });
        },
        getOrganizationLocationTypes({}) {
            return endpoints.getOrganizationLocationTypes()
                .then((response) => {
                    return response;
                });
        },
        getAllOrganizationLocationNamesForOrganization({}, organizationID) {
            return endpoints.getAllOrganizationLocationNamesForOrganization(organizationID)
                .then((response) => {
                    return response;
                });
        },
    },
};

export default organizationLocationsModule;
