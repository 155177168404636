<template src="./usersPage.html"></template>

<script>
import Vue from 'vue';

    export default {
        name: 'admin-users-page',
        data() {
            return {
                searchString: '',
                dto: {},
                MyShyftAdmins: [],
                usersTableColumns: [],
                usersTableSortBy: {
                    columns: [
                        { field: 'FirstLastName', direction: 'Ascending' },                    
                    ]
                },
                usersTableCustomClass: 'dm-bg-color-black',
                usersTableActions: [
                    {
                        text: 'Toggle Active Status'
                    },
                    {
                        text: 'Edit User'
                    },   
                    {
                        text: 'Delete'
                    },                
                ],
                selectedRow: null
            }
        },
        computed: {
            usersTableItems() {
                return this.$store.state.UserModule.AllUsers
            },
            userID() {
                return this.selectedRow.rowData.UserID;
            },
            signedInUser() {
                return this.$store.state.AuthorizationModule.SignedInUser;
            },
        },
        methods: {
            usersTableRowOnClick(args) {
                this.selectedRow = args;
            },
            toggleStatus() {           
                this.$store
                    .dispatch('UserModule/getUserByID', this.selectedRow.rowData.UserID)
                        .then((response) => {
                            this.dto = response;
                            if (this.dto.Status === "Active"){
                                this.dto.Status = "Inactive";
                            } else {
                                this.dto.Status = "Active";
                            }
                            this.$store.dispatch('UserModule/updateUser', this.dto);
                        });
            },
            addNewUser() {
                let modalData = {
                    mode: 'add',
                    accountType: 'MyShyftAdmin',
                    dto: {},
                };

                DMUtils.openApplicationModal('usersEditModal', modalData);
            },
            editUser() {           
                this.$store
                    .dispatch('UserModule/getUserByID', this.selectedRow.rowData.UserID)
                        .then((response) => {
                               let modalData = {
                                mode: 'edit',
                                accountType: 'MyShyftAdmin',
                                dto: response,
                            };
                            DMUtils.openApplicationModal('usersEditModal', modalData);
                        });
            },
            deleteUser() {
                let dto = {
                    UserID: this.userID,
                }
                
                this.$store.dispatch('UserModule/deleteUser', dto);
            },
            usersTableActionOnClick(args) {
                if (args.item.text === 'Toggle Active Status') {
                    const message = 'Are you sure you want to Toggle Active Status?';
                    const title = 'Confirm';
                    const confirmCallback = this.toggleStatus;

                    DMUtils.openApplicationModal('dmConfirmModal', {
                        title: title,
                        message: message,
                        confirmCallback: confirmCallback,
                        confirmText: 'Yes',
                        denyText: 'Cancel',
                    });
                } 
                else if (args.item.text === 'Edit User'){
                     this.editUser();
                }
                else if (args.item.text === 'Delete'){
                    const message = 'Are you sure you want to delete this MyShyft User?';
                    const title = 'Confirm';
                    const confirmCallback = this.deleteUser;

                    DMUtils.openApplicationModal('dmConfirmModal', {
                        title: title,
                        message: message,
                        confirmCallback: confirmCallback,
                        confirmText: 'Yes',
                        denyText: 'Cancel',
                    });
                }             
            },
        },
        created() {
            this.$store.dispatch('UserModule/getAllUsers');

            if (this.signedInUser.Role === 'MyShyftEmployee' || this.signedInUser.Role === 'MyShyftViewer') {
                this.usersTableColumns = [
                    {
                        key: 'UserID',
                        label: 'ID',
                        width: '75px',
                    },
                    {
                        key: 'FirstLastName',
                        label: 'Name'
                    },
                    {
                        key: 'EmailAddress',
                        label: 'Email Address',
                    }, 
                    {
                        key: 'RoleF',
                        label: 'Role',
                    },           
                    {
                        key: 'PhoneNumberF',
                        label: 'Phone Number',
                        width: '150px'
                    },
                    {
                        key: 'Status',
                        label: 'Status',
                        width: '100px',
                        template: function () {
                            return {
                                template: Vue.component('columnTemplate', {
                                    template: `<div :class="setColor">{{data.Status}}</div>`,
                                    computed: {
                                        setColor: function () {
                                            if (this.data.Status === 'Inactive') {
                                                return 'app-grid-status-orange';
                                            }
    
                                            if (this.data.Status === 'Active') {
                                                return 'app-grid-status-green';
                                            }     
                                        },                             
                                    },
                                }),
                            };
                        },
                    },
                ];
            } 
            else if (this.signedInUser.Role === 'MyShyftAdmin') {
                 this.usersTableColumns = [
                    {
                        type: 'iconTemplate',
                        iconName: 'ellipsis',
                        ellipsisActions: this.usersTableActions,
                        iconOnClick: this.usersTableActionOnClick,
                        width: '55px',
                    },
                    {
                        key: 'UserID',
                        label: 'ID',
                        width: '75px',
                    },
                    {
                        key: 'FirstLastName',
                        label: 'Name'
                    },
                    {
                        key: 'EmailAddress',
                        label: 'Email Address',
                    }, 
                    {
                        key: 'RoleF',
                        label: 'Role',
                    },           
                    {
                        key: 'PhoneNumberF',
                        label: 'Phone Number',
                        width: '150px'
                    },
                    {
                        key: 'Status',
                        label: 'Status',
                        width: '100px',
                        template: function () {
                            return {
                                template: Vue.component('columnTemplate', {
                                    template: `<div :class="setColor">{{data.Status}}</div>`,
                                    computed: {
                                        setColor: function () {
                                            if (this.data.Status === 'Inactive') {
                                                return 'app-grid-status-orange';
                                            }
    
                                            if (this.data.Status === 'Active') {
                                                return 'app-grid-status-green';
                                            }     
                                        },                             
                                    },
                                }),
                            };
                        },
                    },
                ];
            }
        },
    }
</script>
