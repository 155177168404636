export default {
    createMessageCaregiver(message) {
        let dto = {
            ShiftID: message.ShiftID,
            CaregiverID: message.CaregiverID,
            MessageText: message.MessageText
        };

        if (!dto.ShiftID) {
            dto.ShiftID = 0;
        }

        if (!dto.CaregiverID) {
            dto.CaregiverID = 0;
        }

        if (!dto.MessageText) {
            dto.MessageText = '';
        }

        return DMUtils.http({
            method: 'POST',
            url: '/Message/CreateCaregiver',
            data: dto,
        }, true);
    },

    createMessageOrganization(message) {
        let dto = {
            ShiftID: message.ShiftID,
            CaregiverID: message.CaregiverID,
            MessageText: message.MessageText
        };

        if (!dto.ShiftID) {
            dto.ShiftID = 0;
        }

        if (!dto.CaregiverID) {
            dto.CaregiverID = 0;
        }

        if (!dto.MessageText) {
            dto.MessageText = '';
        }

        return DMUtils.http({
            method: 'POST',
            url: '/Message/CreateOrganization',
            data: dto,
        }, true);
    },

    getAllShiftsWithMessagesByShiftIDCaregiver(data, hideLoader) {
        let dto = {
            StartDateRangeUtc: data.StartDateRangeUtc,
            EndDateRangeUtc: data.EndDateRangeUtc,
            OrganizationID: data.OrganizationID,
            SearchText: data.SearchText
        };

        if (!dto.StartDateRangeUtc) {
            dto.StartDateRangeUtc = '';
        }

        if (!dto.EndDateRangeUtc) {
            dto.EndDateRangeUtc = '';
        }

        if (!dto.OrganizationID) {
            dto.OrganizationID = 0;
        }

        if (!dto.SearchText) {
            dto.SearchText = '';
        }

        return DMUtils.http({
            method: 'POST',
            url: '/Message/GetAllShiftsCaregiver',
            data: dto
        }, hideLoader);
    },

    getAllShiftsWithMessagesByShiftIDOrganization(data, hideLoader) {
        let dto = {
            StartDateRangeUtc: data.StartDateRangeUtc,
            EndDateRangeUtc: data.EndDateRangeUtc,
            CaregiverID: data.CaregiverID,
            SearchText: data.SearchText
        };

        if (!dto.StartDateRangeUtc) {
            dto.StartDateRangeUtc = '';
        }

        if (!dto.EndDateRangeUtc) {
            dto.EndDateRangeUtc = '';
        }

        if (!dto.CaregiverID) {
            dto.CaregiverID = 0;
        }

        if (!dto.SearchText) {
            dto.SearchText = '';
        }

        return DMUtils.http({
            method: 'POST',
            url: '/Message/GetAllShiftsOrganization',
            data: dto,
        }, hideLoader);
    },

    getAllMessagesByShiftIDCaregiver(data) {
        let dto = {
            ShiftID: data.shiftID
        }

        if (!dto.ShiftID) {
            dto.ShiftID = 0;
        }

        return DMUtils.http({
            method: 'GET',
            url: '/Message/GetByShiftIDCaregiver',
            params: dto,
        }, true);
    },

    getAllMessagesByShiftIDOrganization(data) {
        let params = {
            ShiftID: data.shiftID,
            CaregiverID: data.caregiverID
        };

        if (!params.ShiftID) {
            params.ShiftID = 0;
        }

        if (!params.CaregiverID) {
            params.CaregiverID = 0;
        }

        return DMUtils.http({
            method: 'GET',
            url: '/Message/GetByShiftIDOrganization',
            params: params,
        }, true);
    },

    getNewMessagesByShiftIDCaregiver(data) {
        let params = {
            ShiftID: data.shiftID,
            CaregiverID: data.caregiverID
        };

        if (!params.ShiftID) {
            params.ShiftID = 0;
        }

        if (!params.CaregiverID) {
            params.CaregiverID = 0;
        }

        return DMUtils.http({
            method: 'GET',
            url: '/Message/GetNewByShiftIDCaregiver',
            params: data,
        }, true, true);
    },

    getNewMessagesByShiftIDOrganization(data) {
        let params = {
            ShiftID: data.shiftID,
            CaregiverID: data.caregiverID
        };

        if (!params.ShiftID) {
            params.ShiftID = 0;
        }

        if (!params.CaregiverID) {
            params.CaregiverID = 0;
        }

        return DMUtils.http({
            method: 'GET',
            url: '/Message/GetNewByShiftIDOrganization',
            params: params,
        }, true, true);
    },

    getAllAdminMessagesMyShyftAdmin(data, hideLoader) {
        let dto = {
            StartDateRangeUtc: data.StartDateRangeUtc,
            EndDateRangeUtc: data.EndDateRangeUtc,
            CaregiverID: data.CaregiverID,
            SearchText: data.SearchText,
            ShownMessageLength: data.ShownMessageLength
        };

        if (!dto.StartDateRangeUtc) {
            dto.StartDateRangeUtc = '';
        }

        if (!dto.EndDateRangeUtc) {
            dto.EndDateRangeUtc = '';
        }

        if (!dto.CaregiverID) {
            dto.CaregiverID = 0;
        }

        if (!dto.SearchText) {
            dto.SearchText = '';
        }

        if (!dto.ShownMessageLength) {
            dto.ShownMessageLength = 100;
        }

        return DMUtils.http({
            method: 'POST',
            url: '/Message/GetAllAdminMessagesMyShyftAdmin',
            data: dto,
        }, hideLoader);
    },

     createMessageAdmin(data, hideLoader) {
        let dto = {
            CaregiverID: data.CaregiverID,
            MessageText: data.MessageText,
        };

        if (!dto.CaregiverID) {
            dto.CaregiverID = 0;
        }

        if (!dto.MessageText) {
            dto.MessageText = '';
        }

        return DMUtils.http({
            method: 'POST',
            url: '/Message/AdminCreate',
            data: dto,
        }, hideLoader);
    },

    getAdminByIDMyShyftAdmin(caregiverID, hideLoader) {
        return DMUtils.http({
            method: 'GET',
            url: '/Message/GetAdminByIDMyShyftAdmin',
            params: {
                CaregiverID: caregiverID
            },
        }, hideLoader);
    },

    getAllMessagesByShiftIDMyShyftAdmin(data) {
        return DMUtils.http({
            method: 'GET',
            url: '/Message/GetByShiftIDMyShyftAdmin',
            params: {
                ShiftID: data.shiftID,
                CaregiverID: data.caregiverID
            },
        });
    },

    getNewAdminMessagesMyShyftAdmin(caregiverID, hideLoader) {
        return DMUtils.http({
            method: 'GET',
            url: '/Message/GetNewAdminMessagesMyShyftAdmin',
            params: {
                CaregiverID: caregiverID
            },
        }, hideLoader);
    },

    getAllAdminMessagesCaregiver(data, hideLoader) {
        let dto = {
            StartDateRangeUtc: data.StartDateRangeUtc,
            EndDateRangeUtc: data.EndDateRangeUtc,
            SearchText: data.SearchText,
            ShownMessageLength: data.ShownMessageLength
        };

        if (!dto.StartDateRangeUtc) {
            dto.StartDateRangeUtc = '';
        }

        if (!dto.EndDateRangeUtc) {
            dto.EndDateRangeUtc = '';
        }

        if (!dto.SearchText) {
            dto.SearchText = '';
        }

        if (!dto.ShownMessageLength) {
            dto.ShownMessageLength = 100;
        }

        return DMUtils.http({
            method: 'POST',
            url: '/Message/GetAllAdminMessagesCaregiver',
            data: dto,
        }, hideLoader);
    },

    getAdminByIDCaregiver(caregiverID, hideLoader) {
        return DMUtils.http({
            method: 'GET',
            url: '/Message/GetAdminByIDCaregiver',
            params: {
                CaregiverID: caregiverID
            },
        }, hideLoader);
    },

    getNewAdminMessagesCaregiver(caregiverID, hideLoader) {
        return DMUtils.http({
            method: 'GET',
            url: '/Message/GetNewAdminMessagesCaregiver',
            params: {
                CaregiverID: caregiverID
            },
        }, hideLoader);
    },

    getAdminMessagedCaregivers() {
        return DMUtils.http({
            method: 'GET',
            url: '/Message/GetAdminMessagedCaregivers',
        });
    },

}