<template src="./messagesShiftActiveChatPage.html"></template>

<script>
    import messagesActiveChatPage from '../../../messagesComponents/messagesShiftActiveChatPage/messagesShiftActiveChatPage'
    import messagesThreadPage from '../../../messagesComponents/messagesShiftThreadPage/messagesShiftThreadPage'

    export default {
        name: 'messages-shift-active-chat-page',
        components: {
            'messages-active-chat-page': messagesActiveChatPage,
            'messages-thread-page': messagesThreadPage
        },
        data() {
            return {
                selectedShiftID: 0,
                selectedCaregiverID: 0,
                selectedMessageID: 0
            }
        },
        methods: {
            messageChanged(shift) {
                this.selectedShiftID = shift.shiftID;
                this.selectedCaregiverID = shift.caregiverID;
                this.selectedMessageID = shift.shownMessageID;
            }
        }
    }
</script>
