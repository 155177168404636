<template src="./createSupportRequestsModal.html"></template>

<script>
import dmModalMixin from '../../../../dmFramework/dmJS/dmModalMixin';
import imageUploadMixin from '../../../js/imageUploadMixin';

export default {
    name: 'support-modal',
    mixins: [dmModalMixin, imageUploadMixin],
    data() {
        return {
            lblTitle: 'Report Issue',
            shiftSupportTypeList: [],
            dto: {
                SupportImageBase64: '',
                SupportImageFileName: '',
            },
        };
    },
    methods: {
        refreshImageUpload(image) {
            this.dto.SupportImageBase64 = image;
        },
        btnSaveOnClick() {
            if (this.mode === 'ShiftSupport') {
                this.$store.dispatch('SupportModule/createShiftSupportRequest', this.dto).then(() => {
                    this.successClose();
                });
            } else {
                this.$store.dispatch('SupportModule/createGeneralSupportRequest', this.dto).then(() => {
                    this.successClose();
                });
            }
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.mode = this.modalData.mode;

        if (this.mode === 'ShiftSupport') {
            this.dto.ShiftID = this.modalData.shiftID;
        }

        this.$store.dispatch('SupportModule/getShiftSupportTypes').then((response) => {
            this.shiftSupportTypeList = response.ShiftSupportTypes;
        });
    },
};
</script>
