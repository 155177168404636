import endpoints from "../../js/endpoints";

const userModule = {
    namespaced: true,
    state: () => ({
        AllMedicalLicenses: [],
        AllCaregiverMedicalLicenses: []
    }),
    mutations: {
        setMedicalLicenses(state, licenses) {
            state.AllMedicalLicenses = licenses;
        },
        setCaregiverMedicalLicenses(state, licenses) {
            state.AllCaregiverMedicalLicenses = licenses;
        },
    },
    actions: {
        getAllMedicalLicensesMyShyftAdmin({commit}) {
            commit('setMedicalLicenses', []);

            return endpoints.getAllMedicalLicensesMyShyftAdmin()
                .then((response) => {
                    commit('setMedicalLicenses', response);
                    return response;
                });
        },
        getAllMedicalLicensesOrganization({commit}) {
            commit('setMedicalLicenses', []);

            return endpoints.getAllMedicalLicensesOrganization()
                .then((response) => {
                    commit('setMedicalLicenses', response);
                });
        },
        getAllMedicalLicensesOrganizationLocation({commit}, organizationLocationID) {
            commit('setMedicalLicenses', []);

            return endpoints.getAllMedicalLicensesOrganizationLocation(organizationLocationID)
                .then((response) => {
                    commit('setMedicalLicenses', response);
                });
        },
        createMedicalLicense({dispatch}, dto) {
            return endpoints.createMedicalLicense(dto)
                .then(() => {
                    dispatch('getAllMedicalLicensesMyShyftAdmin');
                });
        },
        updateMedicalLicense({dispatch}, dto) {
            return endpoints.updateMedicalLicense(dto)
                .then(() => {
                    dispatch('getAllMedicalLicensesMyShyftAdmin');
                });
        },
        deleteMedicalLicense({dispatch}, medicalLicenseID) {
            return endpoints.deleteMedicalLicense(medicalLicenseID)
                .then(() => {
                    dispatch('getAllMedicalLicensesMyShyftAdmin');
                });
        },
        getCaregiverMedicalLicenseByID({commit}, caregiverMedicalLicenseID) {
            return endpoints.getCaregiverMedicalLicenseByID(caregiverMedicalLicenseID)
                .then((response) => {
                    return response;
                });
        },
        getAllCaregiverMedicalLicensesForCaregiverMyShyftAdmin({commit}, caregiverID) {
            commit('setCaregiverMedicalLicenses', []);

            return endpoints.getAllCaregiverMedicalLicensesForCaregiverMyShyftAdmin(caregiverID)
                .then((response) => {
                    commit('setCaregiverMedicalLicenses', response);
                    return response;
                });
        },
        getAllCaregiverMedicalLicensesForCaregiverOrganization({commit}, caregiverID) {
            commit('setCaregiverMedicalLicenses', []);

            return endpoints.getAllCaregiverMedicalLicensesForCaregiverOrganization(caregiverID)
                .then((response) => {
                    commit('setCaregiverMedicalLicenses', response);
                    return response;
                });
        },
        updateCaregiverMedicalLicense({dispatch}, dto) {
            return endpoints.updateCaregiverMedicalLicense(dto)
                .then(() => {
                    dispatch('getAllCaregiverMedicalLicensesForCaregiverMyShyftAdmin', dto.CaregiverID);
                });
        },
        createCaregiverMedicalLicenseMyShyftAdmin({dispatch}, dto) {
            return endpoints.createCaregiverMedicalLicenseMyShyftAdmin(dto)
                .then(() => {
                    dispatch('getAllCaregiverMedicalLicensesForCaregiverMyShyftAdmin', dto.CaregiverID);
                });
        },
        approveCaregiverMedicalLicense({dispatch}, dto) {
            return endpoints.approveCaregiverMedicalLicense(dto)
                .then(() => {
                    dispatch('getAllCaregiverMedicalLicensesForCaregiverMyShyftAdmin', dto.CaregiverID);
                });
        },
        denyCaregiverMedicalLicense({dispatch}, dto) {
            return endpoints.denyCaregiverMedicalLicense(dto)
                .then(() => {
                    dispatch('getAllCaregiverMedicalLicensesForCaregiverMyShyftAdmin', dto.CaregiverID);
                });
        },
    },
};

export default userModule;
