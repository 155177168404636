import endpoints from "../../../js/endpoints";

const authorizationModule = {
    namespaced: true,
    state: () => ({
        SignedInUser: {},
        AllTimeZones: {}
    }),
    mutations: {
        setUser(state, user) {
            state.SignedInUser = user;
        },
        setTimeZones(state, timeZones) {
            state.AllTimeZones = timeZones;
        },
        setUserName(state, user) {
            state.SignedInUser.FirstLastName = user.FirstLastName;
            state.SignedInUser.FirstName = user.FirstName;
            state.SignedInUser.LastName = user.LastName;
        },
        clearUser(state) {
            state.SignedInUser = {};
        },
    },
    actions: {
        signIn({ commit }, credentials) {
            return endpoints.signIn(credentials)
                .then((user) => {
                    commit('setUser', user);
                    if (user.Role === 'MyShyftAdmin' || user.Role === 'MyShyftEmployee' || user.Role === 'MyShyftViewer'){
                        commit('NavigationModule/setActiveTemplate', 'adminDashboardPage', { root: true });
                    } else if (user.Role === 'OrganizationAdmin' || user.Role === 'OrganizationCorporate' || user.Role === 'OrganizationEmployee'){
                        commit('NavigationModule/setActiveTemplate', 'organizationDashboardPage', { root: true });
                    }
                    return user;
                });
        },
        checkAccountVerification({}) {
            return endpoints.checkAccountVerification()
                .then(response => {
                    if (response.AccountStatusMessage) {
                        DMUtils.alertShow(response.AccountStatusMessage);
                    }
    
                    if (response.AppVersionMessage) {
                        DMUtils.alertShow(response.AppVersionMessage);
                    }
    
                    if (response.PhoneVerificationStatusMessage) {
                        DMUtils.alertShow(response.PhoneVerificationStatusMessage);
                    }
                });
        },
        signInWithAuthToken({ commit }, authToken) {
            return endpoints.signInWithAuthToken(authToken).then((user) => {
                commit('setUser', user);
            });
        },
        signOut({ commit }) {
            return endpoints.signOut()
                .then((response) => {
                    commit('clearUser');
                    commit('NavigationModule/setActiveTemplate', 'signInPage', { root: true });
                });
        },
        sendResetPasswordEmail({}, emailAddress) {
            return endpoints.sendResetPasswordEmail(emailAddress);
        },
        getAndSetSignedInUser({ commit }, signedInUserID) {
            return endpoints.getUserByIDWithOrganization(signedInUserID)
                .then((user) => {
                    commit('setUser', user);
                });
        },
        getUserById({}, userID) {
            return endpoints.getUserByID(userID);
        },
        getUserByIDWithOrganization({}, userID) {
            return endpoints.getUserByIDWithOrganization(userID);
        },
        getUserProfile({}) {
            return endpoints.getUserProfile(false);
        },
        resetPasswordFromToken({}, dto) {
            return endpoints.resetPasswordFromToken(dto);
        },
        updateProfile({ commit }, dto) {
            return endpoints.updateUser(dto)
                .then((response) => {
                    endpoints.getUserByID(dto.UserID)
                    .then((user) => {
                        commit('setUserName', user);
                    });
                });
        },
        updateNotificationSettings({}, notifications) {
            return endpoints.updateNotificationSettings(notifications);
        },
        updatePassword({}, dto) {
            return endpoints.updatePassword(dto);
        },
        verifyUserEmailAddress({}, dto) {
            return endpoints.verifyUserEmailAddress(dto);
        },
        sendEmailVerificationCode({}) {
            return endpoints.sendEmailVerificationCode();
        },
        validateRecaptchaResponse({}, recaptchaToken){
            return endpoints.validateRecaptchaResponse(recaptchaToken)
                .then((response) => {
                    return response;
                });
        },
        getAllTimeZones({commit}){
            commit('setTimeZones', []);

            return endpoints.getAllTimeZones()
            .then((response) => {                  
                commit('setTimeZones', response);
                return response;
            });
        }
    },
};

export default authorizationModule;
