<template src="./organizationOldOutstandingBalanceModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

export default {
    name: 'organization-old-outstanding-balance-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            dto: {},
        }
    },
    computed: {
        lblTitle() {
            return 'Outstanding Balance for ' + this.modalData.OrganizationName;
        },
    },
    methods: {
        btnSaveOnClick() {
            this.$store          
                .dispatch('AdminOrganizationModule/updateOrganizationInvoice', this.dto)
                .then(() => {
                    this.shouldModalStayOpen = false;
                    this.successClose(this.dto);
                });
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.dto = DMUtils.copy(this.modalData.dto);
    },
}
</script>
