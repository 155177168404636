<template src="./organizationAddContactModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin';

export default {
    name: 'organization-add-contact-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            accountType: '',
            organizationContactStatusList: [],
            dto: {},
        };
    },
    computed: {
        mode() {
            return this.modalData.mode;
        },
        organizationID() {
            return this.modalData.organizationID;
        },
        lblTitle() {
            return this.modalData.mode === 'edit' ? 'Edit Contact: ' + this.modalData.dto.Name : 'Add Contact';
        },
    },
    methods: {
        btnSaveOnClick() {
            this.dto.OrganizationID = this.organizationID;

            if (this.mode === 'add') {
                this.$store.dispatch('AdminOrganizationModule/createOrganizationContact', this.dto).then(() => {
                    this.shouldModalStayOpen = false;
                    this.successClose(this.dto);
                });
            } else if (this.mode === 'edit') {
                this.$store.dispatch('AdminOrganizationModule/updateOrganizationContact', this.dto).then(() => {
                    this.shouldModalStayOpen = false;
                    this.successClose(this.dto);
                });
            }
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.dto = DMUtils.copy(this.modalData.dto);
        this.accountType = this.modalData.accountType;

        this.$store.dispatch('AdminOrganizationModule/getAllOrganizationContactStatusTypes').then((response) => {
            this.organizationContactStatusList = response;
        });
    },
};
</script>
