<template src="./shiftRequestsMultipleDaysModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'shift-requests-multiple-days-modal',
        mixins: [dmModalMixin],
        data() {
            return {
                lblTitle: 'Duplicate Shift for Multiple Days',
                date: null,
                selectedDates: []
            }
        },
        computed: {
            selectedDatesSorted() {
                let selectedDatesCopy = DMUtils.copy(this.selectedDates);
                selectedDatesCopy.sort(function (left, right) {
                    return moment.utc(left.value).diff(moment.utc(right.value));
                });
                return selectedDatesCopy;
            }
        },
        methods: {
            onDatePickerClose(args) {
                args.preventDefault();
            },
            onDatePickerPageChange() {
                this.highlightSelectedDates();
            },
            highlightSelectedDates() {
                this.selectedDates.forEach(d => {
                    let $element = document.querySelector('span[title="' + d.title + '"]');
                    if($element) {
                        if(!$element.parentElement.classList.contains('app-selected-date')) {
                            $element.parentElement.classList.add('app-selected-date');
                        }
                    }
                });
            },
            btnSaveOnClick() {
                let dto = {
                    ShiftDates: this.selectedDates.map(d => moment(d.value).format('MM-DD-YYYY')),
                    ShiftID: this.modalData.shiftID
                };

                endpoints.createShiftMultipleDays(dto)
                    .then(() => {
                        this.selectedDates = [];
                        this.shouldModalStayOpen = false;
                        this.successClose();
                    });
            },
            btnCancelOnClick() {
                this.selectedDates = [];
                this.closeModal();
            }
        },
        created() {
            let handleDate = ($event) => {
                let className = 'app-selected-date';
                let date = moment($event.target.title).format();
                let match = this.selectedDates.filter(d => d.value === date)[0];
                if (match) {
                    $event.target.parentElement.classList.remove(className);
                    $event.target.parentElement.classList.remove('e-selected');
                    this.selectedDates = this.selectedDates.filter(d => d.value !== date);
                } else {
                    $event.target.parentElement.classList.add(className);
                    this.selectedDates.push({
                        value: moment($event.target.title).format(),
                        title: $event.target.title
                    });
                }

                this.highlightSelectedDates();
            };

            this.handleClick = ($event) => {
                let content = $event.target.innerHTML;
                if($event.target.classList.contains('e-day')) {
                    if (!isNaN(content)) {
                        let num = parseInt(content);
                        if(num > 0 && num <= 31) {
                            handleDate($event);
                        }
                    }
                }
            };

            document.body.addEventListener('click', this.handleClick);
        },
        mounted() {
            setTimeout(() => {
                this.$refs.datePicker.show();
            });
        },
        destroyed() {
            document.body.removeEventListener('click', this.handleClick);
        }
    }
</script>
