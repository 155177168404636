import endpoints from "../../js/endpoints";

const blockedCaregiversModule = {
    namespaced: true,
    state: () => ({
        AllBlockedCaregivers: []
    }),
    mutations: {
        setBlockedCaregivers(state, caregivers) {
            state.AllBlockedCaregivers = caregivers;
        },
    },
    actions: {
        getAllBlockedCaregiversForOrganization({commit}, organizationID) {
            commit('setBlockedCaregivers', []);

            return endpoints.getAllBlockedCaregiversForOrganization(organizationID)
                .then((response) => {
                    commit('setBlockedCaregivers', response);
                });
        },     
        blockCaregiverOrganization({dispatch}, dto) {
            return endpoints.blockCaregiverOrganization(dto)
                .then(() => {
                    dispatch('getAllBlockedCaregiversForOrganization', 0);
                });
        },
        unblockCaregiverOrganization({dispatch}, dto) {
            return endpoints.unblockCaregiverOrganization(dto)
                .then(() => {
                    dispatch('getAllBlockedCaregiversForOrganization', 0);
                });
        },
        importBlockedCaregiversOrganization({dispatch}, file) {
            return endpoints.importBlockedCaregiversOrganization(file)
                .then(() => {
                    dispatch('getAllBlockedCaregiversForOrganization');
                });
        },  
        exportBlockedCaregiversOrganization({}) {
            return endpoints.exportBlockedCaregiversOrganization()
                .then((response) => {
                    return response;
                });
        },   
        exportBlockedCaregiversTemplate({}) {
            return endpoints.exportBlockedCaregiversTemplate()
                .then((response) => {
                    return response;
                });
        }     
    },
};

export default blockedCaregiversModule;
