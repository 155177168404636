x<template src="./paymentsPage.html"></template>

<script>
    import Vue from 'vue';

    export default {
        name: 'payments-page',
        data() {
            return {
                searchString: '',
                organizationUsersTableColumns: [],
                organizationUsersTableSortBy: {
                    columns: [
                        { field: 'CreatedUtc', direction: 'Descending' },
                    ]
                },
                organizationUsersTableCustomClass: 'dm-bg-color-black',
                organizationUsersTableActions: [
                    {
                        text: 'Edit'
                    },
                ],
                selectedRow: null
            }
        },
        computed: {
            organizationUsersTableItems() {
                const filteredOrganizationUsers = this.dummyData.filter(
                (person) => {
                    return person.OrganizationUserName.toLowerCase().includes(this.searchString.toLowerCase());
                }
                )
                return filteredOrganizationUsers;
                //return this.$store.state.OrganizationUserModule.AllOrganizationUsers;
            },
            organizationUserID() {
                return this.selectedRow.rowData.OrganizationUserID;
            },
        },
        methods: {
            organizationUsersTableRowOnClick(args) {
                this.selectedRow = args;
            },
            addNewOrganizationUser() {
                let modalData = {
                    mode: 'add',
                    dto: {},
                };

                DMUtils.openApplicationModal('organizationUsersEditModal', modalData);
            },
            editOrganizationUser() {
                let modalData = {
                    mode: 'edit',
                    dto: {},
                };

                DMUtils.openApplicationModal('organizationUsersEditModal', modalData);

                // this.$store
                //     .dispatch('OrganizationUserModule/getOrganizationUserById', this.organizationUserID)
                //     .then((response) => {
                //         modalData.dto = response;
                //         DMUtils.openApplicationModal('organizationUsersEditModal', modalData);
                //     });
            },
            deleteOrganizationUser() {
                this.$store.dispatch('OrganizationUserModule/deleteOrganizationUser', this.organizationUserID);
            },
            organizationUsersTableActionOnClick(args) {
                if (args.item.text === 'Edit') {
                    this.editOrganizationUser();
                } 
                else if (args.item.text === 'Delete') {
                    const message = 'Are you sure you want to delete this organizationUser?';
                    const title = 'Confirm';
                    const confirmCallback = this.deleteOrganizationUser;

                    DMUtils.openApplicationModal('dmConfirmModal', {
                        title: title,
                        message: message,
                        confirmCallback: confirmCallback,
                        confirmText: 'Yes',
                        denyText: 'Cancel',
                    });
                }
            },
        },
        created() {
            //this.$store.dispatch('OrganizationUserModule/getOrganizationUsers');
            this.organizationUsersTableColumns = [
                {
                    type: 'iconTemplate',
                    iconName: 'ellipsis',
                    ellipsisActions: this.organizationUsersTableActions,
                    iconOnClick: this.organizationUsersTableActionOnClick,
                    width: '55px',
                },
                {
                    key: 'OrganizationUserName',
                    label: 'Name',
                },
                {
                    key: 'OrganizationUserEmailAddress',
                    label: 'Email Address',
                },
                {
                    key: 'OrganizationUserUserType',
                    label: 'User Type',
                },
                {
                    key: 'OrganizationUserPhoneNumber',
                    label: 'Phone Number',
                },
            ];
        },
    }
</script>