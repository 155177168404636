<template src="./caregiverReferenceEditModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

export default {
    name: 'caregiver-reference-edit-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            mode: '',
            dto: {},
            relationshipTypes: [],
        }
    },
    computed: {
        lblTitle() {
            return this.modalData.mode === 'edit' ? 'Edit Reference' : 'Add Reference';
        },
    },
    methods: {
        btnSaveOnClick() {
             if (this.mode === 'edit') {              
                this.$store
                .dispatch('AdminCaregiverModule/updateReference', this.dto)
                    .then(() => {                       
                        this.shouldModalStayOpen = false;
                        this.successClose(this.dto);
                    });
            } else if (this.mode === 'add') {
                this.$store
                    .dispatch('AdminCaregiverModule/createReference', this.dto)
                    .then(() => {
                        this.shouldModalStayOpen = false;
                        this.successClose(this.dto);
                    });            
            }           
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {       
        this.dto = DMUtils.copy(this.modalData.dto);
        this.mode = this.modalData.mode;
        this.$store.dispatch('AdminCaregiverModule/getAllReferencesRelationshipTypes')
            .then((response) => {
                this.relationshipTypes = response.Relationships;
            })
        
    },
}
</script>
