<template src="./messagesAdminThreadModal.html"></template>

<script>
import dmModalMixin from '../../../../dmFramework/dmJS/dmModalMixin'
import messagesAdminThreadPage from '../../../messagesComponents/messagesAdminThreadPage/messagesAdminThreadPage'

export default {
    name: 'messages-admin-thread-modal',
    mixins: [dmModalMixin],
    components: {
        'messages-thread-page': messagesAdminThreadPage
    },
    data() {
        return {

        }
    },
    computed: {
        lblTitle() {
            return this.modalData.selectedCaregiverName;
        },
    },
    methods: {
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {

    },
}
</script>
