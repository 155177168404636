<template src="./usersMyProfileEditModal.html"></template>

<script>
    import dmModalMixin from '../../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'users-my-profile-edit-modal',
        mixins: [dmModalMixin],
        data() {
            return {
                lblTitle: 'My Profile',
                dto: {},
                notifications: {}
            }
        },
        computed: {
            //TODO: Come back and fix so timezone is relative to accountType
            getFormattedUpdatedUtc() {
                let moment = require('moment-timezone');

                let formattedDate = moment.tz(this.dto.UpdatedUtc, this.myShyftAdminTimeZone).format("M/D/YYYY hh:mmA z");

                if (formattedDate === "Invalid date"){
                    formattedDate = "N/A"
                }

                return formattedDate;
            },
            getFormattedCreatedUtc() {
                let moment = require('moment-timezone');

                let formattedDate = moment.tz(this.dto.CreatedUtc, this.myShyftAdminTimeZone).format("M/D/YYYY hh:mmA z");

                if (formattedDate === "Invalid date"){
                    formattedDate = "N/A"
                }

                return formattedDate;
            },
            myShyftAdminTimeZone() {
                return this.$store.state.AdminModule.MyShyftAdminTimeZone;
            },
        },  
        methods: {
            updateNotificationSettings(setting) {
                let notifications = DMUtils.copy(this.notifications);
                notifications[setting] = !notifications[setting];

                this.$store.dispatch('AuthorizationModule/updateNotificationSettings', notifications)
                    .then(() => {
                        this.notifications[setting] = notifications[setting];
                    });
            },
            btnSendVerificationEmailOnClick() {
                this.$store
                    .dispatch('AuthorizationModule/sendEmailVerificationCode', this.dto)
                        .then(() => {
                             DMUtils.alertShow(
                                'Verification email sent!'
                            );
                        });
            },
            btnSaveOnClick() {
                this.$store
                    .dispatch('AuthorizationModule/updateProfile', this.dto)
                        .then(() => {
                            this.successClose();
                        });
            },
            btnCancelOnClick() {
                this.closeModal();
            },
        },
        created() {
            this.dto = DMUtils.copy(this.modalData.dto);
            this.$store.dispatch('AuthorizationModule/getUserProfile')
                .then((response) => {
                    this.notifications = response.NotificationSettings;
                });
        },
    }
</script>
