<template src="./shiftRequestsDeclineModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

export default {
    name: 'organization-decline-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            dto: {},
        }
    },
    computed: {
        lblTitle() {
            return 'Decline: ' + this.dto.ApplicantFirstLastName;
        },
        formatShiftHourlyRate(){
            let formatted = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',               
            });

            this.dto.ShiftHourlyRate = this.dto.ShiftHourlyRate * (1 + this.dto.HourlyRateMarkUpPercentage);

            return formatted.format(this.dto.ShiftHourlyRate)
        }
    },
    methods: {
        declineApplicant(){
            this.$store
                .dispatch('ShiftModule/declineShiftApplicant', this.dto)
                .then(() => {
                    this.shouldModalStayOpen = false;
                    this.successClose(this.dto);
                });
        },
        btnSaveOnClick() {
            const message = 'Are you sure you want to Decline this shift applicant?';
            const title = 'Confirm';

            DMUtils.openApplicationModal('dmConfirmModal', {
                title: title,
                message: message,
                confirmCallback:  this.declineApplicant,
                confirmText: 'Yes',
                denyText: 'Cancel',
            });          
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.dto = DMUtils.copy(this.modalData.dto);
    },
}
</script>
