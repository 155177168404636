const menuModule = {
    namespaced: true,
    state: () => ({
        AllNotificationHistory: []
    }),
    mutations: {
        setNotificationHistory(state, history) {
            state.AllNotificationHistory = history;
        },
    },
    actions: {
        getNotificationHistoryOrganization({commit}, params) {
            commit('setNotificationHistory', []);

            return endpoints.getNotificationHistoryOrganization(params)
                .then((response) => {
                    commit('setNotificationHistory', response);
                });
        },  
        getNotificationHistoryMyShyftAdmin({commit}, params) {
            commit('setNotificationHistory', []);

            return endpoints.getNotificationHistoryMyShyftAdmin(params)
                .then((response) => {
                    commit('setNotificationHistory', response);
                });
        }, 
    },
};

export default menuModule;
