export default {
    data() {
        return {
            openFilters: false,
            activeChats: [],
            searchText: '',
            dateValues: [moment().subtract(3, 'months').format(), moment().format()],
            startDate: null,
            endDate: null,
            selectedOrganizationID: 0,
            selectedCaregiverFilterID: 0,
            organizationOptions: [],
            caregiverOptions: [],
            filtersLocalStorageName: this.$store.state.localStoragePrefix + 'active-chat-filters',
        }
    },
    computed: {
        signedInUser() {
            return this.$store.state.AuthorizationModule.SignedInUser;
        },
        isCaregiverOrganizationApp() {
            return app.repoName === 'myshyft-caregiver-organization-mobile-frontend';
        },
        isAdminOrganizationApp() {
            return app.repoName === 'myshyft-admin-organization-frontend';
        }
    },
    methods: {
        formatActiveChatDateTimeDetails(message, startUtc, createdUtc, timezone) {
            message.startDate = app.formatDateDisplay(startUtc, timezone);
            message.createdDate = app.formatDateDisplay(createdUtc, timezone);
            message.createdDate += ' at ' + app.formatTimeDisplay(createdUtc, timezone);

            let createdDate = moment(createdUtc);
            let now = moment();
            if(createdDate > now.startOf('day')) {
                message.createdDate = app.formatTimeDisplay(createdUtc, timezone);
            } else if(createdDate < now.startOf('day') && createdDate > now.subtract(1, 'day')) {
                message.createdDate = 'Yesterday';
            } else if(createdDate < now.startOf('day') && createdDate > now.subtract(7, 'days')) {
                message.createdDate = createdDate.format('dddd');
            } else {
                message.createdDate = app.formatDateDisplay(createdUtc, timezone);
            }
        },
        formatThreadDateTimeDetails(message, createdUtc, timezone, dateDisplayList) {
            message.createdTime = app.formatTimeDisplay(createdUtc, timezone);

            let createdDate = moment(createdUtc);
            let now = moment();
            if(createdDate > now.startOf('day')) {
                message.dateDisplay = 'Today';
            } else if(createdDate < now.startOf('day') && createdDate > now.subtract(1, 'day')) {
                message.dateDisplay = 'Yesterday';
            } else if(createdDate < now.startOf('day') && createdDate > now.subtract(7, 'days')) {
                message.dateDisplay = createdDate.format('dddd');
            } else {
                message.dateDisplay = app.formatDateDisplay(createdUtc, timezone);
            }

            if(dateDisplayList.includes(message.dateDisplay)) {
                message.dateDisplay = '';
            } else {
                dateDisplayList.push(message.dateDisplay);
            }
        },
        toggleFilters() {
            if (this.openFilters) {
                this.$refs.filtersSidebar.hide();
            } else {
                this.savedFilters = DMUtils.copy({
                    dateValues: this.dateValues,
                    selectedOrganizationID: this.selectedOrganizationID,
                    searchText: this.searchText,
                });
                this.$refs.filtersSidebar.show();
            }

            this.openFilters = !this.openFilters;
        },
        applyFilters() {
            this.toggleFilters();

            this.getActiveChats();
        },
        cancelFilterChange() {
            this.searchText = this.savedFilters.searchText;
            if(this.savedFilters.dateValues) {
                this.startDate = new Date(this.savedFilters.dateValues[0]);
                this.endDate = new Date(this.savedFilters.dateValues[1]);
            } else {
                this.dateValues = null;
            }

            this.toggleFilters();
        },
    },
    created() {
        if(this.isCaregiverOrganizationApp) {
            if (this.$route.meta.isBack) {
                let localStorageFilters = DMUtils.localStorageGet(this.filtersLocalStorageName);
                if (localStorageFilters) {
                    localStorageFilters = JSON.parse(localStorageFilters);
                    this.searchText = localStorageFilters.searchText;
                    if (localStorageFilters.dateValues) {
                        this.startDate = new Date(localStorageFilters.dateValues[0]);
                        this.endDate = new Date(localStorageFilters.dateValues[1]);
                    }
                    this.selectedOrganizationID = localStorageFilters.selectedOrganizationID;
                }
            }
        }

        this.userRoleEndpoints = this.endpointsMap[this.signedInUser.Role];
    }
}