<template src="./viewImageModal.html"></template>

<script>
import dmModalMixin from '../../../../dmFramework/dmJS/dmModalMixin';

export default {
    name: 'view-image-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            imageFileName: '',
            imageBase64: '',
        };
    },
    computed: {
        lblTitle() {
            return 'View Image: ' + this.imageFileName;
        },
    },
    methods: {
        btnCloseOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.imageFileName = DMUtils.copy(this.modalData.imageFileName);
        this.imageBase64 = DMUtils.copy(this.modalData.imageBase64);
    },
};
</script>
