<template>

    <ejs-grid
        ref="grdTableList"
        :dataSource="items"
        :recordClick="recordOnClick"
        :commandClick="actionOnClick"
        :allowSorting="true"
        :allowScrolling="true"
        :sortSettings="sortBy"
        class="dm-flex-1 dm-overflow-hidden"
        :allowTextWrap="false"
        :textWrapSettings="{ wrapMode: 'Header' }"
        :toolbar="toolbar"
        :allowExcelExport="true"
        :allowPdfExport="true"
        :toolbarClick="toolbarClick"
        :allowPaging="allowPaging"
        :pageSettings="pageSettings"
        :dataStateChange="dataStateChange"
        height="100%"
        width="100%">
        <e-columns>
            <e-column
                v-for="field in columns"
                v-if="field.type === 'iconTemplate'"
                :field="field.key"
                :template="getIconTemplate(field)"
                :width="field.width"
                :minWidth="field.minWidth"
                :customAttributes="field.classes"
                :format="field.format"
                :textAlign="field.textAlign"
                >
            </e-column>
            <e-column
                v-for="field in columns"
                v-if="field.type !== 'iconTemplate'"
                :field="field.key"
                :template="field.template"
                :headerText="field.label"
                :type="field.type"
                :allowSorting="field.allowSorting !== undefined ? field.allowSorting : true"
                :commands="field.commands"
                :width="field.width"
                :minWidth="field.minWidth"
                :customAttributes="field.classes"
                :format="field.format"
                :formatter="field.formatter"
                :disableHtmlEncode="false"
                :textAlign="field.textAlign">
            </e-column>
        </e-columns>
        <e-aggregates>
            <e-aggregate>
                <e-columns>
                    <e-column
                        v-for="field in columns"
                        v-if="field.aggregate"
                        :columnName="field.key"
                        type="Custom"
                        :customAggregate="field.aggregateColumn.doAggregation"
                        :footerTemplate="footerTemplate"
                        :customAttributes="aggregateClasses">
                    </e-column>
                </e-columns>
            </e-aggregate>
        </e-aggregates>
    </ejs-grid>

</template>

<script>
    import Vue from 'vue'
    import { Sort, CommandColumn, Aggregate, PdfExport, ExcelExport, Group, Toolbar, Search, Page } from "@syncfusion/ej2-vue-grids";

    export default {
        name: 'app-table',
        props: [
            'props',
            'columns',
            'items',
            'rowOnClick',
            'actionOnClick',
            'sortBy',
            'customClass',
            'toolbar',
            'search',
            'allowPaging',
            'pageSettings',
            'dataStateChange',
        ],
        data() {
            return {
                footerTemplate(args) {
                    return {
                        template: Vue.component('footerTemplate', {
                            template: `<span class="dm-text-right">{{data.Custom}}</span>`,
                            data() {
                                return {
                                    data: {},
                                };
                            }
                        })
                    }
                },
                aggregateClasses: {
                    class: 'dm-text-right'
                },
            }
        },
        provide: {
            grid:[Sort, CommandColumn, Aggregate, PdfExport, ExcelExport, Group, Toolbar, Search, Page]
        },
        methods: {
            getIconTemplate(column) {
                if (column.iconName === 'ellipsis') {
                    return this.getEllipsisTemplate(column.ellipsisActions, column.iconOnClick).template;
                }
                // Add additional icons here as needed, such as edit and download
            },
            getEllipsisTemplate(ellipsisActions, iconOnClick) {
                return {
                    template: () => {
                        return {
                            template: Vue.component('columnTemplate', {
                                template: `<ejs-dropdownbutton
                                                :items="iconActions"
                                                :select="select"
                                                iconCss="e-icons e-ellipsis-vert"
                                                cssClass= "e-caret-hide">
                                            </ejs-dropdownbutton>`,
                                data() {
                                    return {
                                        iconActions: ellipsisActions
                                    }
                                },
                                methods: {
                                    select(args) {
                                        iconOnClick(args);
                                    }
                                },
                            })
                        }
                    }
                }
            },
            recordOnClick(data) {
                if (!data.column.commands || data.column.commands.length === 0) {
                    this.rowOnClick(data);
                }
            },
            toolbarClick(args) {
                switch (args.item.text) {
                    case 'PDF Export':
                        this.$refs.grdTableList.pdfExport();
                        break;
                    case 'Excel Export':
                        this.$refs.grdTableList.excelExport();
                        break;
                    case 'CSV Export':
                        this.$refs.grdTableList.csvExport();
                        break;
                }
            },
        },
    }
</script>