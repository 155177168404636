import endpoints from "../../js/endpoints";

const exportCSVsModule = {
    namespaced: true,
    state: () => ({

    }),
    mutations: {

    },
    actions: {
        exportShiftsMyShyftAdmin({}, params) {
            return endpoints.exportShiftsMyShyftAdmin(params)
                .then((response) => {
                    return response;
                });
        },     
        exportShiftsOrganization({}, params) {
            return endpoints.exportShiftsOrganization(params)
                .then((response) => {
                    return response;
                });
        },
        exportTransactions({}, params) {
            return endpoints.exportTransactions(params)
                .then((response) => {
                    return response;
                });
        },
        exportInvoices({}, params) {
            return endpoints.exportInvoices(params)
                .then((response) => {
                    return response;
                });
        },
        exportCaregivers({}, params) {
            return endpoints.exportCaregivers(params)
                .then((response) => {
                    return response;
                });
        },     
        exportCaregiverMedicalLicenses({}, params) {
            return endpoints.exportCaregiverMedicalLicenses(params)
                .then((response) => {
                    return response;
                });
        },    
        exportOrganizations({}, params) {
            return endpoints.exportOrganizations(params)
                .then((response) => {
                    return response;
                });
        }, 
        exportOrganizationsUsersAndContacts({}, params) {
            return endpoints.exportOrganizationsUsersAndContacts(params)
                .then((response) => {
                    return response;
                });
        },
        exportOrganizationLocations({}, params) {
            return endpoints.exportOrganizationLocations(params)
                .then((response) => {
                    return response;
                });
        },
        exportShiftClocksMyShyftAdmin({}, params) {
            return endpoints.exportShiftClocksMyShyftAdmin(params)
                .then((response) => {
                    return response;
                });
        },
        exportCaregiverLicenseSummaryMyShyftAdmin({}, params) {
            return endpoints.exportCaregiverLicenseSummaryMyShyftAdmin(params)
                .then((response) => {
                    return response;
                });
        }
    },
};

export default exportCSVsModule;
