<template src="./shiftRequestsSelectLowerRateModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

export default {
    name: 'organization-select-lower-rate-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            dto: {},
        }
    },
    computed: {
        lblTitle() {
            return 'Select a Caregiver with a Proposed Lower Hourly Rate';
        },
        proposedHourlyRate() {
            this.dto.ProposedShiftHourlyRate = this.dto.ShiftHourlyRate;
        }
    },
    methods: {
        btnSaveOnClick() {
            this.$store
                .dispatch('ShiftModule/selectWithLowerHourlyRateShiftApplicant', this.dto)
                .then(() => {
                    this.shouldModalStayOpen = false;
                    this.successClose(this.dto);
                });
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.dto = DMUtils.copy(this.modalData.dto);
        this.proposedHourlyRate();
    },
}
</script>
