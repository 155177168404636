<template src="./favoriteCaregiversModal.html"></template>

<script>
import dmModalMixin from '../../../../dmFramework/dmJS/dmModalMixin';

export default {
    name: 'favorite-caregivers-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            lblTitle: 'Favorite Caregivers',
            searchString: '',

            favoriteCaregiversTableColumns: [],
            favoriteCaregiversTableSortBy: {
                columns: [{ field: 'CreatedUtc', direction: 'Descending' }],
            },
            favoriteCaregiversTableActions: [
                {
                    text: 'Remove',
                },
            ],
            selectedRow: null,
        };
    },
    computed: {
        favoriteCaregiversTableItems() {
            let favoriteCaregivers = this.$store.state.FavoriteCaregiverModule.AllFavoriteCaregivers;
            const filteredCaregivers = favoriteCaregivers.filter((person) => {
                return person.LastName.toLowerCase().includes(this.searchString.toLowerCase());
            });
            return filteredCaregivers;
        },
    },
    methods: {
        favoriteCaregiversTableRowOnClick(args) {
            this.selectedRow = args;
        },
        removeCaregiverFromFavorites() {
            let dto = {
                OrganizationFavoriteCaregiverID: this.selectedRow.rowData.OrganizationFavoriteCaregiverID,
            };

            this.$store.dispatch('FavoriteCaregiverModule/removeFavoriteCaregiverOrganization', dto);
        },
        favoriteCaregiversTableActionOnClick(args) {
            if (args.item.text === 'Remove') {
                const message = 'Are you sure you want to Remove this Caregiver from your Organizations Favorites?';
                const title = 'Confirm';
                const confirmCallback = this.removeCaregiverFromFavorites;

                DMUtils.openApplicationModal('dmConfirmModal', {
                    title: title,
                    message: message,
                    confirmCallback: confirmCallback,
                    confirmText: 'Yes',
                    denyText: 'Cancel',
                });
            }
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.$store.dispatch('FavoriteCaregiverModule/getAllFavoriteCaregiversForOrganization', 0);

        this.favoriteCaregiversTableColumns = [
            {
                type: 'iconTemplate',
                iconName: 'ellipsis',
                ellipsisActions: this.favoriteCaregiversTableActions,
                iconOnClick: this.favoriteCaregiversTableActionOnClick,
                width: '55px',
            },
            {
                key: 'FirstLastName',
                label: 'Caregiver',
            },
            {
                key: 'CreatedUtc',
                label: 'Favorited On',
                type: 'date',
                format: 'MM/dd/yyyy',
                width: '180px',
            },
        ];
    },
};
</script>
