<template src="./organizationDashboardPage.html"></template>

<script>
    import { CircularGaugePlugin, Annotations } from "@syncfusion/ej2-vue-circulargauge";
    import { ChartPlugin, ColumnSeries, Category } from "@syncfusion/ej2-vue-charts";

    export default {
        name: 'organization-dashboard-page',
        data() {
            return {
                dashboardDto: {},

                // #region Gauge Component
                ranges: [
                    {
                        start: 0, end: 100,
                        radius: '100%', startWidth: 30,
                        endWidth: 30, color: '#E0E0E0'
                    }
                ],
                lineStyle: { width: 0 },
                radius: '100%',
                labelStyle: { font: { size: '0px' } },
                annotations: [
                    {
                        content: '',
                        angle: 180, radius: '-10%', zIndex: '5',
                    }, 
                    {
                        content: 'USED OUT OF',
                        angle: 180, radius: '20%', zIndex: '5',
                        textStyle: {
                            fontFamily: 'Poppins',
                            color: '#9E9E9E',
                            fontStyle: 'Bold',
                            fontWeight: 'Regular',
                            size: '14px'
                        }
                    },
                    {
                        content: '',
                        angle: 180, radius: '40%', zIndex: '5',
                    }
                ],
                pointers: [
                    {
                        type: 'RangeBar',
                        value: 0, radius: '100%',
                        color: '#37bbca', animation: { duration: 0 },
                        pointerWidth: 30
                    }
                ],
                minimum: 0,
                maximum: 0,
                majorTicks: { width: 0 },
                minorTicks: { height: 0 },
                // #endregion Gauge Component

                primaryXAxis: {
                    valueType: 'Category',
                },
                primaryYAxis: {
                    labelFormat: '${value}',
                    rangePadding: 'Additional',
                    minimum: 0,
                    lineStyle: { width: 0 },
                    majorTickLines: { width: 0 },
                    minorTickLines: { width: 0 }
                },
                palettes: ["#37bbca"],
                cornerRadius: {
                    topLeft: 10, topRight: 10
                }
            }
        },
        provide:{
            circulargauge: [Annotations],
            chart: [ColumnSeries, Category]
        },
        created() {
            this.$store.dispatch('OrganizationDashboardModule/getOrganizationDashboard')
                .then((response) => {
                    this.dashboardDto = response;

                    let formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });

                    if (this.dashboardDto.RecentlyResignedShifts){
                        let moment = require('moment-timezone');
                        this.dashboardDto.RecentlyResignedShifts.forEach((shift) => {
                            shift.ShiftStartUtc = moment.tz(shift.ShiftStartUtc, shift.LocalTimeZone).format("M/D/YYYY hh:mmA z"); 
                        })
                    }

                    this.pointers[0].value = response.OutstandingBalance;
                    this.maximum = response.CreditLimit;
                    this.ranges[0].end = response.CreditLimit;
                    this.annotations[0].content ='<p style="color:#37bbca; font-size:26px; font-family: Poppins;">' + formatter.format(response.OutstandingBalance) + '</p>';
                    this.annotations[2].content ='<p style="color:#8a8a8d; font-size:18px; font-family: Poppins;">' + formatter.format(response.CreditLimit) + '</p>';
                });
        },
    }
</script>