import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import emailVerificationPage from '../views/shared/authorization/emailVerification/emailVerification.vue'
import resetPasswordPage from '../views/shared/authorization/resetPassword/resetPassword.vue'

const router = new Router({
    routes: [
        {
            path: '/emailVerification/:token',
            name: 'emailVerificationPage',
            component: emailVerificationPage,
        },
        {
            path: '/resetPassword/:token',
            name: 'resetPasswordPage',
            component: resetPasswordPage,
        },
        {
            path: '/marketingPage',
            name: 'marketingPage',
            beforeEnter() {location.href = 'http://myshyft.org'}
       }
    ]
});

export default router;