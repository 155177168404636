<template src="./transfersPage.html"></template>

<script>
export default {
    name: 'transfers-page',
    data() {
        return {
            searchStartDate: '',
            searchEndDate: '',
            paramsDto: {},
            allowFiltering: true,
            organizationNameList: [],
            organizationFields: { text: 'OrganizationName', value: 'OrganizationID' },
            caregiverNameList: [],
            caregiverFields: { text: 'FullName', value: 'CaregiverID' },
            filterType: 'Contains',
            allowFiltering: true,
            transfersTableColumns: [],
            transfersTableSortBy: {
                columns: [{ field: 'CreatedUtc', direction: 'Descending' }],
            },
            transfersTableActions: [
                {
                    text: 'Retry',
                },
                {
                    text: 'Edit Shift Clock',
                },
                {
                    text: 'View Shift Details',
                },
            ],
            transfersTablePageSettings: { pageSize: 300, pageSizes: [500, 300, 100, 50], pageCount: 4, direction: 'Descending' },
            selectedRow: null,
        };
    },
    computed: {
        transfersTableItems() {
            let transfers = this.$store.state.InvoiceModule.AllTransactions;

            if (transfers) {
                transfers.forEach((transfer) => {
                    transfer.myShyftAdminTimeZone = this.myShyftAdminTimeZone;
                });
            }

            return {
                result: transfers,
                count: this.$store.state.InvoiceModule.AllTransactionsNumberOfRows,
            };
        },
        myShyftAdminTimeZone() {
            return this.$store.state.AdminModule.MyShyftAdminTimeZone;
        },
    },
    methods: {
        scrollGridToTop() {
            this.$refs.transfersTable.$refs.grdTableList.getContent().children[0].scrollTop = 0;
        },
        transfersTableRowOnClick(args) {
            this.selectedRow = args;
        },
        editShiftClock() {
            this.$store
                .dispatch('ShiftModule/getShiftClockByID', this.selectedRow.rowData.ShiftClockID)
                .then((response) => {
                    let modalData = {
                        mode: 'edit',
                        shiftID: this.selectedRow.rowData.ShiftID,
                        dto: response,
                        timeZone:  this.myShyftAdminTimeZone
                    };
                    DMUtils.openApplicationModal('shiftRequestsClocksEditModal', modalData);
                });
        },
        retryTransaction() {
            let dto = {
                TransactionID: this.selectedRow.rowData.TransactionID,
                RowVersion: this.selectedRow.rowData.RowVersion,
            };

            this.$store.dispatch('InvoiceModule/retryTransaction', dto);
        },
        viewShiftRequest() {
            this.$store.dispatch('ShiftModule/getShiftByIDMyShyftAdmin', this.selectedRow.rowData.ShiftID)
                .then((response) => {
                    let modalData = {
                        dto: response,
                        accountType: 'MyShyftUser',
                    };
                    DMUtils.openApplicationModal('shiftRequestsViewModal', modalData);
                });
        },
        transfersTableActionOnClick(args) {
            if (args.item.text === 'Retry') {
                this.retryTransaction();
            } else if (args.item.text === 'Edit Shift Clock') {
                this.editShiftClock();
            } else if (args.item.text === 'View Shift Details') {
                this.viewShiftRequest();
            }
        },
        searchTransfers() {
            let moment = require('moment-timezone');
            let utcNow = moment.utc();

            let startDay = moment.utc(this.searchStartDate).startOf('day');
            let endDay = moment.utc(this.searchEndDate).endOf('day');
            let timeZoneOffset = moment.tz.zone(this.myShyftAdminTimeZone).utcOffset(utcNow);

            this.paramsDto.StartDateRange = startDay.add(timeZoneOffset).format();
            this.paramsDto.EndDateRange = endDay.add(timeZoneOffset).format();
            this.paramsDto.PageToLoad =
                this.$refs.transfersTable.$refs.grdTableList.ej2Instances.pagerModule.pagerObj.properties.currentPage;
            this.paramsDto.RowsPerPage = this.transfersTablePageSettings.pageSize;
            this.paramsDto.SortByColumn = this.transfersTablePageSettings.columnName;
            this.paramsDto.SortDescending = this.transfersTablePageSettings.direction === 'Descending';

            this.scrollGridToTop();

            this.$store.dispatch('InvoiceModule/getAllTransactionsMyShyftAdmin', this.paramsDto);
        },
        transfersTableDataStateChange(state) {
            if (state.action.requestType === 'sorting' && state.action.direction && state.action.columnName) {
                this.transfersTablePageSettings.direction = state.action.direction;
                this.transfersTablePageSettings.columnName = state.action.columnName;
                this.searchTransfers();
            }

            if (state.action.requestType === 'paging') {
                this.transfersTablePageSettings.pageSize = state.take;
                this.searchTransfers();
            }
        },
    },
    created() {
        this.transfersTableColumns = [
            {
                type: 'iconTemplate',
                iconName: 'ellipsis',
                ellipsisActions: this.transfersTableActions,
                iconOnClick: this.transfersTableActionOnClick,
                width: '55px',
            },
            {
                key: 'TransactionID',
                label: 'ID',
                width: '75px',
            },
            {
                key: 'ShiftID',
                label: 'Shift ID',
                width: '95px',
            },
            {
                key: 'InvoiceID',
                label: 'Invoice ID',
                width: '115px',
            },
            {
                key: 'OrganizationName',
                label: 'Organization Name',
                width: '200px',
            },
            {
                key: 'CaregiverFullName',
                label: 'Caregiver Name',
                width: '200px',
            },
            {
                key: 'ThirdPartyTransferID',
                label: 'Stripe Transfer ID',
                width: '250px',
            },
            {
                key: 'CaregiverTotalAmount',
                label: 'Caregiver Amount',
                format: 'C2',
                textAlign: 'Right',
                width: '160px',
            },
            {
                key: 'HoursWorkedString',
                label: 'Hours',
                textAlign: 'Right',
                width: '90px',
            },
            {
                key: 'TransferStatus',
                label: 'Status',
                width: '100px',
            },
            {
                key: 'ErrorMessage',
                label: 'Error Message',
                width: '300px',
            },
            {
                key: 'ErrorType',
                label: 'Error Type',
                width: '150px',
            },
            {
                key: 'ErrorCreatedUtc',
                label: 'Error Created On',
                type: 'date',
                width: '200px',  
                formatter(column, transfer) {
                    let date = moment.tz(transfer.ErrorCreatedUtc, transfer.myShyftAdminTimeZone).format("MM/DD/YYYY hh:mmA z");

                    if (date === 'Invalid date'){
                        return '';
                    }

                    return date; 
                }             
            },
            {
                key: 'CreatedUtc',
                label: 'Created On',
                type: 'date',
                width: '200px',
                formatter(column, transfer) {
                    return moment.tz(transfer.CreatedUtc, transfer.myShyftAdminTimeZone).format("MM/DD/YYYY hh:mmA z"); 
                }
            },
        ];
    },
    mounted() {
        this.searchTransfers();
        this.$store.dispatch('AdminOrganizationModule/getAllOrganizationNames')
            .then((response) => {
                this.organizationNameList = response;
            });

        this.$store.dispatch('AdminCaregiverModule/getAllCaregiverNames')
            .then((response) => {
                this.caregiverNameList = response;
            });
    },
};
</script>
