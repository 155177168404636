<template src="./caregiverPayoutTransactionsModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'caregiver-payout-tranactions-modal',
        mixins: [dmModalMixin],
        data() {
            return {
                searchString: '',
                dto: {},
                accountType: "",
                payoutTransactionsTableColumns: [],
                payoutTransactionsTableSortBy: {
                    columns: [
                        { field: 'CreatedUtc', direction: 'Descending' },
                    ]
                },
                payoutTransactionsTableCustomClass: 'dm-bg-color-black',
                selectedRow: null
            }
        },
        computed: {
            lblTitle() {
                return 'Transactions for Payout ID: ' + this.dto.ThirdPartyPayoutID;
            },
            payoutTransactionsTableItems() {
                let transactions = this.$store.state.AdminCaregiverModule.TransactionsForPayoutID;
                let moment = require('moment-timezone');

                if (transactions){
                    transactions.forEach((transaction) => {
                        transaction.CreatedUtc = moment.tz(transaction.CreatedUtc, this.myShyftAdminTimeZone).format(); 
                    });
                }

                return transactions;
            },
            myShyftAdminTimeZone(){
                return this.$store.state.AdminModule.MyShyftAdminTimeZone;
            },
        },
        methods: {
            payoutTransactionsTableRowOnClick(args) {
                this.selectedRow = args;
            },         
            btnCloseOnClick(){
                this.closeModal();
            },          
        },
        created() {
            this.dto = DMUtils.copy(this.modalData.dto);
            this.$store.dispatch('AdminCaregiverModule/getTransactionsForPayoutID', this.dto);
            
            this.payoutTransactionsTableColumns = [
                {
                    key: 'ShiftID',
                    label: 'Shift ID',
                    width: '100px',
                },
                 {
                    key: 'OrganizationName',
                    label: 'Organization Name',
                    width: '200px',
                },
                {
                    key: 'CaregiverTotalAmount',
                    label: 'Caregiver Amount',
                    format: 'C2',
                    textAlign: 'Right',
                    width: '160px',
                },
                {
                    key: 'ThirdPartyTransferID',
                    label: 'Transfer ID',
                },
                {
                    key: 'CreatedUtc',
                    label: 'Created On',
                    type: "date",
                    format: "MM/dd/yyyy hh:mm a",
                    width: '170px',
                },
            ];
        },
    }
</script>
