<template src="./organizationLocationEditModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin';
import dmStates from '../../../../../dmFramework/dmJS/dmStates.js';

export default {
    name: 'organization-location-edit-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            dto: {},
            hasPet: false,
            step: 1,
            min: 0,
            stateList: dmStates,
            allowFiltering: false,
            timezoneList: [],
            locationTypeList: [],
            organizationTypeList: []
        };
    },
    computed: {
        mode() {
            return this.modalData.mode;
        },
        signedInUser() {
            return this.$store.state.AuthorizationModule.SignedInUser;
        },
        isMyShyftAdmin() {
            if (this.signedInUser.Role === 'MyShyftAdmin' 
              || this.signedInUser.Role === 'MyShyftEmployee'
              || this.signedInUser.Role === 'MyShyftViewer') 
            {
                return true;
            }
            return false;
        },
        lblTitle() {
            return this.modalData.mode === 'edit' ? 'Edit ' + app.organizationLocationTerminology(this.dto.OrganizationCategory, false, false) + ': ' + this.modalData.dto.LocationNameF : 'Add ' + app.organizationLocationTerminology(this.dto.OrganizationCategory, false, false);
        },
        modalWidth() {
            return '700px';
        },
        namePlaceholder() {
            if (this.dto.OrganizationCategory === 'Agency') {
                return 'Agency Name';
            } else {
                return 'Name';
            }
        }
    },
    methods: {
        getAllOrganizationTypes() {
            endpoints.getAllOrganizationTypes(this.dto.OrganizationCategory)
                .then(response => {
                    this.organizationTypeList = response;
                });
        },
        hasPetChanged() {
            if (this.hasPet) {
                this.dto.NumberOfPets = 0;
                this.dto.PetDescription = "";
            }
        },
        getLatitudeLongitude() {
            let addressString = "";

            if (this.dto.Address2 === ""){
                addressString = this.dto.Address1 + '%20' + this.dto.City + '%20' + this.dto.State;
            }
            else {
                addressString = this.dto.Address1 + '%20' + this.dto.Address2 + '%20' + this.dto.City + '%20' + this.dto.State;
            }

            let url = 'https://maps.googleapis.com/maps/api/geocode/json?address='+ addressString + '&key=' + window.googleAPIKey;

            return axios({
                method: 'GET',
                url: url
            }).then((response) => {
                if (response.data.status === "OK"){
                    this.dto.Latitude = response.data.results[0].geometry.location.lat;
                    this.dto.Longitude = response.data.results[0].geometry.location.lng;
                }
                return response.data;
            })
        },
        async btnSaveOnClick() {
            if (this.mode === 'edit') {
                await this.getLatitudeLongitude();
                this.$store
                    .dispatch('OrganizationLocationModule/updateOrganizationLocation', this.dto)
                    .then(() => {
                        this.shouldModalStayOpen = false;
                        this.successClose(this.dto);
                    });
            } else if (this.mode === 'add') {
                await this.getLatitudeLongitude();
                this.$store
                    .dispatch('OrganizationLocationModule/createOrganizationLocation', this.dto)
                    .then(() => {
                        this.shouldModalStayOpen = false;
                        this.successClose(this.dto);
                    });
            }
        },            
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.dto = DMUtils.copy(this.modalData.dto);
        this.getAllOrganizationTypes();

        if (this.dto.NumberOfPets > 0) {
            this.hasPet = true;
        }

        this.$store
            .dispatch('AuthorizationModule/getAllTimeZones')
            .then((response) => {
                this.timezoneList = response;
            });
        this.$store
            .dispatch('OrganizationLocationModule/getOrganizationLocationTypes')
            .then((response) => {
                this.locationTypeList = response.OrganizationLocationTypes;
            });
    },
};
</script>
