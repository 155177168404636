import Vue from 'vue'
import App from './App.vue';

import dmAlertModal from '../dmFramework/dmJS/dmAlertModal';
import dmConfirmModal from '../dmFramework/dmJS/dmConfirmModal';
import dmTable from '../dmFramework/dmJS/dmTable.vue';
import tabTable from './tabTable.vue';
import appTable from './appTable.vue';
import dmPasswordInput from '../dmFramework/dmJS/dmPasswordInput.vue';
import DMUtils from '../dmFramework/dmJS/dmUtils';
import app from './js/app.js';
import axios from 'axios'
import moment from 'moment'
import tz from 'moment-timezone'
import endpoints from './js/endpoints';
import store from "./js/store.js";
import router from "./js/router.js";

import { L10n } from '@syncfusion/ej2-base';
import { ButtonPlugin, CheckBoxPlugin, RadioButtonPlugin } from '@syncfusion/ej2-vue-buttons';
import { MenuPlugin, SidebarPlugin, TabPlugin } from '@syncfusion/ej2-vue-navigations';
import { GridPlugin } from '@syncfusion/ej2-vue-grids';
import { DialogPlugin } from '@syncfusion/ej2-vue-popups';
import { TextBoxPlugin } from '@syncfusion/ej2-vue-inputs';
import { DatePickerPlugin, DateRangePickerPlugin, DateTimePickerPlugin } from '@syncfusion/ej2-vue-calendars';
import { DropDownButtonPlugin } from "@syncfusion/ej2-vue-splitbuttons";
import { DropDownListPlugin, MultiSelectPlugin} from "@syncfusion/ej2-vue-dropdowns";
import { MultiSelect, CheckBoxSelection } from '@syncfusion/ej2-dropdowns';
import { RichTextEditorPlugin } from '@syncfusion/ej2-vue-richtexteditor';
import { NumericTextBoxPlugin } from "@syncfusion/ej2-vue-inputs";
import { SchedulePlugin} from '@syncfusion/ej2-vue-schedule';
import { ListViewPlugin } from '@syncfusion/ej2-vue-lists';
import { UploaderPlugin } from '@syncfusion/ej2-vue-inputs';
import { ChartPlugin } from '@syncfusion/ej2-vue-charts';
import { CircularGaugePlugin } from "@syncfusion/ej2-vue-circulargauge";
import { AccumulationChartPlugin } from '@syncfusion/ej2-vue-charts';
import { MaskedTextBoxPlugin } from '@syncfusion/ej2-vue-inputs';

import { registerLicense } from '@syncfusion/ej2-base';

// Material Design + Bootstrap Import statements for quick hot-swapping based on what's commented out.


// Material Design

// import './css/app.css'
// import './css/syncFusion.css'
// import '../dmFramework/dmCSS/dmApp.css'
// import '../dmFramework/dmCSS/dmIcons.css'
// import '../dmFramework/dmIcons/faIcons/faCSS/font-awesome.min.css'
// import "@syncfusion/ej2-icons/styles/material.css";
// import "@syncfusion/ej2-base/styles/material.css";
// import "@syncfusion/ej2-vue-buttons/styles/material.css";
// import "@syncfusion/ej2-vue-navigations/styles/material.css";
// import "@syncfusion/ej2-vue-grids/styles/material.css";
// import "@syncfusion/ej2-vue-popups/styles/material.css";
// import "@syncfusion/ej2-vue-inputs/styles/material.css";
// import "@syncfusion/ej2-vue-calendars/styles/material.css";
// import '@syncfusion/ej2-splitbuttons/styles/material.css';
// import '@syncfusion/ej2-vue-dropdowns/styles/material.css';

// Bootstrap

import './css/app.css'
import './css/syncFusion.css'
import './messagesComponents/syncFusionChat.css'
import './messagesComponents/appMessages.css'
import '../dmFramework/dmCSS/dmApp.css'
import '../dmFramework/dmCSS/dmIcons.css'
import '../dmFramework/dmIcons/faIcons/faCSS/font-awesome.min.css'

Vue.component('dmAlertModal', dmAlertModal);
Vue.component('dmConfirmModal', dmConfirmModal);
Vue.component('dmTable', dmTable);
Vue.component('appTable', appTable);
Vue.component('tabTable', tabTable);
Vue.component('dmPasswordInput', dmPasswordInput);

window.DMUtils = DMUtils;
window.app = app;
window.axios = axios;
window.moment = moment;
window.tz = tz;
window.endpoints = endpoints;
window.store = store;

L10n.load({
    'en-US': {
        'grid': {
            'EmptyRecord': 'No records to display.',
        },
    }
});

//registerLicense('ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkNjUX5YcXRWT2FdV0Q=');

Vue.use(ButtonPlugin);
Vue.use(CheckBoxPlugin);
Vue.use(MenuPlugin);
Vue.use(SidebarPlugin);
Vue.use(TabPlugin);
Vue.use(GridPlugin);
Vue.use(DialogPlugin);
Vue.use(TextBoxPlugin);
Vue.use(DatePickerPlugin);
Vue.use(DateRangePickerPlugin);
Vue.use(DropDownButtonPlugin);
Vue.use(DropDownListPlugin);
Vue.use(MultiSelectPlugin);
Vue.use(RichTextEditorPlugin);
Vue.use(NumericTextBoxPlugin);
Vue.use(DateTimePickerPlugin);
Vue.use(SchedulePlugin);
Vue.use(ListViewPlugin);
Vue.use(UploaderPlugin);
Vue.use(ChartPlugin);
Vue.use(CircularGaugePlugin);
Vue.use(AccumulationChartPlugin);
Vue.use(MaskedTextBoxPlugin);
Vue.use(RadioButtonPlugin);

MultiSelect.Inject(CheckBoxSelection);

Vue.config.errorHandler = (err) => {
    console.error(err);
    
    if (window.environmentName === 'dev') {
        return;
    }

    let expectedErrors = [
        `TypeError: Cannot read property '0' of undefined`,
        `TypeError: Cannot read property 'fitBounds' of null`,
    ];

    let logError = true;
    expectedErrors.map((expectedError) => {
        if (stack.startsWith(expectedError)) {
            logError = false;
        }
    });

    if (logError) {
        // DMUtils.sendErrorToDelMarMessaging({
        //     projectKey: '8af3d529951d4177a6344fb1aef86a61',
        //     clicks: clickTracking,
        //     stackTrace: err
        // });
    }

}

let clickTracking = [];
window.addEventListener('click', ($event) => {
    clickTracking.push($event.target.outerHTML);
    if (clickTracking.length > 10) {
        clickTracking.shift();
    }
});

window.eventBus = new Vue();

new Vue({
    el: '#app',
    store,
    router,
    render: h => h(App)
});
