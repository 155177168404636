<template src="./messagesShiftThreadPage.html"></template>

<script>
import Vue from 'vue'

let messageComponent = Vue.component('message', {
    template: `<div class="dm-flex-column dm-align-items-flex-end dm-margin-top-sm" v-if="data.rightSide" :key="data.MessageID" :data-message-id="data.MessageID">
                    <div class="date">{{ data.dateDisplay }}</div>
                    <div class="name dm-padding-right-sm">{{ data.senderName }}</div>
                    <div class="content1 dm-flex-column dm-align-items-flex-end dm-padding-sm">
                        <div class="info1">{{ data.MessageText }}</div>
                    </div>
                    <div class="time">{{ data.createdTime }}</div>
                </div>
                <div v-else class="dm-flex-column dm-align-items-start dm-margin-top-sm"  :data-message-id="data.MessageID">
                    <div class="date">{{ data.dateDisplay }}</div>
                    <div class="name dm-padding-left-sm">{{ data.senderName }}</div>
                    <div class="content2 dm-padding-sm">
                        <div class="info1">{{ data.MessageText }}</div>
                    </div>
                    <div class="time">{{ data.createdTime }}</div>
                </div>`,
    data() {
        return {

        };
    }
});

export default {
    name: 'messages-shift-thread-page',
    props: [
        'selectedShiftID',
        'selectedCaregiverID',
        'selectedMessageID',
        'selectedOrganizationID',
        'shiftIDAndCaregiverID'
    ],
    data() {
        return {
            messageText: '',
            messages: [],
            fields: { text: 'Name' },
            messageTemplate: function(e) {
                return {
                    template: messageComponent,
                };
            },
            shift: {},
            organizationName: '',
            organizationTimeZone: '',
            shiftDate: '',
            shiftTime: '',
            endpointsMap: {
                Caregiver: {
                    createMessage: 'createMessageCaregiver',
                    getAllMessagesByShiftID: 'getAllMessagesByShiftIDCaregiver',
                    getNewMessagesByShiftID: 'getNewMessagesByShiftIDCaregiver',
                    getShiftByID: 'getShiftByIDCaregiver'
                },
                OrganizationAdmin: {
                    createMessage: 'createMessageOrganization',
                    getAllMessagesByShiftID: 'getAllMessagesByShiftIDOrganization',
                    getNewMessagesByShiftID: 'getNewMessagesByShiftIDOrganization',
                    getShiftByID: 'getShiftByIDOrganization'
                },
                OrganizationEmployee: {
                    createMessage: 'createMessageOrganization',
                    getAllMessagesByShiftID: 'getAllMessagesByShiftIDOrganization',
                    getNewMessagesByShiftID: 'getNewMessagesByShiftIDOrganization',
                    getShiftByID: 'getShiftByIDOrganization'
                },
                MyShyftAdmin: {
                    getAllMessagesByShiftID: 'getAllMessagesByShiftIDMyShyftAdmin',
                    getShiftByID: 'getShiftByIDMyShyftAdmin'
                },
                MyShyftEmployee: {
                    getAllMessagesByShiftID: 'getAllMessagesByShiftIDMyShyftAdmin',
                    getShiftByID: 'getShiftByIDMyShyftAdmin'
                },
                MyShyftViewer: {
                    getAllMessagesByShiftID: 'getAllMessagesByShiftIDMyShyftAdmin',
                    getShiftByID: 'getShiftByIDMyShyftAdmin'
                },
            },
            shiftID: 0,
            oldShiftID: 0
        };
    },
    computed: {
        signedInUser() {
            return this.$store.state.AuthorizationModule.SignedInUser;
        },
        isCaregiverOrganizationApp() {
            return app.repoName === 'myshyft-caregiver-organization-mobile-frontend';
        },
        isAdminOrganizationApp() {
            return app.repoName === 'myshyft-admin-organization-frontend';
        },
        caregiverID() {
            if(this.isCaregiverOrganizationApp) {
                if(this.signedInUser.Role === 'Caregiver') {
                    return this.signedInUser.CaregiverID;
                } else {
                    return this.$route.params.caregiverID;
                }
            } else {
                return this.selectedCaregiverID;
            }
        }
    },
    watch: {
        selectedShiftID(newShiftID, oldShiftID) {
            this.shiftID = newShiftID;
            this.oldShiftID = oldShiftID;
        },
        shiftIDAndCaregiverID() {
            if(this.shiftID) {
                if (!this.oldShiftID) {
                    this.getOrganizationNameAndTimeZone()
                        .then(this.getShiftByIDAndGetMessages);
                } else {
                    this.getShiftByIDAndGetMessages();
                }
            }
        },
        selectedMessageID(newMessageID) {
            this.scrollToMessage(newMessageID);
        }
    },
    methods: {
        formatMessages(messages) {
            let dateDisplayList = [];

            messages.forEach((m, index) => {
                let timezone = '';
                if(this.signedInUser.Role === 'Caregiver') {
                    m.rightSide = m.IsCaregiver;
                    m.senderName = m.IsCaregiver ? this.signedInUser.FirstName + ' ' + this.signedInUser.LastName : this.organizationName;
                    timezone = moment.tz.guess();
                } else if(this.signedInUser.Role === 'OrganizationAdmin' || this.signedInUser.Role === 'OrganizationEmployee') {
                    m.rightSide = !m.IsCaregiver;
                    m.senderName = !m.IsCaregiver ? this.organizationName : m.CaregiverName;
                    timezone = this.organizationTimeZone;
                } else if(this.signedInUser.Role === 'MyShyftAdmin' || this.signedInUser.Role === 'MyShyftEmployee' || this.signedInUser.Role === 'MyShyftViewer') {
                    m.rightSide = !m.IsCaregiver;
                    m.senderName = !m.IsCaregiver ? this.organizationName : m.CaregiverName;
                    timezone = this.$store.state.AuthorizationModule.SignedInUser.LocalTimeZone || moment.tz.guess();
                }

                m.createdTime = app.formatTimeDisplay(m.MessageCreatedUtc, timezone);

                let createdDate = moment(m.MessageCreatedUtc);
                let now = moment();
                if(createdDate > now.startOf('day')) {
                    m.dateDisplay = 'Today';
                } else if(createdDate < now.startOf('day') && createdDate > now.subtract(1, 'day')) {
                    m.dateDisplay = 'Yesterday';
                } else if(createdDate < now.startOf('day') && createdDate > now.subtract(7, 'days')) {
                    m.dateDisplay = createdDate.format('dddd');
                } else {
                    m.dateDisplay = app.formatDateDisplay(m.MessageCreatedUtc, timezone);
                }

                if(dateDisplayList.includes(m.dateDisplay)) {
                    m.dateDisplay = '';
                } else {
                    dateDisplayList.push(m.dateDisplay);
                }
            });

            return messages;
        },
        getAllShiftMessages() {
            endpoints[this.userRoleEndpoints.getAllMessagesByShiftID]({shiftID: this.shiftID, caregiverID: this.caregiverID}, true)
                .then(response => {
                    this.messages = this.formatMessages(response);

                    setTimeout(() => {
                        if(this.isCaregiverOrganizationApp) {
                            this.scrollToMessage(this.$route.params.messageID);
                        } else if(this.selectedShiftID) {
                            this.shiftID = this.selectedShiftID;
                            this.scrollToMessage(this.selectedMessageID);
                        }
                    });
            });
        },
        getNewShiftMessages() {
            endpoints[this.userRoleEndpoints.getNewMessagesByShiftID]({shiftID: this.shiftID, caregiverID: this.caregiverID}, true)
                .then(response => {
                    this.messages = this.formatMessages(this.messages.concat(response));

                    if(response.length > 0) {
                        setTimeout(() => {
                            document.getElementById('List').scrollTop = document.getElementById('List').scrollHeight;
                        });
                    }
            });
        },
        sendMessage() {
            endpoints[this.userRoleEndpoints.createMessage]({
                ShiftID: this.shiftID,
                CaregiverID: this.caregiverID,
                MessageText: this.messageText
            })
                .then(() => {
                    this.messageText = '';
                    this.getNewShiftMessages();
                });
        },
        getShiftByIDAndGetMessages() {
            endpoints[this.userRoleEndpoints.getShiftByID](this.shiftID, true)
                .then(response => {
                    this.shift = response;
                    if (response.OrganizationName) {
                        this.organizationName = response.OrganizationName;
                        this.organizationTimeZone = response.LocalTimeZone;
                    }
                    
                    this.shiftDate = app.formatDateDisplay(response.ShiftStartUtc, this.organizationTimeZone);
                    this.shiftTime = app.formatTimeDisplay(response.ShiftStartUtc, this.organizationTimeZone);

                    this.getAllShiftMessages();
                });
        },
        getOrganizationNameAndTimeZone() {
            return endpoints.getUserByID(this.signedInUser.UserID, true)
                .then(response => {
                    return endpoints.getOrganizationByID(response.OrganizationID, true);
                })
                .then(response => {
                    this.organizationName = response.OrganizationName;
                    this.organizationTimeZone = response.LocalTimeZone;
                });
        },
        scrollToMessage(messageID) {
            let messageDiv = document.querySelector('div[data-message-id="' + messageID + '"]');
            if(messageDiv) {
                document.getElementById('List').scrollTop = messageDiv.parentElement.offsetTop;
            } else {
                document.getElementById('List').scrollTop = document.getElementById('List').scrollHeight;
            }
        }
    },
    created() {
        this.userRoleEndpoints = this.endpointsMap[this.signedInUser.Role];

        if(this.isCaregiverOrganizationApp) {
            this.shiftID = this.$route.params.shiftID;
            this.getShiftByIDAndGetMessages();
        } else if(this.selectedShiftID) {
            this.shiftID = this.selectedShiftID;

            if (this.signedInUser.Role === 'MyShyftAdmin' || this.signedInUser.Role === 'MyShyftEmployee' || this.signedInUser.Role === 'MyShyftViewer') {
                endpoints.getOrganizationByID(this.selectedOrganizationID, true)
                    .then(response => {
                        this.organizationName = response.OrganizationName;
                    })
                    .then(this.getShiftByIDAndGetMessages);
            } else {
                this.getOrganizationNameAndTimeZone()
                    .then(this.getShiftByIDAndGetMessages);
            }
        } else {
            this.shiftID = this.selectedShiftID;
        }

        this.newShiftsTimer = setInterval(() => {
            if(document.hasFocus() && this.shiftID && (this.signedInUser.Role !== 'MyShyftAdmin' || this.signedInUser.Role !== 'MyShyftEmployee' || this.signedInUser.Role === 'MyShyftViewer')) { // Don't keep getting messages in the background
                this.getNewShiftMessages();
            }
        }, 5 * 1000);
    },
    destroyed() {
        clearInterval(this.newShiftsTimer);
    }
};
</script>
