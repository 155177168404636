<template src="./supportRequestsViewModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'
import userModule from '../../users/users.store';

export default {
    name: 'support-request-view-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            dto: {},
        }
    },
    computed: {
        lblTitle() {
            return 'View Details for Support Request';
        },
        modalWidth() {
            if (!this.dto.HasSupportImage || this.dto.HasSupportImage == false) {
                return '400px'
            } 

            return '800px';
        },
        getFormattedCreatedUtc(){
            return moment.tz(this.dto.CreatedUtc, this.myShyftAdminTimeZone).format("M/D/YYYY");
        },       
        timeZone(){
            if (this.signedInUser.RoleCategory == 'MyShyft') {               
                return this.$store.state.AdminModule.MyShyftAdminTimeZone;
            }
            else {
                return this.signedInUser.LocalTimeZone;
            }
        },
        signedInUser() {
            return this.$store.state.AuthorizationModule.SignedInUser;
        }
    },
    methods: {    
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.dto = DMUtils.copy(this.modalData.dto);      
    },
}
</script>
