<template src="./transactionErrorsModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'
import Vue from 'vue';

    export default {
        name: 'transaction-errors-modal',
        mixins: [dmModalMixin],
        data() {
            return {
                transactionErrorsTableColumns: [],
                transactionErrorsTableSortBy: {
                    columns: [
                        { field: 'ErrorCreatedUtc', direction: 'Descending' },
                    ]
                },
                transactionErrorsTableCustomClass: 'dm-bg-color-black',
                transactionTransferErrorsTableActions: [
                    {
                        text: 'Retry'
                    }
                ],
                transactionMaxShiftClockErrorsTableActions: [
                    {
                        text: 'Edit Shift Clock'
                    }
                ],
                selectedRow: null
            }
        },
        computed: {
            lblTitle() {
                return 'Transaction Errors';
            },
            transactionErrorsTableItems() {
                let transactions = this.$store.state.InvoiceModule.AllFailedTransactions;
                let moment = require('moment-timezone');

                if (transactions){
                    transactions.forEach((transaction) => {
                        transaction.ErrorCreatedUtc = moment.tz(transaction.ErrorCreatedUtc, this.myShyftAdminTimeZone).format("M/D/YYYY hh:mmA z");                                                     
                    });
                }
            
                return transactions;
            },
            myShyftAdminTimeZone(){
                return this.$store.state.AdminModule.MyShyftAdminTimeZone;
            },
        },
        methods: {
           transactionErrorsTableRowOnClick(args) {
                this.selectedRow = args;
            },
            btnCloseOnClick(){
                this.closeModal();
            },           
            editShiftClock() {
                this.$store
                    .dispatch('ShiftModule/getShiftClockByID', this.selectedRow.rowData.ShiftClockID)
                        .then((response) => {
                            let modalData = {
                                mode: "edit",
                                shiftID: this.selectedRow.rowData.ShiftID,
                                dto: response,
                            };
                            DMUtils.openApplicationModal('shiftRequestsClocksEditModal', modalData);
                        });
            },
            retryTransaction() {
                let dto = {
                    TransactionID: this.selectedRow.rowData.TransactionID,
                    RowVersion: this.selectedRow.rowData.RowVersion
                };

                this.$store.dispatch('InvoiceModule/retryTransaction', dto);
            },
           transactionErrorsTableActionOnClick(args) {
                if (args.item.text === 'Retry') {
                    this.retryTransaction();
                }
                else if (args.item.text === 'Edit Shift Clock') {
                    this.editShiftClock();
                }             
            },
        },
        created() {
            this.$store.dispatch('InvoiceModule/getAllTransferFailures');
            let self = this;
            this.transactionErrorsTableColumns = [
               {
                    type: 'template',
                    width: '55px',
                    template: function() {
                        return {
                            template: Vue.component('columnTemplate', {
                                template: `<ejs-dropdownbutton
                                                :items="grdRowActions"
                                                :select="select"
                                                iconCss="e-icons e-ellipsis-vert"
                                                cssClass="e-caret-hide">
                                           </ejs-dropdownbutton>`,
                                methods: {
                                    select(args) {                      
                                        self.transactionErrorsTableActionOnClick(args);
                                    }
                                },
                                computed: {
                                    grdRowActions: function() {
                                        if (this.data.ErrorType === "Max Shift Clock") {
                                            return self.transactionMaxShiftClockErrorsTableActions;
                                        } else {
                                            return self.transactionTransferErrorsTableActions;
                                        }
                                    },
                                }
                            })
                        }
                    }
                },
                {
                    key: 'TransactionID',
                    label: 'Transaction ID',
                    width: '150px'
                },
                {
                    key: 'CaregiverFirstLastName',
                    label: 'Caregiver',
                },
                {
                    key: 'ErrorMessage',
                    label: 'Error Message',
                },
                {
                    key: 'ErrorType',
                    label: 'Error Type',
                    width: '150px'
                },
                {
                    key: 'ErrorCreatedUtc',
                    label: 'Error Created On',
                    type: 'date',
                    format: "MM/dd/yyyy hh:mm a",
                    width: '180px',
                },
            ];           
        },
    }
</script>