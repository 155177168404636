<template src="./organizationContactsModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'organization-contacts-modal',
        mixins: [dmModalMixin],
        data() {
            return {
                dto: {},
                accountType: "",
                organizationContactsTableColumns: [],
                organizationContactsTableSortBy: {
                    columns: [
                        { field: 'Name', direction: 'Ascending' },
                    ]
                },
                organizationContactsTableCustomClass: 'dm-bg-color-black',
                organizationContactsMyShyftAdminTableActions: [
                    {
                        text: 'Edit'
                    },
                    {
                        text: 'Delete'
                    },
                ],
                organizationContactsOrganizationTableActions: [
                    {
                        text: 'Edit'
                    },
                ],
                selectedRow: null
            }
        },
        computed: {
            lblTitle() {
                if (this.accountType === "MyShyftUser"){
                    return 'Contacts for ' + this.dto.OrganizationName;
                } else {
                    return 'Contacts';
                }
            },
            organizationContactsTableItems() {
                return this.$store.state.AdminOrganizationModule.AllOrganizationContactEntrys;
            },
            organizationContactID() {
                return this.selectedRow.rowData.OrganizationContactID;
            },
        },
        methods: {
            organizationContactsTableRowOnClick(args) {
                this.selectedRow = args;
            },
            btnCloseOnClick(){
                this.closeModal();
            },
            addOrganizationContact() {
                let modalData = {
                    mode: 'add',
                    accountType: this.accountType,
                    dto: {},
                    organizationID: this.dto.OrganizationID,
                };

                DMUtils.openApplicationModal('organizationAddContactModal', modalData);
            },
            editOrganizationContact() {
                let modalData = {
                    mode: 'edit',
                    accountType: this.accountType,
                    dto: {},
                    organizationID: this.dto.OrganizationID,
                };
                this.$store
                    .dispatch('AdminOrganizationModule/getOrganizationContactByID', this.organizationContactID)
                    .then((response) => {
                        modalData.dto = response;
                        DMUtils.openApplicationModal('organizationAddContactModal', modalData);
                    });
            },
            deleteOrganizationContact() {
                this.$store.dispatch('AdminOrganizationModule/deleteOrganizationContact', this.organizationContactID);
            },
            organizationContactsMyShyftAdminTableActionOnClick(args) {
                if (args.item.text === 'Edit') {
                    this.editOrganizationContact();
                }
                else if (args.item.text === 'Delete') {
                    const message = 'Are you sure you want to delete this contact?';
                    const title = 'Confirm';
                    const confirmCallback = this.deleteOrganizationContact;

                    DMUtils.openApplicationModal('dmConfirmModal', {
                        title: title,
                        message: message,
                        confirmCallback: confirmCallback,
                        confirmText: 'Yes',
                        denyText: 'Cancel',
                    });
                }             
            },
            organizationContactsOrganizationTableActionOnClick(args) {
                if (args.item.text === 'Edit') {
                    this.editOrganizationContact();
                }              
            },
        },
        created() {
            this.dto = DMUtils.copy(this.modalData.dto);
            this.accountType = this.modalData.accountType;

            this.$store.dispatch('AdminOrganizationModule/getAllOrganizationContactsForOrganization', this.dto.OrganizationID);           

            this.organizationContactsMyShyftAdminTableColumns = [
                {
                    type: 'iconTemplate',
                    iconName: 'ellipsis',
                    ellipsisActions: this.organizationContactsMyShyftAdminTableActions,
                    iconOnClick: this.organizationContactsMyShyftAdminTableActionOnClick,
                    width: '55px',
                },
                {
                    key: 'Name',
                    label: 'Name',
                },
                {
                    key: 'Title',
                    label: 'Title',
                },
                {
                    key: 'EmailAddress',
                    label: 'Email Address',
                },
                {
                    key: 'PhoneNumberF',
                    label: 'Phone Number',
                    width: '150px'
                },
                {
                    key: 'ContactNotes',
                    label: 'Notes',
                },
                {
                    key: 'Status',
                    label: 'Status',
                    width: '100px'
                },
            ];
            this.organizationContactsOrganizationAdminTableColumns = [
                 {
                    type: 'iconTemplate',
                    iconName: 'ellipsis',
                    ellipsisActions: this.organizationContactsOrganizationTableActions,
                    iconOnClick: this.organizationContactsOrganizationTableActionOnClick,
                    width: '55px',
                },
                {
                    key: 'Name',
                    label: 'Name',
                },
                {
                    key: 'Title',
                    label: 'Title',
                },
                {
                    key: 'EmailAddress',
                    label: 'Email Address',
                },
                {
                    key: 'PhoneNumberF',
                    label: 'Phone Number',
                },
                {
                    key: 'Status',
                    label: 'Status',
                    width: '100px'
                },
            ];
        },
    }
</script>