<template src="./supportRequestsPage.html"></template>

<script>
export default {
    name: 'support-requests-page',
    data() {
        return {
            generalSearchString: '',
            incorrectHoursSearchString: '',
            misconductSearchString: '',
            paramsDto: {},
            generalSupportTableColumnsMyShyftAdmin: [],
            generalSupportTableColumnsOrganization: [],
            generalSupportTableSortBy: {
                columns: [{ field: 'CreatedUtc', direction: 'Descending' }],
            },
            generalSupportTableActionsOrganization: [
                {
                    text: 'View Details'
                }
            ],
            generalSupportTableActionsMyShiftAdmin: [
                {
                    text: 'View Details',
                },
                {
                    text: 'Toggle Status',
                },
            ],
            generalSupportTablePageSettings: { pageSize: 300, pageSizes: [500, 300, 100, 50], pageCount: 4, direction: 'Descending' },
            shiftSupportTableColumnsMyShyftAdmin: [],
            shiftSupportTableColumnsOrganization: [],
            shiftSupportTableActionsMyShyftAdmin: [
                {
                    text: 'View Details',
                },
                {
                    text: 'View Shift Details',
                },
                {
                    text: 'View Shift Clocks',
                },
                {
                    text: 'Toggle Status',
                },
            ],
            shiftSupportTableActionsOrganization: [
                {
                    text: 'View Details',
                },
                {
                    text: 'View Shift Details',
                },
                {
                    text: 'View Shift Clocks',
                },
            ],
            incorrectHoursTableSortBy: {
                columns: [{ field: 'CreatedUtc', direction: 'Descending' }],
            },
            incorrectHoursTablePageSettings: { pageSize: 300, pageSizes: [500, 300, 100, 50], pageCount: 4 },
            misconductTableSortBy: {
                columns: [{ field: 'CreatedUtc', direction: 'Descending' }],
            },          
            misconductTablePageSettings: { pageSize: 300, pageSizes: [500, 300, 100, 50], pageCount: 4 },
            selectedRow: null,
        };
    },
    computed: {
        generalSupportTableItems() {
            let generalSupport = this.$store.state.SupportModule.AllGeneralSupportRequests;
            if (generalSupport) {
                generalSupport.forEach((support) => {
                    support.timeZone = this.timeZone;
                    if (support.HasSupportImage === true) {
                        support.AttachedImageText = 'Yes';
                    } else {
                        support.AttachedImageText = 'No';
                    }
                });
            }

            return {
                result: generalSupport,
                count: this.$store.state.SupportModule.GeneralSupportNumberOfRows,
            };
        },
        incorrectHoursTableItems() {
            let incorrectHoursSupport = this.$store.state.SupportModule.AllShiftSupportRequests;

            if (incorrectHoursSupport) {  
                incorrectHoursSupport.forEach((support) => {
                    support.timeZone = this.timeZone;
                });
            }

            return {
                result: incorrectHoursSupport,
                count: this.$store.state.SupportModule.ShiftSupportNumberOfRows,
            };
        },
        misconductTableItems() {
            let misconductSupport = this.$store.state.SupportModule.AllShiftSupportRequests;

            if (misconductSupport) {  
                misconductSupport.forEach((support) => {
                    support.timeZone = this.timeZone;
                });
            }

            return {
                result: misconductSupport,
                count: this.$store.state.SupportModule.ShiftSupportNumberOfRows,
            };
        },
        timeZone() {
            if (this.userRoleCategory === 'MyShyft') {
                return this.$store.state.AdminModule.MyShyftAdminTimeZone;
            }
            else {
                return this.signedInUser.LocalTimeZone;
            }
        },
        userRoleCategory() {
            return this.$store.state.AuthorizationModule.SignedInUser.RoleCategory;
        },
        signedInUser() {
            return this.$store.state.AuthorizationModule.SignedInUser;
        }
    },
    methods: {
        supportTableRowOnClick(args) {
            this.selectedRow = args;
        },
        getCurrentTabList() {
            try {
                let selectingID = this.$refs.supportTabs.ej2Instances.selectingID;
                if (selectingID === '0') {
                    this.paramsDto.PageToLoad =
                        this.$refs.generalSupportTable.$refs.grdTableList.ej2Instances.pagerModule.pagerObj.properties.currentPage;
                    this.paramsDto.RowsPerPage = this.generalSupportTablePageSettings.pageSize;
                    this.paramsDto.SortByColumn = this.generalSupportTablePageSettings.columnName;
                    this.paramsDto.SortDescending =
                        this.generalSupportTablePageSettings.direction === 'Descending';
                    this.searchGeneralSupportRequests();
                    return;
                }

                if (selectingID === '1') {
                    this.paramsDto.PageToLoad =
                        this.$refs.incorrectHoursTable.$refs.grdTableList.ej2Instances.pagerModule.pagerObj.properties.currentPage;
                    this.paramsDto.RowsPerPage = this.incorrectHoursTablePageSettings.pageSize;
                    this.paramsDto.SortByColumn = this.incorrectHoursTablePageSettings.columnName;
                    this.paramsDto.SortDescending = this.incorrectHoursTablePageSettings.direction === 'Descending';
                    this.searchIncorrectHoursRequests();
                    return;
                }

                if (selectingID === '2') {
                    this.paramsDto.PageToLoad =
                        this.$refs.misconductTable.$refs.grdTableList.ej2Instances.pagerModule.pagerObj.properties.currentPage;
                    this.paramsDto.RowsPerPage = this.misconductTablePageSettings.pageSize;
                    this.paramsDto.SortByColumn = this.misconductTablePageSettings.columnName;
                    this.paramsDto.SortDescending = this.misconductTablePageSettings.direction === 'Descending';
                    this.searchMisconductRequests();
                    return;
                }
            } catch (error) {
                //Throwing the error away to avoid undefined selectingID
            }
        },
        viewShiftRequest() {
            let shiftModuleMethod = 'ShiftModule/getShiftByIDOrganization';
            let accountType = 'Organization';

            if (this.userRoleCategory === 'MyShyft') {
                shiftModuleMethod = 'ShiftModule/getShiftByIDMyShyftAdmin';
                accountType = 'MyShyft'
            }

            this.$store
                .dispatch(shiftModuleMethod, this.selectedRow.rowData.ShiftID)
                .then((response) => {
                    let modalData = {
                        dto: response,
                        accountType: accountType,
                    };
                    DMUtils.openApplicationModal('shiftRequestsViewModal', modalData);
                });
        },
        viewShiftClocks() {
            let shiftModuleMethod = 'ShiftModule/getShiftByIDOrganization';
            let accountType = 'Organization';

            if (this.userRoleCategory === 'MyShyft') {
                shiftModuleMethod = 'ShiftModule/getShiftByIDMyShyftAdmin';
                accountType = 'MyShyft';
            }

            this.$store
                .dispatch(shiftModuleMethod, this.selectedRow.rowData.ShiftID)
                .then((response) => {
                    let modalData = {
                        dto: response,
                        accountType: accountType,
                    };
                    DMUtils.openApplicationModal('shiftRequestsClocksModal', modalData);
                });
        },
        viewShiftSupportDetails() {
            this.$store
                .dispatch('SupportModule/getShiftSupportRequestByID', this.selectedRow.rowData.ShiftSupportID)
                .then((response) => {
                    let modalData = {
                        dto: response,
                    };
                    DMUtils.openApplicationModal('supportRequestsViewModal', modalData);
                });
        },
        viewGeneralSupportDetails() {
            this.$store
                .dispatch('SupportModule/getGeneralSupportRequestByID', this.selectedRow.rowData.GeneralSupportID)
                .then((response) => {
                    let modalData = {
                        dto: response,
                    };
                    DMUtils.openApplicationModal('supportRequestsViewModal', modalData);
                });
        },
        toggleGeneralSupportStatus() {
            this.$store
                .dispatch('SupportModule/getGeneralSupportRequestByID', this.selectedRow.rowData.GeneralSupportID)
                .then((response) => {
                    this.dto = response;
                    if (this.dto.SupportStatus === 'Incomplete') {
                        this.dto.SupportStatus = 'Complete';
                    } else {
                        this.dto.SupportStatus = 'Incomplete';
                    }
                    this.$store.dispatch('SupportModule/updateGeneralSupportRequest', this.dto);
                });
        },
        toggleShiftSupportStatus() {
            this.$store
                .dispatch('SupportModule/getShiftSupportRequestByID', this.selectedRow.rowData.ShiftSupportID)
                .then((response) => {
                    this.dto = response;
                    if (this.dto.SupportStatus === 'Incomplete') {
                        this.dto.SupportStatus = 'Complete';
                    } else {
                        this.dto.SupportStatus = 'Incomplete';
                    }
                    this.$store.dispatch('SupportModule/updateShiftSupportRequest', this.dto);
                });
        },
        generalSupportTableActionOnClick(args) {
            if (args.item.text === 'View Details') {
                this.viewGeneralSupportDetails();
            } else if (args.item.text === 'Toggle Status') {
                this.toggleGeneralSupportStatus();
            }
        },
        shiftSupportTableActionOnClick(args) {
            if (args.item.text === 'View Details') {
                this.viewShiftSupportDetails();
            } else if (args.item.text === 'View Shift Details') {
                this.viewShiftRequest();
            } else if (args.item.text === 'View Shift Clocks') {
                this.viewShiftClocks();
            }else if (args.item.text === 'Toggle Status') {
                this.toggleShiftSupportStatus();
            }
        },
        searchGeneralSupportRequests() {
            this.paramsDto.PageToLoad =
                this.$refs.generalSupportTable.$refs.grdTableList.ej2Instances.pagerModule.pagerObj.properties.currentPage;
            this.paramsDto.RowsPerPage = this.generalSupportTablePageSettings.pageSize;
            this.paramsDto.SortByColumn = this.generalSupportTablePageSettings.columnName;
            this.paramsDto.SortDescending = this.generalSupportTablePageSettings.direction === 'Descending';

            if (this.userRoleCategory === 'MyShyft') {
                this.$store.dispatch('SupportModule/getAllGeneralSupportRequestsMyShyftAdmin', this.paramsDto);
            }

            if (this.userRoleCategory === 'Organization') {
                this.$store.dispatch('SupportModule/getAllGeneralSupportRequestsOrganization', this.paramsDto);
            }
        },
        searchIncorrectHoursRequests() {
            this.paramsDto.PageToLoad =
                this.$refs.incorrectHoursTable.$refs.grdTableList.ej2Instances.pagerModule.pagerObj.properties.currentPage;
            this.paramsDto.RowsPerPage = this.incorrectHoursTablePageSettings.pageSize;
            this.paramsDto.SortByColumn = this.incorrectHoursTablePageSettings.columnName;
            this.paramsDto.SortDescending = this.incorrectHoursTablePageSettings.direction === 'Descending';
            this.paramsDto.ShiftSupportType = "Incorrect Hours";       
            
            if (this.userRoleCategory === 'MyShyft') {
                this.$store.dispatch('SupportModule/getAllShiftSupportRequestsMyShyftAdmin', this.paramsDto);
            }

            if (this.userRoleCategory === 'Organization') { 
                this.$store.dispatch('SupportModule/getAllShiftSupportRequestsOrganization', this.paramsDto);
            }
        },
        searchMisconductRequests() {
            this.paramsDto.PageToLoad =
                this.$refs.misconductTable.$refs.grdTableList.ej2Instances.pagerModule.pagerObj.properties.currentPage;
            this.paramsDto.RowsPerPage = this.misconductTablePageSettings.pageSize;
            this.paramsDto.SortByColumn = this.misconductTablePageSettings.columnName;
            this.paramsDto.SortDescending = this.misconductTablePageSettings.direction === 'Descending';
            this.paramsDto.ShiftSupportType = "Misconduct";   
                        
            if (this.userRoleCategory === 'MyShyft') {
                this.$store.dispatch('SupportModule/getAllShiftSupportRequestsMyShyftAdmin', this.paramsDto);
            }

            if (this.userRoleCategory === 'Organization') {
                this.$store.dispatch('SupportModule/getAllShiftSupportRequestsOrganization', this.paramsDto);
            }    
        },
        generalSupportTableDataStateChange(state) {
            if (state.action.requestType === 'sorting' && state.action.direction && state.action.columnName) {
                this.generalSupportTablePageSettings.direction = state.action.direction;
                this.generalSupportTablePageSettings.columnName = state.action.columnName;
                this.searchGeneralSupportRequests();
            }

            if (state.action.requestType === 'paging') {
                this.searchGeneralSupportRequests();
            }
        },
        incorrectHoursTableDataStateChange(state) {
            if (state.action.requestType === 'sorting' && state.action.direction && state.action.columnName) {
                this.incorrectHoursTablePageSettings.direction = state.action.direction;
                this.incorrectHoursTablePageSettings.columnName = state.action.columnName;
                this.searchIncorrectHoursRequests();
            }

            if (state.action.requestType === 'paging') {
                this.incorrectHoursTablePageSettings.pageSize = state.take;
                this.searchIncorrectHoursRequests();
            }
        },
        misconductTableDataStateChange(state) {
            if (state.action.requestType === 'sorting' && state.action.direction && state.action.columnName) {
                this.misconductTablePageSettings.direction = state.action.direction;
                this.misconductTablePageSettings.columnName = state.action.columnName;
                this.searchMisconductRequests();
            }

            if (state.action.requestType === 'paging') {
                this.misconductTablePageSettings.pageSize = state.take;
                this.searchMisconductRequests();
            }
        },
        refresh() {
            let selectingID = this.$refs.supportTabs.ej2Instances.selectingID;
            if (selectingID === '0') {
                this.searchGeneralSupportRequests();
            }
            else if (selectingID === '1') {
                this.searchIncorrectHoursRequests();
            }
            else if(selectingID === '2') {
                this.searchMisconductRequests();
            }
        }
    },
    created() {
        this.generalSupportTableColumnsMyShyftAdmin = [
            {
                type: 'iconTemplate',
                iconName: 'ellipsis',
                ellipsisActions: this.generalSupportTableActionsMyShiftAdmin,
                iconOnClick: this.generalSupportTableActionOnClick,
                width: '55px',
            },
            {
                key: 'GeneralSupportID',
                label: 'ID',
                width: '75px',
            },
            {
                key: 'CreatedUserFullName',
                label: 'Name',
                width: '200px',
                allowSorting: false
            },
            {
                key: 'SupportTitle',
                label: 'Title',
                width: '200px',
            },
            {
                key: 'SupportMessage',
                label: 'Support Message',
            },
            {
                key: 'SupportStatus',
                label: 'Status',
                width: '100px',
            },
            {
                key: 'AttachedImageText',
                label: 'Image',
                width: '100px',
                allowSorting: false
            },
            {
                key: 'CreatedUtc',
                label: 'Created On',
                type: 'date',
                width: '200px',
                formatter(column, support) {
                    return moment.tz(support.CreatedUtc, support.timeZone).format("MM/DD/YYYY hh:mmA z"); 
                } 
            },
        ];

        this.generalSupportTableColumnsOrganization = [
            {
                type: 'iconTemplate',
                iconName: 'ellipsis',
                ellipsisActions: this.generalSupportTableActionsOrganization,
                iconOnClick: this.generalSupportTableActionOnClick,
                width: '55px',
            },
            {
                key: 'GeneralSupportID',
                label: 'ID',
                width: '75px',
            },
            {
                key: 'CreatedUserFullName',
                label: 'Name',
                width: '200px',
                allowSorting: false
            },
            {
                key: 'SupportTitle',
                label: 'Title',
                width: '200px',
            },
            {
                key: 'SupportMessage',
                label: 'Support Message',
            },
            {
                key: 'AttachedImageText',
                label: 'Image',
                width: '100px',
                allowSorting: false
            },
            {
                key: 'CreatedUtc',
                label: 'Created On',
                type: 'date',
                width: '200px',
                formatter(column, support) {
                    return moment.tz(support.CreatedUtc, support.timeZone).format("MM/DD/YYYY hh:mmA z"); 
                } 
            },
        ];

        this.shiftSupportTableColumnsMyShyftAdmin = [
            {
                type: 'iconTemplate',
                iconName: 'ellipsis',
                ellipsisActions: this.shiftSupportTableActionsMyShyftAdmin,
                iconOnClick: this.shiftSupportTableActionOnClick,
                width: '55px',
            },
            {
                key: 'ShiftSupportID',
                label: 'ID',
                width: '75px',
            },
            {
                key: 'ShiftID',
                label: 'Shift ID',
                width: '100px',
            },
            {
                key: 'CreatedUserFullName',
                label: 'Created By',
                width: '200px',
                allowSorting: false
            },
            {
                key: 'CaregiverFullName',
                label: 'Caregiver',
                width: '200px',
                allowSorting: false
            },
            {
                key: 'SupportMessage',
                label: 'Support Message',
            },
            {
                key: 'SupportStatus',
                label: 'Status',
                width: '100px',
            },
            {
                key: 'CreatedUtc',
                label: 'Created On',
                type: 'date',
                width: '200px',
                formatter(column, support) {
                    return moment.tz(support.CreatedUtc, support.timeZone).format("MM/DD/YYYY hh:mmA z"); 
                } 
            },
        ];
        this.shiftSupportTableColumnsOrganization = [
            {
                type: 'iconTemplate',
                iconName: 'ellipsis',
                ellipsisActions: this.shiftSupportTableActionsOrganization,
                iconOnClick: this.shiftSupportTableActionOnClick,
                width: '55px',
            },
            {
                key: 'ShiftSupportID',
                label: 'ID',
                width: '75px',
            },
            {
                key: 'ShiftID',
                label: 'Shift ID',
                width: '100px',
            },
            {
                key: 'CreatedUserFullName',
                label: 'Created By',
                width: '200px',
                allowSorting: false
            },
            {
                key: 'CaregiverFullName',
                label: 'Caregiver',
                width: '200px',
                allowSorting: false
            },
            {
                key: 'SupportMessage',
                label: 'Support Message',
            },
            {
                key: 'CreatedUtc',
                label: 'Created On',
                type: 'date',
                width: '200px',
                formatter(column, support) {
                    return moment.tz(support.CreatedUtc, support.timeZone).format("MM/DD/YYYY hh:mmA z"); 
                } 
            },
        ];
    },
    mounted() {
        this.$refs.supportTabs.ej2Instances.animation.next.effect = 'None';
        this.$refs.supportTabs.ej2Instances.animation.previous.effect = 'None';
        this.searchGeneralSupportRequests();
    },
};
</script>
