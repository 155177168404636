<template src="./invoicesPage.html"></template>

<script>
import Vue from 'vue';

export default {
    name: 'invoices-page',
    data() {
        return {
            searchStartDate: '',
            searchEndDate: '',
            paramsDto: {},
            allowFiltering: true,
            organizationNameList: [],
            organizationFields: { text: 'OrganizationName', value: 'OrganizationID' },
            filterType: 'Contains',
            invoicesTableColumns: [],
            invoicesTableSortBy: {
                columns: [{ field: 'CreatedUtc', direction: 'Descending' }],
            },
            invoicesTableCustomClass: 'dm-bg-color-black',
            invoicesMyShyftAdminTableActions: [
                {
                    text: 'View Shifts',
                },
                {
                    text: 'Download Invoice',
                },
                {
                    text: 'Update Payment Details',
                },
            ],
            invoicesOrganizationTableActions: [
                {
                    text: 'View Shifts',
                },
                {
                    text: 'Download Invoice',
                },
            ],
            invoicesTablePageSettings: { pageSize: 300, pageSizes: [500, 300, 100, 50], pageCount: 4, direction: 'Descending' },
            selectedRow: null,
        };
    },
    computed: {
        invoicesTableItems() {
            let invoices = this.$store.state.InvoiceModule.AllInvoices;

            if (this.userRole === 'MyShyftAdmin' || this.userRole === 'MyShyftEmployee' || this.userRole === 'MyShyftViewer') {
                if (invoices) {
                    invoices.forEach((invoice) => {
                        invoice.PaymentMethodAndNumber = '';

                        if (invoice.PaymentMethod) {
                            invoice.PaymentMethodAndNumber = invoice.PaymentMethod;
                        }

                        if (invoice.PaymentNumber) {
                            invoice.PaymentMethodAndNumber += ' - ' + invoice.PaymentNumber;
                        }
                    });
                }
            }

            return {
                result: invoices,
                count: this.$store.state.InvoiceModule.AllInvoicesNumberOfRows,
            };
        },
        myShyftAdminTimeZone() {
            return this.$store.state.AdminModule.MyShyftAdminTimeZone;
        },
        invoiceID() {
            return this.selectedRow.rowData.InvoiceID;
        },
        userRole() {
            return this.$store.state.AuthorizationModule.SignedInUser.Role;
        }
    },
    methods: {
        scrollGridToTop() {
            this.$refs.invoicesTable.$refs.grdTableList.getContent().children[0].scrollTop = 0;
        },
        filtering: function (e) {
            let query = new Query();
            query = e.text != '' ? query.where('Contains', e.text, true) : query;
            e.updateData(this.organizationNameList, query);
        },
        invoicesTableRowOnClick(args) {
            this.selectedRow = args;
        },
        searchInvoices() {
            let moment = require('moment-timezone');
            let utcNow = moment.utc();

            let startDay = moment.utc(this.searchStartDate).startOf('day');
            let endDay = moment.utc(this.searchEndDate).endOf('day');
            let timeZoneOffset = moment.tz.zone(this.myShyftAdminTimeZone).utcOffset(utcNow);

            this.paramsDto.StartDateRange = startDay.add(timeZoneOffset).format();
            this.paramsDto.EndDateRange = endDay.add(timeZoneOffset).format();
            this.paramsDto.PageToLoad =
                this.$refs.invoicesTable.$refs.grdTableList.ej2Instances.pagerModule.pagerObj.properties.currentPage;
            this.paramsDto.RowsPerPage = this.invoicesTablePageSettings.pageSize;
            this.paramsDto.SortByColumn = this.invoicesTablePageSettings.columnName;
            this.paramsDto.SortDescending = this.invoicesTablePageSettings.direction === 'Descending';

            this.scrollGridToTop();

            if (this.userRole === 'MyShyftAdmin' || this.userRole === 'MyShyftEmployee' || this.userRole === 'MyShyftViewer') {
                this.$store.dispatch('InvoiceModule/getAllInvoicesMyShyftAdmin', this.paramsDto);
            }

            if (this.userRole === 'OrganizationAdmin' || this.userRole === 'OrganizationCorporate') {
                this.$store.dispatch('InvoiceModule/getAllInvoicesOrganization', this.paramsDto);
            }
        },
        viewShiftsForInvoice() {
            let modalData = {
                searchType: 'Invoice',
                invoiceID: this.invoiceID,
            };

            DMUtils.openApplicationModal('viewShiftsForInvoiceModal', modalData);
        },
        viewShiftsNotInvoiced() {
            let modalData = {
                searchType: 'Not Invoiced',
                invoiceID: null,
            };

            DMUtils.openApplicationModal('viewShiftsForInvoiceModal', modalData);
        },
        payInvoices() {
            let modalData = {
                dto: {},
            };

            DMUtils.openApplicationModal('invoicesSelectionModal', modalData);
        },
        downloadInvoice() {
            this.$store.dispatch('InvoiceModule/downloadInvoice', this.invoiceID).then((response) => {
                DMUtils.downloadAjaxResponseAsFile(response);
            });
        },
        updatePaymentDetails() {
            let modalData = {
                dto: {},
            };

            this.$store.dispatch('InvoiceModule/getInvoiceByIDMyShyftAdmin', this.invoiceID).then((response) => {
                modalData.dto = response;
                DMUtils.openApplicationModal('invoiceUpdatePaymentDetailsModal', modalData);
            });
        },
        invoicesTableActionOnClick(args) {
            if (args.item.text === 'View Shifts') {
                this.viewShiftsForInvoice();
            } else if (args.item.text === 'Update Payment Details') {
                this.updatePaymentDetails();
            } else if (args.item.text === 'Download Invoice') {
                this.downloadInvoice();
            }
        },
        invoicesTableDataStateChange(state) {
            if (state.action.requestType === 'sorting' && state.action.direction && state.action.columnName) {
                this.invoicesTablePageSettings.direction = state.action.direction;
                this.invoicesTablePageSettings.columnName = state.action.columnName;
                this.searchInvoices();
            }

            if (state.action.requestType === 'paging') {
                this.invoicesTablePageSettings.pageSize = state.take;
                this.searchInvoices();
            }
        },
    },
    created() {      
        this.invoicesMyShyftAdminTableColumns = [
            {
                type: 'iconTemplate',
                iconName: 'ellipsis',
                ellipsisActions: this.invoicesMyShyftAdminTableActions,
                iconOnClick: this.invoicesTableActionOnClick,
                width: '55px',
            },
            {
                key: 'InvoiceID',
                label: 'ID',
                width: '100px',
            },
            {
                key: 'OrganizationName',
                label: 'Organization Name',
                minWidth: '150px',
            },
            {
                key: 'InvoiceStatus',
                label: 'Status',
                width: '100px',
            },
            {
                key: 'InvoiceAmount',
                label: 'Invoice Amount',
                format: 'C2',
                textAlign: 'Right',
                width: '145px',
            },
            {
                key: 'PaymentMethodAndNumber',
                label: 'Payment Method',
                minWidth: '190px',
                allowSorting: false
            },
            {
                key: 'PaidUtc',
                label: 'Paid On',
                type: 'date',
                format: 'MM/dd/yyyy hh:mm a',
                width: '170px',
            },
            {
                key: 'DueUtc',
                label: 'Due On',
                type: 'date',
                format: 'MM/dd/yyyy hh:mm a',
                width: '170px',
            },
            {
                key: 'CreatedUtc',
                label: 'Created On',
                type: 'date',
                format: 'MM/dd/yyyy hh:mm a',
                width: '170px',
            },
        ];

        this.invoicesOrganizationTableColumns = [
            {
                type: 'iconTemplate',
                iconName: 'ellipsis',
                ellipsisActions: this.invoicesOrganizationTableActions,
                iconOnClick: this.invoicesTableActionOnClick,
                width: '55px',
            },
            {
                key: 'InvoiceID',
                label: 'ID',
                width: '100px',
            },
            {
                key: 'InvoiceStatus',
                label: 'Status',
                width: '95px',
            },
            {
                key: 'InvoiceAmount',
                label: 'Invoice Amount',
                format: 'C2',
                textAlign: 'Right',
                minWidth: '150px',
            },
            {
                key: 'PaymentMethod',
                label: 'Payment Method',
                minWidth: '190px',
                allowSorting: false
            },
            {
                key: 'PaidUtc',
                label: 'Paid On',
                type: 'date',
                format: 'MM/dd/yyyy',
                width: '130px',
            },
            {
                key: 'DueUtc',
                label: 'Due On',
                type: 'date',
                format: 'MM/dd/yyyy',
                width: '130px',
            },
            {
                key: 'CreatedUtc',
                label: 'Created On',
                type: 'date',
                format: 'MM/dd/yyyy',
                width: '130px',
            },
        ];
    },
    mounted() {
        if (this.userRole === 'MyShyftAdmin' || this.userRole === 'MyShyftEmployee' || this.userRole === 'MyShyftViewer') {
            this.searchInvoices();
            this.$store.dispatch('AdminOrganizationModule/getAllOrganizationNames').then((response) => {
                this.organizationNameList = response;
            });
        }

        if (this.userRole === 'OrganizationAdmin' || this.userRole === 'OrganizationCorporate') {
            this.searchInvoices();
        }
    },
};
</script>
