<template src="./messagesAdminActiveChatPage.html"></template>

<script>
    import messagesAdminActiveChatPage from '../../../messagesComponents/messagesAdminActiveChatPage/messagesAdminActiveChatPage'
    import messagesAdminThreadPage from '../../../messagesComponents/messagesAdminThreadPage/messagesAdminThreadPage'

    export default {
        name: 'messages-admin-active-chat-page',
        components: {
            'messages-active-chat-page': messagesAdminActiveChatPage,
            'messages-thread-page': messagesAdminThreadPage
        },
        data() {
            return {
                selectedCaregiverID: 0,
                selectedMessageID: 0
            }
        },
        methods: {
            messageChanged(message) {
                this.selectedCaregiverID = message.caregiverID;
                this.selectedMessageID = message.shownMessageID;
            }
        }
    }
</script>
