import endpoints from "../../../js/endpoints";

const myShyftAdminDashboardModule = {
    namespaced: true,
    state: () => ({
        FailedPayouts: [],
        IncompleteCaregiverFirstShifts: 0,
        CaregiverFirstShifts: [],
        CaregiverFirstShiftsNumberOfRows: 0,
        ParamsDto: {},
    }),
    mutations: {
        setFailedPayouts(state, payouts) {
            state.FailedPayouts = payouts;
        },
        setIncompleteCaregiverFirstShifts(state, count) {
            state.IncompleteCaregiverFirstShifts = count;
        },
        setCaregiverFirstShifts(state, caregiverFirstShifts) {
            state.CaregiverFirstShifts = caregiverFirstShifts;
        },
        setCaregiverFirstShiftsNumberOfRows(state, count) {
            state.CaregiverFirstShiftsNumberOfRows = count;
        },
        setParamsDto(state, paramsDto) {
            state.ParamsDto = paramsDto;
        },
    },
    actions: {
        getFailedPayouts({commit}) {
            commit('setFailedPayouts', []);

            return endpoints.getAllPayoutFailures()
                .then((response) => {
                    commit('setFailedPayouts', response);
                });
        },  
        getIncompleteCaregiverFirstShifts({commit}) {
            commit('setIncompleteCaregiverFirstShifts', 0);

            return endpoints.getAllIncompleteCaregiverFirstShifts()
                .then((response) => {
                    commit('setIncompleteCaregiverFirstShifts', response.NumberOfRecords);
                });
        },
        getAllCaregiverFirstShifts({commit}) {
                commit('setCaregiverFirstShifts', []);
                commit('setCaregiverFirstShiftsNumberOfRows', 0);
                return endpoints.getAllCaregiverFirstShifts(this.state.AdminDashboardModule.ParamsDto)
                    .then((response) => {
                        commit('setCaregiverFirstShifts', response.Rows);
                        commit('setCaregiverFirstShiftsNumberOfRows', response.NumberOfRows);
                    });
        },
        toggleCaregiverFirstShiftsStatus({dispatch}, args) {
            return endpoints.toggleCaregiverFirstShiftsStatus(args)
                .then(() => {
                    dispatch('getAllCaregiverFirstShifts');
            });
        },
        getMyShyftAdminDashboard({}) {
            return endpoints.getMyShyftAdminDashboard()
                .then((response) => {
                    return response
                });
        },   
    },
};

export default myShyftAdminDashboardModule;
