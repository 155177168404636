<template src="./addFrequentlyAskedQuestionModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin'
import { Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar } from "@syncfusion/ej2-vue-richtexteditor";

export default {
    name: 'add-frequently-asked-question-modal',
    provide: {
        richtexteditor:[Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar]
    },
    mixins: [dmModalMixin],
    data() {
        return {
            dto: {},
            userTypes: [],
            imageSettings: {
                saveFormat: "Base64"
            }
        }
    },
    computed: {
        mode() {
            return this.modalData.mode;
        },
        lblTitle() {
            return this.modalData.mode === 'edit' ? 'Edit Frequently Asked Question' : 'Add Frequently Asked Question';
        },
    },
    methods: {
        btnSaveOnClick() {
            if (this.mode === 'add') {
                this.$store
                    .dispatch('FrequentlyAskedQuestionModule/createFrequentlyAskedQuestion', this.dto)
                    .then(() => {
                        this.shouldModalStayOpen = false;
                        this.successClose(this.dto);
                    });
            } else if (this.mode === 'edit') {
                this.$store
                    .dispatch('FrequentlyAskedQuestionModule/updateFrequentlyAskedQuestion', this.dto)
                    .then(() => {
                        this.shouldModalStayOpen = false;
                        this.successClose(this.dto);
                    });
            }
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.dto = DMUtils.copy(this.modalData.dto);
        
        this.$store.dispatch('FrequentlyAskedQuestionModule/getFrequentlyAskedQuestionsUserTypes')
            .then((response) => {
                this.userTypes = response;
            });      
    },
}
</script>
