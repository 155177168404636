import { render, staticRenderFns } from "./organizationEditModal.html?vue&type=template&id=08789838&"
import script from "./organizationEditModal.vue?vue&type=script&lang=js&"
export * from "./organizationEditModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports