import endpoints from "../../../js/endpoints";

const organizationDashboardModule = {
    namespaced: true,
    state: () => ({
        
    }),
    mutations: {
        
    },
    actions: {
        getOrganizationDashboard({}) {
            return endpoints.getOrganizationDashboard()
                .then((response) => {
                    return response
                });
        },   
    },
};

export default organizationDashboardModule;
