<template src="./notificationCenterPage.html"></template>

<script>
    import Vue from 'vue';
    import { Sort, CommandColumn, Aggregate, PdfExport, ExcelExport, Group, Search, Page } from "@syncfusion/ej2-vue-grids";
    import { Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar } from "@syncfusion/ej2-vue-richtexteditor";
    import { ChipListPlugin } from '@syncfusion/ej2-vue-buttons';
    Vue.use(ChipListPlugin);
    import states from '../../../../dmFramework/dmJS/dmStates'

    var template = Vue.component('valueTemplate', {
        template: `<span>{{data.text}}</span>`,
        data() {
            return {
                data: {}
            };
        }
    });

    export default {
        name: 'notification-center-page',
        provide: {
            grid:[Sort, CommandColumn, Aggregate, PdfExport, ExcelExport, Group, Search, Page],
            richtexteditor:[Toolbar, Link, Count, HtmlEditor, QuickToolbar]
        },
        data() {
            return {
                messageDto: {
                    Message: '',
                    Link: '',
                    BroadcastType: ''
                },
                paramsDto: {
                    UserName: '',
                    UserRoles: [],
                    MedicalLicenseIDs: [],
                    Status: '',
                    City: '',
                    States: [],
                },
                title: '',
                message: '',
                link: '',
                statesList: states,
                broadcastTypeList: [],
                licensesList: [],
                userRoleList: [
                    {text: 'Caregiver', value: 'Caregiver'},
                    {text: 'Org. Admin', value: 'OrganizationAdmin'},
                    {text: 'Org. Employee', value: 'OrganizationEmployee'},
                    {text: 'Org. Corporate', value: 'OrganizationCorporate'}
                ],
                organizationCategoryList: [],
                statusList: [],
                searchResultsTableItems: {result: [], count: 0},
                searchResultsTableColumns: [],
                searchResultsTableSortBy: {
                    columns: [
                        { field: 'FirstMiddleLastName', direction: 'Ascending' },
                    ]
                },
                searchResultsTableSelectedRow: null,
                searchResultsTablePageSettings: { pageSize: 50, pageSizes: [500, 300, 100, 50], pageCount: 4, direction: 'Ascending', columnName: 'FirstMiddleLastName'},
                selectedUsers: [],
                selectedUsersIDs: [],
                key: 0,
                toolbarSettings: {
                    enable: true,
                    enableFloating: true,
                    items: ['Bold', 'Italic', 'Underline', '|', 'Formats', 'Alignments', 'OrderedList', 'UnorderedList', '|', 'CreateLink', '|', 'SourceCode', 'Undo', 'Redo'],
                    itemConfigs: {}
                }
            }
        },
        computed: {
            unselectedSearchResultsTableItems() {
                let unselectedUsers = this.searchResultsTableItems.result.filter(u => !this.selectedUsersIDs.includes(u.UserID));
                return {
                    result: unselectedUsers,
                    count: unselectedUsers.length === 0 ? 0 : this.searchResultsTableItems.count
                };
            }
        },
        methods: {
            medicalLicenseChange(data) {
                this.updateText(data, 'MedicalLicenseIDs');
            },
            userRolesChange(data) {
                this.updateText(data, 'UserRoles');
            },
            statesChange(data) {
                this.updateText(data, 'States');
            },
            updateText(data, paramName) {
                setTimeout(() => {
                    let $el = document.querySelector('#app-' + paramName + ' .e-delim-view');
                    if(this.paramsDto[paramName].length > 0) {
                        let display = 'block';
                        if(!data) {
                            display = 'inline';
                        }
                        $el.style.display = display;
                        $el.innerHTML = this.paramsDto[paramName].length.toString() + ' selected';
                    } else {
                        $el.style.display = 'none';
                        $el.innerHTML = '';
                    }
                });
            },
            btnSendOnClick() {
                this.messageDto.UserIDs = this.selectedUsersIDs;
                endpoints.sendNotificationBroadcast(this.messageDto)
                    .then(response => {
                        DMUtils.alertShow('Your notifications have been sent.')
                    });
            },
            btnSendAllOnClick() {
                let numberOfUsers = this.unselectedSearchResultsTableItems.count + this.selectedUsersIDs.length;
                numberOfUsers = numberOfUsers.toLocaleString();
                DMUtils.confirmShow(
                    'Your notification will send to both Selected Users AND all users that match your search filters. Notifications will be sent to ' + numberOfUsers + ' users.',
                    'Are you sure?',
                    this.sendAllUsers
                )
            },
            sendAllUsers() {
                let messageDtoCopy = DMUtils.copy(this.messageDto);
                messageDtoCopy.UserIDs = this.selectedUsersIDs;
                Object.assign(messageDtoCopy, this.paramsDto);

                endpoints.sendAllNotificationBroadcast(messageDtoCopy)
                    .then(response => {
                        DMUtils.alertShow('Your notifications have been sent.')
                    });
            },
            searchResultsTableRowOnClick(args) {
                this.searchResultsTableSelectedRow = args;
            },
            selectedUsersTableRowOnClick(args) {
                this.selectedUsersTableSelectedRow = args;
            },
            searchUsers() {
                let params = DMUtils.copy(this.paramsDto);
                if(!params.OrganizationCategory) {
                    params.OrganizationCategory = '';
                }
                if(!params.Status) {
                    params.Status = '';
                }
                params.PageToLoad = this.$refs.searchResultsTable.ej2Instances.pagerModule.pagerObj.properties.currentPage;
                params.RowsPerPage = this.searchResultsTablePageSettings.pageSize;
                params.SortByColumn = this.searchResultsTablePageSettings.columnName;
                params.SortDescending = this.searchResultsTablePageSettings.direction === 'Descending';

                endpoints.getAllUsersForNotificationBroadcast(params)
                    .then(response => {
                        this.searchResultsTableItems = {
                            result: response.Rows,
                            count: response.NumberOfRows
                        };
                    });
            },
            getBroadcastTypes() {
                endpoints.getNotificationBroadcastTypes()
                    .then(response => {
                        this.broadcastTypeList = DMUtils.getCboOptions(response.BroadcastTypes);
                        setTimeout(() => {
                            this.messageDto.BroadcastType = this.broadcastTypeList[0].value;
                        });
                    });
            },
            getAllMedicalLicensesMyShyftAdmin() {
                endpoints.getAllMedicalLicensesMyShyftAdmin(this.paramsDto.state)
                    .then(response => {
                        this.licensesList = DMUtils.getCboOptions(response, 'MedicalLicenseID', 'MedicalLicenseName');
                    });
            },
            getUserAccountStatusCodes() {
                endpoints.getUserAccountStatusCodes()
                    .then(response => {
                        this.statusList = DMUtils.getCboOptions(response);
                    });
            },
            getAllOrganizationCategories() {
                endpoints.getAllOrganizationCategories()
                    .then(response => {
                        this.organizationCategoryList = response.Categories;
                    });
            },
            searchResultsTableDataStateChange(state) {
                if (state.action.requestType === 'sorting' && state.action.direction && state.action.columnName) {
                    this.searchResultsTablePageSettings.direction = state.action.direction;
                    this.searchResultsTablePageSettings.columnName = state.action.columnName;
                    this.searchUsers();
                }

                if (state.action.requestType === 'paging') {
                    this.searchResultsTablePageSettings.pageSize = state.take;
                    this.searchUsers();
                }
            },
            rowSelected(data) {
                if(data.isHeaderCheckboxClicked) {
                    return;
                    // data.data.forEach(u => {
                    //     u.selected = true;
                    //     this.selectedUsers.push(u);
                    // });
                } else {
                    this.selectedUsers.push({
                        UserID: data.data.UserID,
                        FirstMiddleLastName: data.data.FirstMiddleLastName
                    });
                }

                setTimeout(() => {
                    this.selectedUsersIDs = this.selectedUsers.map(u => u.UserID);
                    this.key++;
                });
            },
            userRemoved() {
                this.selectedUsers = this.selectedUsers.filter(u => this.selectedUsersIDs.includes(u.UserID));
            }
        },
        created() {
            this.getBroadcastTypes();
            this.getAllMedicalLicensesMyShyftAdmin();
            this.getUserAccountStatusCodes();
            this.getAllOrganizationCategories();

            this.searchResultsTableColumns = [
                {
                    key: 'UserID',
                    label: 'ID',
                    width: '80px'
                },
                {
                    key: 'FirstMiddleLastName',
                    label: "User's Name",
                },
                {
                    key: 'EmailAddress',
                    label: 'Email Address',
                    width: '200px'
                },
                {
                    key: 'PhoneNumberF',
                    label: 'Phone #',
                    width: '140px'
                },
                {
                    key: 'City',
                    label: 'City',
                    width: '100px'
                },
                {
                    key: 'State',
                    label: 'State',
                    width: '83px'
                },
                {
                    key: 'RoleF',
                    label: 'Role',
                },
                {
                    key: 'Status',
                    label: 'Status',
                    width: '100px'
                },
                {
                    key: 'OrganizationCategory',
                    label: 'Category',
                },
                {
                    key: 'MedicalLicenses',
                    label: 'Medical Licenses',
                    formatter(column, data) {
                        return data.MedicalLicenses.map(l => l.LicenseName).join('<br />');
                    },
                    allowSorting: false
                }
            ];

            this.$watch('paramsDto.state', (newVal) => {
                this.getAllMedicalLicensesMyShyftAdmin();
            });
        },
    }
</script>
